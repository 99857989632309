import * as React from 'react';

import { ImageMap } from '../../../../../../utils/countriesMap';
import { Country } from '../../../../../../types/graphql/Country';
import { Wrapper, BlockingOverlay, ContentWrapper, Content } from '../styles';

interface Props {
  countries: Array<Country>;
  onCountrySelection: (c: Country) => void;
}

const CountrySelectionStep: React.FC<Props> = ({ countries, onCountrySelection }) => (
  <Wrapper>
    <ContentWrapper>
      {Object.keys(ImageMap).map((k: string) => {
        const found = Boolean(countries.find(c => c === k));
        return (
          <Content
            onClick={() => found && onCountrySelection(k as Country)}
            disabled={!found}
            key={k}
          >
            {!found && <BlockingOverlay />}
            <img src={ImageMap[k]} />
          </Content>
        );
      })}
    </ContentWrapper>
  </Wrapper>
);

export { CountrySelectionStep };
