import * as React from 'react';
import { Dialog, Typography, Button } from '@freeda/react-components';

import { Dialogable } from '../../../types/Dialogable';
import { DeleteButtonWrapper } from './styles';

import DeletePlatformButton from '../../../containers/DeleteMediaButton2.0';
import { DeleteComponentButton } from '../../../containers/DeleteMediaFrameButton2.0';

import { DialogHeader } from '../../ui/DialogHeader';
import { Colors } from '@freeda/react-components/lib/theme';
import { ButtonWrapperDialog } from '../../ui/ButtonWrapperDialog';

type Props = Dialogable & {
  parentId: string;
  platformId: string;
  componentId: string | null;
  componentPositions: Array<{ id: string; position: number }>;
  shouldCloseAfterDelete: boolean;
  updateLocalizationsAfterDelete: () => void;
  updateComponentsAfterDelete: () => void;
};

const DeletePlatformDialog: React.FC<Props> = ({
  open,
  onClose,
  parentId,
  platformId,
  componentId,
  componentPositions,
  shouldCloseAfterDelete,
  updateLocalizationsAfterDelete,
  updateComponentsAfterDelete,
}) => {
 
  return (
    <Dialog
      onClose={onClose}
      openDialog={open}
      rootIdAttributes="root"
      style={{ width: 400, paddingLeft: 30 }}
    >
      <DialogHeader title="Delete  content" onClose={onClose} />
      <Typography variantName="text" textAlign="left" textColor={Colors.GRIGIO}>
        You are deleting the selected content, you will not be able to recover this item.
      </Typography>
      <ButtonWrapperDialog>
        <Button action="undo" onClick={onClose}>
          Don't do it
        </Button>
        <DeleteButtonWrapper>
          {!componentId ? (
            <DeletePlatformButton
              parentId={parentId}
              platformId={platformId}
              shouldCloseAfterDelete={shouldCloseAfterDelete}
              onCloseDialog={onClose}
              updateLocalizationsAfterDelete={updateLocalizationsAfterDelete}
            />
          ) : 
          (
            <DeleteComponentButton
              componentId={componentId}
              componentPositions={componentPositions!}
              onCloseDialog={onClose}
              updateComponentsAfterDelete={updateComponentsAfterDelete}

            />
          )}
        </DeleteButtonWrapper>
      </ButtonWrapperDialog>
    </Dialog>
  );
};

export { DeletePlatformDialog };
