import * as React from 'react';
import Media from 'react-media';
import { ActionButtonLink as Link } from '../../ui/RouterLink';

import { ActionButtonsWrapper, ActionIcon, StyledButtonIcon } from './styles';

interface Props {
  //isPinned: boolean;
  //onPin: () => void;
  //onUnpin: () => void;
  onForward: () => void;
  onDownload: () => void;
  editPageLocation?: string;
}

const ActionButtons: React.FC<Props> = ({
  //isPinned,
  //onPin,
  //onUnpin,
  onForward,
  onDownload,
  editPageLocation
}) => (
  <Media query={{ maxWidth: 768 }}>
    {(matches: boolean) => (
      <ActionButtonsWrapper>
        
        <StyledButtonIcon onClick={() => onForward()}>
          <ActionIcon iconName="reply" iconColor="#ffffff" />
        </StyledButtonIcon>
        <StyledButtonIcon onClick={async () => await onDownload()}>
          <ActionIcon iconName="download" iconColor="#ffffff" />
        </StyledButtonIcon>
        {!matches &&
          editPageLocation && (
            <Link to={editPageLocation}>
              <StyledButtonIcon>
                <ActionIcon iconName="edit" iconColor="#ffffff" />
              </StyledButtonIcon>
            </Link>
          )}
      </ActionButtonsWrapper>
    )}
  </Media>
);

export default ActionButtons;
