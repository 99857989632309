import * as React from 'react';
import { Mutation } from 'react-apollo';
import GenerateExternalLinkMutationResultT from '../../types/graphql/GenerateExternalLinkMutationResult';
import { GENERATE_EXTERNAL_LINK } from '../../apollo/mutation/mutation2.0/GenerateExternalLink';
import { CustomMutationProps } from '../../types/graphql/utils';
//import clientTobe from '../../apollo/clientTobe';

class TypedMutation extends Mutation<GenerateExternalLinkMutationResultT> {}

export const GenerateExternalLinkMutation: React.FC<
  CustomMutationProps<GenerateExternalLinkMutationResultT>
> = ({ children, ...others }) => {
  return (
    <TypedMutation /*client={clientTobe}*/ mutation={GENERATE_EXTERNAL_LINK} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
