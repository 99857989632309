import * as React from 'react';
import { Mutation } from 'react-apollo';
import { DELETE_PARENT_CONTENT} from '../../../apollo/mutation/mutation2.0/DeleteParentContent';

import { CustomMutationProps } from '../../../types/graphql/utils';

interface DeleteParentContentResult {
  deleteParentContent: {
    parentDeleted: boolean;
  };
}

class TypedMutation extends Mutation<DeleteParentContentResult> {}

export const DeleteParentMutation: React.FC<CustomMutationProps<DeleteParentContentResult>> = ({
  children,
  ...others
}) => {
  return (
    <TypedMutation mutation={DELETE_PARENT_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};

