import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dialog, Typography, Button } from '@freeda/react-components';
import { Dialogable } from '../../../types/Dialogable';
import { DialogHeader } from '../../ui/DialogHeader';
import { Colors } from '@freeda/react-components/lib/theme';
import { ButtonWrapperDialog } from '../../ui/ButtonWrapperDialog';

type Props = Dialogable & {
  onFileSelection: (newFile: File, clone:boolean) => void;
  fileIn : File
};

class CloneMediaDialog extends React.Component<Props & RouteComponentProps<{}>> {
  render() {
    let { open, onClose, onFileSelection, fileIn} = this.props;

   
    return (
      <Dialog
        onClose={onClose}
        openDialog={open}
        rootIdAttributes="root"
        style={{ width: 350, paddingLeft: 30 }}
      >
        <DialogHeader title="Clone content" onClose={onClose} />
        <Typography variantName="text" textAlign="left" textColor={Colors.GRIGIO}>
         Replace content on all platforms ?
        </Typography>
        <ButtonWrapperDialog>
          <Button action="undo" onClick={() => (onClose(), onFileSelection(fileIn, false))}>
           No
          </Button>

          <Button onClick={() => (onClose(), onFileSelection(fileIn, true))}>
           Yes
          </Button>
         
        </ButtonWrapperDialog>
      </Dialog>
    );
  }
}

const WithRouterCloneMediaDialog = withRouter<Props & RouteComponentProps<{}>, any>(CloneMediaDialog);

export { WithRouterCloneMediaDialog as CloneMediaDialog };
