import * as React from 'react';

const ShareMediaDialogContext = React.createContext({
  showDialog: false,
  mediaId: '',
  showCountryCutItem: true,
  idPlatformContent:'',
  onOpen: (mediaId: string, showCountryCutItem: boolean, idPlatformConten:string) =>
    console.warn('Please implement DialogContext.onOpen XXXXXX'),
  onClose: () => console.warn('Please implement DialogContext.onClose XXXXXXX')
});

class ShareMediaDialogProvider extends React.Component<
  {},
  { showDialog: boolean; mediaId: string; showCountryCutItem: boolean , idPlatformContent:string}
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showDialog: false,
      mediaId: '',
      showCountryCutItem: true,
      idPlatformContent:''
    };
  }

  onOpen = (mediaId: string, showCountryCutItem: boolean, idPlatformContent:string) =>
    this.setState({ showDialog: true, mediaId, showCountryCutItem, idPlatformContent });

  onClose = () => this.setState({ showDialog: false, mediaId: '', idPlatformContent:'' });

  render() {
    return (
      <ShareMediaDialogContext.Provider
        value={{ ...this.state, onOpen: this.onOpen, onClose: this.onClose }}
      >
        {this.props.children}
      </ShareMediaDialogContext.Provider>
    );
  }
}

export { ShareMediaDialogProvider, ShareMediaDialogContext };
