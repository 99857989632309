import * as React from 'react';
import { Mutation } from 'react-apollo';
import { CustomMutationProps } from '../../../types/graphql/utils';
import { DELETE_LABEL } from '../../../apollo/mutation/mutation2.0/DeleteLabel';

export interface DeleteLabelResult {
  deleteLabel: {
    deletedLabelId: string;
  };
}

class TypedMutation extends Mutation<DeleteLabelResult> {}

export const DeleteLabelMutation: React.FC<CustomMutationProps<DeleteLabelResult>> = ({
  children,
  ...others
}) => {
  console.log('DeleteLabelMutation')
  return (
    <TypedMutation mutation={DELETE_LABEL} {...others}>
      {(mutation, result) => {
        console.log(result)
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
