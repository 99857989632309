import * as React from 'react';
import { Query } from 'react-apollo';
import GetUsersQueryT from '../../../types/types2.0/graphql2.0/GetUsersQuery';
import { GET_USERS } from '../../../apollo/queries/queries2.0/GetUsers';
import { CustomQueryProps } from '../../../types/graphql/utils';
//import clientTobe from '../../../apollo/clientTobe';

class TypedQuery extends Query<GetUsersQueryT, {}> {}

export const GetUsersQuery: React.FC<CustomQueryProps<GetUsersQueryT>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={GET_USERS} {...others}>
      {result => {
        
        return children(result);
      }}
    </TypedQuery>
  );
};
