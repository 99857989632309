import * as React from 'react';

import { Wrapper, Content, ContentWrapper } from '../styles';
import UploadInput from '../../../../../../components/ui/UploadInput';
import { EditStep } from '../../../../../../pages/EditMediaPage2';

interface Props {
  onAddItem: (file: File) => void;
  goToFileSelection: () => void;
  changeEditStep: (editStep: EditStep) => void;
  mimeType: string;
}

const UploadCutStep: React.FC<Props> = ({
  onAddItem,
  changeEditStep,
  goToFileSelection,
  mimeType
}) => (
  <Wrapper>
    <ContentWrapper>
      <Content onClick={goToFileSelection}>
        <div>From Content Gallery</div>
      </Content>
      <Content>
        <div>From your computer</div>
        <UploadInput
          style={{ left: 0 }}
          accept={mimeType}
          type="file"
          onChange={e => {
            if (e.target.files) {
              const file = e.target.files[0];
              onAddItem(file);
              localStorage.setItem('merge', '')
              changeEditStep('EDIT');
            }
          }}
        />
      </Content>
    </ContentWrapper>
  </Wrapper>
);

export default UploadCutStep;
