import * as React from 'react';
import { CheckBox } from '@freeda/react-components';

import { DividerSection } from '../../../components/ui/DividerSection';
import { PreviewImageWrapper, PreviewImage } from './styles';

interface Props {
  imageUrl: Array<{ thumbnailUrl: string; storagePath: string }>;
  downloadImage: boolean;
  onChangeDownloadImage: () => void;
}

const ImageSection: React.FC<Props> = ({ imageUrl, downloadImage, onChangeDownloadImage }) => {
  return (
    <div style={{ marginBottom: 25 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <CheckBox
          checked={downloadImage}
          onClick={() => onChangeDownloadImage()}
          label="Image"
          style={{ marginRight: 15 }}
        />
        <DividerSection style={{ width: '100%' }} />
      </div>
      <PreviewImageWrapper>
        {imageUrl.map(img => (
          <PreviewImage key={img.storagePath} src={img.thumbnailUrl || img.storagePath} />
        ))}
      </PreviewImageWrapper>
    </div>
  );
};

export { ImageSection };
