import * as React from 'react';
import styled from 'styled-components';
import MediaCard from './MediaCard';
import Front from './MediaCard/Front2.0';
import Overlay from './MediaCard/Overlay2.0';
import { RouterLink as Link } from '../ui/RouterLink';
import { ContentCard as TypeContentCard } from '../../types/types2.0/ContentCard';
import { Country } from '../../types/graphql/Country';
import { User } from '../../types/types2.0/User';
import { ShareMediaDialogContext } from '../../providers/ShareDialogProvider2.0';
import { ParentContentSelection } from '../../types/types2.0/ParentContentSelection';
import { ParentContent } from '../../types/types2.0/ParentContent';
import { DownloadMediaDialogContext } from '../../providers/DownloadDialogProvider2.0';
//import { getUsers, getUsersFromArray } from '../../utils/getUsers';
import { NoMediaFound } from '../ui/NoMediaFound';
//import { flatMap } from 'lodash';
import { getItemTypeFromString } from '../../utils/contentUtils';
//import ReactDOM, * as ReactNode from 'react-dom';


const List = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 10px;
  justify-items: center;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  }
`;

interface Props {
  media: Array<TypeContentCard>;
  users: Array<User>;
  selectedContent: Array<ParentContentSelection>;
  hightlightNotCompleted?: boolean;
  onCardSelected: (parentContent: ParentContent) => void;
  loading: boolean;
  cleanList:boolean;
}

const MediaList: React.FC<Props> = ({
  media,
  users,
  selectedContent,
  hightlightNotCompleted,
  onCardSelected,
  loading,
  cleanList,
}) => {
  
  
  if (media.length === 0 && !loading) {
    return <NoMediaFound />;
  }

  const userCountry = localStorage.getItem('country') as Country;
  localStorage.setItem('merge', '');

  const getLocalizationCountry = (
    userDistributionCountry: string,
    mediaCountries: Array<Country>
  ): Country => {
    return mediaCountries.find(country => country === userDistributionCountry) || mediaCountries[0];
  };
  
  return (
    <List id='lista'>
      {media.map(m => {
        
        const localContent =
          m.localizations.find(t =>  t.distributionCountry === userCountry) || m.localizations[0];
        const platform = localContent.platforms[0] ;
        const notCompleted = Boolean(
          //flatMap(m.localizations.map(t => t.platforms)).find(i => !i.completed)
          !m.completed
        );

        //const members = getUsersFromArray(users, localContent.members).filter(u => u !== null);
        const members = localContent.members
        const isSelected = Boolean(selectedContent.find(selected => selected.id === m.id));

        return (
       
          <MediaCard
            //cleanList={cleanList}
            key={m.id}
            isSelected={isSelected}
            mediaId={m.id}
            notCompleted={Boolean(hightlightNotCompleted) && notCompleted}
            onSelection={() => onCardSelected(m)}
            renderMain={showOverlay => (
              <Front
                title={m.title}
                mimeType= {platform ? platform.mimeType : ''} 
                selected={isSelected}
                sourceUrl={platform ? platform.storagePath : ''}
                thumbnailUrl={platform ? platform.thumbnailUrl :''}
                teamMembers={members as Array<User>}
                videoLength={platform ? platform.videoLength : 0}
                showOverlay={showOverlay && !isSelected}
                onSelection={() => onCardSelected(m)}
              />
            )}
            renderOverlay={() =>
              !isSelected && (
                <ShareMediaDialogContext.Consumer>
                  {shareMediaDialogContext => (
                    
                    <DownloadMediaDialogContext.Consumer>
                      {downloadMediaDialogContext => (

                        <Overlay
                          contentId = {m.id}
                          title = {m.title}
                          localizations={m.localizations.map(t => ({
                            id: t.id,
                            distributionCountry: t.distributionCountry,
                            platforms: t.platforms.map(i => ({
                              url: i.storagePath,
                              creator:i.creator,
                              itemType: getItemTypeFromString(i.mimeType, i.itemType),
                              components: i.components!
                            }))
                          }))}
                          selected={isSelected}
                          //pinned={Boolean(pinnedMediaIds.find(id => m.id === id))}
                          //onPin={onPin}
                          //onUnpin={onUnpin}
                          onSelection={() => onCardSelected(m)}
                          onShare={rootId => shareMediaDialogContext.onOpen(rootId, true, '')}
                          onDownload={rootId => downloadMediaDialogContext.onOpen(rootId)}
                          
                          renderGalleryLink={children => (
                            <Link
                              to={`/gallery/${m.id}?country=${getLocalizationCountry(
                                localStorage.getItem('country')!,
                                m.localizations.map(t => t.distributionCountry)
                              )}`}
                            >
                              {children}
                            </Link>
                          )}
                        />
                      )}
                    </DownloadMediaDialogContext.Consumer>
                  )}
                </ShareMediaDialogContext.Consumer>
              )
            }
          />
       
        );
      })}
    </List>
  );
};

export default MediaList;
/*function handleChildUnmount() {
  throw new Error('Function not implemented.');
}*/

