import styled, { css } from 'styled-components';
import { ListItem } from '@freeda/react-components';

import { zIndex } from '../../../utils/zIndex';

const SidebarListItem = styled(ListItem)<{
  open?: boolean;
  backgroundHover?: string;
}>`
  && {
    margin-top: 5px;
    margin-bottom: 5px;
    transition: all 0.3s;
    padding-left: 18px;
    padding-right: 18px;
    background: ${props => props.theme.primary};
    &:hover {
      background: ${props => (props.backgroundHover ? props.backgroundHover : 'rgb(97, 32, 195)')};
    }
    ${props =>
      !props.open &&
      css`
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
      `};
  }
`;

const AppBar = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  background: #ffffff;
  z-index: ${zIndex.AppBar};
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  box-sizing: border-box;
  display: flex;

  @media (max-width: 768px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 6px 0px;
  }
`;

const ListWrapper = styled.div<{ isopen: string } & React.HTMLProps<HTMLDivElement>>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  height: 100%;
  @media (min-width: 769px) {
    background: ${props => (props.isopen === 'true' ? '#FFFFFF' : props.theme.primary)};
  }

  @media (max-width: 768px) {
    background: ${props => props.theme.primary};
  }
`;

const HelpLink = styled.a`
  text-decoration: none;
  li > span {
    color: rgba(0, 0, 0, 0.54);
  }
  div > svg {
    fill: rgba(0, 0, 0, 0.54);
  }

  &:hover {
    div > p {
      color: ${props => props.theme.rossino};
    }
    li > span {
      color: ${props => props.theme.rossino};
    }
  }
`;

const Span = styled.span`
  font-size: 18px;
  margin-left: 18px;
  color: rgba(255, 255, 255, 0.54);
  &:hover {
    color: #ffffff;
  }
`;

const FirstPart = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`;

const SecondPart = styled.div`
  height: calc(100% - 287px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 20px;
  & > ul {
    margin: 0px;
  }
  & > ul > a > li {
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  & > ul > li > span {
    color: rgba(0, 0, 0, 0.54);
  }
`;

const FirstPartMobile = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > button {
    margin: 0px;
  }
`;

const SubMenuIconTransition = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 18px;
  transition: top 0.3s ease-out;
  top: -60px;

  &.entering {
    top: -60px;
  }
  &.entered {
    top: 0px;
  }
  &.exiting {
    top: -60px;
  }
  &.exited {
    top: -60px;
  }
`;

export {
  AppBar,
  ListWrapper,
  HelpLink,
  Span,
  FirstPart,
  SecondPart,
  FirstPartMobile,
  SidebarListItem,
  SubMenuIconTransition
};
