import * as React from 'react';
import Media from 'react-media';

//http://help.freeda.tech/peggy/index.html

import {
  Icon,
  List,
  DrawerMini,
  Typography,
  ButtonIcon,
  DividerSection,
  Button,
} from '@freeda/react-components';
import { RouterNavLink as NavLink } from '../../ui/RouterLink';
import LogoutButton from '../../ui/LogoutButton';
import { FirstPart, HelpLink, SidebarListItem } from './styles';
//import { NotCompletedBadge } from '../../../containers/NotCompletedBadge';
import { NotCompletedBadge } from '../../../containers/NotCompletedBadge2.0';

import UploadInput from '../../../components/ui/UploadInput';
import { zIndex } from '../../../utils/zIndex';
import SidebarMobile from './SidebarMobile';
import { Colors } from '@freeda/react-components/lib/theme';
import MetadataIcon from '../../ui/MetadataIcon';
//import { CSSTransition } from 'react-transition-group';
import { SidebarContext } from '../../../providers/SidebarProvider';
import { MetadataType } from '../../../types/MetadataType';
import {Roles} from '../../../utils/roles'
import { FetchedItemsCounterContext } from '../../../providers/FetchedItemsCounterProvider';

interface Props {
  onUpload: Function;
}

const removeScrollTop = () => {
    localStorage.removeItem('scrollTop');
    
};

export const getLabelColor = (isClicked: boolean, mobileColor?: string) => {
  if (mobileColor) {
    return isClicked ? '#fff' : mobileColor;
  } else {
    return isClicked ? '#fff' : Colors.DARKpurple;
  }
};

const Sidebar: React.FC<Props> = ({ onUpload }) => {
  const { /*handleCreateMetadataDialog,*/ onChangeMetadataTable } = React.useContext(SidebarContext);

  const [open, setOpen] = React.useState(false);

  const isMediaGalleryClicked = window.location.pathname === '/';
  const isMyMediaClicked = window.location.pathname === '/my-media';
  //const isSavedClicked = window.location.pathname === '/my-pins';
  const isMetadataClicked = window.location.pathname.includes('/metadata');

  const roles:string = localStorage.getItem('roles')!;
  const disable:boolean = roles.includes(Roles['read_only'])



  return (
   
    <FetchedItemsCounterContext.Consumer>
      { fetchedItemsCounterContext => (
        <div>
          <Media query={{ maxWidth: 768 }}>
            {(matches: boolean) => {
              return !matches ? (
                <DrawerMini
                  style={{
                    position: 'fixed',
                    zIndex: zIndex.Sidebar,
                    top: 0,
                    flex: '1 0 auto',
                    left: 0,
                    right: 'auto',
                  }}
                >
                  <>
                    <FirstPart>
                      <SidebarListItem
                        backgroundHover={Colors.PURPLE}
                        open={open}
                        style={{ height: 80, margin: 0, marginTop: 25, userSelect: 'none' }}
                      >
                        <Typography variantName="title" style={{ fontSize: 30 }}>
                          {localStorage.getItem('emoji')!}
                        </Typography>
                      </SidebarListItem>
                      <SidebarListItem open={open} style={{ margin: 0, marginBottom: 10 }}>
                        <LogoutButton>
                          <ButtonIcon
                            iconName="logout"
                            iconColor={Colors.DARKpurple}
                            style={{ cursor: 'pointer', margin: 0, padding: 0 }}
                          />
                        </LogoutButton>
                      </SidebarListItem>
                      <DividerSection
                        dividerColor={Colors.WHITE}
                        style={{ width: 'calc(100% - 24px)' }}
                      />
                      <Button
                        action="submit"
                        style={{
                          whiteSpace: 'nowrap',
                          minWidth: '50px',
                          width: '50px',
                          padding: 0,
                          paddingLeft: 3,
                          marginTop: 10,
                          position: 'relative',
                        }}
                      >
                        <>
                          <Icon
                            iconName="upload"
                            iconColor="white"
                            style={{ marginRight: 5, cursor: 'pointer' }}
                          />
                          <UploadInput
                            disabled={disable}
                            style={{ height: 40, left: 0, top: 0 }}
                            id="file-upload"
                            type="file"
                            onChange={(e) => {
                              onUpload(e.target.files);
                            }}
                          />
                        </>
                      </Button>
                    </FirstPart>
                    <List>
                      <NavLink isopen={String(open)} to="/" exact={true}>
                        <SidebarListItem onClick={removeScrollTop} onMouseDown={() =>  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")() }>
                          <Icon
                            iconName="media"
                            style={{ cursor: 'pointer' }}
                            iconColor={getLabelColor(isMediaGalleryClicked)}
                          />
                        </SidebarListItem>
                      </NavLink>
                      <NavLink isopen={String(open)} to="/my-media">
                        <SidebarListItem onClick={removeScrollTop} onMouseDown={() =>  fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")() } style={{ height: 48 }}>
                          <NotCompletedBadge iconColor={getLabelColor(isMyMediaClicked)} />
                        </SidebarListItem>
                      </NavLink>
                  
                      <NavLink isopen={String(open)} to="/metadata/brands">
                        <SidebarListItem
                          onClick={() => {
                            removeScrollTop();
                            onChangeMetadataTable(MetadataType.BRANDS);
                          }}
                        >
                          <MetadataIcon color={getLabelColor(isMetadataClicked)} />
                        </SidebarListItem>
                      </NavLink>
                      
                      <HelpLink href="https://help.freeda.tech/peggy/index.html" target="_blank"> 
                        <SidebarListItem>
                          <Icon
                            iconName="help"
                            style={{ cursor: 'pointer' }}
                            iconColor={Colors.DARKpurple}
                          />
                        </SidebarListItem>
                      </HelpLink>
                    </List>
                  </>
                </DrawerMini>
              ) : (
                <SidebarMobile
                  open={open}
                  onOpen={() => setOpen(true)}
                  changeOpenSidebar={(isOpen) => setOpen(isOpen)}
                  emoji={localStorage.getItem('emoji')!}
                  removeScrollTop={removeScrollTop}
                />
              );
            }}
          </Media>
        </div>
    )}
    </FetchedItemsCounterContext.Consumer>
   
  
  
  
  );
};

export default Sidebar;
