import styled from 'styled-components';
import { Button, ButtonIcon } from '@freeda/react-components';
import { Button as ButtonP } from '@freeda/react-components/lib/Button';
import { ButtonIcon as ButtonIconP } from '@freeda/react-components/lib/ButtonIcon';
import { Colors } from '@freeda/react-components/lib/theme';

const Avatar = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: #e9e9e9;
`;

const PlaceholderHr = styled.hr`
  height: 1px;
  border: none;
  flex-shrink: 0;
  flex-basis: 100%;
  background: #ffffff;
  margin: 18px 0px;
`;

const BigText = styled.div`
  background: #eeeeee;
  width: 165px;
  height: 10px;
  border-radius: 10px;
`;

const SmallText = styled.div`
  background: #eeeeee;
  width: 60px;
  height: 4px;
  border-radius: 4px;
`;

const MediaPlaceholder = styled.img`
  justify-self: center;
  align-self: center;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  padding: 20px 0px;
`;

const ButtonStyled = styled(Button)<ButtonP.Props>`
  && {
    color: ${props => (props.disabled ? `${Colors.GRIGINOtransparentLight}` : 'rgba(0,0,0,0.87)')};
    margin: 0;
    background-color: transparent;
    & > i {
      color: ${props =>
        props.disabled ? `${Colors.GRIGINOtransparentLight}` : 'rgba(0,0,0,0.87)'};
    }
  }
`;

const ButtonIconStyled = styled(ButtonIcon)<ButtonIconP.Props>`
  && {
    & > span {
      color: ${props =>
        props.disabled ? `${Colors.GRIGINOtransparentLight}` : 'rgba(0,0,0,0.87)'};
    }
    @media (max-width: 768px) {
      & > span {
        font-size: 40px;
      }
    }
  }
`;

export {
  Avatar,
  PlaceholderHr,
  BigText,
  SmallText,
  MediaPlaceholder,
  ButtonStyled,
  ButtonIconStyled
};
