import * as React from 'react';
import { parse } from 'query-string';

import { Dialogable } from '../types/Dialogable';
import DownloadMediaDialog from '../components/layout/DownloadMediaDialog2.0';
import { ParentContentQuery } from '../components/queries/queries2.0/ParentContentQuery';
import { Spinnerone } from '../components/ui/Spinnerone';
import { Country } from '../types/graphql/Country';

export const DownloadMediaDialogContainer: React.FC<Dialogable & { contentId: string }> = ({
  open,
  onClose,
  contentId
}) => {
  return (
    <ParentContentQuery variables={{ id: contentId }}>
      {mediaQueryResult => {
      
        //alert('DownloadMediaDialogContainer')

       const queryParams = parse(window.location.search);

        if (mediaQueryResult.error) {
          Raven.captureException(mediaQueryResult.error);
          return 'Error...';
        }

        if (
          mediaQueryResult.loading ||
          !mediaQueryResult.data ||
          Object.keys(mediaQueryResult.data).length === 0
        ) {
          return <Spinnerone />;
        }

        const content = mediaQueryResult.data!.getParentContent;

        const userCountry = localStorage.getItem('country') as Country;

        const selectedLocalization =
          content.localizations.find(t => t.distributionCountry === userCountry) ||
          content.localizations[0];

        const localization =
          (content && content.localizations.find(t => t.distributionCountry === queryParams.country)) ||
          selectedLocalization;

        const platform =
          (content && localization && localization.platforms.find(i => i.itemType === queryParams.cut)) ||
          localization.platforms[0];

        return (
          <DownloadMediaDialog
            open={open}
            mediaMimeType={platform.mimeType}
            onClose={onClose}
            contentId={content!.id}
            content={content}
            countries={content.localizations.map(t => t.distributionCountry)}
          />
        );
      }}
    </ParentContentQuery>
  );
};
