import gql from 'graphql-tag';
const PARENT_CONTENT = gql`
  fragment parentContent on ParentContent {
      id
      title
      localizations {
        id
        parent {
          id
        }
        distributionCountry
        creator
        members {
          id
          name
        }
        noSubtitle
        subtitles
        languageCopy
        languageSubtitle
        creator
        createdAt
        updatedAt
        updatedBy
        platforms {
          id
          parent{
            id
          }
          localization{
            id
          }
          components {
            id
            parent{
              id
            }
            localization{
              id
            }
            platform{
              id
            }
            position
            storagePath
            mimeType
            thumbnailUrl
            videoLength
            creator
            createdAt
            updatedBy
            updatedAt
          }
        }
      }
    }
  
`;

export { PARENT_CONTENT };
