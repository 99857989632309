import * as React from 'react';

import { Wrapper, SingleMediaList } from './styles';
import { MediaBox } from './MediaBox';
import { ParentContent } from '../../../../../../types/types2.0/ParentContent';

interface Props {
  selected: string | null;
  mediaList: Array<ParentContent>;
  onSelect: (media: ParentContent) => void;
}

const MediaToMergeList: React.FC<Props> = ({ selected, mediaList, onSelect }) => {
  const parentWithOneLocalization = mediaList.filter(parentContent => parentContent.localizations.length === 1);

  if (parentWithOneLocalization.length === 0) {
    return (
      <div
        style={{
          width: '100%',
          height: 320,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <span style={{ textAlign: 'center' }}>No media found :(</span>
      </div>
    );
  }

  return (
    <Wrapper>
      {parentWithOneLocalization.map(parentContent => {
        const item = parentContent.localizations[0].platforms[0];

        return (
          <SingleMediaList key={parentContent.id} onClick={() => onSelect(parentContent)}>
            <MediaBox
              previewUrl={{ storagePath: item.storagePath, thumbnailUrl: item.thumbnailUrl }}
              title={parentContent.title}
              mimeType={item.mimeType}
              selected={parentContent.id === selected}
            />
          </SingleMediaList>
        );
      })}
    </Wrapper>
  );
};

export { MediaToMergeList };
