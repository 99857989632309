import * as React from 'react';
import Media from 'react-media';
import { padStart } from 'lodash';
import { Typography, CheckBox } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { User } from '../../../types/types2.0/User';


import {
  AvatarWrapper,
  AuthorAvatar,
  Content,
  ImagePreview,
  VideoPreview,
  TitleWrapper,
  SelectedHoverWrapper,
  VideoLengthWrapper
} from './styles';


interface Props {
  title: string;
  teamMembers: Array<User>;
  videoLength: number | null;
  mimeType: string;
  selected: boolean;
  sourceUrl: string;
  thumbnailUrl: string | null;
  showOverlay: boolean;
  onSelection: Function;
}

const formatVideoLength = (videoLength: number|null) => {
  if (videoLength) {
    return `${padStart(String(Math.floor(videoLength / 60)), 2, '0')}:${padStart(
      String(videoLength % 60),
      2,
      '0'
    )}`;
  }

  return null;
};

const Front: React.FC<Props> = ({
  title,
  teamMembers,
  videoLength,
  mimeType,
  selected,
  sourceUrl,
  thumbnailUrl,
  showOverlay,
  onSelection
}) => {
  const videoProps = thumbnailUrl ? { poster: thumbnailUrl } : { src: sourceUrl };
  //console.log(thumbnailUrl)
  return selected ? (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <SelectedHoverWrapper>
        <CheckBox
          checked={selected}
          checkedColor={Colors.ROSSINO}
          onClick={() => onSelection()}
          bold
          colorBox="#ffffff"
          style={{ position: 'absolute', top: 15, left: 15, zIndex: 2000 }}
        />
      </SelectedHoverWrapper>
      {mimeType.indexOf('image') >= 0 ? (
        <ImagePreview src={thumbnailUrl || sourceUrl} />
      ) : (
        <VideoPreview {...videoProps}>
          <source src={thumbnailUrl || sourceUrl} type={mimeType} />
        </VideoPreview>
      )}
    </div>
  ) : (
    <>
      <CheckBox
        checked={selected}
        checkedColor={Colors.ROSSINO}
        onClick={() => onSelection()}
        bold
        colorBox="#000000"
        style={{ position: 'absolute', top: 15, left: 15, zIndex: 200 }}
      />
      {mimeType.indexOf('image') >= 0 ? (
        <ImagePreview src={thumbnailUrl || sourceUrl} />
      ) : (
        <VideoPreview {...videoProps}>
          <source src={thumbnailUrl || sourceUrl} type={mimeType} />
        </VideoPreview>
      )}
      {!showOverlay &&
        !selected && (
          <Media query={{ minWidth: 769 }}>
            {(matches: boolean) => {
              return (
                matches && (
                  <Content>
                    <TitleWrapper>
                      <Typography variantName="card-title">
                        {`${title ? title.substring(0, 22):''}${(title && title.length >= 22) ? '...' : ''}`}
                      </Typography>
                    </TitleWrapper>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 10,
                        minHeight: 30
                      }}
                    >
                      {teamMembers.length > 0 && (
                        <AvatarWrapper>
                          {teamMembers.slice(0, 4).map(member => (
                            
                            <AuthorAvatar
                              name={member.name}
                              key={member.id}
                              size={30}
                            />
                          ))}
                          {teamMembers.length > 4 && (
                            <AuthorAvatar name={`+${teamMembers.length - 4}`} size={30} />
                          )}
                        </AvatarWrapper>
                      )}
                      <VideoLengthWrapper>{formatVideoLength(videoLength)}</VideoLengthWrapper>
                    </div>
                  </Content>
                )
              );
            }}
          </Media>
        )}
    </>
  );
};

export default Front;
