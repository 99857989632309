import gql from 'graphql-tag';
//import { MEDIA_ROOT } from '../../apollo/queries/MediaRootFragment';

const MERGE_CONTENT = gql`
  mutation mergeContent(
    $mainContent: ID!
    $subContent: ID!
    $distributionCountry: CountryEnumDao!
  ) {
    mergeContent(
      mainContent: $mainContent
      subContent: $subContent
      distributionCountry: $distributionCountry
    ) {
      id
    }
  }
  
`;

export { MERGE_CONTENT };
