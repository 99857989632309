import { User } from '../types/types2.0/User';

const getUsers = (
  users: Array<User> | null,
  usersId?: string | null
): Array<User | null> => {

  //alert('getUsers')
  if (!usersId || !users) {
    return [];
  }

  return usersId.split(',').map(id => {
    const foundUser = users.find(user => user.name === id);
    if (!foundUser) {
      return null;
    }
    return foundUser;
  });
};


const getUsersFromArray = (
  users: Array<User> | null,
  usersId: Array<User> | null
): Array<User|null>  => {

 // alert('getUsersFromArray')
  if (!usersId || !users) {
    return [];
  }

   usersId.forEach( el =>{
    let foundUser = users.find(user => user.name === el.name); 
    if (!foundUser) {
      return [];
    }
    return foundUser;

  });
  return []


};

export { getUsers, getUsersFromArray };
