import gql from 'graphql-tag';

const GENERATE_EXTERNAL_LINK = gql`
  mutation generateExternalLink($platformId: ID!) {
    result: generateExternalLink(platformId: $platformId) {
      externalId
      expiryDate
    }
  }
`;

export { GENERATE_EXTERNAL_LINK };
