import * as React from 'react';
import { Dialog, Typography, Button } from '@freeda/react-components';
import { Dialogable } from '../../../types/Dialogable';
import { MetadataType } from '../../../types/MetadataType';
import { DialogHeader } from '../../ui/DialogHeader';
import { ErrorWrapper } from './styles';
import { useMediaQuery } from 'react-responsive';
import { CreateMetadata } from '../../mutations/mutations2.0/CreateMetadata';
import { FeedbackContext } from '../../../providers/FeedbackProvider';
import { getFilterContext, onCreateMetadata } from '../../../utils/metadataUtils2.0';
import { CSSTransition } from 'react-transition-group';
import { useKeyUpEsc } from '../../../hooks/useKeyUpEsc';
import { buildCreationSectionByMetadata } from './buildCreationSectionByMetadata';
import { ButtonWrapperDialog } from '../../ui/ButtonWrapperDialog';

type Props = Dialogable & {
  metadataType: MetadataType;
  onOpenSnackbarReload: () => void;
};

const CreateMetadataDialog: React.FC<Props> = ({
  open,
  onClose,
  metadataType,
  onOpenSnackbarReload,
}) => {
  useKeyUpEsc(onClose);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  //const isBrand = metadataType === MetadataType.BRANDS;
  const isTopic = metadataType === MetadataType.TOPICS;

  const metadataName = String(metadataType);
  const nameMetadataKey =  'name' ;

  const {
    searchValue,
    selectedBrandCategoryName: brandCategoryNameFilter,
    selectedMacroTopicName: macroTopicNameFilter,
    selectedBrand: brandNameFilter,

    order,
    orderBy,
  } = React.useContext(getFilterContext(metadataType));

  const [brandCategoryName, setbrandCategoryName] = React.useState<string | null>(null);

  const [macroTopicName, setmacroTopicName] = React.useState<string | null>(null);

  const [brandName, setBrandName ]= React.useState<string | null>(null);

  const [projectIds, setprojectIds] = React.useState<Array<string>>([]);
  const [metadataLabel, setMetadataLabel] = React.useState<string>('');
  const [availableMetadataLabel, setAvailableMetadataLabel] = React.useState<boolean>(true);

  let buttonDisabled = metadataLabel.length === 0;

  if(isTopic){
    buttonDisabled = macroTopicName ? false : true
  }





  const dialogMobileStyle: React.CSSProperties = isMobile
    ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }
    : {};

  return (
    <FeedbackContext.Consumer>
      {({ onOpen }) => (
        <CreateMetadata metadataType={metadataType}>
          {(createMetadata, createResult) => {
            return (
              <Dialog
                style={{
                  width: isMobile ? '100%' : 400,
                  height: isMobile ? '100%' : 'auto',
                  ...dialogMobileStyle,
                }}
                openDialog={open}
                onClose={onClose}
                rootIdAttributes="root"
                disableEscapeKeyUp
              >
                <div>
                  <DialogHeader title={`Add new ${metadataName}`} onClose={onClose} />
                  {buildCreationSectionByMetadata(
                    metadataType,
                    metadataLabel,
                    setMetadataLabel,
                    setAvailableMetadataLabel,

                    brandCategoryName,
                    setbrandCategoryName,

                    macroTopicName,
                    setmacroTopicName,

                    brandName,
                    setBrandName,

                    projectIds,
                    setprojectIds
                  )}
                  <CSSTransition in={!availableMetadataLabel} timeout={0} classNames="">
                    {(status) => (
                      <ErrorWrapper className={status}>
                        <Typography variantName="error">
                          <>This {nameMetadataKey} isn't available, it's already exists.</>
                        </Typography>
                        <Typography
                          variantName="text"
                          style={{ top: -2, position: 'relative', fontSize: 16 }}
                        >
                          &nbsp; 🧐
                        </Typography>
                      </ErrorWrapper>
                    )}
                  </CSSTransition>
                </div>
                <ButtonWrapperDialog>
                  <Button action="undo" onClick={onClose} style={{ width: isMobile ? '100%' : '' }}>
                    Cancel
                  </Button>
                  <Button
                    loading={createResult.loading}
                    disabled={buttonDisabled}
                    style={{ width: isMobile ? '100%' : '' }}
                    onClick={async () => {
                      await onCreateMetadata(
                        createMetadata,
                        metadataType,
                        brandCategoryName,
                        brandCategoryNameFilter,
                        macroTopicName,
                        macroTopicNameFilter,
                        brandName,
                        brandNameFilter,
                        projectIds,
                        projectIds,
                        metadataLabel,
                        setAvailableMetadataLabel,
                        searchValue,
                        order,
                        orderBy,
                        onOpen,
                        onOpenSnackbarReload,
                        onClose
                      );
                    }}
                  >
                    Create
                  </Button>
                </ButtonWrapperDialog>
              </Dialog>
            );
          }}
        </CreateMetadata>
      )}
    </FeedbackContext.Consumer>
  );
};

export { CreateMetadataDialog };
