import * as React from 'react';
import { Mutation } from 'react-apollo';
import { UPDATE_PLATFORM_CONTENT } from '../../../apollo/mutation/mutation2.0/UpdatePlatformContent';
import { PlatformContent } from '../../../types/types2.0/PlatformContent';
import { CustomMutationProps } from '../../../types/graphql/utils';

class TypedMutation extends Mutation<{ updatePlatformContent: PlatformContent }> {}

export const UpdatePlatformContentMutation: React.FC<
  CustomMutationProps<{ updatePlatformContent: PlatformContent }>
> = ({ children, ...others }) => {
  return (
    <TypedMutation mutation={UPDATE_PLATFORM_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
