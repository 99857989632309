import * as React from 'react';
import { Query } from 'react-apollo';
import FilterQueryT from '../../../types/types2.0/graphql2.0/FilterQuery';
import { FILTER_DATA } from '../../../apollo/queries/queries2.0/FilterData';
import { CustomQueryProps } from '../../../types/graphql/utils';

class TypedQuery extends Query<FilterQueryT, {}> {}

export const FilterQuery: React.FC<CustomQueryProps<FilterQueryT>> = ({ children, ...others }) => {
  return (
    <TypedQuery query={FILTER_DATA} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
