import * as React from 'react';
import styled from 'styled-components';
import Media from 'react-media';

import { Monogramma } from '../components/ui/Monogramma';
import { Button, Typography, Input } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const artGallery = require('../assets/art_gallery.jpg');
const freedaLogo = require('../assets/freeda_logo.png');
const peggy = require('../assets/peggy.png');

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #6b2ed6;
`;

const WrapperLoginBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #ffffff;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

const ArtGallery = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("${artGallery}") repeat-x;
  background-size: cover;
  animation-duration: 40000s;
  animation-name: move;
  animation-timing-function: linear;

  @keyframes move {
    from {
      background-position: 0;
    }

    to {
      background-position: 300000%;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: -webkit-center;
  z-index: 5;
`;

const Info = styled.div`
  font-size: 15px;
  color: ${Colors.GRIGINO};
  margin-top: 20px;
  margin-bottom: 30px;
`;

const FreedaLogo = styled.img`
  position: absolute;
  top: 51px;
  width: 176px;
  z-index: 100;

  @media (max-width: 768px) {
    width: 230px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ErrorWrapper = styled.div`
  margin-right: 15px;
  opacity: 0;
  transition: opacity 0.4s;
  &.wrapper-entering {
    opacity: 0;
  }
  &.wrapper-entered {
    opacity: 1;
  }
  &.wrapper-exiting {
    opacity: 0;
  }
  &.wrapper-exited {
    opacity: 0;
  }
`;

const LoginPage: React.FC<{ login: Function }> = ({ login }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [error, setError] = React.useState<boolean>(false);
  const history = useHistory();

  return (
    <PageWrapper>
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (matches ? <Monogramma /> : <FreedaLogo src={freedaLogo} />)}
      </Media>
      <Wrapper>
        <ArtGallery />
        <WrapperLoginBox>
          <img style={{ position: 'absolute', top: -55, width: 110, zIndex: 1 }} src={peggy} />
          <LoginBox
            onSubmit={async e => {
              e.preventDefault();
              setLoading(true);
              const result = await login(username, password);
              setLoading(false);

              if (result.result === 'OK') {
                history.push('/');
              } else {
                setError(true);
              }
            }}
          >
            <Typography variantName="title">Welcome!</Typography>
            <Info>
              "Peggy" is the new Freeda's tool designed to organize and catalogue all Freeda's
              contents (Illustrations, Photos, Videos)
            </Info>
            <Input
              style={{ marginBottom: 35 }}
              type="text"
              placeholder="Username"
              value={username}
              onChange={e => {
                setUsername(e.target.value);
                setError(false);
              }}
            />
            <Input
              style={{ marginBottom: 35 }}
              type="password"
              placeholder="Password"
              value={password}
              onChange={e => {
                setPassword(e.target.value);
                setError(false);
              }}
            />

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <CSSTransition in={error} timeout={{ enter: 300, exit: 500 }} classNames="">
                {status => (
                  <ErrorWrapper className={`wrapper-${status}`}>
                    <Typography variantName="error">Oops, wrong username or password!</Typography>
                  </ErrorWrapper>
                )}
              </CSSTransition>
              <Button loading={loading} type="submit">
                Sign in
              </Button>
            </div>
          </LoginBox>
        </WrapperLoginBox>
      </Wrapper>
    </PageWrapper>
  );
};

export default LoginPage;
