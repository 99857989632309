import * as React from 'react';
import Media from 'react-media';
import { FilterQuery } from '../components/queries/queries2.0/FilterQuery';
import { FilterContext } from '../providers/FilterProvider2.0';
import Filter from '../components/layout/Filter2.0';
import { PlaceholderFilter } from '../components/ui/Placeholders/PlaceholderFilter';
import { PlaceholderFilterMobile } from '../components/ui/PlaceholdersMobile/PlaceholderFilterMobile';
import { Topic } from '../types/types2.0/Topic';
import { uniqBy } from 'lodash';

//import {filterData} from '../apollo/tests/filterData'



const FilterContainer: React.FC = () => {
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <FilterContext.Consumer>
          {({ filterValue, callbacks }) => {
            const { selectedCategoryId } = filterValue;
            const { getBrands } = callbacks;

            return (
              <FilterQuery fetchPolicy="cache-first">
                {filterQueryResult => {
                  //QUI
                  if (filterQueryResult.loading) {
                    if (!matches) {
                      return <PlaceholderFilter />;
                    } else {
                      return <PlaceholderFilterMobile />;
                    }
                  }
                  
                  if (filterQueryResult.error) {
                    console.log('ERRRORE FILTRI')
                    return `Error in loading filters : ${filterQueryResult.error.message}`;
                    
                  }
                  
                  if (filterQueryResult.data && Object.keys(filterQueryResult.data).length === 0) {
                    return null;
                  }

                  ///////QUI////
                

                  const {
                    brandCategories,
                    topics,
                    productTypes,
                    teamMembers,
                    contributors,
                    projects,
                    temperatures,
                    genres,
                    objectives

                  } = filterQueryResult.data!['filterData'];

                  /*const {
                    brandCategories,
                    topics,
                    productTypes,
                    teamMembers,
                    contributors,
                    projects,
                    temperatures,
                    genres,
                    objectives,
                  } = filterData*/



                  
                  //qui macrotopic
                  let macroTopics = topics
                    ? topics.map((t:Topic) => ({ name: t.macroTopic!, topics:[] }))
                    : []; 
                  
                  macroTopics = uniqBy(macroTopics, 'name');
                  
                  return (
                    <Filter
                      brandCategories={brandCategories}
                      brands={getBrands(brandCategories, selectedCategoryId)}
                      //series={series}
                      macroTopics={macroTopics}
                      topics={topics}
                      productTypes={productTypes}
                      teamMembers={teamMembers}
                      contributors={contributors}
                      projects={projects}
                      temperatures={temperatures}
                      objectives={objectives}
                      genres={genres}
                      //campaigns={campaigns}
                      //editorialCampaigns={editorialCampaigns}
                      //products={products}

                    />
                  );
                }}
              </FilterQuery>
            );
          }}
        </FilterContext.Consumer>
      )}
    </Media>
  );
};

export { FilterContainer };
