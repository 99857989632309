import * as React from 'react';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';
import { Button } from '@freeda/react-components';

import { FeedbackContext } from '../providers/FeedbackProvider';
import { RefreshSpan } from '../components/RefreshSpan';
import { ItemType } from '../types/ItemType';
//import { MergeMediaItemMutation } from '../components/mutations/MergeMediaItemMutation';
import { MergePlatformContentMutation } from '../components/mutations/mutations2.0/MergePlatformContentMutation';
import { Country } from '../types/graphql/Country';
import { ParentContent } from '../types/types2.0/ParentContent';
//import { MassiveUpdateItemTypes } from '../components/mutations/MassiveUpdateItemTypes';
import { MediaItemTypes } from '../types/MediaItemTypes';
//import { GET_PARENT_CONTENT } from '../apollo/queries/queries2.0/GetParentContent';

interface Props {
  selected: ParentContent | null; //platform
  toExclude: string; //
  searchValue: string;
  distributionCountry: Country | null;
  selectedItemType: ItemType | null;
  mediaItemTypes: MediaItemTypes;
  onAfterMerge: (newRoot: ParentContent, newItemType: ItemType) => void;
}

const MergeCutButton: React.FC<Props> = ({
  selected,
  toExclude,
  selectedItemType,
  distributionCountry,
  mediaItemTypes,
  onAfterMerge
}) => (

  <FeedbackContext.Consumer>
    {({ onOpen }) => (
     
          <MergePlatformContentMutation>
            {(mergePlatformContent, mergePlatformContentResult) => (
              <Button
                disabled = {
                  selected === null || mergePlatformContentResult.loading
                }
                loading = {mergePlatformContentResult.loading}
                onClick = {async () => {
                  try {
                    
                    let arrCut = selectedItemType?.split('_')

                   /* let result = await mergePlatformContent({
                      variables: {
                        mainContent:  toExclude ,
                        subContent: selected!.id ,
                        distributionCountry,
                        selectedItemType: arrCut ? arrCut[1] : selectedItemType
                      }
                    })

                    
                    if(result){
                      //onAfterMerge(result.data!.mergePlatformContent, selectedItemType!);
                      localStorage.setItem('merge', 'merge')
                      onOpen('Content merged', 'notification');

                      setTimeout(function () {
                        window.location.reload()
                       }, 2000);
                    }
                    else{
                      
                      onOpen(<RefreshSpan>Refresh</RefreshSpan>, 'error');
                      setTimeout(function () {
                        window.location.reload()
                       }, 2000);

                    }*/

                    await mergePlatformContent({
                      variables: {
                        mainContent:  toExclude ,
                        subContent: selected!.id ,
                        distributionCountry,
                        selectedItemType: arrCut ? arrCut[1] : selectedItemType
                      },
                      update: (
                        cache: DataProxy,
                        result: FetchResult<{ mergePlatformContent: ParentContent }>
                      ) => {
                        localStorage.setItem('merge', 'merge')
                        window.location.reload()
                        //onOpen('Content merged', 'notification');
                       
                       /* setTimeout(function () {
                          window.location.reload()
                         }, 6000);*/

                        /*if(result.data!){
                          const query = GET_PARENT_CONTENT;
                          const q = {
                            query,
                            variables:{'id':toExclude},
                          };
            
                          cache.writeQuery({
                            ...q,
                            data: result.data!.mergePlatformContent,
                          });
                          localStorage.setItem('merge', 'merge')
                        }
                        onAfterMerge(result.data!.mergePlatformContent, selectedItemType!);*/
                      }
                    });
                  } catch (err) {
                    Raven.captureException(err);
                    onOpen(<RefreshSpan>{err instanceof Error ? err.message : "Errore"}</RefreshSpan>, 'error');
                  }
                }}
              >
                Merge content
              </Button>
            )}
          </MergePlatformContentMutation>
    )}
  </FeedbackContext.Consumer>
);

export default MergeCutButton;
