import * as React from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Icon } from '@freeda/react-components';

import { zIndex } from '../../utils/zIndex';
const freedaLogo = require('../../assets/freeda_logo.png');

const HeaderWrapper = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 65px;
  display: flex;
  align-items: center;
  color: rgb(107, 32, 218);
  font-size: 14px;
  font-weight: 400;
`;

const FreedaLogo = styled.img`
  position: relative;
  width: 110px;
  right: 6px;
  z-index: ${zIndex.FreedaLogo};
`;

const TechQuote = styled.div`
  font-size: 12px;
  color: #000000;
  margin-left: auto;
  position: relative;
  right: 15px;
`;

const HeartIcon = styled(Icon)`
  && {
    position: relative;
    top: 2px;
  }
`;

interface Props {
  backElement?: ReactNode;
  style?: React.CSSProperties;
}

const HeaderEditMedia: React.FC<Props> = ({ backElement, style }) => {
  return (
    <HeaderWrapper style={style}>
      {backElement}
      <TechQuote>
        Designed with <HeartIcon iconName="favorite" iconSize="xsmall" iconColor="#FF0A43" /> by
        Dati Team
      </TechQuote>
      <FreedaLogo src={freedaLogo} />
    </HeaderWrapper>
  );
};

export { HeaderEditMedia };
