import * as React from 'react';
import Media from 'react-media';

import { Dialogable } from '../../../types/Dialogable';
import { Country } from '../../../types/graphql/Country';
import { ItemType } from '../../../types/ItemType';
import {
  //getFirstAvailablePlatformContentType,
  getLocalContent,
  //getPlatformContent
} from '../../../utils/mediaUtils2.0';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { CountrySelection } from '../../../components/ui/CountrySelection';
//import { CutType } from '../../../types/CutType';
//import { PlatformType } from '../../../types/types2.0/PlatformType';
//import { DescriptionCutsMap } from '../../../utils/videoCutsMap';
import { DownloadFramesSelection } from '../../../components/ui/DownloadFramesSelection2.0';
import { download } from '../../../utils/downloadMedia';
import { Typography, ButtonIcon, Icon, Button, Dialog } from '@freeda/react-components';
import { VideoCutSelection } from '../../../components/ui/VideoCutSelection2';
import { getItemTypesFromString } from '../../../utils/contentUtils';
import {
  getPlatformContent,
  getFirstAvailablePlatformContentType2
} from '../../../utils/contentUtils';
import { Format } from '../../../types/types2.0/Format';

type Props = Dialogable & {
  contentId: string;
  mediaMimeType: string;
  fullScreen?: boolean;
  content: ParentContent;
  countries: Array<Country>;
};

interface State {
  selectedTranslationCountry: Country;
  platformId:string| null;
  selectedItemType: ItemType;
  selectedComponentIds: Array<string>;
}

class DownloadMediaDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const userCountry = localStorage.getItem('country') as Country;

    const localContent =
      props.content.localizations.find(t => t.distributionCountry === userCountry) ||
      props.content.localizations[0];

    /*QUI
    const mediaItemType = getFirstAvailablePlatformContentType(
      localContent.platforms.map(i => i.itemType)
    );*/
    
    const mediaItemType = getFirstAvailablePlatformContentType2(
      localContent.platforms.map(i => i.mimeType),
      localContent.platforms.map(i => i.itemType)
    );

    console.log('mediaItemType____', mediaItemType)

    console.log('contentParent', props.content)
    
    this.state = {
      selectedTranslationCountry: localContent.distributionCountry,
      selectedItemType: mediaItemType,
      platformId: null,
      selectedComponentIds:
              mediaItemType === ItemType.VIDEO_INSTAGRAM_STORY  || props.content.format ==  Format.INSTAGRAM_STORY ? [localContent.platforms[0].id] : []
    };
  }

  onChangePlatformType = (itemType: ItemType, idPlatformContent:string) => {
    this.setState({
      //selectedItemType: ItemType[mimeType.concat('_').concat(PlatformType[platformType])] , 
      selectedItemType: itemType,
      platformId:idPlatformContent
    });
    
    //console.log('selectedItemType___', this.state.selectedItemType)
  };

  handleSelectedLocalizationChange = (country: Country) => {
    this.setState(
      {
        selectedTranslationCountry: country,
        platformId : null
      },
      () => {
        const localContent = getLocalContent(
          this.props.content.localizations,
          this.state.selectedTranslationCountry
        );
        /*const itemType = getFirstAvailablePlatformContentType(
          localContent.platforms.map(i => i.itemType)
        );*/
        const itemType = getFirstAvailablePlatformContentType2(
          localContent.platforms.map(i => i.mimeType),
          localContent.platforms.map(i => i.itemType)
        );
    
        this.setState({
          selectedItemType: itemType
        });
      }
    );
  };

  render() {
    const { open, onClose, countries, mediaMimeType, content } = this.props;

    const {
      platformId
    } = this.state;
    
    //console.log('mediaMimeType___', mediaMimeType)
    const { selectedTranslationCountry, selectedItemType, selectedComponentIds } = this.state;
    const selectedLocalContent = getLocalContent(
      content.localizations,
      selectedTranslationCountry
    );

    const selectedPlatformContent = getPlatformContent(selectedLocalContent, selectedItemType, platformId);
    //const itemType = selectedPlatformContent.itemType;
    /*const cutTypesAvailable = selectedLocalContent.platforms.map(i => CutType[i.itemType]) as Array<
      CutType
    >;*/
   /* const platformAvailable = selectedLocalContent.platforms.map(i => PlatformType[i.itemType]) as Array<
    PlatformType
    >;*/
    
    let selectedComponentContents: Array<{
      id: string;
      storagePath: string;
      }> = selectedPlatformContent
      .components!.filter(f => f.id === selectedComponentIds.find(id => id === f.id))
      .map(({ id, storagePath }) => ({ id, storagePath }));

    if (selectedComponentIds.find(id => id === selectedPlatformContent.id)) {
      selectedComponentContents = [
        ...selectedComponentContents,
        { id: selectedPlatformContent.id, storagePath: selectedPlatformContent.storagePath }
      ];
    }
    //console.log('CONTENT____', content.format)
    //console.log('mediaMimeType____', mediaMimeType)

   // const isVideo = mediaMimeType.indexOf('video') >= 0;
    
    const isIgStories = /*isVideo &&*/ content.format.indexOf( Format.INSTAGRAM_STORY ) >= 0 ;
    const carousel = content.format.indexOf('CAROUSEL') >= 0 ;
   
    const idPlatformContents = selectedLocalContent
    ? selectedLocalContent!.platforms.map((i) => i.id)
    : null; 

   
    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <Dialog
            style={{
              width: matches ? '100vw' : 450,
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 30
            }}
            openDialog={open}
            onClose={onClose}
            fullScreen={matches}
            rootIdAttributes="root"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxHeight: 60
              }}
            >
              <Typography variantName="subtitle" textColor="#6B20DA">
                Download Content
              </Typography>
              <ButtonIcon onClick={onClose} style={{ width: 45, height: 45 }}>
                <Icon iconName="close" style={{ fontSize: 35 }} />
              </ButtonIcon>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: 35,
                flexDirection: 'column'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                  marginTop: 15
                }}
              >
                <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                  DISTRIBUTION COUNTRY
                </Typography>
                <CountrySelection
                  countries={countries}
                  selectedCountry={selectedTranslationCountry}
                  onSelection={this.handleSelectedLocalizationChange}
                />
              </div>
  
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '230px'
                  }}
                >
                  <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                    Platform
                  </Typography>

                  <VideoCutSelection
                    itemTypes = { getItemTypesFromString(
                                    selectedLocalContent!.platforms.map((i) => i.mimeType), 
                                    selectedLocalContent!.platforms.map((i) => i.itemType)) 
                                }
                    onSelection={(it, idPlatform) => this.onChangePlatformType(it, idPlatform)}
                    selectedCut={this.state.selectedItemType!}
                    idPlatformContents={idPlatformContents!}
                  />
                
                </div>
             
              { (isIgStories  || carousel) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  { carousel && 
                    <Typography variantName="italic-label" style={{ marginRight: 10 ,fontSize:10}}>
                    Frames
                  </Typography>
                  
                  }

                  <DownloadFramesSelection
                    platformContent={selectedPlatformContent}
                    selectedComponentIds={selectedComponentIds}
                    isIgStories = {isIgStories}
                    onSelectFrame={(frameId: string) =>
                      this.setState(state => {
                        const selectedFrame = state.selectedComponentIds.find(id => id === frameId);
                        const lastSelectedFrame = Boolean(
                          state.selectedComponentIds.length === 1 && selectedFrame
                        );

                        if (lastSelectedFrame) {
                          return state;
                        }

                        return {
                          ...state,
                          selectedComponentIds: selectedFrame
                            ? state.selectedComponentIds.filter(id => id !== frameId)
                            : [...state.selectedComponentIds, frameId]
                        };
                      })
                    }
                  />
                </div>
              )}
            </div>

            {!matches ? (
              <div
                style={{
                  float: 'right'
                }}
              >
                <Button
                  icon={{
                    position: 'left',
                    iconName: 'download',
                    iconColor: '#ffffff'
                  }}
                  style={{ margin: 0 }}
                  onClick={() => {
                    const item = getPlatformContent(selectedLocalContent, selectedItemType, this.state.platformId);
                    const availableToDownload =
                      selectedComponentContents.length > 0
                        ? selectedComponentContents.map(({ storagePath }) => storagePath)
                        : [item.storagePath];
                    download(availableToDownload);
                  }}
                >
                  Download
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  icon={{
                    position: 'left',
                    iconName: 'download',
                    iconColor: '#ffffff'
                  }}
                  style={{ width: '100%', margin: 0 }}
                  onClick={onClose}
                >
                  Download
                </Button>
              </div>
            )}
          </Dialog>
        )}
      </Media>
    );
  }
}

export default DownloadMediaDialog;
