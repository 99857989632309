import * as React from 'react';
import styled from 'styled-components';

import Media from 'react-media';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ReactNode } from 'react';
import { Typography, Icon, ButtonIcon, Tooltip } from '@freeda/react-components';

import { PreviewWrapper, Content, ArrowWrapper, Row, InfoBox, SelectionWrapper } from './styles';
import { renderPreview } from '../../../utils/renderPreview';
import ActionButtons from '../ActionButtons2.0';
//import { MediaRoot } from '../../../types/MediaRoot';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { Country } from '../../../types/graphql/Country';

//import { MediaTranslation } from '../../../types/MediaTranslation';
import { LocalContent } from '../../../types/types2.0/LocalContent';

import { CountrySelection } from '../../../components/ui/CountrySelection';
import { VideoCutSelection } from '../../../components/ui/VideoCutSelection2';
import { ItemType } from '../../../types/ItemType';
import { FramesSelection } from '../../../components/ui/FramesSelection2.0';
//import { getFirstAvailablePlatformContentType } from '../../../utils/mediaUtils2.0';
import { CutType } from '../../../types/CutType';
import { PlaceholderPreviewPage } from '../../../components/ui/Placeholders/PlaceholderPreviewPage';


import { getItemTypeFromString, getFirstAvailablePlatformContentType2, getItemTypesFromString } from '../../../utils/contentUtils';


const PreviewWrapperDiv = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;


/*const PreviewWrapperDivYt = styled.div`
  height: 60%;
  weigth: 60%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;*/

interface Props {
  content: ParentContent | null;
  distributionCountry: Country | null;
  nextId: string | null;
  previousId: string | null;
  itemType: ItemType | null;
  idPlatformContent: string | null;
  componentPosition: number | null;
 // isPinned: boolean;
  loading: boolean;
  renderBackToGallery: (children: ReactNode) => React.ReactElement<any> | null;
  renderPreviousLink: (children: ReactNode) => React.ReactElement<any> | null;
  renderNextLink: (children: ReactNode) => React.ReactElement<any> | null;
  //onPin: () => void;
  //onUnpin: () => void;
  onForward: (localContentId: string, idPlatformContent:string) => void;
  onDownload: (storagePath: string) => void;
  editPageLocation: string;
}

type PropsWithRouterProps = Props & RouteComponentProps<{}>;

interface State {
  selectedTranslationCountry: Country | null;
  selectedItemType: ItemType | null;
  selectedComponentPosition: number | null;
  idPlatformContent: string | null;
}

class MediaPreview extends React.Component<PropsWithRouterProps, State> {
  constructor(props: PropsWithRouterProps) {
    super(props);
    const selectedTranslationCountry = (props.distributionCountry ||
      localStorage.getItem('country')) as Country;

    if (this.props.content) {
      
      const localContent = this.getSelectedLocalContent(
        props.content,
        selectedTranslationCountry
      );

    
      const selectedItemType = getFirstAvailablePlatformContentType2(
        localContent!.platforms.map((i) => i.mimeType),
        localContent!.platforms.map((i) => i.itemType),
        this.props.itemType as string
      )

      let idPlatformContent =  localContent!.platforms.map((i) => i.id)[0]
      
        this.state = {
        selectedTranslationCountry,
        selectedItemType,
        idPlatformContent,
        selectedComponentPosition: props.componentPosition,
      };

    } else {
      this.state = {
        selectedTranslationCountry,
        selectedItemType: null,
        selectedComponentPosition: null,
        idPlatformContent: null
      };
    }
  }

  changeMediaWithKeyboard = (e: KeyboardEvent) => {
    const { distributionCountry, nextId, previousId, history } = this.props;
    if (e.keyCode === 37 && previousId) {
      // left arrow
      history.push(`/gallery/${previousId}?country=${distributionCountry}`);
    }
    if (e.keyCode === 39 && nextId) {
      // right arrow
      history.push(`/gallery/${nextId}?country=${distributionCountry}`);
    }
  };

  componentDidMount() {
    window.addEventListener('keydown', this.changeMediaWithKeyboard);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.changeMediaWithKeyboard);
  }

  componentWillReceiveProps(nextProps: PropsWithRouterProps) {
    // use only when apollo returns the media
    const differencesId =
      (nextProps.content && nextProps.content.id) !== (this.props.content && this.props.content.id);

    if (nextProps.content && nextProps.content.id && differencesId) {
      const localContent =
        nextProps.content.localizations.find(
          (t) => t.distributionCountry === this.state.selectedTranslationCountry
        ) || nextProps.content.localizations[0];

      /*const firstItemType = getFirstAvailablePlatformContentType(
        localContent!.platforms.map((i) => i.itemType),
        this.state.selectedItemType || (this.props.itemType as string)
      );*/

      const firstItemType = getFirstAvailablePlatformContentType2(
        localContent!.platforms.map((i) => i.mimeType),
        localContent!.platforms.map((i) => i.itemType),
        this.props.itemType as string
      )

      this.setState(
        {
          selectedTranslationCountry: localContent.distributionCountry,
          selectedItemType: firstItemType,
          selectedComponentPosition: nextProps.componentPosition,
        },
        () => {
          const {
            selectedItemType,
            selectedComponentPosition,
            selectedTranslationCountry,
           
          } = this.state;

          const selectedLocalContent = this.getSelectedLocalContent(
            nextProps.content,
            selectedTranslationCountry
          )!;
         
          const selectedPlatformContent = selectedLocalContent
            ? selectedLocalContent.platforms.find((i) => i.itemType != null)!
            : null;
          
            const components = selectedPlatformContent && selectedPlatformContent.components;

          const selectedComponent = (components &&
            selectedComponentPosition !== null &&
            components.find((f) => f.position === selectedComponentPosition))!;

          const link = `/gallery/${nextProps.content!.id}?country=${
            localContent.distributionCountry
          }`;

          const cut = selectedItemType ? CutType[selectedItemType] : null;
          
          if (cut) {
            window.history.replaceState(window.history.state, link, `${link}&cut=${cut}`);
          } else if (selectedComponent && selectedItemType === ItemType.VIDEO_INSTAGRAM_STORY) {
            window.history.replaceState(
              window.history.state,
              link,
              `${link}&frame=${selectedComponent.position + 1}`
            );
          } else {
            window.history.replaceState(window.history.state, link, link);
          }
        }
      );
    }
  }

  changeSelectedLocalization = (country: Country) => {
    const { content } = this.props;
    const contentLocalization = content!.localizations.find((t) => t.distributionCountry === country);
   
    const selectedItemType = getFirstAvailablePlatformContentType2(
      contentLocalization!.platforms.map((i) => i.mimeType),
      contentLocalization!.platforms.map((i) => i.itemType)
    );

    this.setState({ idPlatformContent:null ,selectedTranslationCountry: country, selectedItemType }, () => {
      const link = `/gallery/${content!.id}?country=${country}`;
      const cut = CutType[selectedItemType];
      if (cut) {
        window.history.replaceState(window.history.state, link, `${link}&cut=${cut}`);
      } else {
        window.history.replaceState(window.history.state, link, link);
      }
    });
  };

  changeSelectedItemType = (itemType: ItemType, idPlatformContent:string) => {
    const { content } = this.props;

    this.setState({ idPlatformContent:idPlatformContent, selectedItemType: itemType }, () => {
      window.history.replaceState(
        window.history.state,
        itemType,
        `/gallery/${content!.id}?country=${this.state.selectedTranslationCountry}&cut=${itemType}`
      );
    });
  };

  changeSelectedComponent = (componentPosition: number | null) => {
    const { content } = this.props;

    this.setState(
      {
        selectedComponentPosition: componentPosition,
      },
      () => {
        const selectedComponentPosition = componentPosition !== null ? `&component=${componentPosition + 1}` : '';

        window.history.replaceState(
          window.history.state,
          selectedComponentPosition,
          `/gallery/${content!.id}?country=${
            this.state.selectedTranslationCountry
          }${selectedComponentPosition}`
        );
      }
    );
  };

  getSelectedLocalContent = (
    content: ParentContent | null,
    selectedTranslationCountry: Country | null
  ): LocalContent | null => {
    if (!content) {
      return null;
    }

    return (
      content.localizations.find((t) => t.distributionCountry === selectedTranslationCountry) ||
      content.localizations[0]
    );
  };

  render() {
    const {
      content,
      //isPinned,
      loading,
      renderBackToGallery,
      renderPreviousLink,
      renderNextLink,
      //onPin,
      //onUnpin,
      onForward,
      onDownload,
      editPageLocation,
    } = this.props;

    const { selectedTranslationCountry, selectedItemType, selectedComponentPosition, idPlatformContent } = this.state;

    const selectedLocalContent = this.getSelectedLocalContent(
      content,
      selectedTranslationCountry
    )!;

    let type = selectedItemType? selectedItemType.split('_') : null

    let selectedPlatformContent: any ;
    console.log(idPlatformContent)
    
    if(idPlatformContent){
      selectedPlatformContent = selectedLocalContent
        ? selectedLocalContent.platforms.find((i) => idPlatformContent==i.id )
        : null;
    }
    else{
     selectedPlatformContent = selectedLocalContent
      ? selectedLocalContent.platforms.find((i) => type? i.itemType == type[1]: i.itemType != null)!
      : null;
    }
   // console.log('selectedPlatformContent ___', selectedPlatformContent)
    const idPlatformContents = selectedLocalContent
      ? selectedLocalContent!.platforms.map((i) => i.id)
      : null;  

     // console.log('idPlatformContents ___', idPlatformContents)

    //const isVideo = selectedPlatformContent && selectedPlatformContent.mimeType.indexOf('video') >= 0;
    const itemType = selectedPlatformContent && getItemTypeFromString(selectedPlatformContent.mimeType,selectedPlatformContent.itemType);
    const isIgStories = /*isVideo && */ content && content.format.indexOf('INSTAGRAM_STORY') >= 0;
    //const isVideoCut = isVideo && itemType && itemType.indexOf('VIDEO_') >= 0;

    

    const components = selectedPlatformContent && selectedPlatformContent.components;

    const selectedComponentContent = (components &&
      selectedComponentPosition !== null &&
      components.find((f:any) => f.position === selectedComponentPosition))!;

    ///console.log('_______selectedComponentContents',selectedComponentContent )
    ///console.log('_______selectedPlatformContent',selectedPlatformContent )

    
    const title = selectedComponentContent
      ? selectedComponentContent.title
      : /*selectedPlatformContent && selectedPlatformContent!.title*/ selectedPlatformContent && selectedPlatformContent!.title;

    const mimeType = selectedComponentContent
      ? selectedComponentContent.mimeType
      : selectedPlatformContent && selectedPlatformContent!.mimeType;

    const storagePath = selectedComponentContent
      ? selectedComponentContent.storagePath
      : selectedPlatformContent && selectedPlatformContent!.storagePath;

    const itemTypePath =
      selectedItemType === ItemType.VIDEO_INSTAGRAM_STORY ? '' : `&cut=${selectedItemType}`;

    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <PreviewWrapper>
            {loading ? (
              <PlaceholderPreviewPage />
            ) : (
              <>
                <Row>
                  {renderBackToGallery(
                    <div
                      style={{ display: 'flex', alignItems: 'center', margin: '10px 0px 0px 10px' }}
                    >
                      <ButtonIcon style={{ marginRight: 5 }}>
                        <Icon iconName="arrow-left" />
                      </ButtonIcon>
                      <Typography
                        variantName="label"
                        textColor="#6B20DA"
                        style={{ fontSize: 14, cursor: 'pointer' }}
                      >
                        Back
                      </Typography>
                    </div>
                  )}
                </Row>
                <Content>
                  <ArrowWrapper>
                    {content && /*selectedComponentContent &&*/ (

                      


                      <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                          
                        <InfoBox>

                          <div style={{ paddingBottom: 10}}>
                            { title !=null  &&
                                <Tooltip
                                referenceChildren={
                                  <Typography  variantName="italic-label" style={{ marginRight: 10 , color: '#6B2DD5'}}>
                                        { title.length>50 ? title.substring(0,50).concat('...') : title} 
                                  </Typography>
                                }
                                tooltipChildren={
                                  <div>
                                    {title}
                                  </div>
                                }
                                position = "auto"
                                referenceStyle = {{ display: 'flex', alignItems: 'left',  marginRight: 10}}
                                tooltipStyle = {{  width: (title.length>50 ? 500 : 400), top: 4,  left: 20,  color:'black'    }}
                              />
                            }
                          </div> 

                          <Typography variantName="italic-label">DISTRIBUTION COUNTRY</Typography>
                          <SelectionWrapper>
                            <CountrySelection
                              selectedCountry={selectedLocalContent!.distributionCountry}
                              countries={content.localizations.map((t) => t.distributionCountry)}
                              onSelection={(country) => this.changeSelectedLocalization(country)}
                            />
                          </SelectionWrapper>
                        </InfoBox>                        
                          <InfoBox>
                            <Typography variantName="italic-label">PLATFORMS</Typography>
                            <SelectionWrapper>
                              <VideoCutSelection
                                itemTypes = { getItemTypesFromString(
                                                selectedLocalContent!.platforms.map((i) => i.mimeType), 
                                                selectedLocalContent!.platforms.map((i) => i.itemType)) 
                                            }
                                onSelection={(it, idPlatform) => this.changeSelectedItemType(it, idPlatform)}
                                selectedCut={this.state.selectedItemType!}
                                idPlatformContents={idPlatformContents!}

                              />
                            </SelectionWrapper>
                          </InfoBox>
                        

                        { (components && components?.length>0) && (
                          <InfoBox>
                            <Typography variantName="italic-label">FRAMES</Typography>
                            <SelectionWrapper style={{ paddingTop: 0 }}>
                              
                              <FramesSelection
                                isIgStories={isIgStories}
                                platformContent={selectedPlatformContent}
                                selectedComponentPosition={selectedComponentPosition}
                                onSelectComponent={(componentPosition: number | null) =>
                                  this.changeSelectedComponent(componentPosition)
                                }
                              />

                            </SelectionWrapper>
                          </InfoBox>
                        )}
                      </div>
                    )}
                    {renderPreviousLink(
                      <ButtonIcon style={{ width: 85, height: 85 }}>
                        <Icon iconName="arrow-left" iconSize="xlarge" />
                      </ButtonIcon>
                    )}
                  </ArrowWrapper>
                  <PreviewWrapperDiv>{renderPreview(loading, itemType, mimeType, storagePath, true)}</PreviewWrapperDiv>
                  
                  <ArrowWrapper style={{ justifyContent: 'flex-end' }}>
                    {renderNextLink(
                      <ButtonIcon style={{ width: 85, height: 85 }}>
                        <Icon iconName="arrow-right" iconSize="xlarge" />
                      </ButtonIcon>
                    )}
                  </ArrowWrapper>
                  {matches && (
                    <div
                      style={{
                        gridColumnStart: 2,
                        justifySelf: 'center',
                      }}
                    >
                      <Typography variantName="title" textColor="primary">
                        {title ? title : ""}
                      </Typography>
                    </div>
                  )}
                </Content>
              </>
            )}
            <ActionButtons
              //isPinned={isPinned}
             // onPin={onPin}
            //  onUnpin={onUnpin}
              onForward={() => onForward(content!.id, idPlatformContent!)}
              onDownload={() =>
                (selectedComponentContent || selectedPlatformContent) && onDownload(storagePath ? storagePath : "")
              }
              editPageLocation={`${editPageLocation}?country=${selectedTranslationCountry}${itemTypePath}`}
            />
          </PreviewWrapper>
        )}
      </Media>
    );
  }
}

export default withRouter<Props & RouteComponentProps<{}>, any>(MediaPreview);
