import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';
import { MetadataType, LabelType } from '../types/MetadataType';

import { BrandsFilterContext } from '../providers/BrandsFilterProvider2.0';
import { TopicsFilterContext } from '../providers/TopicsFilterProvider2.0';
import { LabelsFilterContext } from '../providers/LabelsFilterProvider2.0';

import { CampaignsFilterContext } from '../providers/CampaignsFilterProvider';


import { CREATE_LABEL} from '../apollo/mutation/mutation2.0/CreateMetadata';
import { LIST_BRANDS, LIST_LABELS, LIST_FORMATS } from '../apollo/queries/queries2.0/ListMetadata';
import { Order } from '../types/Order';

import { UPDATE_LABEL } from '../apollo/mutation/mutation2.0/UpdateMetadata';

//import { getLabelMetadataAvailable } from './getLabelMetadataAvailable';
import {
  OnCreateLabel,
} from '../types/types2.0/graphql2.0/CreateMetadata';


//import ListBrandsQuery from '../types/types2.0/graphql2.0/ListBrands';
import ListLabels from '../types/types2.0/graphql2.0/ListLabels';

import { MessageType } from '../providers/FeedbackProvider';

import { MutationFn, OperationVariables } from 'react-apollo';

export const METADATA_PAGE_SIZE = 25;

export const getFilterContext = (metadataType: MetadataType) => {
  const context = {
    [MetadataType.BRANDS]: BrandsFilterContext,
    [MetadataType.TOPICS]: TopicsFilterContext,
    [MetadataType.SERIES]: LabelsFilterContext,
    [MetadataType.CAMPAIGNS]: CampaignsFilterContext,
    [MetadataType.BOOLEANS]: LabelsFilterContext,
    [MetadataType.EDITORIAL_CAMPAIGNS]: LabelsFilterContext,
    [MetadataType.FORMATS]: LabelsFilterContext,
    [MetadataType.GENRES]: LabelsFilterContext,
    [MetadataType.KEYWORDS]: LabelsFilterContext,
    [MetadataType.OBJECTIVES]: LabelsFilterContext,
    [MetadataType.PRODUCTS]: LabelsFilterContext,
    [MetadataType.TEMPERATURES]: LabelsFilterContext,

  };

  return context[metadataType];
};



export const getListMetadataQuery = (metadataType: MetadataType) => {
  const query = {
    [MetadataType.BRANDS]: LIST_BRANDS,
    [MetadataType.TOPICS]: LIST_LABELS,
    [MetadataType.SERIES]: LIST_LABELS,
    [MetadataType.CAMPAIGNS]: LIST_LABELS,
    [MetadataType.BOOLEANS]: LIST_LABELS,
    [MetadataType.EDITORIAL_CAMPAIGNS]: LIST_LABELS,
    [MetadataType.FORMATS]: LIST_FORMATS,
    [MetadataType.GENRES]: LIST_LABELS,
    [MetadataType.KEYWORDS]: LIST_LABELS,
    [MetadataType.OBJECTIVES]: LIST_LABELS,
    [MetadataType.PRODUCTS]: LIST_LABELS,
    [MetadataType.TEMPERATURES]: LIST_LABELS
  };

  return query[metadataType];
};

export const getCreateMetadataMutation = (metadataType: MetadataType) => {
  const mutation = {
    [MetadataType.SERIES]: CREATE_LABEL,
    [MetadataType.TOPICS]: CREATE_LABEL,
    [MetadataType.BOOLEANS]: CREATE_LABEL,
    [MetadataType.EDITORIAL_CAMPAIGNS]: CREATE_LABEL,
    [MetadataType.FORMATS]: CREATE_LABEL,
    [MetadataType.GENRES]: CREATE_LABEL,
    [MetadataType.KEYWORDS]: CREATE_LABEL,
    [MetadataType.OBJECTIVES]: CREATE_LABEL,
    [MetadataType.PRODUCTS]: CREATE_LABEL,
    [MetadataType.TEMPERATURES]: CREATE_LABEL,
  };

  return mutation[metadataType];
};

export const getUpdateMetadataMutation = (metadataType: MetadataType) => {
  const mutation = {
    [MetadataType.SERIES]: UPDATE_LABEL,
    [MetadataType.TOPICS]: UPDATE_LABEL,
    [MetadataType.BOOLEANS]: UPDATE_LABEL,
    [MetadataType.EDITORIAL_CAMPAIGNS]: UPDATE_LABEL,
    [MetadataType.FORMATS]: UPDATE_LABEL,
    [MetadataType.GENRES]: UPDATE_LABEL,
    [MetadataType.KEYWORDS]: UPDATE_LABEL,
    [MetadataType.OBJECTIVES]: UPDATE_LABEL,
    [MetadataType.PRODUCTS]: UPDATE_LABEL,
    [MetadataType.TEMPERATURES]: UPDATE_LABEL,
  };

  return mutation[metadataType];
};

export const buildMetadataQueryVariables = (
  metadataType: MetadataType,
  searchValue: string,
  selectedBrandCategoryName: string | null,
  selectedMacroTopicName:string | null,
  selectedBrand: string | null,
  selectedProjectIds: Array<string>,
  order: Order,
  orderBy: string | null
) => {
  const orderByParam = order && orderBy ? { orderBy: `${orderBy}_${order}` } : null;

  const brandVariablesQuery = Boolean(metadataType === MetadataType.BRANDS && selectedBrandCategoryName) && {
      category: selectedBrandCategoryName
  };

  const topicVariablesQuery = Boolean(metadataType === MetadataType.TOPICS && selectedMacroTopicName) && {
    macroTopic: selectedMacroTopicName
  };

  const campaignsVariablesQuery = Boolean(metadataType === MetadataType.CAMPAIGNS && selectedBrand) && {
    visibleFor: selectedBrand
  };
  
  const labesVariabledQuery = Boolean(selectedProjectIds.length > 0) && {
    projectList :selectedProjectIds.map((pT) => ({ id: pT })),
    category : LabelType[metadataType.toUpperCase()]
  };

  return {
    where: {
      [`name_contains`]: searchValue,
      ...brandVariablesQuery
    },
    ...labesVariabledQuery,
    ...topicVariablesQuery,
    ...campaignsVariablesQuery,
    ...orderByParam,
  };
};

export const onCreateMetadata = async (
  createMetadata: MutationFn<any, OperationVariables>,
  metadataType: MetadataType,
  brandCategoryName: string | null,
  brandCategoryNameQuery: string | null,
  macroTopicName: string | null,
  macroTopicNameQuery: string | null,
  brandName: string | null,
  brandNameQuery: string | null,
  projectIds: Array<string>,
  projectIdQuery: Array<string>,
  metadataLabel: string,
  handleAvailableMetadataLabel: (available: boolean) => void,
  searchValue: string,
  order: Order | null,
  orderBy: string | null,
  onOpenSnackbar: (message: string | React.ReactElement<any>, type: MessageType) => void,
  onOpenSnackbarReload: () => void,
  onCloseDialog: () => void
) => {

  const isBrand = metadataType === MetadataType.BRANDS;
  const isFormat = metadataType === MetadataType.FORMATS;
  const nameMetadataKey = 'name';
  const metadataName = String(metadataType).slice(0, -1);
  const category = LabelType[metadataType.toUpperCase()]
  
  const brandVariablesMutation = Boolean(isBrand && brandCategoryName) && {
    brand_category: brandCategoryName
  };

  const labelVariablesMutation = Boolean(( !isBrand  && !isFormat) /*&&  projectIds.length > 0*/) && {
    category : category,
    macroTopic : macroTopicName ? macroTopicName : null,
    project: projectIds.length > 0 ?
                      projectIds.map((pT) => ({
                        id: pT,
                      })) : null,
  };

  const variablesMutation = {
    [nameMetadataKey]: metadataLabel.trim(),
    ...brandVariablesMutation,
    ...labelVariablesMutation,
  };

  try {
   
      const query = getListMetadataQuery(metadataType);
      await createMetadata({
        variables: variablesMutation,
        update: (
          cache: DataProxy,
          result: FetchResult<OnCreateLabel>
        ) => {
         
          
          let variables = buildMetadataQueryVariables(
            metadataType,
            searchValue,
            brandCategoryNameQuery,
            macroTopicNameQuery,
            brandNameQuery,
            projectIdQuery,
            order,
            orderBy
          );
          const q = {
            query,
            variables,
          };
          const cachedQuery = cache.readQuery<ListLabels>(
            q
          );
          if (cachedQuery && result.data) {

           /*{ (result.data[`createLabel`].projectList || result.data[`createLabel`].projectList!.length==0) ? 
                result.data[`createLabel`].projectList 
            }*/

            /*if(category==LabelType.BRANDS){
              const updatedQuery = {
                [`list${metadataType}`]: [
                  ...cachedQuery[`list${String(metadataType)}`],
                  result.data[`create${metadataName}`],
                ],
              };
  
              cache.writeQuery({
                ...q,
                data: updatedQuery,
              });
            }
            else{*/

              const updatedQuery = {
                [`listLabels`]: [
                  result.data[`createLabel`],
                  ...cachedQuery[`listLabels`]
                ],
              };
  
              cache.writeQuery({
                ...q,
                data: updatedQuery,
              });

            //}
          }
        },
      });

      onOpenSnackbar(
        `Create new ${metadataName}: "${metadataLabel}" correctly! 😎`,
        'notification'
      );
      onOpenSnackbarReload();
      onCloseDialog();
    //}
  } catch (err) {
    
    if(String(err).indexOf('GraphQL error: Exception while fetching data (/createLabel)')>=0){
      onOpenSnackbar(`Oops, something went wrong : Label already exists for ${metadataName}! 😔`, 'error');

    }
    else{
      onOpenSnackbar(`Oops, something went wrong when trying to create ${metadataName}! 😔`, 'error');

    }
    //console.log(String(err));
  }
};
