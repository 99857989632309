import * as React from 'react';
import { Mutation } from 'react-apollo';

import { CustomMutationProps } from '../../../types/graphql/utils';

import { DELETE_COMPONENT_CONTENT } from '../../../apollo/mutation/mutation2.0/DeleteComponentContent';

export interface DeleteComponentContentResult {
  deleteComponentContent: {
    deletedComponentId: string;
  };
}

class TypedMutation extends Mutation<DeleteComponentContentResult> {}

export const DeleteComponentContentMutation: React.FC<CustomMutationProps<DeleteComponentContentResult>> = ({
  children,
  ...others
}) => {
  return (
    <TypedMutation mutation={DELETE_COMPONENT_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
