import * as React from 'react';
import { MetadataType } from '../../../types/MetadataType';
import { TitleInput } from '@freeda/react-components';
//import { SelectBrandCategory } from '../MetadataFilter/SelectBrandCategory2.0';
import { SelectMacroTopic } from '../MetadataFilter/SelectMacroTopic';
import { MultiSelectProjects } from '../MetadataFilter/MultiSelectProject';

export const buildCreationSectionByMetadata = (
  metadataType: MetadataType,
  metadataLabel: string,
  onChangeMetadataLabel: (label: string) => void,
  handleAvailableMetadataLabel: (available: boolean) => void,
  brandCategoryName: string | null,
  onChangeBrandCategoryName: (id: string | null) => void,
  
  macroTopicName: string | null,
  onChangeMacroTopicName: (id: string | null) => void,

  brandName: string | null,
  onChangeBrandName:(id: string | null) => void,

  selectedProjectIds: Array<string>,
  onChangeProjectIds: (ids: Array<string>) => void
): JSX.Element => {
  //alert('buildCreationSectionByMetadata')
  const nameMetadataKey =  'name';

  const metadataName = metadataType== MetadataType.SERIES? String(metadataType): String(metadataType).slice(0, -1);

  const placeholder = `${metadataName} label`;

  const titleInput = (
    <TitleInput
      label={nameMetadataKey}
      placeholder={placeholder}
      value={metadataLabel}
      onChange={e => {
        onChangeMetadataLabel(e.currentTarget.value);
        handleAvailableMetadataLabel(true);
      }}
    />
  );

  const macroTopic = (
    <SelectMacroTopic
      style={{
        width: '100%',  marginBottom:20,
      }}
      label="Macro topic"
      onChangeMacroTopicName={onChangeMacroTopicName}
      selectedMacroTopicName={macroTopicName}
    />
    );


    return (
      <>
        {metadataType==MetadataType.TOPICS && macroTopic}

        { (metadataType==MetadataType.SERIES || metadataType==MetadataType.KEYWORDS 
          || metadataType==MetadataType.EDITORIAL_CAMPAIGNS || metadataType==MetadataType.PRODUCTS) &&
        
            <MultiSelectProjects
              style={{ width: '100%', marginBottom: 20 }}
              label="Project"
              selectedProjectIds={selectedProjectIds}
              onChangeProjectIds={ids => onChangeProjectIds(ids)}
            />
        }
        {titleInput}
      </>
    );


  //return titleInput;
};
