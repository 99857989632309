import * as React from 'react';
import Media from 'react-media';
import styled from 'styled-components';
import { HeaderEditMedia } from '../../../components/ui/HeaderEditMedia';
import { PlaceholderInput } from './PlaceholderInput';
import { PlaceholderUploader } from './PlaceholderUploaded';
import { PlaceholderCheckbox } from './PlaceholderCheckbox';
import {
  Avatar,
  PlaceholderHr,
  MediaPlaceholder,
  BigText,
  ButtonStyled,
  ButtonIconStyled,
} from './styles';

const imgPlaceholder = require('../../../assets/placeholder-img.jpg');

const PageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PreviewEdit = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 35% 65%;
  height: calc(100vh - 65px);
  background-color: #ffffff;
`;

const PreviewWrapper = styled.div`
  padding: 20px 35px;
  background: #f2f2f2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const EditWrapper = styled.div`
  padding: 32px 35px 35px 35px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  margin-bottom: 20px;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const avatarStyled = { position: 'absolute' as 'absolute', right: '-55px', background: '#ebebeb' };

const PlaceholderEditPage: React.FC = () => (
  <Media query={{ maxWidth: 1085 }}>
    {(matches: boolean) => (
      <PageWrapper>
        <HeaderEditMedia />
        <PreviewEdit>
          <PreviewWrapper>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <BigText style={{ width: '40%', background: '#e9e9e9', marginRight: 20 }} />
              <Avatar style={{ background: '#e9e9e9' }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <MediaPlaceholder
                src={imgPlaceholder}
                style={{ maxHeight: '450px', padding: '20px 0px 10px 0px' }}
              />

              <ButtonWrapper>
                {!matches ? (
                  <>
                    <div>
                      <ButtonStyled
                        disabled={true}
                        icon={{
                          position: 'left',
                          iconName: 'delete',
                        }}
                      >
                        Delete
                      </ButtonStyled>
                    </div>
                    <div>
                      <ButtonStyled
                        disabled={true}
                        icon={{
                          position: 'left',
                          iconName: 'replace',
                        }}
                      >
                        Replace
                      </ButtonStyled>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <ButtonIconStyled iconName="delete" disabled={true} />
                    </div>
                    <div>
                      <ButtonIconStyled iconName="replace" disabled={true} />
                    </div>
                  </>
                )}
              </ButtonWrapper>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <PlaceholderUploader />
                <PlaceholderUploader />
              </div>
            </div>
          </PreviewWrapper>
          <EditWrapper>
            <div>
              <BigText style={{ width: '60%', background: '#ebebeb' }} />
              <PlaceholderHr style={{ background: '#ebebeb' }} />
            </div>
            <RowWrapper>
              <PlaceholderCheckbox style={{ marginRight: 20 }} />
              <PlaceholderCheckbox />
              <PlaceholderInput style={{ marginLeft: 35, flexBasis: '40%' }} />
            </RowWrapper>
            <RowWrapper>
              <PlaceholderCheckbox style={{ marginRight: 20 }} />
              <PlaceholderCheckbox />
              <PlaceholderInput style={{ marginLeft: 35, flexBasis: '60%' }} />
            </RowWrapper>
            <RowWrapper>
              <PlaceholderInput style={{ flexBasis: '100%' }} />
            </RowWrapper>
            <RowWrapper>
              <PlaceholderInput style={{ flexBasis: '100%' }} />
            </RowWrapper>
            <RowWrapper>
              <PlaceholderInput style={{ flexBasis: '45%' }} />
            </RowWrapper>
            <div>
              <PlaceholderHr style={{ marginBottom: 25, marginTop: 0, background: '#ebebeb' }} />
            </div>
            <RowWrapper>
              <PlaceholderInput style={{ flexBasis: '30%' }}>
                <Avatar style={avatarStyled} />
              </PlaceholderInput>
              <PlaceholderInput style={{ flexBasis: '30%', marginLeft: '135px' }}>
                <Avatar style={avatarStyled} />
              </PlaceholderInput>
            </RowWrapper>
            <RowWrapper>
              <PlaceholderInput style={{ flexBasis: '100%' }} />
            </RowWrapper>
            <RowWrapper>
              <BigText
                style={{
                  width: '30%',
                  height: 4,
                  borderRadius: 4,
                  marginRight: 100,
                  background: '#ebebeb',
                }}
              />
              <PlaceholderInput style={{ flexBasis: '40%' }} />
            </RowWrapper>
            <div>
              <PlaceholderHr style={{ marginBottom: 25, marginTop: 0, background: '#ebebeb' }} />
            </div>
            <RowWrapper>
              <PlaceholderInput style={{ flexBasis: '30%', marginRight: 100 }} />
              <PlaceholderInput style={{ flexBasis: '30%' }} />
            </RowWrapper>
          </EditWrapper>
        </PreviewEdit>
      </PageWrapper>
    )}
  </Media>
);

export { PlaceholderEditPage };
