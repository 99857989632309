import * as React from 'react';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'react-apollo';
import { Button } from '@freeda/react-components';

import {
  DeleteComponentContentMutation,
  DeleteComponentContentResult,
} from '../components/mutations/mutations2.0/DeleteComponentMutation';

import { FeedbackContext } from '../providers/FeedbackProvider';

import { MassiveUpdateComponentPositions } from '../components/mutations/mutations2.0/MassiveUpdateComponentsPosition';

interface Props {
  componentId: string;
  componentPositions: Array<{ id: string; position: number }>;
  onCloseDialog: () => void;
  updateComponentsAfterDelete: () => void;
}

const DeleteComponentButton: React.FC<Props> = ({
  componentId,
  componentPositions,
  onCloseDialog,
  updateComponentsAfterDelete,
}) => (
  <FeedbackContext.Consumer>
    {({ onOpen }) => (
      <MassiveUpdateComponentPositions>
        {(massiveUpdatePosition, updatePositionsResult) => (
          <DeleteComponentContentMutation>
            {(deleteComponent, deleteResult) => (
              <Button
                disabled={updatePositionsResult.loading || deleteResult.loading}
                loading={updatePositionsResult.loading || deleteResult.loading}
                
                onClick={async () => {
                  try{
                      await massiveUpdatePosition({ variables: { componentPositions } });

                      await deleteComponent({
                        variables: { id: componentId },
                        update: (cache: DataProxy, result: FetchResult<DeleteComponentContentResult>) => {
                          if (deleteResult.error) {
                            onOpen('Error!', 'error');
                          } else {
                            updateComponentsAfterDelete();
                            onCloseDialog();
                          }
                          onOpen('Frame content DELETED', 'notification');
                        },
                      });
                  }catch(e){
                    onOpen(''+e, 'error');
                  }

                }}
              >
                I’m sure, delete it
              </Button>
            )}
          </DeleteComponentContentMutation>
        )}
      </MassiveUpdateComponentPositions>
    )}
  </FeedbackContext.Consumer>
);

export { DeleteComponentButton };
