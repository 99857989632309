import { Country } from '../types/graphql/Country';

const it = require('../assets/flags/it.png');
const es = require('../assets/flags/es.png');
const uk = require('../assets/flags/uk.png');
const de = require('../assets/flags/de.png');
const fr = require('../assets/flags/fr.png');

export const ImageMap = {
  [Country.IT]: it,
  [Country.ES]: es,
  [Country.UK]: uk,
  [Country.DE]: de,
  [Country.FR]: fr

};

export const DescriptionMap = {
  [Country.IT]: 'Italy',
  [Country.ES]: 'Spain',
  [Country.UK]: 'England',
  [Country.DE]: 'Germany',
  [Country.FR]: 'France'
};
