import * as React from 'react';
import { Icon, CheckBox } from '@freeda/react-components';
import { ActionButtons, EditContentButton, OverlayWrapper, ActionIcon } from './styles';
import { ButtonLink as Link } from '../../ui/RouterLink';
import { Country } from '../../../types/graphql/Country';
import { ItemType } from '../../../types/ItemType';
import { CutType } from '../../../types/CutType';
import { getFirstAvailablePlatformContentType } from '../../../utils/mediaUtils2.0';
//import { MediaFrame } from '../../../types/MediaFrame';
import { ComponentContent } from '../../../types/types2.0/ComponentContent';
import { download } from '../../../utils/downloadMedia';
import { DeleteMediaDialog } from '../DeleteMediaDialog';
import { platform } from 'os';
//import { any } from 'prop-types';
//import { platform } from 'os';

interface Props {
  selected: boolean;
  contentId: string;
  title: string;
  localizations: Array<{
    distributionCountry: Country;
    id: string;
    platforms: Array<{
      url: string;
      itemType: ItemType;
      creator:string,
      components: Array<ComponentContent> | null;
    }>;
  }>;
  //pinned: boolean;
  onSelection: Function;
  //onPin: (contentIds: Array<string>) => void;
  //onUnpin: (contentIds: Array<string>) => void;
  onShare: (rootId: string) => void;
  onDownload: (rootId: string) => void;
  renderGalleryLink: (children: React.ReactNode) => React.ReactNode;
}

const getTranslationCountry = (userCountry: string, mediaCountries: Array<Country>): Country => {
  return mediaCountries.find(country => country === userCountry) || mediaCountries[0];
};

const getEditLink = (
  contentId: string,
  distributionCountries: Array<Country>,
  itemType: ItemType
): string => {
  let link = `/edit-media/${contentId}?country=${getTranslationCountry(
    localStorage.getItem('country')!,
    distributionCountries
  )}`;

  const cut = CutType[itemType];
  if (cut) {
    return `${link}&cut=${cut}`;
  }

  return link;
};

interface State {
  action: 'share' | 'download' | 'delete' | null;
  openDeleteDialog: boolean
 
}

class Overlay extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
    
    this.state = {
      action: null,
      openDeleteDialog:false
      
    };
  }

  render() {
    
    const {
      selected,
      contentId,
      localizations,
      onSelection,
      onShare,
      onDownload,
      renderGalleryLink
    } = this.props;

    const userCountry = localStorage.getItem('country') as Country;
    const onCloseDeleteDialog = () => this.setState({ openDeleteDialog: false });
    const selectedLocalization = localizations.find(t => t.distributionCountry === userCountry) || localizations[0];

    const platforms = selectedLocalization.platforms;

    const components = platforms[0].components;
    const deleteButton = localStorage.getItem('name')!.toLocaleLowerCase() === platforms[0].creator.toLocaleLowerCase()

    return (
      <OverlayWrapper>
        <CheckBox
          checked={selected}
          onClick={() => onSelection()}
          bold
          colorBox="#ffffff"
          style={{ position: 'absolute', top: 15, left: 15, zIndex: 2000 }}
        />
        <ActionButtons>

       {deleteButton && 
          <ActionIcon
              iconName="delete"
              data-testid="delete"
              onClick={e => {
                this.setState({ openDeleteDialog: true })
              }}
          /> 
       }  

        <ActionIcon
            iconName="reply"
            data-testid="share"
            onClick={e => {
              onShare(contentId);
            }}
        />
        <ActionIcon
            iconName="download"
            data-testid="download"
            onClick={() => {
              if (
                localizations.length === 1 &&
                platforms.length === 1 &&
                components &&
                components.length === 0
              ) {
                const i = platforms[0];
                download([i.url]);
              } else {
                onDownload(contentId);
              }
            }}
        />
         
        <ActionIcon data-testid="view-content">
          {renderGalleryLink(
            <Icon iconName="show" style={{ color: '#fff', display: 'flex', cursor: 'pointer' }} />
          )}
        </ActionIcon>
        </ActionButtons>
        <Link
          to={getEditLink(
            contentId,
            localizations.map(t => t.distributionCountry),
            getFirstAvailablePlatformContentType(selectedLocalization.platforms.map(i => i.itemType))
          )}
        >
          <EditContentButton>Edit content</EditContentButton>
        </Link>

        <DeleteMediaDialog
          open = {this.state.openDeleteDialog}
          onClose = {onCloseDeleteDialog}
          contentId={contentId}
          
        />
      </OverlayWrapper>
    );
  }
}

export default Overlay;
