import { GetPutS3SignedUrlQuery } from '../../types/graphql/GetPutS3SignedUrlQuery';
import { v4 } from 'uuid';

import clientTobe from '../clientTobe';
import { GET_PUT_S3_SIGNED_URL } from '../queries/GetPutS3SignedUrl';

const getPutS3SignedUrl = (mimeType: string, multipart:boolean) =>
  clientTobe.query<{ getPutS3SignedUrl: GetPutS3SignedUrlQuery }>({
    query: GET_PUT_S3_SIGNED_URL,
    variables: { mimeType, dummyId: v4(), multipart},
    fetchPolicy: 'no-cache'
  });

export { getPutS3SignedUrl };
