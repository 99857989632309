import * as React from 'react';
import { Cuts, Wrapper } from './styles';
import { PlatformsCutMap } from '../../../utils/videoCutsMap';
import { ItemType } from '../../../types/ItemType';
//import { PlatformContent } from '../../../types/types2.0/PlatformContent';


interface Props {
  itemTypes: Array<ItemType>;
  selectedCut: ItemType;
  onSelection: (cut: ItemType, idPlatform:string) => void;
  idPlatformContents:Array<string>
}

const VideoCutSelection: React.FC<Props> = ({ itemTypes, selectedCut, onSelection, idPlatformContents }) => (

  
  <Wrapper>
    {Object.keys(PlatformsCutMap).map((v: string) => {
      
       let cutType: any[] = []
       itemTypes.forEach(function(value, index, array) {
         if(value===v){
          cutType.push(
            <Cuts
                key={index}
                onClick={() => { onSelection(v as ItemType, idPlatformContents[index])}}
                isselected={String(v === selectedCut)}
              >
                <img src={PlatformsCutMap[v]} />
            </Cuts>
          )
         }
       }); 
       return(
        cutType
       )

      //return (
        //postsItems
        /*itemTypes.forEach(item => v === item) && (
          <Cuts
            key={v}
            onClick={() => onSelection(v as ItemType)}
            isselected={String(v === selectedCut)}
          >
            <img src={PlatformsCutMap[v]} />
          </Cuts>
        )


        /*itemTypes.forEach(function(item)  {
          <Cuts
            key={item}
            onClick={() => onSelection(item as ItemType)}
            isselected={String(v === selectedCut)}
          >
            <img src={PlatformsCutMap[item]} />
          </Cuts>
        })*/

        /*itemTypes.forEach((item,index)=>{
          <Cuts
          key={item}
          onClick={() => onSelection(item as ItemType)}
          isselected={String(v === selectedCut)}
        >
          <img src={PlatformsCutMap[item]} />
        </Cuts>
        })*/
        
      //);
      
    })}
  </Wrapper>
);

export { VideoCutSelection };


/** <Wrapper>
    {Object.keys(PlatformsCutMap).map((v: string) => {
      return (
        itemTypes.find(item => v === item) && (
          <Cuts
            key={v}
            onClick={() => onSelection(v as ItemType)}
            isselected={String(v === selectedCut)}
          >
            <img src={PlatformsCutMap[v]} />
          </Cuts>
        )
      );
    })}
  </Wrapper> */
