import styled from 'styled-components';
import { ButtonIcon } from '@freeda/react-components';

import { zIndex } from '../../../utils/zIndex';

const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  background-color: ${props => props.theme.azzurrino};
  box-sizing: border-box;

  @media (max-width: 768px) {
    background-color: transparent;
    flex-direction: column;
    align-items: normal;
    position: fixed;
    z-index: ${zIndex.FilterWrapper};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: inherit;
  }
`;

const ClearFilterWrapper = styled.div`
  margin-left: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TeamMemberWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: inherit;
  }
`;

const Field = styled.div<
  { basis?: string | number } & {
    mobileBasis?: string | number;
  } & React.HTMLProps<HTMLDivElement>
>`
  flex-basis: ${props => (props.basis ? props.basis : '10%')};
  @media (min-width: 769px) {
    padding-right: 30px;
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    flex-basis: ${props => (props.mobileBasis ? props.mobileBasis : 0)};
    margin-top: 10px;
  }
`;

const FieldWithSeparator = styled(Field)`
  @media (min-width: 769px) {
    border-left: solid 1px #ffffff;
    box-sizing: border-box;
  }
`;

const MobileFilterHeaderWrapper = styled.div`
  color: #ffffff;
  font-size: 20px;
  background-color: ${props => props.theme.primary};
  padding: 9px;
  display: grid;
  align-items: center;
  grid-template-columns: 33.33% 33.33% 33.33%;
  position: fixed;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
`;

const FreeFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin: 15px 0;

  @media (max-width: 768px) {
    padding: 15px;
    margin-bottom: 15px;
    background: ${props => props.theme.azzurrino};
    justify-content: space-around;
    margin: 0px;
  }
`;

const OrderStuffWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
`;

const ButtonIconStyled = styled(ButtonIcon)`
  && {
    width: 48px;
    height: 48px;
  }
`;

export {
  FilterWrapper,
  ClearFilterWrapper,
  Field,
  FieldWithSeparator,
  MobileFilterHeaderWrapper,
  OrderStuffWrapper,
  FreeFilterWrapper,
  TeamMemberWrapper,
  Row,
  ButtonIconStyled
};
