import gql from 'graphql-tag';
//import { PARENT_CONTENT } from '../../../apollo/queries/queries2.0/ParentContentFragment';

const MERGE_PLATFORM_CONTENT = gql`
  mutation mergePlatformContent(
    $mainContent: ID!
    $subContent: ID!
    $distributionCountry: CountryEnumDao!
    $selectedItemType: ItemType!
  ) {
    mergePlatformContent(
      mainContent: $mainContent
      subContent: $subContent
      distributionCountry: $distributionCountry
      selectedItemType: $selectedItemType
    ) {
      id
    }
  }

`;

export { MERGE_PLATFORM_CONTENT };
