import styled, { css } from 'styled-components';
import { Button, Input } from '@freeda/react-components';

import {
  scrollbarVerticalStyle,
  scrollbarHorizontalStyle,
} from '../../../components/ui/ScrollBarStyle';

const EditMediaWrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

const ButtonStyled = styled(Button)`
  && {
    color: ${(props) => (props.disabled ? '#C3C3C3' : 'rgba(0,0,0,0.87)')};
    & > i {
      color: ${(props) => (props.disabled ? '#C3C3C3' : 'rgba(0,0,0,0.87)')};
    }
  }
`;



const RowForm = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const PreviewEditWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 30% 70%;
  height: calc(100vh - 65px);
  background-color: #ffffff;
  top: 65px;
  overflow: hidden;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-bottom: 20px;
  }
`;

const TeamMemberWrapper = styled.div`
  width: 100%;
  & > div > div {
    width: 100%;
    align-items: center;
  }
`;

const mediaStyle = css`
  justify-self: center;
  align-self: center;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 450px;
  padding: 20px 0px 10px 0px;
`;

const calendarStyle = css`
    width: 100%;
    height: auto;
`;



const Img = styled.img<{ isVisible?: boolean }>`
  ${mediaStyle};

  ${(props) =>
    !props.isVisible &&
    css`
      display: none;
    `};
`;

const Video = styled.video`
  ${mediaStyle};
`;

const TitleInputForm = styled(Input)`
  && {
    cursor: text;
    &::after {
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      transform: scaleX(0);
      transition: transform 0.5s cubic-bezier(0, 0, 0.2, 1) 2ms;
      pointer-events: none;
      border-bottom: ${(props) => `2px solid ${props.theme.primary}`};
    }
    &::before {
      left: 0;
      right: 0;
      bottom: 0;
      content: '\00a0';
      position: absolute;
      transition: border-bottom-color 0.5s cubic-bezier(0.4, 0, 0.2, 1) 2ms;
      pointer-events: none;
      border-bottom: ${(props) => `1px solid ${props.theme.primary}`};
    }
    &:hover {
      ::before {
        border-bottom: ${(props) => `2px solid ${props.theme.primary}`};
      }
    }

    & > input {
      background: none;
      padding-left: 0;
      padding-right: 0;
      border: none;
      font-size: 24px;
      color: ${(props) => props.theme.primary};
      ::placeholder {
        color: #d8d8d8;
      }
      &:focus {
        ::before {
          transform: scaleX(1);
          border-bottom: ${(props) => `2px solid ${props.theme.primary}`};
        }
      }
    }
  }
`;

const ChipList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 35px;
  overflow-x: scroll;
  position: relative;
  align-self: start;

  & > div {
    margin-bottom: 3px;
  }
  & > div:not(:last-child) {
    margin-right: 3px;
  }

  ${scrollbarVerticalStyle};
`;

const AvatarList = styled.div`
  position: relative;
  top: 10px;
  display: flex;
  flex-basis: 80%;
  height: 40px;
  align-items: center;
  padding: 10px 0px;
  overflow-x: overlay;
  overflow-y: hidden;
  margin-right: 5px;
  margin-left: 5px;

  & > div {
    margin-right: 2px;
    margin-left: 2px;
  }

  ${scrollbarHorizontalStyle};
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: 20px;
  flex-wrap: wrap;
  & > div {
    padding-bottom: 5px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const InputWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  box-sizing: border-box;
  & > div > input {
    border-color: #999999;
  }
`;

const EditFormWrapper = styled.div`
  padding: 15px 35px 35px 35px;
  background: rgb(249, 249, 249);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: overlay;

  ${scrollbarVerticalStyle}
`;

const PreviewWrapper = styled.div`
  padding: 20px 35px;
  background: rgb(242, 242, 242);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: overlay;

  ${scrollbarVerticalStyle}
`;

const OtherCutsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

const Label = styled.span`
  margin-right: 15px;
  font-size: 12px;
  font-style: italic;
  text-transform: uppercase;
`;

export {
  PreviewEditWrapper,
  ColumnWrapper,
  Img,
  Video,
  TitleInputForm,
  ChipList,
  AvatarList,
  InfoWrapper,
  TeamMemberWrapper,
  ButtonWrapper,
  EditMediaWrapper,
  EditFormWrapper,
  OtherCutsWrapper,
  Label,
  ButtonStyled,
  RowForm,
  InputWrapperStyled,
  PreviewWrapper,
  calendarStyle,
};
