import * as React from 'react';
import styled from 'styled-components';
import { PlaceholderInput } from '../Placeholders/PlaceholderInput';
import { ButtonIconStyled } from '../Placeholders/styles';

const FilterWrapper = styled.div`
  width: 100%;
  height: 78px;
  background: #f5f5f5;
  padding: 0px 25px 0px 40px;
  justify-content: space-around;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #e6e6e6;
  margin-left: auto;
`;

const PlaceholderFilterMobile: React.FC = () => (
  <FilterWrapper>
    <SearchWrapper>
      <PlaceholderInput style={{ flexBasis: '94%' }}>
        <Circle />
      </PlaceholderInput>
    </SearchWrapper>
    <ButtonIconStyled disabled iconName="filter-list" style={{ width: 82, height: 65 }} />
  </FilterWrapper>
);

export { PlaceholderFilterMobile };
