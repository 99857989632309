import styled from 'styled-components';
import { Icon, ButtonIcon } from '@freeda/react-components';

const StyledButtonIcon = styled(ButtonIcon)`
  && {
    width: 48px;
    height: 48px;
  }
`;

const ActionIcon = styled(Icon)`
  && {
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  background-color: #6b20da;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 0;

  & > button {
    margin: 0px 25px;
  }

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
  }
`;

export { StyledButtonIcon, ActionIcon, ActionButtonsWrapper };
