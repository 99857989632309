import * as React from 'react';
import { parse } from 'query-string';
import { padStart } from 'lodash';
import { LastMediaPageLocationContext } from '../../providers/LastMediaPageLocationProvider';
import { uniq, difference } from 'lodash';
import {Roles} from '../../utils/roles'
import { Button } from '@freeda/react-components';
import {
  PreviewEditWrapper,
  EditMediaWrapper,
  EditFormWrapper,
  ButtonWrapper,
} from '../../components/layout/EditMediaPage/styles';
import GetEditFormInfoQuery from '../../types/types2.0/graphql2.0/GetEditInfoFormQuery';
import { ContentType } from '../../types/ContentType';
import { User } from '../../types/types2.0/User';
import { Keywords } from '../../types/types2.0/Keywords';
import { LanguageCountry } from '../../types/Language';
import { Country as DistributionCountry, Country } from '../../types/graphql/Country';
import { Format } from '../../types/types2.0/Format';
import { Preview } from '../../components/layout/EditMediaPage/Preview2.0';
import {PublicationPanel} from '../../components/layout/EditMediaPage/PublicationPanel'
import { HeaderEditMedia } from '../../components/ui/HeaderEditMedia';
import { ItemType } from '../../types/ItemType';
import { ContentInfo } from '../../components/layout/EditMediaPage/ContentInfo2.0';
import { ProductionInfo } from '../../components/layout/EditMediaPage/ProductionInfo2.0';
import { LanguageSettings } from '../../components/layout/EditMediaPage/LanguageSettings2.0';
import SaveMediaButton from '../../containers/SaveMediaButton2.0';
import { DeletePlatformDialog } from '../../components/layout/DeleteMediaDialog2.0';
import { SaveMediaDialog } from '../../components/layout/SaveMediaDialog';
//import { PlatformContent } from '../../types/types2.0/PlatformContent';
import { DisabledLayer } from '../../components/ui/DisabledLayer';

import {
  //getFirstAvailablePlatformContentType,
  getFirstAvailablePlatformContentType2,
  getItemTypesFromString,
  stringifyMedia,
  getLocalContent,
  getPlatformContent,
  getComponentContent,
  getVideoDuration
} from '../../utils/contentUtils';

import {
  EnhancedParentContent,
  EnhancedLocalContent,
  EnhancedPlatformContent,
  EnhancedComponentContent,
} from '../../types/types2.0/EnhancedContent';

import { ParentContent } from '../../types/types2.0/ParentContent';
import { UploadNewCountryPage } from '../UploadNewCountryPage2.0';

import { v4 } from 'uuid';
import { UploadNewCutPage } from '../UploadNewCutPage2.0';
import {
  buildEnhancedParentContent,
  buildEnhancedLocalContent,
  save,
  withMergedItemLocalization,
  changePath,
  changePlatform,
  getComponents
} from './utils';

import {
  FetchedItemsCounterContext
} from '../../providers/FetchedItemsCounterProvider';

import { CutType } from '../../types/CutType';
import { VideoType } from '../../types/VideoType';
//import { getUpdateInfo } from '../../utils/getUpdateInfo';
import { LocalContent } from '../../types/types2.0/LocalContent';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ComponentContent } from '../../types/types2.0/ComponentContent';
import { ButtonIcon, Icon, Typography, /*TitleInput ,*/ Input} from '@freeda/react-components';
import { PlatformType } from '../../types/types2.0/PlatformType';
import { forEach } from 'lodash';
//import { readFile } from 'fs';
//import { platform } from 'os';
//import { Campaign } from '../../types/types2.0/Campaign';
import { Label } from '../../types/types2.0/Label';
import { CSSTransition } from 'react-transition-group';
//import { ParentContentQuery } from '../../components/queries/queries2.0/ParentContentQuery';

export type EditStep = 'EDIT' | 'NEW_COUNTRY' | 'NEW_CUT';

type Props = { parentContent: ParentContent } & GetEditFormInfoQuery;

type State = {
  newFileUploadProgress: Array<{ id: string; progress: number }>;
  selectedLocalizationCountry: DistributionCountry;
  parent: EnhancedParentContent;
  localizations: Array<EnhancedLocalContent>;
  stepIndex: number;
  newTranslationDistributionCountry: DistributionCountry | null;
  openDeleteDialog: boolean;
  openSaveDialog: boolean;
  stringifiedContent: string;
  selectedItemType: ItemType;
  idPlatform: string| null;
  selectedPlatformType: PlatformType;
  //selectedItemType: String;
  selectedComponentId: string | null;
  editStep: EditStep;
  editPanel: boolean;
  disableButton: boolean;
  readOnlyUser:boolean;
  member:any
};

class EditMediaPage extends React.Component<Props & RouteComponentProps<{}>, State> {
  
  constructor(props: Props & RouteComponentProps<{}>) {
    super(props);

    const { parentContent } = props;
    const queryParams = parse(window.location.search);

    const parent: EnhancedParentContent = buildEnhancedParentContent(parentContent);
    

    // build state translations with items enhancing them with field used only to
    // update form or check if it is a new added object
    const localizations: Array<EnhancedLocalContent> = buildEnhancedLocalContent(parentContent);

    // stringify  before adding addedTranslations and addedItems
    // in this way we notice about added stuff


    const stringifiedContent = stringifyMedia(parent, localizations);
    const localContent =
          localizations.find((t) => t.distributionCountry === queryParams.country) || localizations[0];

    
    /*const platformType = getFirstAvailablePlatformContentType(
      localContent.platforms.map((i) => i.itemType),
      queryParams.cut as string | undefined
    );*/
    const platformType = PlatformType[localContent.platforms[0].itemType];

    const mediaItemType = getFirstAvailablePlatformContentType2(
      localContent.platforms.map((i) => i.mimeType),
      localContent.platforms.map((i) => i.itemType),
      queryParams.cut as string | undefined
    );

    localStorage.removeItem('merge');
    const roles:string = localStorage.getItem('roles')!;
    const readOnlyUser:boolean = roles.includes(Roles['read_only']);
    const member:any= this.props.allTeamMembers.filter(m=>m.name===localStorage.getItem('name')!)[0]


    this.state = {
      stringifiedContent,
      readOnlyUser: readOnlyUser,
      member: member,
      parent,
      localizations,
      newFileUploadProgress: [],
      selectedLocalizationCountry: localContent.distributionCountry,
      stepIndex: 0,
      newTranslationDistributionCountry: null,
      openDeleteDialog: false,
      openSaveDialog: false,
      selectedItemType: mediaItemType,
      selectedPlatformType:platformType,
      selectedComponentId: null,
      idPlatform:null,
      editPanel:false,
      disableButton:false,
      editStep: 'EDIT'
    };
  }

  
  /*getVideoDuration = (file: Blob) =>
  new Promise<number>((resolve, reject) => {
    const previewUrl = URL.createObjectURL(file);
    const reader : FileReader= new FileReader();
    reader.onload = () => {
      const media = new Audio(previewUrl);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });*/

  formatVideoLength = (videoLength?: number) => {
    if (videoLength) {
      return `${padStart(String(Math.floor(videoLength / 60)), 2, '0')}:${padStart(
        String(videoLength % 60),
        2,
        '0'
      )}`;
    }
  
    return null;
  };

  addLocalization = (distributionCountry: DistributionCountry) => async (file: File) => {
    
    const { name, type } = file;
    const isImage = type.indexOf('image') >= 0;
    let length: number | null;
    if(!isImage){
      
      try{
        length = await getVideoDuration(file).then(function(length){
          return length;
        });
        
        if(length && length>0 && length.toString().indexOf('.')>=0) {
          let arr = length.toString().split('.')
          length = parseInt(arr[0])
        }
       
      } catch(e){
        console.log('ERRORE NEL TROVARE VIDEO_LENGTH')
      }
      
    }
    const videoType = isImage ? null : (this.state.parent.format==Format.INSTAGRAM_STORY ? VideoType.STORY : VideoType.VIDEO);
    const cutType = isImage ? null : videoType == VideoType.STORY ? CutType.VIDEO_INSTAGRAM_STORY : CutType.VIDEO_FACEBOOK;
    //valori di default
    const itemType = isImage ? ItemType.IMAGE_INSTAGRAM : (this.state.parent.format==Format.INSTAGRAM_STORY ? ItemType.VIDEO_INSTAGRAM_STORY : ItemType.VIDEO_FACEBOOK) ;
    
    let split = itemType.split('_')
    let platform = ( this.state.parent.format ==Format.INSTAGRAM_STORY ? "INSTAGRAM": split![1])

    const previewUrl = URL.createObjectURL(file);  

    const platformId = v4()
    this.setState((state) => ({
      selectedItemType: itemType,
      selectedLocalizationCountry: distributionCountry,
      parent: {...state.parent, completed: false},
      localizations: [
        ...state.localizations,
        {
          id:v4(),
          creator: localStorage.getItem('name')!,
          members:[],
          contributors: [],
          distributionCountry,
          addedContributors: [],
          removedContributors: [],
          newContributors: [],
          addedMembers: [state.member],
          removedMembers: [],
          newMembers: [],
          videoType,
          isNew: true,
          isUpdate:false,
          languageSubtitle:null,
          platforms:[
            {
              id: platformId,
              thumbnailUrl: null,
              itemType: PlatformType[platform],
              mimeType:type,
              platform:platform,
              storagePath:'',
              title:name,
              sources: state.parent.sources, //lo stesso di parent
              cutType,
              originalItemType: itemType,
              creator: localStorage.getItem('name')!,
              newFile: file,
              newUrl: previewUrl,
              videoLength: length ? length : null ,
              onlyMusic: false,
              noSubtitle: false,
              country: distributionCountry,
              isNew: true,
              isUpdate: false,
              components: []
            }
          ]
    }]}));

    this.setState(
      { 
        idPlatform: platformId
      },
      () => {
          changePath(this.state.parent.id, distributionCountry, itemType);
        }
    );
  };


  removeClonePlatform = ( platform:string) => {

    let oldplatform : EnhancedPlatformContent[]= []
    let newplatforms : EnhancedPlatformContent[]= []

    this.state.localizations.map((t) => {
      if ( t.distributionCountry === this.state.selectedLocalizationCountry ) {
        oldplatform = t.platforms
      }
    })

    oldplatform.map((i) => {
      console.log(i.originalItemType === platform)
      if (i.originalItemType !== platform) {
        newplatforms.push(i)
      }
    })
    
    this.setState((state) => ({ 
      parent: {...state.parent},
      localizations: 
        state.localizations.map((t) => {
        if ( t.distributionCountry === this.state.selectedLocalizationCountry ) {
          if(t.isNew){
            t.isUpdate = false
          }
          else{
            t.isUpdate = true
          }
          
          return {
              ...t,
              platforms: newplatforms
          }
        }
        return t;
        }
      )
    }))

  }

  clonePlatform = ( /*platforms: Array<string>,*/ platform:string, platformToCLone:EnhancedPlatformContent) => {
   
    let platformClone:EnhancedPlatformContent[] = [];
    let components:EnhancedComponentContent[] = []

    if(platformToCLone.components && platformToCLone.components.length > 0){
      const localization = this.state.localizations.filter(l => l.distributionCountry == this.state.selectedLocalizationCountry)[0];
      const platform = localization.platforms.filter(p => p.id == platformToCLone.id )[0];
      //components = platform.components

      platform.components.map((c) => {
        c.isNew = true
        components.push(c)
      })

    }

    let newPlatform:EnhancedPlatformContent = {
              id: v4(),
              title: platformToCLone.title,
              mimeType: platformToCLone.mimeType,
              storagePath: platformToCLone.storagePath,
              thumbnailUrl: platformToCLone.thumbnailUrl,
              itemType: PlatformType[platform],
              cutType: null,
              originalItemType: PlatformType[platform],
              creator: localStorage.getItem('name')!,
              videoLength: platformToCLone.videoLength ,
              newFile: platformToCLone.newFile,
              newUrl: platformToCLone.newUrl,
              //platform: PlatformType[platform],
              //noSubtitle: false,
              country: this.state.selectedLocalizationCountry,
              isNew: true,
              isUpdate:false,
              components: components
      }
      platformClone.push(newPlatform)


    this.setState((state) => ({ 
        parent: {...state.parent},
        localizations: 
          state.localizations.map((t) => {

          if ( t.distributionCountry === this.state.selectedLocalizationCountry ) {
            
            if(t.isNew){
              t.isUpdate = false
            }
            else{
              t.isUpdate = true
            }
            t.addedMembers.push(this.state.member)
            
            
            return {
                ...t,
                platforms: [
                  ...t.platforms,
                  ...platformClone,
                ]
            }
          }
          return t;
          }
        )
      }))
 
  }

  addPlatforms = (
    selectedItemType: ItemType,
    selectedCutType: CutType,
    selectedCountry: DistributionCountry
  ) => async (file: File) => {
    const { name, type } = file;
    const isImage = type.indexOf('image') >= 0;
    let length: number | null;
    
    if(!isImage){
      try{
        length = await getVideoDuration(file).then(function(length){
          return length;
        });
        if(length && length>0 && length.toString().indexOf('.')>=0) {
          let arr = length.toString().split('.')
          length = parseInt(arr[0])
        }
      }catch(e){
        console.log('ERRORE NEL TROVARE VIDEO_LENGTH')
      }
    }
   
    let platform = ''
    if(selectedItemType.indexOf('_')>0){
      let split=selectedItemType.split('_')
      platform=split[1]
    }
    else{
      platform=selectedItemType
    }

    const previewUrl = URL.createObjectURL(file);



    this.setState((state) => ({ 
      selectedItemType,
      parent: {...state.parent, onlyMusic: state.parent.onlyMusic},
      localizations: 
        state.localizations.map((t) => {
          if (t.distributionCountry === selectedCountry) {
            if(t.isNew === false) t.isUpdate = true
            t.addedMembers.push(this.state.member)
            
          
            return {
              ...t,
              platforms: [
                ...t.platforms,
                {
                  id: v4(),
                  title: name,
                  mimeType: type,
                  storagePath: '',
                  thumbnailUrl: null,
                  itemType: PlatformType[platform],
                  cutType: selectedCutType,
                  originalItemType: selectedItemType,
                  creator: localStorage.getItem('name')!,
                  videoLength: length ? length : null ,
                  newFile: file,
                  newUrl: previewUrl,
                  platform: PlatformType[platform], ///qui da prendere il valore della piattaforma
                  noSubtitle: false,
                  country: selectedCountry,
                  isNew: true,
                  isUpdate:false,
                  components: [],
                },
              ],
            };
          }
          return t;
      }),
    }));
  };


  addComponents = async(files: Array<File>, platformId:string) => {
    const localization = this.state.localizations.filter(l => l.distributionCountry == this.state.selectedLocalizationCountry)[0];
    const platform = localization.platforms.filter(p => p.id == platformId )[0];
    let updatedPlatforms:EnhancedPlatformContent[] = [];
    const newComponents: Array<EnhancedComponentContent> = []
    let i = 0;
    
    for await (const file of files) {

      const { name, type } = file;
      const previewUrl = URL.createObjectURL(file);
      const isImage = type.indexOf('image') >= 0;

      let el = {
          id: v4(),
          title: name.replace(/\.[^/.]+$/, ''),
          mimeType: type,
          newUrl: previewUrl,
          newFile: file,
          isNew: true,
          isUpdate:false,
          storagePath: '',
          thumbnailUrl: null,
          videoLength:0,
          position: platform.components.length + (i++) ,
          creator: localStorage.getItem('username')!
      }
      if(!isImage){
        
        try{
          let length: number =  await getVideoDuration(file).then(function(length){ return length });
          
          if(length && length>0) {
            if(length.toString().indexOf('.')>=0){
              let arr = length.toString().split('.')
              el.videoLength=parseInt(arr[0]) 
            }
            else{
              el.videoLength=length
            }
            
            newComponents.push(el)

          }
        }catch(e){
          console.log('ERRORE NEL TROVARE VIDEO_LENGTH')
        }
      }
      else{
        newComponents.push(el)
      }

    }
    forEach(localization.platforms, function(plt){

      if(platform && plt.id==platform.id){
        plt.isUpdate = plt.isNew ? false : true
        plt.components = [...plt.components, ...newComponents]
      }
      updatedPlatforms.push(plt)

    })

    const newLocalization = { ...localization, platforms: updatedPlatforms }
    let  updatedLocalizations:EnhancedLocalContent[] = [];
    forEach(this.state.localizations, function(lc){

      if(lc.id==newLocalization.id){
        lc = newLocalization
      }
      updatedLocalizations.push(lc)
    })
    this.setState((state) => {
      return {
        ...state,
        localizations: updatedLocalizations,
      };
    });

    
  };


  handleTitleChange = (title: string) => {
    this.setState({
      parent: Object.assign({}, this.state.parent, { title }),
    });
  };



  handleTitleChangeOLD = (title: string) => {
    
    this.setState((state) => {
      const localContent = getLocalContent(
        state.localizations,
        state.selectedLocalizationCountry
      );
      const platform = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
        localContent,
        state.selectedItemType,
        null
      );
      return {
        localizations: state.localizations.map((t) => {
          if (t.id === localContent.id) {
            return {
              ...localContent,
              platforms: localContent.platforms.map((i) => {
                if (i.id === platform.id) {
                  const selectedComponentId = state.selectedComponentId;
                  if (selectedComponentId) {
                    return {
                      ...i,
                      components: i.components!.map((f) =>
                        f.id === selectedComponentId ? { ...f, title } : f
                      ),
                    };
                  } else {
                    return { ...i, title };
                  }
                }
                return i;
              }),
            };
          }
          return t;
        }),
      };
    });
  };

  handleProductTypeChange = (productTypeId: string) => {
    const productType = this.props.allProductTypes.find((p) => p.id === productTypeId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { productType }),
    });
  };

  handleContentTypeChange = (contentType: ContentType) => {
    this.setState({
      parent: Object.assign({}, this.state.parent, { contentType, brand: null }),
    });
  };

  handleItemTypeChange = (videoType: VideoType) => {
    const { parentContent } = this.props;
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const platformContent = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      this.state.idPlatform
    );
    const selectedItem =
        platformContent.mimeType.startsWith('video') && videoType==VideoType.STORY
          ? ItemType.VIDEO_INSTAGRAM_STORY
          : platformContent.originalItemType;
    
    const format = videoType==VideoType.STORY ? selectedItem : videoType

    this.handleChangeFormat(Format[format])

    this.setState(
      (state) => {
        return {
          ...state,

          localizations: state.localizations.map((t) => {
            if (t.id === localContent.id) {
              return {
                ...localContent,
                videoType,
                platforms: localContent.platforms.map((i) => {
                  
                  /*const originalItemType =
                    i.originalItemType === ItemType.VIDEO_INSTAGRAM_STORY
                      ? ItemType.VIDEO_FACEBOOK
                      : i.originalItemType;*/

                  const itemType =
                    videoType === VideoType.STORY ? PlatformType.INSTAGRAM : i.originalItemType;
                  
                  const isReel=
                    videoType === VideoType.STORY ? false : i.isReel;
                  
                  return {
                    ...i,
                    itemType,
                    isReel,
                    cutType: videoType === VideoType.STORY ? null : (CutType[itemType] as CutType),
                  };
                }),
              };
            }
            return t;
          }),
          //selectedItemType: videoType === VideoType.STORY ? ItemType.VIDEO_INSTAGRAM_STORY : selectedItem,
          selectedItemType: selectedItem,
          selectedFrame: null,
        };
      },
      () => {
        const { selectedItemType } = this.state;
        changePath(parentContent.id, localContent.distributionCountry, selectedItemType);
      }
    );
  };

  handleCutTypeChange = (cutType: CutType) => {
    const { parentContent } = this.props;
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const item = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      this.state.idPlatform
    );
    this.setState(
      {
        selectedItemType: ItemType[cutType],
        localizations: this.state.localizations.map((t) => {
          if (t.id === localContent.id) {
            localContent.isUpdate=true
            return {
              ...localContent,
              platforms: localContent.platforms.map((i) => {
                if (i.id === item.id) {
                  return {
                    ...i,
                    cutType,
                    itemType: ItemType[cutType],
                    originalItemType: ItemType[cutType],
                    isUpdate:true
                  };
                }
                return i;
              }),
            };
          }
          return t;
        }),
      },
      () => {
        changePath(parentContent.id, localContent.distributionCountry, ItemType[cutType]);
      }
    );
  };
  
  handleIsReel  = (reel:boolean) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    const item = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      this.state.idPlatform
    );

    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate = t.isNew ? false : true

          return {
            ...localContent,
            platforms: localContent.platforms.map((i) => {
              if (i.id === item.id) {
                let isUpdate = i.isNew ? false : true
                return { ...i, isReel:reel , isUpdate:isUpdate };
              }
              return i;
            }),
          };
        }
        return t;
      }),
    }));


  }

  handlePlatformTypeChange = (platformType: PlatformType, isReel?: boolean) => {
    const { parentContent } = this.props;
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    const item = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      this.state.idPlatform
    );

    const type = item.mimeType.indexOf('image') >= 0 ? 'IMAGE' : 'VIDEO'
    const itemType= String( type.concat('_').concat(PlatformType[platformType]))
    localStorage.setItem('cut',  itemType)
    
   
    this.setState(
      {
        selectedPlatformType: PlatformType[platformType],
        selectedItemType:ItemType[itemType],
        localizations: this.state.localizations.map((t) => {
          if (t.id === localContent.id) {
            localContent.isUpdate=true
            return {
              ...localContent,
              platforms: localContent.platforms.map((i) => {
                if (i.id === item.id) {
                  if(i.isNew){
                    return {
                      ...i,
                      platformType,
                      itemType: PlatformType[platformType],
                      originalItemType: this.state.selectedItemType,
                      isUpdate: false,
                      isReel: isReel ?  true : null
                    };
                  }
                  else{
                    return {
                      ...i,
                      platformType,
                      itemType: PlatformType[platformType],
                      originalItemType: this.state.selectedItemType,
                      instantArticleUrl: "",
                      isUpdate: true,
                      isReel: isReel ?  true : null
                    };
                  }
                      
                }
                return i;
              }),
            };
          }
          return t;
        }),
      },
      () => {
        changePlatform(parentContent.id, localContent.distributionCountry, PlatformType[platformType], type);
      }
    );
  };

  //qui
  handleChangeFormat = (format: Format) => {
   
    this.handleInstantArticleUrlChange("") 

    let productType = this.props.allProductTypes.find((s) => s.format === format)!; //in generale prendo il primo elemento che ritorna il filtro
    
    //se il formato è instant Article trigghero il change su platform
    if( format != Format.INSTANT_ARTICLES ){
      //console.log('handleChangeFormat___',format )
        if(format==Format.INSTAGRAM_STORY || format==Format.CAROUSEL || format==Format.IMAGE){
            this.handlePlatformTypeChange(PlatformType.INSTAGRAM, false)
        }
        else if(format==Format.VIDEO){
            productType = this.props.allProductTypes.find((p) => p.name.toLowerCase() === 'video original')!;
        }
       
    } else {
        this.handlePlatformTypeChange(PlatformType.FACEBOOK, false)
    }

    this.setState({
      parent: Object.assign({}, this.state.parent, { format, productType: productType? productType : null }),
    });
    //console.log("___handleChangeFormat__",this.state.parent.format)

  };

  handleBrandChange = (brandId: string) => {
    const brand = this.props.allBrands.find((b) => b.id === brandId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { brand }),
    });
  };

  handleCampaignChange = (campaignId: string) => {
    const campaign = this.props.allCampaigns.find((c) => c.id === campaignId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { campaign }),
    });
    
  };

  handleEditorialCampaignChange = (editorialCampaignId: string) => {
    const editorialCampaign = this.props.allEditorialCampaigns.find((c) => c.id === editorialCampaignId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { editorialCampaign }),
    });
   
  };

  handleProjectChange = (projectId: string) => {
    const project = this.props.allProjects.find((p) => p.id === projectId)!;
    
    let contentType = this.state.parent.contentType;
    //let booleans=null;
    
    /*if(project.name.toLocaleLowerCase() !='freeda'){
       contentType=ContentType.BRANDED
       booleans  = this.state.parent.booleans?.filter(function(item) {
        return item.name.toLowerCase() !== 'branded'
      })

    }*/
 
    this.setState({
      parent: Object.assign({}, this.state.parent, { project, /*booleans ,*/ contentType, editorialCampaign:null, brand:null, campaign:null, serie:null,
                                topic:null, product:null, genre:null, objective:null, temperature:null}),
    });
  };

  handleSerieChange = (serieId: string) => {
    const serie = this.props.allSeries.find((s) => s.id === serieId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { serie }),
    });
  };

  handleTopicChange = (topicId: string) => {
    const topic = this.props.allTopics.find((t) => t.id === topicId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { topic }),
    });
  };


  handleObjectiveChange = (objectiveId: string) => {
   const objective = this.props.allObjectives.find((t) => t.id === objectiveId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { objective }),
    });
   
  };

  handleTemperatureChange = (temperatureId: string) => {
    const temperature = this.props.allTemperatures.find((t) => t.id === temperatureId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { temperature }),
    });
   
  };
  

  handleAddBoolean= (idBooleans: Array<Label>) => {
   
    let booleans:Array<Label>=[]
    let contentType = ContentType.EDITORIAL
    let editorialCampaign=this.state.parent.editorialCampaign
    let brand = this.state.parent.brand
    let campaign = this.state.parent.campaign
   
    //sel il progetto non ha nome freeda tolgo branded
    idBooleans.forEach((booleanIn) => {
      if(booleanIn.name.toLowerCase() == ContentType.BRANDED.toLowerCase() ){
          contentType = ContentType.BRANDED
          editorialCampaign = undefined
        };
      booleans.push( {id:booleanIn.id,name:booleanIn.name})
    });
      
    if(contentType == ContentType.EDITORIAL){
        brand = undefined
        campaign = undefined

    }

    this.setState({
      parent: Object.assign({}, this.state.parent, { booleans , contentType, editorialCampaign, brand, campaign}),
    });
  }

  handleProductChange = (productId: string) => {
   const product = this.props.allProducts.find((t) => t.id === productId)!;
    this.setState({
      parent: Object.assign({}, this.state.parent, { product }),
    });
   
  };

  handleGenreChange = (genreId: string) => {
     const genre = this.props.allGenres.find((t) => t.id === genreId)!;
     this.setState({
       parent: Object.assign({}, this.state.parent, { genre }),
     });
    
  };

  //i members sono di tipo array non più string CHECK
  handleMembersChange = (member: User) => {
    
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    const wasRemoved = localContent.removedMembers.find((c) => c.id === member.id);

    const originalMediaTranslation = this.state.localizations.find(
      (c) => c.distributionCountry === this.state.selectedLocalizationCountry
    )!;
    const memberCheck =
      originalMediaTranslation.members &&
      originalMediaTranslation.members.find((c) => c.id === member.id);
    
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate = true;
         
          return Object.assign({}, t, {
            addedMembers: !memberCheck
              ? [{ id: member.id, name: member.name }, ...t.addedMembers]
              : t.addedMembers,

            removedMembers: wasRemoved
              ? t.removedMembers.filter((c) => c.id !== member.id)  : t.removedMembers
          })
        }
        return t;
      }),
    }));

    
  };


  handleAddedContributorsChange = (contributor: User) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const wasRemoved = localContent.removedContributors.find((c) => c.id === contributor.id);

    const originalMediaTranslation = this.state.localizations.find(
      (c) => c.distributionCountry === this.state.selectedLocalizationCountry
    )!;
    const mediaContributor =
      originalMediaTranslation.contributors &&
      originalMediaTranslation.contributors.find((c) => c.id === contributor.id);

    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate = true;
          return Object.assign({}, t, {
            addedContributors: !mediaContributor
              ? [{ id: contributor.id, name: contributor.name }, ...t.addedContributors]
              : t.addedContributors,
            removedContributors: wasRemoved
              ? t.removedContributors.filter((c) => c.id !== contributor.id)
              : t.removedContributors,
          });
        }
        return t;
      }),
    }));
  };

 
  //parte dei metadatati
  handleAddedKeywordsChange = (metadata: Keywords) => {
    const wasRemoved = this.state.parent.removedKeyword.find((k) => k.id === metadata.id);
    const mediaKeyword =
      this.props.parentContent.keywords && this.props.parentContent.keywords.find((k) => k.id === metadata.id);

    this.setState((state) => ({
      parent: {
        ...state.parent,
        addedKeyword: !mediaKeyword
          ? [...state.parent.addedKeyword, metadata]
          : state.parent.addedKeyword,
        removedKeyword: wasRemoved
          ? state.parent.removedKeyword.filter((k) => k.id !== metadata.id)
          : state.parent.removedKeyword,
      },
    }));
  };

  handleRemovedKeywordsChange = (metadata: Keywords) => {
    const wasAdded = this.state.parent.addedKeyword.find((k) => k.id === metadata.id);
    const mediaKeyword =
      this.props.parentContent.keywords && this.props.parentContent.keywords.find((k) => k.id === metadata.id);

    this.setState((state) => ({
      parent: {
        ...state.parent,
        removedKeyword: mediaKeyword
          ? [...state.parent.removedKeyword, metadata]
          : state.parent.removedKeyword,
        addedKeyword: wasAdded
          ? state.parent.addedKeyword.filter((k) => k.id !== metadata.id)
          : state.parent.addedKeyword,
      },
    }));
  };

  //qui aggiungono i metadati  ma a tendere non è possibile
  handleAddedNewKeywordsChange = (keywordLabel: string) => {
    const lowerCasedKeywordLabel = keywordLabel.toLowerCase().trim();
    const existingMetadata = this.props.parentContent.keywords || [];
    const { addedKeyword, removedKeyword } = this.state.parent;

    const currentKeywords = [...addedKeyword, ...existingMetadata].filter(
      (k) => !removedKeyword.find((rk) => rk.id === k.id)
    );

    const existingKeyword = existingMetadata.find(
      (k) => k.name.toLowerCase() === lowerCasedKeywordLabel
    );

    if (existingKeyword) {
      this.setState((state) => ({
        parent: {
          ...state.parent,
          removedKeyword: state.parent.removedKeyword.filter((k) => k.id !== existingKeyword.id),
        },
      }));
    } else {
      const exists =
        currentKeywords.find((k) => k.name.toLowerCase() === lowerCasedKeywordLabel) ||
        this.state.parent.newKeyword.find((k) => k === lowerCasedKeywordLabel);

      if (!exists) {
        const keyword = this.props.allKeywords.find(
          (k) => k.name.toLowerCase() === lowerCasedKeywordLabel
        );
        if (keyword) {
          this.setState((state) => ({
            parent: {
              ...state.parent,
              addedKeyword: [...state.parent.addedKeyword, keyword],
            },
          }));
        } else {
          this.setState((state) => ({
            parent: {
              ...state.parent,
              newKeyword: [...state.parent.newKeyword, lowerCasedKeywordLabel],
            },
          }));
        }
      }
    }
  };

  handleRemovedNewKeywordsChange = (keywordLabel: string) => {
    this.setState((state) => ({
      parent: {
        ...state.parent,
        newKeyword: state.parent.newKeyword.filter((k) => k !== keywordLabel),
      },
    }));
  };

  handleRemovedMembersChange = (member: User) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const wasAdded = localContent.addedMembers.find((c) => c.id === member.id);

    const originalMediaTranslation = this.state.localizations.find(
      (c) => c.distributionCountry === this.state.selectedLocalizationCountry
    )!;
    const contentMembers =
      originalMediaTranslation.members &&
      originalMediaTranslation.members.find((c) => c.id === member.id);

    this.setState((state) => ({
      localizations: state.localizations.map((t) => {

        localContent.isUpdate = true;
        if (t.id === localContent.id) {
          return Object.assign({}, t, {
            removedMembers: contentMembers
              ? [member, ...t.removedMembers]
              : t.removedMembers,
            addedMembers: wasAdded
              ? t.addedMembers.filter((c) => c.id !== member.id)
              : t.addedMembers,
          });
        }
        return t;
      }),
    }));
  };

  handleRemovedContributorsChange = (contributor: User) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const wasAdded = localContent.addedContributors.find((c) => c.id === contributor.id);

    const originalMediaTranslation = this.state.localizations.find(
      (c) => c.distributionCountry === this.state.selectedLocalizationCountry
    )!;
    const mediaContributor =
      originalMediaTranslation.contributors &&
      originalMediaTranslation.contributors.find((c) => c.id === contributor.id);

    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate = true;
          return Object.assign({}, t, {
            removedContributors: mediaContributor
              ? [contributor, ...t.removedContributors]
              : t.removedContributors,
            addedContributors: wasAdded
              ? t.addedContributors.filter((c) => c.id !== contributor.id)
              : t.addedContributors,
          });
        }
        return t;
      }),
    }));
  };

  handleAddedNewContributorsChange = (contributorName: string) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    const existingContributors = localContent.contributors || [];
    const { addedContributors, removedContributors } = localContent;
    const currentContributors = [...addedContributors, ...existingContributors].filter(
      (c) => !removedContributors.find((rc) => rc.id === c.id)
    );

    const exists =
      currentContributors.find(
        (c: any) => c.name.toLowerCase() === contributorName.toLowerCase()
      ) ||
      localContent.newContributors.find(
        (c) => c.toLowerCase() === contributorName.toLowerCase()
      );

    if (!exists) {
      this.setState((state) => ({
        localizations: state.localizations.map((t) => {
          if (t.id === localContent.id) {
            localContent.isUpdate = true;
            return Object.assign({}, t, {
              newContributors: [...t.newContributors, contributorName],
            });
          }
          return t;
        }),
      }));
    }
  };

  handleRemovedNewContributorsChange = (contributorName: string) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        localContent.isUpdate= !t.isNew
        if (t.id === localContent.id) {
          return Object.assign({}, t, {
            newContributors: t.newContributors.filter((c) => c !== contributorName),
          });
        }
        return t;
      }),
    }));
  };

  //field di  parent
  handleSourcesChange = (sources: string) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const item = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      this.state.idPlatform
    );
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate=!t.isNew
          return {
            ...localContent,
            platforms: localContent.platforms.map((i) => {
              if (i.id === item.id) {
                return { ...i, sources, isUpdate:!i.isNew };
              }
              return i;
            }),
          };
        }
        return t;
      }),
    }));

    this.setState({
      parent: Object.assign({}, this.state.parent, { sources: sources }),
    });
  };

  handleInstantArticleUrlChange = (instantArticleUrl: string) => {
    
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const item = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      this.state.idPlatform
    );
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate=!t.isNew
          return {
            ...localContent,
            platforms: localContent.platforms.map((i) => {
              if (i.id === item.id) {
                return { ...i, instantArticleUrl, isUpdate: !i.isNew };
              }
              return i;
            }),
          };
        }
        return t;
      }),
    }));

  };

  ///cambiare nel contenuto LOCAL
  handleCopyLanguageChange = (languageCopy: LanguageCountry) => {
    
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          return {
            ...localContent, languageCopy, isUpdate: !t.isNew
          };
        }
        return t;
      }),
    }));
  };

  //cambia in Parent Content
  handleSoundLanguageChange = (languageSound: LanguageCountry) => {
    this.setState((state) => ({
      parent: {
        ...state.parent, languageSound:languageSound
      }
    }));
  };

  handleSubLanguageChange = (languageSubtitle: LanguageCountry) => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
   
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          return Object.assign({}, t, { languageSubtitle, isUpdate: !t.isNew});
        }
        return t;
      }),
    }));
  };

  handleDistributionCountryChange = (distributionCountry: DistributionCountry) => {
    const { parentContent } = this.props;
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    this.setState(
      (state) => ({
        selectedLocalizationCountry: distributionCountry,
        localizations: state.localizations.map((t) => {
          if (t.id === localContent.id) {
            return Object.assign({}, t, { distributionCountry, isUpdate: !t.isNew });
          }
          return t;
        }),
      }),
      () => {
        const { selectedItemType } = this.state;
        changePath(parentContent.id, distributionCountry, selectedItemType);
      }
    );
  };

  ///cambia in Parent
  handleOnlyMusicChange = () => {
    
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    this.setState((state) => ({
      
      parent: {
        ...state.parent,
        onlyMusic: !state.parent.onlyMusic,
        noSubtitle: false,
        languageSound: null
      },
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          return {
            ...localContent,
            languageSubtitle: null,
            isUpdate: !t.isNew
            
          };
        }
        return t;
      }),
    }));
  };

  //cambia in LOCAL CONTENT
  handleNoSubtitleChange = () => {
    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
   
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          return {
            ...localContent,
            noSubtitle: !t.noSubtitle,
            languageSubtitle: null,
            isUpdate: !t.isNew
          };
        }
        return t;
      }),
    }));
  };

  
  handleNewFileSelection = (platformId: string) => async (newFile: File, clone:boolean) => {

    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );
    const { type } = newFile;
    const isImage = type.indexOf('image') >= 0;
    let length: number | null;
    const url = URL.createObjectURL(newFile);
    let newFileInfo = { newFile, newUrl: url, title: newFile.name.replace(/\.[^/.]+$/, '')};
    
    if(!isImage){
      try{
        length = await getVideoDuration(newFile).then(function(length){
          return length;
        });

        if(length && length>0 && length.toString().indexOf('.')>=0) {
          let arr = length.toString().split('.')
          length = parseInt(arr[0])
        }
        newFileInfo['videoLength'] = length ;
      }catch(e){
        console.log('ERRORE NEL TROVARE VIDEO_LENGTH')
      }
    }
    
    this.setState((state) => ({
      localizations: state.localizations.map((t) => {
        if (t.id === localContent.id) {
          localContent.isUpdate=true

          if(clone){
            
            let position:any = null
            if (state.selectedComponentId) {
                localContent.platforms
                .map((t) => t.components)
                .map(
                    (c) => { 
                        c.map((f) => {
                          if (f.id === state.selectedComponentId) {
                            position = f.position
                          }
                          
                        }
                      ) 
                    }
                  )
                }

            return {
              ...localContent,
              platforms: localContent.platforms.map((i) => {
                  i.isUpdate=true
                  if (!state.selectedComponentId) {
                    i.awsReplace = true
                    return {
                      ...i,
                      ...newFileInfo,
                    };
                  } 
                  else {
                    return {
                      ...i,
                      components: i.components
                        ? i.components.map((f) =>
                            f.id === state.selectedComponentId || position==f.position ? { ...f, ...newFileInfo, ...{awsReplace:true} } : f
                          )
                        : i.components,
                    };
                  }
                })
            }
          }

          return {
            ...localContent,
            platforms: localContent.platforms.map((i) => {
              if (i.id === platformId) {
                i.isUpdate=true
                i.awsReplace = false
                if (!state.selectedComponentId) {
                  return {
                    ...i,
                    ...newFileInfo,
                  };
                } else {
                  return {
                    ...i,
                    components: i.components
                      ? i.components.map((f) =>
                          f.id === state.selectedComponentId ? { ...f, ...newFileInfo, awsReplace:false } : f
                        )
                      : i.components,
                  };
                }
              }
              return i;
            })
          };
        }
        return t;
      }),
    }));


  };

  handleSelectedTranslationChange = (country: DistributionCountry) => {
    
    const { parentContent } = this.props;
    this.setState({ selectedLocalizationCountry: country }, () => {
      const localContent = getLocalContent(
        this.state.localizations,
        this.state.selectedLocalizationCountry
      );
      const itemType = getFirstAvailablePlatformContentType2(
        localContent.platforms.map((i) => i.mimeType),
        localContent.platforms.map((i) => i.itemType),
      );

     
      this.setState({ idPlatform: null, selectedItemType: itemType }, () => {
        changePath(parentContent.id, country, itemType);
      });
    });
  };

  handleSelectedCutChange = (itemType: ItemType, idPlatform:string) => {

    //alert('handleSelectedCutChange')
    //alert(idPlatform)

    const { parentContent } = this.props;
    const { selectedLocalizationCountry } = this.state;
    
    this.setState(
      {
        selectedItemType: itemType,
        idPlatform:idPlatform
      },
      () => {
        changePath(parentContent.id, selectedLocalizationCountry, itemType);
      }
    );
  };

  onNewFileUploadProgress = (id: string, progress: number) => {
    this.setState((state) => {
      const fileUploadProgress = state.newFileUploadProgress.find((fup) => fup.id === id);
      if (!fileUploadProgress) {
        return {
          newFileUploadProgress: [...state.newFileUploadProgress, { id, progress }],
        };
      }
      return {
        newFileUploadProgress: state.newFileUploadProgress.map((fup) => {
          if (fup.id === fileUploadProgress.id) {
            return { id, progress };
          }
          return fup;
        }),
      };
    });
  };

  incrementStep = () => this.setState((state) => ({ stepIndex: state.stepIndex + 1 }));

  decrementStep = () => this.setState((state) => ({ stepIndex: state.stepIndex - 1 }));

  onOpenDeleteDialog = () => this.setState({ openDeleteDialog: true });

  onCloseDeleteDialog = () => this.setState({ openDeleteDialog: false });

  onOpenSaveDialog = () => this.setState({ openSaveDialog: true });

  onCloseSaveDialog = () => this.setState({ openSaveDialog: false });

  onKeyUp = (e: any) => {
    const { openDeleteDialog, openSaveDialog, stringifiedContent } = this.state;
    
    const stringifiedNewMedia = stringifyMedia(this.state.parent, this.state.localizations);
    
    const isMediaChanged = stringifiedContent !== stringifiedNewMedia;
    
    if (e.keyCode === 27 && !openSaveDialog && !openDeleteDialog) {
      if (isMediaChanged) {
        this.onOpenSaveDialog();
      } else {
        this.props.history.goBack();
      }
    }
  };

  //react behavior
  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUp, true);
  }

  //react behavior
  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp, true);
  }

  render() {

    const {
      parent,
      openDeleteDialog,
      openSaveDialog,
      editStep,
      newFileUploadProgress,
      localizations,
      selectedLocalizationCountry,
      selectedItemType,
      selectedComponentId,
      idPlatform, 
      readOnlyUser
    } = this.state;

    const {
      allBrands,
      allKeywords,
      allProductTypes,
      allSeries,
      allTopics,
      allTeamMembers,
      allContributors,
      allProjects,
      allCampaigns,
      allObjectives,
      allProducts,
      allTemperatures,
      allGenres,
      allEditorialCampaigns,
      allBooleans,
      parentContent,
      //history,
    } = this.props;

    //const allCampignsFiltred = this.props.allCampaigns;
    const contentType = parent.contentType;
    const format = parent.format;
    const productTypeId = parent.productType ? parent.productType.id : undefined;
    const brandId = parent.brand ? parent.brand.id : undefined;
    const serieId = parent.serie ? parent.serie.id : undefined;
    const editorialCampaignId = parent.editorialCampaign ? parent.editorialCampaign.id : undefined;
    const topicId = parent.topic ? parent.topic.id : undefined;
    const projectId = parent.project ? parent.project.id : undefined;
    const projectName = parent.project!.name;
    const campaignId = parent.campaign ? parent.campaign.id : undefined;
    const productionCountry = parent.productionCountry ? parent.productionCountry : undefined;
    const productId = parent.product ? parent.product.id : undefined;
    const genreId = parent.genre ? parent.genre.id : undefined;
    const objectiveId = parent.objective ? parent.objective.id : undefined;
    const temperatureId = parent.temperature ? parent.temperature.id : undefined;
    const booleansId = parent.booleans ? parent.booleans.map((b) => b) : undefined;

    const existingMetadata = parent.keywords || [];

    const localContent = getLocalContent(
      this.state.localizations,
      this.state.selectedLocalizationCountry
    );

    const platformContent = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
      localContent,
      this.state.selectedItemType,
      idPlatform
    );
    
    if (!localContent || !platformContent) {
      return null;
    }
    
    const componentContent = getComponentContent<EnhancedPlatformContent, EnhancedComponentContent>(
      platformContent,
      selectedComponentId
    );

   if( platformContent.itemType.indexOf('VIDEO')>=0 || platformContent.itemType.indexOf('IMAGE')>=0 ){
      let plt_info = platformContent.itemType.split('_')
      platformContent.itemType = plt_info[1]
   }

    const itemId = platformContent.id;
    const titlePreview = componentContent ? componentContent.title : platformContent.title;
    const title = parent.title;
    const mimeType = platformContent.mimeType;
    const itemType = platformContent.itemType;
    const videoType = localContent.videoType;
    //const cutType = platformContent.cutType;
    const platformType = PlatformType[platformContent.itemType];
    const storagePath = componentContent ? componentContent.storagePath : platformContent.storagePath;
    const existingContributors = localContent.contributors || [];
    const existingMembers = localContent.members || [];
    const addedContributors = localContent.addedContributors;
    const newContributors = localContent.newContributors;
    const removedContributors = localContent.removedContributors;
    const removedMembers = localContent.removedMembers;
    const addedMembers = localContent.addedMembers
    const sources = parent.sources;
    const languageCopy = localContent.languageCopy;
    const languageSound = parent.languageSound;
    const languageSubtitle = localContent.languageSubtitle;
    const distributionCountry = localContent.distributionCountry;
    const newUrl = componentContent ? componentContent.newUrl : platformContent.newUrl;
    //const createdAt = localContent.createdAt;
    //const creator = localContent.creator;
    
    const isNew = localContent.isNew || platformContent.isNew || Boolean(componentContent && componentContent.isNew);

    const onlyMusic = parent.onlyMusic;
    const noSubtitle = localContent.noSubtitle;
    const isReel = platformContent && platformContent.isReel ? true : null

    const instantArticleUrl = platformContent.instantArticleUrl 
    
    const translationsDistributionCountries = localizations.map((t) => t.distributionCountry);
    const remainingCountries = difference(
      Object.keys(DistributionCountry),
      translationsDistributionCountries
    ) as Array<DistributionCountry>;

    const allDistributionsCountries = uniq(
      remainingCountries.concat(distributionCountry, localContent.distributionCountry)
    ).filter((c) => c !== null) as Array<DistributionCountry>;

     const remainingPlatformTypes = Object.keys(PlatformType) as Array<PlatformType>;
    
    

   /* const allPlatformTypes =
        mimeType.indexOf('image') >= 0
        ? uniq(remainingPlatformTypes.remove(PlatformType.TIKTOK))

          ? uniq(remainingPlatformTypes.slice(platformTypes!, CutType[platformContent.itemType] as CutType))
          : remainingPlatformTypes; */

    let allPlatformTypes = mimeType.indexOf('image') >= 0 ?  remainingPlatformTypes.filter(function(value, index, arr){ 
                                                                          return value != PlatformType.TIKTOK && value != PlatformType.YOUTUBE
    }): remainingPlatformTypes

    //se instant_articole disabilitare la scelta della piattaforma instagram
    allPlatformTypes = this.state.parent.format==Format.INSTANT_ARTICLES ?  allPlatformTypes.filter(function(value, index, arr){ 
                                                                                    return value != PlatformType.INSTAGRAM
    }): allPlatformTypes    
          
    let metadata: string[] | Set<string> = [...parent.addedKeyword, ...existingMetadata]
      .filter((k) => !parent.removedKeyword.find((rk) => rk.id === k.id))
      .map((k) => JSON.stringify(Object.assign({}, { id: k.id, name: k.name })));
      metadata = new Set(metadata);

    let contributors: string[] | Set<string> = [...addedContributors, ...existingContributors]
      .filter((c) => !removedContributors.find((rc) => rc.id === c.id))
      .map((c) => JSON.stringify(Object.assign({}, { id: c.id, name: c.name })));
    contributors = new Set(contributors);

    let members: string[] | Set<string> = [...addedMembers, ...existingMembers]
    .filter((c) => !removedMembers.find((rc) => rc.id === c.id))
    .map((c) => JSON.stringify(Object.assign({}, { id: c.id, name: c.name })));
    members = new Set(members);

    const stringifiedNewMedia = stringifyMedia(parent, localizations);
    //console.log('stringifiedNewMedia', JSON.parse(stringifiedNewMedia))
    //console.log('this.state.stringifiedContent', JSON.parse(this.state.stringifiedContent))
   

    let isMediaChanged = this.state.stringifiedContent !== stringifiedNewMedia;

    //console.log('isMediaChanged', isMediaChanged)

    const alreadyExistingLocalizations = localizations.filter((t) => !t.isNew);

    const saveMedia = save(parentContent, parent, localizations, this.onNewFileUploadProgress);

    const disabledVideoRadio = Boolean(
      (parent.format != Format['VIDEO'] ) &&
          (localizations.length > 1 || localizations[0].platforms.length > 1 || localizations[0].platforms[0].components.length > 0) ) ;

    const disabledStoriesRadio = Boolean( 
      (parent.format != Format['INSTAGRAM_STORY'] ) && 
        (localizations.length > 1 || localizations[0].platforms.length > 1 ) );
    
    const disabledCarouselRadio = Boolean( 
          (parent.format != Format['CAROUSEL']) && 
            (localizations.length > 1 || localizations[0].platforms.length > 1) ); 

    const disabledImgRadio = Boolean(
      localizations[0].platforms[0].components && localizations[0].platforms[0].components.length > 0 && 
        (parent.format == Format['CAROUSEL'] || parent.format == Format['INSTAGRAM_STORY']));

    const filesUploadProgress = Math.ceil(
      newFileUploadProgress.reduce((acc, fup) => acc + fup.progress, 0) /
        newFileUploadProgress.length
    );

    const visibleLayer = Boolean(this.state.selectedComponentId);
    const isIgStories = format && format.indexOf('INSTAGRAM_STORY') >= 0;


    if(this.state.parent.title.length==0 || this.state.parent.title.length<=3){
      isMediaChanged = false
    }
    
    const onOpenEditPublication = (platform: EnhancedPlatformContent) => {
      localStorage.removeItem('init')
      this.setState({
        editPanel: !this.state.editPanel
      });
    };

    
    return (
      
      <LastMediaPageLocationContext.Consumer>
        {(lastMediaPageLocationContext) => {
          return (
            <>
              <DeletePlatformDialog
                open={openDeleteDialog}
                onClose={this.onCloseDeleteDialog}
                parentId={parentContent.id}
                platformId={itemId}
                componentId={selectedComponentId}
                //componentPositions={platformContent.components.map((f) => ({ id: f.id, position: f.position }))}
                componentPositions={
                  platformContent.components.filter(c => c.isNew === false).map(t => ({ id: t.id, position: t.position }))
                }

                shouldCloseAfterDelete={
                  alreadyExistingLocalizations.length === 1 &&
                  alreadyExistingLocalizations[0].platforms.filter((i) => !i.isNew).length === 1
                }
                updateComponentsAfterDelete={() => {
                  this.setState((state) => {
                    const selectedLocalization = localizations.find(
                      (t) => t.distributionCountry === state.selectedLocalizationCountry
                    )!;

                    const selectedPlatformContent= getPlatformContent(selectedLocalization, selectedItemType, idPlatform);
                    const selectedComponentPosition = selectedPlatformContent.components.find(
                      (f) => f.id === state.selectedComponentId
                    )!.position;

                    const updatedLocalizations = state.localizations.map((t) => {
                      if (t.id === selectedLocalization.id) {
                        return {
                          ...t,
                          platforms: t.platforms.map((i) => {
                            if (i.id === itemId) {
                              return {
                                ...i,
                                components: i.components
                                  .map((f) => ({
                                    ...f,
                                    position:
                                      f.position > selectedComponentPosition
                                        ? f.position - 1
                                        : f.position,
                                  }))
                                  .filter((f) => f.id !== selectedComponentId),
                              };
                            }
                            return i;
                          }),
                        };
                      }
                      return t;
                    });

                    let newSelectedFrameId = null;
                    let findFunction: (f: ComponentContent) => boolean;
                    if (selectedPlatformContent.components.length > 1) {
                      if (selectedComponentPosition === 0) {
                        findFunction = (f) => f.position === 1;
                      } else if (
                        selectedComponentPosition ===
                        selectedPlatformContent.components.length - 1
                      ) {
                        findFunction = (f) => f.position === selectedPlatformContent.components.length - 2;
                      } else {
                        findFunction = (f) => f.position === selectedComponentPosition - 1;
                      }
                      newSelectedFrameId = selectedPlatformContent.components.find(findFunction)!.id;
                    }

                    return {
                      selectedComponentId: newSelectedFrameId,
                      localizations: updatedLocalizations,
                      /*stringifiedContent: stringifyMedia(
                        {
                          ...this.props.parentContent,
                          addedKeyword: [],
                          removedKeyword: [],
                          newKeyword: [],
                        },
                        updatedLocalizations
                      ),*/
                    };
                  });
                }}
                
                updateLocalizationsAfterDelete={() => {
                  
                  this.setState(
                    (state) => {
                      
                      const selectedLocalization = localizations.find(
                        (t) => t.distributionCountry === state.selectedLocalizationCountry
                      )!;

                      let updatedLocalizations:any;

                      if (selectedLocalization.platforms.length > 1) {
                         state.localizations.map((t) => {
                          
                          
                          if (t.id === selectedLocalization.id) {
                            let platforms: Array<EnhancedPlatformContent> = t.platforms.filter((i) => i.id !== itemId)
                            updatedLocalizations =  [{ ...t, platforms }];
                            return
                          }
                          
                        });
                      } else {
                        updatedLocalizations = state.localizations.filter(
                          (t) => t.id !== selectedLocalization.id
                        );
                      }

                      const newSelectedLocalization =
                        updatedLocalizations!.find(
                          (t:any) => t?.distributionCountry === selectedLocalization.distributionCountry
                        ) || updatedLocalizations[0];

                      return {
                        localizations: updatedLocalizations,
                        selectedLocalizationCountry: newSelectedLocalization.distributionCountry /*selectedLocalization.distributionCountry*/,
                        selectedItemType: getFirstAvailablePlatformContentType2(
                          newSelectedLocalization!.platforms.map((i:any) => i.mimeType),
                          newSelectedLocalization!.platforms.map((i:any) => i.itemType),
                        ),
                        stringifiedContent: stringifyMedia(
                          {
                            ...this.props.parentContent,
                            addedKeyword: [],
                            removedKeyword: [],
                            newKeyword: [],
                          },
                          updatedLocalizations
                        ),
                      };
                    },
                    () =>
                      changePath(
                        parent.id,
                        this.state.selectedLocalizationCountry,
                        this.state.selectedItemType
                      )
                  );
                }}
              />
              <SaveMediaDialog
                open = {openSaveDialog}
                isMediaChanged = {isMediaChanged}
                text= "Save changes to the content info before exit ?"
                goBack = {true}
                changeEditStep={(es) => this.setState({ editStep: es })}
                onClose = {this.onCloseSaveDialog}
                onSave={saveMedia}
                onClickButton={
                  lastMediaPageLocationContext.mediaPageLocation
                    ? lastMediaPageLocationContext.mediaPageLocation.pathname
                    : '/'
                }
                newFileUploadProgress={filesUploadProgress}
              />

          
              <EditMediaWrapper>
                {editStep === 'EDIT' && (
                  <>
                    <FetchedItemsCounterContext.Consumer>
                        { fetchedItemsCounterContext => (
                          <HeaderEditMedia
                            style={{
                              position: 'fixed',
                              zIndex: 1,
                              boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 6px 0px',
                            }}
                            backElement={
                              !isMediaChanged ? (
                                <div
                                  onMouseDown={() =>  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")() }
                                  onClick={() =>  this.props.history.goBack() }
                                  style={{ display: 'flex', alignItems: 'center', marginLeft: 13 }}
                                >
                                  <ButtonIcon style={{ marginRight: 5 }}>
                                    <Icon iconName="arrow-left" />
                                  </ButtonIcon>
                                  <Typography
                                    variantName="label"
                                    textColor="#6B20DA"
                                    style={{ fontSize: 14, cursor: 'pointer' }}
                                  >
                                    Back
                                  </Typography>
                                </div>
                              ) : (
                                <div
                                  style={{ display: 'flex', alignItems: 'center', marginLeft: 13 }}
                                  onClick={() => this.onOpenSaveDialog()}
                                  onMouseDown={() =>  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")() }
                                >
                                  <ButtonIcon style={{ marginRight: 5 }}>
                                    <Icon iconName="arrow-left" />
                                  </ButtonIcon>
                                  <Typography
                                    variantName="label"
                                    textColor="#6B20DA"
                                    style={{ fontSize: 14, cursor: 'pointer' }}
                                  >
                                    Back
                                  </Typography>
                                </div>
                              )
                            }
                          />
                        )}
                    </FetchedItemsCounterContext.Consumer>        

                    <CSSTransition in={this.state.editPanel} unmountOnExit timeout={{ enter: 300, exit: 500 }}>
                      {(status) => ( 
                        
                        <PublicationPanel
                          open={this.state.editPanel}
                          onClose={() => {
                            this.setState({
                              editPanel: false
                            });
                          }}
                          readOnlyUser={readOnlyUser}
                          platformContent={platformContent!}
                          localization={this.state.selectedLocalizationCountry}
                          project={this.state.parent.project!}
                          format={format}
                          status={status}
                        />
                    )}      
                    </CSSTransition>

                    <PreviewEditWrapper>
                      <Preview
                        readOnlyUser={this.state.readOnlyUser}
                        platformContent={platformContent}
                        mimeType={mimeType}
                        format={format}
                        itemType={ getFirstAvailablePlatformContentType2(
                          localContent.platforms.map((i) => i.mimeType),
                          localContent.platforms.map((i) => i.itemType),
                        )}
                        url={newUrl || storagePath}
                        newFile={isNew}
                        titlePreview={titlePreview!}
                        allTeamMembers={allTeamMembers}
                        selectedCountry={selectedLocalizationCountry}
                        countries={localizations.map((t) => t.distributionCountry)}
                        onFileSelection={this.handleNewFileSelection(platformContent.id)}
                        onChangeSelectedCountry={this.handleSelectedTranslationChange}
                        onOpenDeleteDialog={this.onOpenDeleteDialog}
                        
                        itemTypes={getItemTypesFromString(
                          localContent.platforms.map((i) => i.mimeType),
                          localContent.platforms.map((i) => i.itemType),
                        )}
                        idPlatformContents ={
                          localContent.platforms.map((i) => i.id)
                        }
                        //itemTypes= { ItemType[ mimeType.concat('_FACEBOOK') ] }
                        onChangeSelectedCut={this.handleSelectedCutChange}
                        selectedCut={selectedItemType}
                        selectedComponentId={selectedComponentId}
                        changeEditStep={(es) => this.setState({ editStep: es })}
                        onAddComponents={this.addComponents}
                        clonePlatform={this.clonePlatform}
                        removeClonePlatform={this.removeClonePlatform}
                        newFileUploadProgress={filesUploadProgress}
                        onSave={saveMedia}
                        
                        onSelectComponent={(componentId: string | null) => {
                          this.setState({
                            selectedComponentId:
                              componentId && platformContent.components
                                ? platformContent.components.find((f) => f.id === componentId)!.id
                                : null
                          })
                          this.setState({
                            disableButton: componentId==null ? false : true
                          });
                         
                        }}

                        onUpdatePosition={(orderedComponentIds: Array<string>) => {
                          this.setState((state) => {
                            
                              const localization = getLocalContent(
                                state.localizations,
                                state.selectedLocalizationCountry
                              );
                              const item = getPlatformContent<EnhancedLocalContent, EnhancedPlatformContent>(
                                localization,
                                state.selectedItemType,
                                idPlatform
                              );

                            return {
                              localizations: state.localizations.map((t) => {
                                if (t.id === localization.id) {
                                  localization.isUpdate= !localization.isNew
                                  //console.log("___localization", localization)
                                  return {
                                    ...localization,
                                    platforms: localization.platforms.map((i) => {
                                      if (i.id === item.id) {
                                        
                                       i.isUpdate= !i.isNew
                                       //console.log("___platform", i)
                                        
                                        return {
                                          ...i,
                                          components: i.components!.map((f) => ({
                                            ...f,
                                            position: orderedComponentIds.findIndex(
                                              (ofId) => ofId === f.id
                                            )!,
                                            isUpdate: !f.isNew
                                          })),
                                        };
                                      }
                                      return i;
                                    }),
                                  };
                                }
                                return t;
                              }),
                            };
                          });
                        }}
                      />
                    


                      <EditFormWrapper>
                        
                        <div style={{width: '100%'}}>
                            <Button 
                                disabled={readOnlyUser || this.state.disableButton} 
                                id='publicationBtn'
                                style={{zIndex:999, float: 'right'} }
                                onClick={async () => {
                                  onOpenEditPublication(platformContent)
                                }}
                              > Schedule Post
                            </Button>
                            <Input
                                id="title"
                                label="Title (min 4 chars)"
                                value={title}
                                onChange={e => this.handleTitleChange(e.target.value)}
                                type="text"
                                placeholder="Write title content"
                                disabled={readOnlyUser}
                                style={{width: '50%'}}
                            />
                        </div>

                        <DisabledLayer visible={visibleLayer} />
                        <ContentInfo
                          readOnlyUser={this.state.readOnlyUser}
                          allBrands={allBrands}
                          allKeywords = {allKeywords}
                          allProductTypes = {allProductTypes}
                          allSeries = {allSeries}
                          allTopics = {allTopics}
                          allProjects = {allProjects}
                          allCampaigns = {allCampaigns}
                          allEditorialCampaigns={allEditorialCampaigns}
                          allObjectives={allObjectives}
                          allProducts={allProducts}
                          allTemperatures={allTemperatures}
                          allGenres={allGenres}
                          allBooleans ={allBooleans}
                          allPlatformType={allPlatformTypes}
                          onChangeProductType={this.handleProductTypeChange}
                          //onChangeContentType={this.handleContentTypeChange}
                          onChangeItemType={this.handleItemTypeChange}
                          //onChangeCutType={this.handleCutTypeChange}
                          onChangePlatformType={this.handlePlatformTypeChange}
                          handleIsReel ={this.handleIsReel}
                          onChangeFormat={this.handleChangeFormat}
                          onChangeBrand={this.handleBrandChange}
                          onChangeCampaign={this.handleCampaignChange}
                          onChangeEditorialCampaign={this.handleEditorialCampaignChange}
                          onChangeProject={this.handleProjectChange}
                          onChangeSerie={this.handleSerieChange}
                          onChangeTopic={this.handleTopicChange}
                          onChangeProduct={this.handleProductChange}
                          onChangeGenre={this.handleGenreChange}
                          onChangeObjective={this.handleObjectiveChange}
                          onChangeTemperature={this.handleTemperatureChange}
                          onAddBoolean={this.handleAddBoolean}
                          onChangeAddedKeywords={this.handleAddedKeywordsChange}
                          onChangeRemovedKeywords={this.handleRemovedKeywordsChange}
                          onAddNewKeywords={this.handleAddedNewKeywordsChange}
                          onRemoveNewKeywords={this.handleRemovedNewKeywordsChange}
                          brandId={brandId}
                          editorialCampaignId={editorialCampaignId}
                          topicId={topicId}
                          projectId={projectId!}
                          productId={productId}
                          genreId = {genreId}
                          campaignId ={campaignId}
                          booleansId ={booleansId}
                          objectiveId = {objectiveId}
                          temperatureId = {temperatureId}
                          title={title}
                          contentType={contentType}
                          format={format}
                          productTypeId={productTypeId}
                          serieId={serieId}
                          keywords={Array.from(metadata).map((k) => JSON.parse(k))}
                          newKeywords={parent.newKeyword}
                          mimeType={mimeType}
                          itemType={itemType}
                          videoType={videoType}
                          platformType={platformType}
                          disabledStoriesRadio={disabledStoriesRadio}
                          disabledVideoRadio={disabledVideoRadio!}
                          disabledCarouselRadio={disabledCarouselRadio!}
                          disabledImgRadio={disabledImgRadio}
                          isReel={isReel}
                        />
                        <ProductionInfo
                          readOnlyUser={this.state.readOnlyUser}
                          format={format}
                          mimeType={mimeType}
                         
                          instantArticleUrl={instantArticleUrl}
                          allTeamMembers={allTeamMembers}
                          allContributors={allContributors}
                          allDistributionsCountry={allDistributionsCountries}
                          contributors={Array.from(contributors).map((c) => JSON.parse(c))}
                          newContributors={newContributors}
                          members={Array.from(members).map((c) => JSON.parse(c))}
                          sources={sources}
                          productionCountry={productionCountry}
                          distributionCountry={distributionCountry}
                          onChangeMembers={this.handleMembersChange}
                          onChangeRemovedMembers={this.handleRemovedMembersChange}

                          onChangeSources={this.handleSourcesChange}
                          onChangeInstanArticleUrl ={this.handleInstantArticleUrlChange}
                          onChangeAddedContributors={this.handleAddedContributorsChange}
                          onChangeRemovedContributors={this.handleRemovedContributorsChange}
                          
                          onAddNewContributors={this.handleAddedNewContributorsChange}
                          onRemoveNewContributors={this.handleRemovedNewContributorsChange}
                          onChangeDistributionCountry={this.handleDistributionCountryChange}
                        />
                        <LanguageSettings
                          readOnlyUser={this.state.readOnlyUser}
                          languageSound={languageSound}
                          languageCopy={languageCopy}
                          languageSubtitle={languageSubtitle}
                          onChangeCopyLanguage={this.handleCopyLanguageChange}
                          onChangeSoundLanguage={this.handleSoundLanguageChange}
                          onChangeSubLanguage={this.handleSubLanguageChange}
                          mimeType={mimeType}
                          onlyMusic={onlyMusic}
                          onChangeOnlyMusic={this.handleOnlyMusicChange}
                          noSubtitle={noSubtitle}
                          onChangeNoSubtitle={this.handleNoSubtitleChange}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: visibleLayer ? 'space-between' : 'flex-end',
                            alignItems: 'flex-end',
                            flexGrow: 1,
                            zIndex: 2,
                          }}
                        >
                          {(visibleLayer && isIgStories) && (
                            <div style={{ letterSpacing: '0.2px', fontSize: '12px', width: '40%' }}>
                              You can’t modify informations from frames, go to Stories section
                            </div>
                          )}

                          {(visibleLayer && !isIgStories) && (
                            <div style={{ letterSpacing: '0.2px', fontSize: '12px', width: '40%' }}>
                               You can’t modify informations from frames, go to Cover section
                            </div>
                          )}

                          <ButtonWrapper>
                            <SaveMediaButton
                              newFileUploadProgress={filesUploadProgress}
                              onSave={saveMedia}
                              isMediaChanged={isMediaChanged}
                            />
                          </ButtonWrapper>
                        </div>
                      </EditFormWrapper>
                    </PreviewEditWrapper>
                  </>
                )}
               
                
                { editStep === 'NEW_COUNTRY'  && (
                  <UploadNewCountryPage
                    parentId={parentContent.id}
                    format={this.state.parent.productType!.id}
                    projectName={projectName}
                    projectId = {this.state.parent.project!.id}
                    localizations={localizations}
                    addLocalization={this.addLocalization}
                    changeEditStep={(es) => this.setState({ editStep: es })}
                    onAfterMerge={(newParent: ParentContent, newDistributionCountry: Country) => {
                      this.setState(
                        (state) => {
                          const newLocalization: LocalContent = newParent.localizations.find(
                            (t) => t.distributionCountry === newDistributionCountry
                          )!;
                          const updatedLocalizations: Array<EnhancedLocalContent> = [
                            ...state.localizations,
                            {
                              ...newLocalization,
                              addedContributors: [],
                              removedContributors: [],
                              newContributors: [],
                              addedMembers: [],
                              removedMembers: [],
                              newMembers: [],
                              isNew: true,
                              isUpdate:false,
                              videoType,
                              platforms: [
                                ...newLocalization.platforms.map((i) => ({
                                  ...i,
                                  cutType:
                                    i.itemType.indexOf('VIDEO_') >= 0
                                      ? (CutType[i.itemType] as CutType)
                                      : null,
                                  newUrl: null,
                                  newFile: null,
                                  originalItemType: i.itemType as ItemType,
                                  country: newLocalization.distributionCountry,
                                  isNew: true,
                                  isUpdate: false,
                                  components: i.components && i.components.length==0 ? [] : getComponents(i.components)
                                 
                                })),
                              ],
                            },
                          ];

                          const updatedLocalizationWithOldFormData: Array<EnhancedLocalContent> = buildEnhancedLocalContent(
                            {
                              ...this.props.parentContent,
                              localizations: [...this.props.parentContent.localizations, newLocalization],
                            }
                          );

                          const firstTranslationItem = newLocalization.platforms[0];

                          return {
                            localizations: updatedLocalizations,
                            selectedLocalizationCountry: newDistributionCountry,
                            selectedItemType:
                              firstTranslationItem.itemType.indexOf('VIDEO_') >= 0
                                ? (CutType[firstTranslationItem.itemType] as ItemType)
                                : ItemType.IMAGE,
                            stringifiedContent: stringifyMedia(
                              {
                                ...this.props.parentContent,
                                addedKeyword: [],
                                removedKeyword: [],
                                newKeyword: [],
                              },
                              updatedLocalizationWithOldFormData
                            ),
                            editStep: 'EDIT',
                          };
                        },
                        () => {
                          changePath(
                            parentContent.id,
                            this.state.selectedLocalizationCountry,
                            this.state.selectedItemType
                          );
                          

                        }
                      );
                    }}
                  />
                )}
                
                
                { editStep === 'NEW_CUT' && (
                  <UploadNewCutPage
                    parentId={parentContent.id}
                    format={this.state.parent.productType!.id}
                    projectId = {this.state.parent.project!.id}
                    formatType ={this.state.parent.format}
                    localizations={localizations}
                    distributionCountry={this.state.selectedLocalizationCountry}
                    addItems={this.addPlatforms}
                    changeEditStep={(es) => this.setState({ editStep: es })}
                    onAfterMerge={(newRoot: ParentContent, newItemType: ItemType) => {
                      this.setState(
                          (state) => {
                            const mapToUpdatedTranslations = withMergedItemLocalization(
                              state.selectedLocalizationCountry,
                              newRoot,
                              newItemType
                            );
                            
                            const updatedLocalizations = state.localizations.map(
                              mapToUpdatedTranslations
                            );
                            // to keep stringified media in order to show dialog
                            // we should care about order of translations inside
                            // this.props.media because of apollo ordering
                            const updatedLocalizationWithOldFormData = buildEnhancedLocalContent({
                              ...this.props.parentContent,
                              localizations: state.localizations
                                .map((t) => t.distributionCountry)
                                .map(
                                  (d) =>
                                    this.props.parentContent.localizations.find(
                                      (t) => t.distributionCountry === d
                                    )!
                                ),
                            }).map(mapToUpdatedTranslations);
  
                            // also as first argument of stringifyMedia
                            return {
                              localizations: updatedLocalizations,
                              selectedItemType: newItemType,
                              stringifiedContent: stringifyMedia(
                                {
                                  ...this.props.parentContent,
                                  addedKeyword: [],
                                  removedKeyword: [],
                                  newKeyword: [],
                                },
                                updatedLocalizationWithOldFormData
                              ),
                              editStep: 'EDIT',
                            };
                          },
                          
                        () => {
                          changePath(
                            parentContent.id,
                            this.state.selectedLocalizationCountry,
                            this.state.selectedItemType
                          );
                        }
                        );
                    }}
                  />
                )}
              </EditMediaWrapper>
              </>
          );
        }}
      </LastMediaPageLocationContext.Consumer>
      
    );
  }
}

export default withRouter<Props & RouteComponentProps<{}>, any>(EditMediaPage);
