import gql from 'graphql-tag';

//qui
const DELETE_COMPONENT_CONTENT = gql`
  mutation deleteComponentContent($id: ID!) {
    deleteComponentContent(id: $id) {
      deletedComponentId
    }
  }
`;

export { DELETE_COMPONENT_CONTENT };
