import * as React from 'react';

import { ParentContentSelection } from '../types/types2.0/ParentContentSelection';


const DownloadMultiMediaDialogContext = React.createContext({
  showDialog: false,
  contents: [] as Array<ParentContentSelection>,
  onOpen: (contents: Array<ParentContentSelection>) => console.warn('Please implement DialogContext.onOpen A'),
  onClose: () => console.warn('Please implement DialogContext.onClose')
});

class DownloadMultiMediaDialogProvider extends React.Component<
  {},
  { showDialog: boolean; contents: Array<ParentContentSelection> }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showDialog: false,
      contents: []
    };
  }
  
  onOpen = (contents: Array<ParentContentSelection>) => this.setState({ showDialog: true, contents:contents });

  onClose = () => this.setState({ showDialog: false, contents: [] });

  render() {
    return (
      <DownloadMultiMediaDialogContext.Provider
        value={{ ...this.state, onOpen: this.onOpen, onClose: this.onClose }}
      >
        {this.props.children}
      </DownloadMultiMediaDialogContext.Provider>
    );
  }
}

export { DownloadMultiMediaDialogProvider, DownloadMultiMediaDialogContext };
