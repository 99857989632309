import * as React from 'react';
import { Spinner } from '@freeda/react-components';
import styled, { css } from 'styled-components';

import { ItemType } from '../types/ItemType';
import { usePreviousData } from '../hooks/usePreviousData';

const framePlaceholder = require('../assets/framePlaceholderOpacity.png');

const contentStyleInternal = css`
  height: 100%;
  width: auto;
  align-self: center;
  justify-self: center;
  padding: 20px 0px 40px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: auto;
  }
`;

const contentStyleExternal = css`
  height: 100%;
  width: auto;
  padding: 60px 0px;

  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: auto;
  }
`;

const getContentStyle = (internal: boolean) =>
  internal ? contentStyleInternal : contentStyleExternal;

const Img = styled.img<
  { internal: string; itemType: ItemType } & React.HTMLProps<HTMLImageElement>
>`
  ${(props) => getContentStyle(props.internal === 'true')};

  width: 100%;
  height: 100%;
  object-fit: contain;

  @media (max-width: 768px) {
    width: ${(props) =>
      props.itemType === ItemType.VIDEO_INSTAGRAM_STORY ? 'auto' : 'calc(100% - 20px)'};
    height: ${(props) => (props.itemType === ItemType.VIDEO_INSTAGRAM_STORY ? '400px' : 'auto')};
  }
`;

const Video = styled.video<
  { internal: string; itemType: ItemType } & React.HTMLProps<HTMLVideoElement>
>`
  ${(props) => getContentStyle(props.internal === 'true')};

  outline: none;

  @media (max-width: 768px) {
    width: ${(props) =>
      props.itemType === ItemType.VIDEO_INSTAGRAM_STORY ? 'auto' : 'calc(100% - 20px)'};
    height: ${(props) => (props.itemType === ItemType.VIDEO_INSTAGRAM_STORY ? '400px' : 'auto')};
  }
`;

const VideoPreview: React.FC<{
  itemType: ItemType | null;
  src: string;
  internal: boolean;
}> = ({ itemType, internal, src }) => {
  
  const [isVisible, setIsVisible] = React.useState(false);
  const [inError, setInError] = React.useState(false);
  //const timeoutLoadingMedia = React.useRef<number>();
  const prevSrc = usePreviousData(src);
  React.useEffect(() => {
    if (prevSrc !== src) {
      setIsVisible(false);
      setInError(false);
    }
  }, [src, prevSrc]);

  React.useEffect(() => {
    if (!isVisible) {
      //timeoutLoadingMedia.current = setTimeout(() => setIsVisible(true), 500);
      setTimeout(() => setIsVisible(true), 500);
    }
  }, [isVisible]);

  React.useEffect(() => {
    return () => {
      setIsVisible(false);
      setInError(false);
      /*if (timeoutLoadingMedia.current) {
        clearTimeout(timeoutLoadingMedia.current);
      }*/
      clearTimeout()
    };
  }, []);
  const posterProp = !isVisible || inError ? { poster: framePlaceholder } : {};
  return (
    <Video
      {...posterProp}
      itemType={itemType!}
      internal={String(internal === true)}
      controls={isVisible}
      preload="auto"
      src={src}
      style={{ width: 'auto'}}
      onError={(e) => {
        console.log('ERROR VIDEO_', e)
        setInError(true);
        return (e.currentTarget.src = framePlaceholder);
      }}
    />
  );
};

const renderPreview = (
  loading: boolean,
  itemType: ItemType | null,
  mimeType: string | null,
  src: string | null,
  internal: boolean
) => {
  if (loading || !src) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner size="large" borderColor="#6B20DA" />
      </div>
    );
  } else {
    if (mimeType) {
      if (mimeType.indexOf('image') >= 0) {
        return <Img itemType={itemType!} internal={String(internal === true)} src={src} />;
      } else {
        return <VideoPreview itemType={itemType!} internal={internal} src={src} />;
      }
    }
    return null;
  }
};

export { renderPreview , Img, VideoPreview};
