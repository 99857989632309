import gql from 'graphql-tag';


const DELETE_LABEL = gql`
  mutation deleteLabel($id: ID!) {
    deleteLabel(id: $id) {
        deletedLabelId
    }
  }
`;
export { DELETE_LABEL };
