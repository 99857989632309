import styled from 'styled-components';
import { ListItem } from '@freeda/react-components';

import { scrollbarVerticalStyle } from '../../../../../../components/ui/ScrollBarStyle';

const Wrapper = styled.div`
  overflow-y: overlay;
  height: 320px;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  & > div {
    width: 33%;
    flex: 0 33%;
  }
  @media (max-width: 1020px) {
    height: 290px;
    & > div {
      width: 50%;
      flex: 0 50%;
    }
  }
  @media (max-width: 590px) {
    & > div {
      width: 100%;
      flex: 0 100%;
    }
  }

  ${scrollbarVerticalStyle};

  ::-webkit-scrollbar-track {
    margin: 10px;
  }
`;

const SingleMediaList = styled.div`
  width: 33%;
  flex: 0 33%;
  @media (max-width: 1020px) {
    width: 50%;
    flex: 0 50%;
  }
  @media (max-width: 590px) {
    width: 100%;
    flex: 0 100%;
  }
`;

const WrapperStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  color: #000000;
  padding: 0px 100px;
  @media (max-width: 1020px) {
    padding: 0px 50px;
  }
`;

const Box = styled(ListItem)`
  && {
    cursor: pointer;
    &:hover {
      background: #d7fff4;
    }
  }
`;

export { Wrapper, WrapperStep, Box, SingleMediaList };
