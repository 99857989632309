import * as React from 'react';
import moment from 'moment';
import { Select } from '@freeda/react-components';

import { DateRange } from '../../../types/DateRange';
import { DateRangeFilterDialog } from './DateRangeFilterDialog';
import { UpdateDateRange } from '../../../types/UpdateDateRange';
import {
  FetchedItemsCounterContext
} from '../../../providers/FetchedItemsCounterProvider';

const labels = {
  [DateRange.ANY_TIME]: 'Any time',
  [DateRange.LAST_24H]: 'Last 24h',
  [DateRange.LAST_WEEK]: 'Last week',
  [DateRange.LAST_MONTH]: 'Last month',
  [DateRange.LAST_YEAR]: 'Last year',
  [DateRange.CUSTOM_RANGE]: 'Custom range',
};

interface Props {
  onChangeUpdateDateRange: (updateDateRange: UpdateDateRange ) => void;
  dateRange: UpdateDateRange;
  //QUI
}

interface State {
  isDialogOpen: boolean;
  from: Date | null;
  to: Date | null;
}

class DateFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state = {
      isDialogOpen: false,
      from: null,
      to: null,
    };

    if (props.dateRange && typeof props.dateRange === 'object') {
      this.state = Object.assign({}, state, { from: props.dateRange.from, to: props.dateRange.to });
    } else {
      this.state = state;
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.dateRange && typeof nextProps.dateRange === 'object') {
      this.setState({ from: nextProps.dateRange.from, to: nextProps.dateRange.to });
    }
  }

  onChangeDate = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    const from = startDate ? startDate.toDate() : null;
    const to = endDate ? endDate.toDate() : null;
    this.setState({
      from,
      to,
    });
  };

  getSelectedValue = (updateDateRange: UpdateDateRange) => {
    if (updateDateRange) {
      if (typeof updateDateRange === 'object') {
        if (!updateDateRange.from) {
          return {
            value: DateRange.CUSTOM_RANGE,
            label: `Until ${moment(updateDateRange.to!).format('DD/MM/YYYY')}`,
          };
        }
        if (!updateDateRange.to) {
          return {
            value: DateRange.CUSTOM_RANGE,
            label: `From ${moment(updateDateRange.from!).format('DD/MM/YYYY')}`,
          };
        }

        return {
          value: DateRange.CUSTOM_RANGE,
          label: `${moment(updateDateRange.from).format('DD/MM/YYYY')} - ${moment(
            updateDateRange.to
          ).format('DD/MM/YYYY')}`,
        };
      } else {
        return { value: DateRange[updateDateRange], label: labels[DateRange[updateDateRange]] };
      }
    }

    return { value: DateRange.ANY_TIME, label: labels[DateRange.ANY_TIME] };
  };

  render() {
    const { isDialogOpen, from, to } = this.state;
    const { onChangeUpdateDateRange, dateRange } = this.props;

    return (
      <>
      <FetchedItemsCounterContext.Consumer>

        { fetchedItemsCounterContext => (
        
        <Select
          isClearable={false}
          onChange={(option: any) => {
            if (option.value === DateRange.CUSTOM_RANGE) {
              this.setState({ isDialogOpen: true });
            } else {
              onChangeUpdateDateRange(option.value);
            }

            fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")()

          }}
          options={[
            { value: DateRange.ANY_TIME, label: labels[DateRange.ANY_TIME] },
            { value: DateRange.LAST_24H, label: labels[DateRange.LAST_24H] },
            { value: DateRange.LAST_WEEK, label: labels[DateRange.LAST_WEEK] },
            { value: DateRange.LAST_MONTH, label: labels[DateRange.LAST_MONTH] },
            { value: DateRange.LAST_YEAR, label: labels[DateRange.LAST_YEAR] },
            {
              value: DateRange.CUSTOM_RANGE,
              label: labels[DateRange.CUSTOM_RANGE],
            },
          ]}
          value={this.getSelectedValue(dateRange)}
          isSearchable={false}
        />

        )}
        </FetchedItemsCounterContext.Consumer>



        <DateRangeFilterDialog
          open={isDialogOpen}
          onClose={() => this.setState({ isDialogOpen: false })}
          from={from}
          to={to}
          onChangeDate={this.onChangeDate}
          onApplyFilter={onChangeUpdateDateRange}
        />
      </>
    );
  }
}

export { DateFilter };
