import gql from 'graphql-tag';


const LIST_LABELS  = gql`
  query listLabels($category : LabelCategory, $macroTopic:String , $where: MetadataWhereInput, 
                  $orderBy: MetadataOrderByInput, $projectList: [ProjectWhereInput], $visibleFor:ID )  {
    listLabels(category :$category, macroTopic:$macroTopic, where: $where, orderBy: $orderBy, projectWhereInput: $projectList, visibleFor:$visibleFor) {
      id
      name
      macroTopic
      project {
        id
        name
        
      }
      brand {
        id
        name
        
      }
    }
  }
`;



const LIST_BRANDS = gql`
  query listBrands($where: MetadataWhereInput, $orderBy: MetadataOrderByInput) {
    listBrands(where: $where, orderBy: $orderBy) {
      id
      name
      brand_category
    }
  }
`;



const LIST_FORMATS = gql`
    query listProductTypes ($where: MetadataWhereInput, $orderBy: MetadataOrderByInput ) {
      listProductTypes(where: $where, orderBy: $orderBy) {
        id
        name
        format
      }
    }
  `;

export {  LIST_FORMATS,  LIST_LABELS , LIST_BRANDS};
