import styled from 'styled-components';

const ErrorWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.4s;
  display: flex;
  align-items: center;
  margin-top: 8px;

  &.entering {
    opacity: 0;
  }
  &.entered {
    opacity: 1;
  }
  &.exiting {
    opacity: 0;
  }
  &.exited {
    opacity: 0;
  }
`;

const ActiveIndicator = styled.div<{ active: boolean; disabled: boolean }>`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: ${(props) => (props.active ? props.theme.primary : props.theme.griginoTransparent)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

export { ErrorWrapper, ActiveIndicator, StepsWrapper };
