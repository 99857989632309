import * as React from 'react';
import { Snackbar } from '@freeda/react-components';

import { ParentContentQuery } from '../components/queries/queries2.0/ParentContentQuery';
import EditMediaPage from '../pages/EditMediaPage2';
import { GetEditFormInfoQuery } from '../components/queries/queries2.0/GetEditFormInfoQuery';
//import { GetEditFormInfoQuery } from '../components/queries/GetEditFormInfoQuery';
import { RefreshSpan } from '../components/RefreshSpan';
import { PlaceholderEditPage } from '../components/ui/Placeholders/PlaceholderEditPage';
import {getUsers } from '../utils/getUsers';
//import client from '../apollo/clientTobe';
//import { GET_PARENT_CONTENT } from '../apollo/queries/queries2.0/GetParentContent';
//import ParentContentT from '../types/types2.0/graphql2.0/GetParentContentQuery';



export const EditMediaPageContainer: React.FC<{
  mediaId: string;
}> = ({ mediaId }) => (
  <ParentContentQuery variables={{ id: mediaId }} fetchPolicy="network-only">
    {mediaQueryResult => {

      if (mediaQueryResult.error) {
        return 'Error in loading content selected ...';
      }
      
      return (
        <GetEditFormInfoQuery fetchPolicy="cache-first">
          {infoFormResult => {
            ///QUI
            if (infoFormResult.error) {
              return 'Error in loading page ...';
            }
            
            if (infoFormResult.loading || infoFormResult.loading) {
              return <PlaceholderEditPage />;
            }
            
            const {
              allProductTypes,
              allBrands,
              allSeries,
              allTopics,
              allKeywords,
              allTeamMembers,
              allContributors,
              allProjects,
              allCampaigns,
              allObjectives,
              allProducts,
              allTemperatures,
              allGenres,
              allEditorialCampaigns,
              allBooleans
            } = infoFormResult.data!.editDialog;

            let media = mediaQueryResult.data!.getParentContent;
            
            /*
            if( localStorage.getItem('merge') == 'merge' && !media ){
                let data:any = client.readQuery<ParentContentT>({
                    query: GET_PARENT_CONTENT,
                    variables: {
                      id:mediaId
                    }
                });
                media = data!.getParentContent;
                localStorage.removeItem('merge');
                client.cache.reset()
                console.log("BACK___")
            }*/
            
            /*
            if( localStorage.getItem('merge') == 'merge' && !media ){
                localStorage.setItem('merge','');
                window.location.reload()
            }
            else*/ if ( !media && mediaQueryResult.networkStatus === 7 /*&& localStorage.getItem('merge') != 'merge'*/ ) {
              
                return (
                  <Snackbar
                    typology = "error"
                    open = {true}
                    label = {<RefreshSpan>Someone has deleted or merged this media</RefreshSpan>}
                  />
                );

            }
        

            if (mediaQueryResult.loading || infoFormResult.loading) {
              return <PlaceholderEditPage />;
            }
            
            try{
              let userId =  getUsers( allTeamMembers , localStorage.getItem('name'))[0]
              if(!userId){
                userId =  getUsers( allContributors , localStorage.getItem('name'))[0]
              }
              localStorage.setItem('userId', userId ? userId.id : '')
            }catch(e){
              console.log('Error in founding userId')
            }

            return (
              <EditMediaPage
                parentContent={media}
                allProductTypes={allProductTypes}
                allBrands={allBrands}
                allSeries={allSeries}
                allTopics={allTopics}
                allKeywords={allKeywords}
                allTeamMembers={allTeamMembers}
                allContributors={allContributors}
                allProjects={allProjects}
                allCampaigns={allCampaigns}
                allObjectives={allObjectives}
                allProducts={allProducts}
                allTemperatures={allTemperatures}
                allGenres={allGenres}
                allEditorialCampaigns ={allEditorialCampaigns}
                allBooleans ={allBooleans}
              />
            );
          }}
        </GetEditFormInfoQuery>
      );
    }}
  </ParentContentQuery>
  
);
