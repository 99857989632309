import gql from 'graphql-tag';


//qui
const MASSIVE_UPDATE_COMPONENT_CONTENTS_POSITION = gql`
  mutation massiveUpdateComponentContentsPosition($componentPositions: [FramePosition]!) {
    result: massiveUpdateComponentContentsPosition(componentPositions: $componentPositions) {
      id
      position
    }
  }
`;

export { MASSIVE_UPDATE_COMPONENT_CONTENTS_POSITION };
