import gql from 'graphql-tag';

const LIST_BRAND_CATEGORIES = gql`
  query listBrandCategories($where: MetadataWhereInput, $orderBy: MetadataOrderByInput) {
    listBrandCategories(where: $where, orderBy: $orderBy) {
      name
    }
  }
`;

export { LIST_BRAND_CATEGORIES };
