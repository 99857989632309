import gql from 'graphql-tag';

const UPDATE_PLATFORM_CONTENT = gql`
  mutation updatePlatformContent(
    $id: ID!
    $itemType: ItemType
    $sources: String
    $storagePath: String
    $mimeType: String
    $title : String
    $videoLength: Int
    $isReel:Boolean
    $instantArticleUrl: String
    $fileUpdatedAt: LocalDateTime
    $updatedBy:ID!
    $awsReplace:Boolean
    $components: CreateComponentInput
    
  ) {
    updatePlatformContent(
      id: $id
      itemType: $itemType
      mimeType: $mimeType
      title: $title
      sources: $sources
      storagePath: $storagePath
      videoLength: $videoLength
      isReel:$isReel
      instantArticleUrl: $instantArticleUrl
      fileUpdatedAt: $fileUpdatedAt
      updatedBy: $updatedBy
      awsReplace: $awsReplace
      components: $components
    ) {
      id
    }
  }
  
`;

export { UPDATE_PLATFORM_CONTENT };
