import gql from 'graphql-tag';

const LIST_MACROTOPIC = gql`
  query listMacroTopic($where: MetadataWhereInput, $orderBy: MetadataOrderByInput) {
    listMacroTopic(where: $where, orderBy: $orderBy) {
      name
      topic{
        id:
        name
        category
        macroTopic
       
      }
    }
  }
`;

export { LIST_MACROTOPIC };
