import * as React from 'react';
import { Order } from '../types/Order';

export type InitialStateFilterContext = {
  searchValue: string;
  selectedBrandCategoryName: string | null;
  selectedMacroTopicName: string | null;
  selectedBrand:string | null;
  selectedProjectIds: Array<string>;
  order: Order;
  orderBy: string | null;
};

export type MetadataFilterContext = InitialStateFilterContext & {
  onChangeSearchValue: (value: string) => void;
  onChangeBrandCategoryName: (name: string | null) => void;
  onChangeMacroTopicName: (name: string | null) => void;
  onChangeBrand: (name: string | null) => void;
  onChangeMetadataOrder: (orderBy: string | null, order: Order) => void;
  onChangeProjectIds: (ids: Array<string>) => void;
  clearFilter: () => void;
};

const initialState: InitialStateFilterContext = {
  searchValue: '',
  selectedBrandCategoryName: null,
  selectedMacroTopicName:null,
  selectedBrand:null,
  selectedProjectIds: [],
  order: 'ASC',
  orderBy: 'name'
};

const BrandsFilterContext = React.createContext({} as MetadataFilterContext);

const BrandsFilterProvider: React.FC = ({ children }) => {
  const [searchValue, setSearchValue] = React.useState<string>(initialState.searchValue);
  
  const [selectedBrandCategoryName, setBrandCategoryName] = React.useState<string | null>(
    initialState.selectedBrandCategoryName
  );

  const [selectedMacroTopicName, setMacroTopicName] = React.useState<string | null>(
    initialState.selectedMacroTopicName
  );

  const [selectedBrand, setBrand] = React.useState<string | null>(
    initialState.selectedBrand
  );

  
  const [selectedProjectIds, setProjectIds] = React.useState<Array<string>>(
    initialState.selectedProjectIds
  );
  const [order, setOrder] = React.useState<Order>(initialState.order);
  const [orderBy, setOrderBy] = React.useState<string | null>(initialState.orderBy);

  const onChangeSearchValue = (value: string) => setSearchValue(value);

  const onChangeBrandCategoryName = (name: string | null) => setBrandCategoryName(name);

  const onChangeMacroTopicName = (name: string | null) => setMacroTopicName(name);
  const onChangeBrand = (name: string | null) => setBrand(name);



  const onChangeProjectIds = (ids: Array<string>) => setProjectIds(ids);


  const onChangeMetadataOrder = (orderBy: string | null, order: Order) => {
    setOrder(order);
    setOrderBy(orderBy);
  };

  const clearFilter = () => {
    setSearchValue(initialState.searchValue);
    setBrandCategoryName(initialState.selectedBrandCategoryName);
  };

  return (
    <BrandsFilterContext.Provider
      value={{
        searchValue,
        selectedBrandCategoryName,
        selectedMacroTopicName,
        selectedBrand,
        selectedProjectIds,
        order,
        orderBy,
        onChangeSearchValue,
        onChangeBrandCategoryName,
        onChangeMacroTopicName,
        onChangeBrand,
        onChangeMetadataOrder,
        onChangeProjectIds,
        clearFilter
      }}
    >
      {children}
    </BrandsFilterContext.Provider>
  );
};

export { BrandsFilterProvider, BrandsFilterContext };
