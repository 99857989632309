import * as React from 'react';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';
import { Button } from '@freeda/react-components';

//import { MergeMediaMutation } from '../components/mutations/MergeMediaMutation';
import { MergeContentMutation } from '../components/mutations/mutations2.0/MergeContentMutation';
import { FeedbackContext } from '../providers/FeedbackProvider';
import { ParentContent } from '../types/types2.0/ParentContent';
import { Country } from '../types/graphql/Country';
import { RefreshSpan } from '../components/RefreshSpan';
//import { GET_PARENT_CONTENT } from '../apollo/queries/queries2.0/GetParentContent';
//import { MassiveUpdateDistributionCountries } from '../components/mutations/MassiveUpdateDistributionCountries';
//import { MassiveUpdateDistributionCountries } from '../components/mutations/mutations2.0/MassiveUpdateDistributionCountries';


interface Props {
  selected: ParentContent | null;
  toExclude: string;
  distributionCountry: Country | null;
  mediaDistributionCountries: Array<{ id: string; distributionCountry: Country }>;
  onAfterMerge: (newParent: ParentContent, newDistributionCountry: Country) => void;
}

const MergeMediaButton: React.FC<Props> = ({
  selected,
  toExclude,
  distributionCountry,
  mediaDistributionCountries,
  onAfterMerge
}) => (
  <FeedbackContext.Consumer>
    {({ onOpen }) => (
      
          <MergeContentMutation>
            {(mergeMedia, mergeMediaResult) => (
              
              <Button
                disabled={
                  selected === null ||
                  mergeMediaResult.loading /*||
                  (updateDistributionCountriesResult.loading || mergeMediaResult.loading)*/
                }
                loading={/*updateDistributionCountriesResult.loading ||*/ mergeMediaResult.loading}
                
                onClick={async () => {
                  try {
                   
                    await mergeMedia({
                      variables: {
                        mainContent: toExclude ,
                        subContent: selected!.id ,
                        distributionCountry
                      },
                      update: (
                        cache: DataProxy,
                        result: FetchResult<{ mergeContent: ParentContent }>
                      ) => {
                          localStorage.setItem('merge', "merge")
                          window.location.reload()
                       // onOpen('Content merged', 'notification');
                       
                        
                      /*  setTimeout(function () {
                          window.location.reload()
                        }, 6000);*/

                        /*if(result.data!){
                            const query = GET_PARENT_CONTENT;
                            //qui
                            const q = {
                              query,
                              variables:{'id':toExclude},
                            };
              
                            cache.writeQuery({
                              ...q,
                              data: result.data!.mergeContent,
                            });
                        }
                        onAfterMerge(result.data!.mergeContent, distributionCountry!);*/
                      }
                    });
                  

                    /*let result = await mergeMedia({
                      variables: {
                        mainContent: toExclude ,
                        subContent: selected!.id ,
                        distributionCountry
                      }
                    })

                    if(result){
                      //onAfterMerge(result.data!.mergePlatformContent, selectedItemType!);
                      localStorage.setItem('merge', 'merge')
                      onOpen('Content merged', 'notification');

                      setTimeout(function () {
                        window.location.reload()
                       }, 2000);
                    }
                    else{
                      
                      onOpen(<RefreshSpan>Refresh</RefreshSpan>, 'error');
                      setTimeout(function () {
                        window.location.reload()
                       }, 2000);
                    }*/

                  } catch (err) {
                    Raven.captureException(err);
                    onOpen(<RefreshSpan>{err instanceof Error ? err.message : "Errore"}</RefreshSpan>, 'error');
                  }
                }}
              >
                Merge content
              </Button>
            )}
          </MergeContentMutation>
        )}
        
  
  </FeedbackContext.Consumer>
);

export default MergeMediaButton;
