import * as React from 'react';
import { MetadataType } from '../types/MetadataType';
import { capitalize } from 'lodash';

type SidebarContext = {
  openCreateMetadataDialogSteps: boolean;
  handleCreateMetadataDialog: (isOpen: boolean) => void;
  activeMetadataTable: MetadataType;
  onChangeMetadataTable: (metadataTable: MetadataType) => void;
};

const SidebarContext = React.createContext<SidebarContext>({} as SidebarContext);

const SidebarProvider: React.FC = ({ children }) => {
  const pathname = window.location.pathname;
  const metadataTablePath = capitalize(pathname.substring(10)) as MetadataType;

  const [openCreateMetadataDialogSteps, setOpenCreateMetadataDialogSteps] = React.useState(false);
  const [activeMetadataTable, setActiveMetadataTable] = React.useState<MetadataType>(
    metadataTablePath
  );

  React.useEffect(() => {
    setActiveMetadataTable(metadataTablePath);
  }, [pathname]);

  const handleCreateMetadataDialog = (isOpen: boolean) => setOpenCreateMetadataDialogSteps(isOpen);

  const onChangeMetadataTable = (metadataTable: MetadataType) =>
    setActiveMetadataTable(metadataTable);

  return (
    <SidebarContext.Provider
      value={{
        openCreateMetadataDialogSteps,
        handleCreateMetadataDialog,
        activeMetadataTable,
        onChangeMetadataTable,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export { SidebarProvider, SidebarContext };
