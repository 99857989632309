import * as React from 'react';
import { Input } from '@freeda/react-components';

import { ListMediaWrapper } from '../styles';
import { ListMediaToMerge } from '../../../../../../containers/ListMediaToMerge2.0';
import { ParentContent } from '../../../../../../types/types2.0/ParentContent';
import { ItemType } from '../../../../../../types/ItemType';
import MergeCutButton from '../../../../../../containers/MergeCutButton2.0';
import { Country } from '../../../../../../types/graphql/Country';
import { MediaItemTypes } from '../../../../../../types/MediaItemTypes';
import { WrapperStep } from '../../newCountry/MergeMediaStep2.0/styles';

interface Props {
  toExclude: string;
  format:string;
  projectId:string;
  mimeType: string;
  itemType: ItemType | null;
  distributionCountry: Country | null;
  mediaItemTypes: MediaItemTypes;
  onAfterMerge: (newParent: ParentContent, newItemType: ItemType) => void;
}

interface State {
  searchValue: string;
  selected: ParentContent | null;
}

class MergeCutStep extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: '',
      selected: null
    };
  }

  onChangeSearchValue = (searchValue: string) => this.setState({ searchValue, selected: null });

  render() {
    const { toExclude, format, itemType, distributionCountry, projectId, mimeType, mediaItemTypes, onAfterMerge } = this.props;
    const { searchValue, selected } = this.state;
    
   // console.log('MergeCutStep___', itemType)
   // console.log('mediaItemTypes___', mediaItemTypes)
    
    return (
      <WrapperStep>
        <div style={{ width: 'calc(100% - 100px)' }}>
          <Input
            placeholder="Search by title or keywords"
            value={searchValue}
            onChange={e => this.onChangeSearchValue(e.target.value)}
            iconName="search"
          />
        </div>

        <ListMediaWrapper>
          {distributionCountry && ( 
              <ListMediaToMerge
                toExclude={toExclude}
                distributionCountry={distributionCountry}
                projectId = {projectId}
                mimeType = {mimeType}
                selected={selected ? selected.id : null}
                searchValue={searchValue}
                format={format}
                parentFilter={(media: Array<ParentContent>) =>
                  media.filter(
                    parent =>
                      parent.localizations.length === 1 &&
                      parent.localizations[0].platforms.length === 1 /*&&
                      parent.localizations[0].platforms[0].mimeType.indexOf('video') >= 0 &&
                      parent.localizations[0].platforms[0].itemType.indexOf('VIDEO_') >= 0*/
                  )
                }
                onSelect={media =>
                  this.setState(state => ({
                    selected: state.selected && state.selected.id === media.id ? null : media
                  }))
                }
              />)}
        </ListMediaWrapper>

        <div style={{ display: 'flex', alignSelf: 'flex-end', marginTop: 35 }}>
          <MergeCutButton
            mediaItemTypes={mediaItemTypes}
            selected={selected}
            toExclude={toExclude}
            selectedItemType={itemType}
            searchValue={searchValue}
            distributionCountry={distributionCountry}
            onAfterMerge={onAfterMerge}
          />
        </div>
      </WrapperStep>
    );
  }
}

export { MergeCutStep };
