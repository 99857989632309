import * as React from 'react';
import { Query } from 'react-apollo';
import { LIST_SLACK_RECEIVERS } from '../../apollo/queries/ListSlackReceivers';
import ListSlackReceiversQueryT from '../../types/graphql/ListSlackReceiversQuery';
import { CustomQueryProps } from '../../types/graphql/utils';
//import clientTobe from '../../apollo/clientTobe';

class TypedQuery extends Query<ListSlackReceiversQueryT, {}> {}

export const ListSlackReceiversQuery: React.FC<CustomQueryProps<ListSlackReceiversQueryT>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={LIST_SLACK_RECEIVERS} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
