import styled from 'styled-components';
import { zIndex } from '../../utils/zIndex';

const BackgroundLayer = styled.div<{ open: boolean } & React.HTMLProps<HTMLDivElement>>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #160127ba;
  z-index: 2000;
  opacity: 0;

  transition: opacity 0.3s;

  &.background-entering {
    opacity: 0;
  }
  &.background-entered {
    opacity: 1;
  }
  &.background-exiting {
    opacity: 0;
  }
  &.background-exited {
    opacity: 0;
  }
`;

const WrapperLateralPanel = styled.div<
  { open: boolean; width?: string } & React.HTMLProps<HTMLDivElement>
>`
  position: fixed;
  background: #ffffff;
  z-index: ${zIndex.LateralPanel};
  width: ${props => (props.width ? props.width : '475px')};
  height: 100vh;
  overflow-y: scroll;
  top: 0px;
  right: calc(${props => (props.width ? `-${props.width}` : '-475px')} - 5px);

  padding: 15px 20px;
  /* padding-bottom: 0px; */
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  transition: right 0.4s;

  &.wrapper-entering {
    right: calc(${props => (props.width ? `-${props.width}` : '-475px')} - 5px);
  }
  &.wrapper-entered {
    right: 0px;
  }
  &.wrapper-exiting {
    right: calc(${props => (props.width ? `-${props.width}` : '-475px')} - 5px);
  }
  &.wrapper-exited {
    right: calc(${props => (props.width ? `-${props.width}` : '-475px')} - 5px);
  }

  @media (max-width: 768px) {
    width: 100vw;
    right: -768px;
    transition: right 0.5s;

    &.wrapper-entering {
      right: -768px;
    }
    &.wrapper-entered {
      right: 0px;
    }
    &.wrapper-exiting {
      right: -768px;
    }
    &.wrapper-exited {
      right: -768px;
    }
  }
`;

const BackWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export { BackgroundLayer, WrapperLateralPanel, BackWrapper };
