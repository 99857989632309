import * as React from 'react';
import { Option } from '@freeda/react-components/lib/Autocomplete';
import { ChipList, RowForm} from './styles';
import { Format } from '../../../types/types2.0/Format';
//import { ContentType } from '../../../types/ContentType';
import GetEditFormInfoQuery from '../../../types/types2.0/graphql2.0/GetEditInfoFormQuery';
import { Keywords } from '../../../types/types2.0/Keywords';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { PlatformContent } from '../../../types/types2.0/PlatformContent';
//import { CutType } from '../../../types/CutType';
import {PlatformType} from '../../../types/types2.0/PlatformType';
import { VideoType } from '../../../types/VideoType';
//import { DescriptionCutsMap } from '../../../utils/videoCutsMap';
import { Radio, Select, /*Icon,*/Chip, Autocomplete, DividerSection, CheckBox } from '@freeda/react-components';
import Multiselect from 'multiselect-react-dropdown';
////import { ItemType } from '../../../types/ItemType';
import Media from 'react-media';
//import { getComponentContent } from '../../../utils/contentUtils';
import { Campaign } from '../../../types/types2.0/Campaign';
import { Serie } from '../../../types/types2.0/Serie';
import { Label } from '../../../types/types2.0/Label';
//import { Project } from '../../../types/types2.0/Project';
import {getLabelsByPeroject} from '../../../utils/contentUtils';



type EditableMediaFields = Pick<PlatformContent,  'itemType'> &
  Pick<ParentContent, 'contentType'> &
  Pick<ParentContent, 'format'> & {
    videoType: VideoType | null;
    //cutType: CutType | null;
    platformType: PlatformType | null;
    projectId?: string;
    campaignId?:string;
    productTypeId?: string;
    brandId?: string;
    booleansId?: Array<Label>;
    serieId?: string;
    editorialCampaignId?:string;
    topicId?: string;
    productId?:string;
    objectiveId?:string;
    genreId?:string;
    temperatureId?:string;
    keywords: Array<Keywords>;
    newKeywords: Array<string>;
    title?:string;
    isReel?:boolean | null;
  };

type Props = { mimeType: string } & Pick<
  GetEditFormInfoQuery,
  'allBrands' | 'allProductTypes' | 'allSeries' | 'allTopics' | 'allKeywords' | 'allProjects' | 'allCampaigns' | 'allObjectives' | 'allProducts' | 'allTemperatures' | 'allGenres' | 'allEditorialCampaigns' | 'allBooleans'
> &
  EditableMediaFields & {
    allPlatformType: Array<PlatformType>;
    onChangeProductType: (productTypeId: string) => void;
    //onChangeContentType: (contentType: ContentType) => void;
    onChangeItemType: (videoType: VideoType) => void;
    //onChangeCutType: (cutType: CutType) => void;
    onChangePlatformType: (platformType: PlatformType) => void;
    onChangeFormat: (format: Format) => void;
    onChangeBrand: (brandId: string) => void;
    onChangeCampaign:(campaignId: string) => void;
    onChangeProduct: (productId: string) => void;
    onChangeGenre:(genreId: string) => void;
    onChangeObjective:(objectiveId: string) => void;
    onChangeTemperature:(temperatureId: string) => void;
    onAddBoolean:(booleanId: Array<Label>) => void;
    onChangeEditorialCampaign:(editorialCampaignId: string) => void;
    onChangeProject: (projectId: string) => void;
    onChangeSerie: (serieId: string) => void;
    onChangeTopic: (topicId: string) => void;
    onChangeAddedKeywords: (metadata: Keywords) => void;
    onChangeRemovedKeywords: (metadata: Keywords) => void;
    onAddNewKeywords: (keywordLabel: string) => void;
    onRemoveNewKeywords: (keywordLabel: string) => void;
    handleIsReel: (reel:boolean) => void;
    disabledStoriesRadio: boolean;
    disabledVideoRadio: boolean;
    disabledCarouselRadio:boolean;
    disabledImgRadio:boolean;
    readOnlyUser:boolean;
    
  };


const ContentInfo: React.FC<Props> = ({
  readOnlyUser,
  allBrands,
  allProductTypes,
  allPlatformType,
  allProjects,
  allSeries,
  allBooleans,
  allTopics,
  allKeywords,
  allProducts,
  allGenres,
  allCampaigns,
  allEditorialCampaigns,
  allObjectives,
  allTemperatures,
  brandId,
  booleansId,
  productId,
  genreId,
  campaignId,
  objectiveId,
  //contentType,
  itemType,
  platformType,
  videoType,
  format,
  //title,
  productTypeId,
  serieId,
  editorialCampaignId,
  projectId,
  topicId,
  temperatureId,
  keywords,
  newKeywords,
  mimeType,
  onChangeProductType,
  //onChangeContentType,
  onChangeItemType,
  //onChangeCutType,
  onChangePlatformType,
  handleIsReel,
  onChangeFormat,
  onChangeBrand,
  onChangeCampaign,
  onChangeEditorialCampaign,
  onChangeProject,
  onChangeSerie,
  onChangeTopic,
  onChangeProduct,
  onChangeGenre,
  onChangeObjective,
  onChangeTemperature,
  onAddBoolean,
  onChangeAddedKeywords,
  onChangeRemovedKeywords,
  onAddNewKeywords,
  onRemoveNewKeywords,
  disabledStoriesRadio,
  disabledVideoRadio,
  disabledCarouselRadio,
  disabledImgRadio,
  isReel
}) => {

  let selectedBrand = allBrands.find((b) => b.id === brandId);
  const selectedTopic = allTopics.find((t) => t.id === topicId);
  const selectedProject = allProjects.find((p) => p.id === projectId);
  let selectedProductType = allProductTypes.find((p) => p.id === productTypeId);
  const selectedGenre = allGenres.find((g) => g.id == genreId)
  const selectedTemperature = allTemperatures.find((t) => t.id == temperatureId)
  const selectedObjective = allObjectives.find((o) => o.id == objectiveId)



  let allCampaignsFiltered: Campaign[] = []
  allCampaigns.forEach(function(value, index, array) {
    if(value.brand){
        value.brand.forEach(function(v) {
          if( v.id == brandId ){
            allCampaignsFiltered.push(value)
          }
        })
    }
  }); 
  let selectedCampaign = allCampaignsFiltered.find((c)=>c.id==campaignId)
  let allSeriesFiltered: Serie[] = []
  let allProductsFiltered: Label [] = []
  let allKeywordsFiltered: Label [] = []
  let allEditorialCampaignsFiltered: Label [] = []
  
  if(projectId){
    allSeriesFiltered = getLabelsByPeroject(allSeries, projectId)
    allProductsFiltered = getLabelsByPeroject(allProducts, projectId)
    allKeywordsFiltered = getLabelsByPeroject(allKeywords, projectId)
    
    allEditorialCampaignsFiltered = getLabelsByPeroject(allEditorialCampaigns, projectId)
    
    if( selectedProject!.name!= 'Freeda' ){
      allBrands = []
      allCampaigns = []
      selectedBrand=undefined
      selectedCampaign=undefined
    }

  }
  else{
    allSeriesFiltered = allSeries
    allProductsFiltered = allProducts
    allKeywordsFiltered = allKeywords
    allEditorialCampaignsFiltered = allEditorialCampaigns
  }
  const selectedSerie = allSeriesFiltered.find((s) => s.id === serieId);
  const selectedProduct = allProductsFiltered.find((p) => p.id === productId);
  const selectedEditorialCampaign = allEditorialCampaignsFiltered.find((c)=>c.id==editorialCampaignId)
  const isBranded = booleansId?.find((b)=> b.name.toLowerCase() == "branded")

  const isImage = mimeType.indexOf('image') >= 0;
  const isVideo = mimeType.indexOf('video') >= 0;
  const isStory = format == Format.INSTAGRAM_STORY;

  if(isStory){
    allPlatformType = [PlatformType.INSTAGRAM]
  }


  if(format === Format.CAROUSEL){
    allPlatformType = [PlatformType.INSTAGRAM, PlatformType.FACEBOOK, PlatformType.LINKEDIN,  PlatformType.TIKTOK]
    
    if(isVideo && selectedProductType &&  !['carousel video', 'carousel mix'].includes( selectedProductType.name.toLowerCase() )){
      selectedProductType = allProductTypes.find( (p) => p.name.toLowerCase() === 'carousel video' );

      onChangeProductType(selectedProductType!.id)
    }
  }

  
 

/*console.log('disable video ', disabledVideoRadio)
console.log('disable igs ', disabledStoriesRadio)
console.log('disable carousel ', disabledCarouselRadio)
console.log('format___', format)*/


  const isContentReel = !isStory && (
                            (isVideo && videoType === VideoType.VIDEO && 
                                    ( itemType.indexOf('FACEBOOK') >= 0 || itemType.indexOf('INSTAGRAM') >= 0 )
                                          /*&& itemType.indexOf('INSTAGRAM') >= 0*/)  || (isReel ? isReel :false) )
  

 /* console.log(isContentReel, isReel)
  console.log('contentinfo project',selectedProject)
  console.log('contentinfo isImage',isImage)
  console.log('contentinfo isVideo',isVideo)
  console.log('contentinfo isStory',isStory)
  console.log('contentinfo format',format)
  console.log('contentinfo itemType',itemType) 
  console.log('contentinfo platformType',platformType) 
  console.log('contentinfo isContentReel', isContentReel) */



  return (
    <Media query={{ maxWidth: 840 }}>
      {(matches: boolean) => (
        <>
          <DividerSection label="Content Info" style={{ margin: '18px 0px 10px' }} />
          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
            {isImage ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    marginRight: 30,
                    position: 'relative',
                    top: matches ? 0 : 10,
                  }}
                >
                  <Radio
                    label="Image"
                    checked={format === Format.IMAGE}
                    disabled={(readOnlyUser || disabledImgRadio )}
                    onClick={() => onChangeFormat(Format.IMAGE)}
                    style={{ marginRight: 20 }}
                  />
                  <Radio
                    label="Carousel"
                    checked={format === Format.CAROUSEL}
                    disabled={readOnlyUser}
                    onClick={() => onChangeFormat(Format.CAROUSEL)}
                    style={{ marginRight: 20 }}
                  />
                   <Radio
                    label="Instant Art."
                    checked={format === Format.INSTANT_ARTICLES}
                    disabled={(readOnlyUser || disabledImgRadio )}
                    onClick={() => onChangeFormat(Format.INSTANT_ARTICLES)}
                    style={{ marginRight: 20 }}
                  />

                  <Radio
                      label="IG Stories"
                      checked={format==Format.INSTAGRAM_STORY}
                      disabled={(readOnlyUser)}
                      onClick={() => onChangeFormat(Format.INSTAGRAM_STORY)}
                      //onClick={() => onChangeItemType(VideoType.STORY)}
                      style={{ marginRight: 20 }}
                    />


                </div>
              </>
            ) : null}
            {isVideo && (
              <>
                <div
                  style={{
                    display: 'flex',
                    marginRight: 30,
                    position: 'relative',
                    top: matches ? 0 : 10,
                  }}
                >
                    <Radio
                      label="IG Stories"
                      checked={format==Format.INSTAGRAM_STORY}
                      disabled={(readOnlyUser || disabledStoriesRadio)}
                      onClick={() => onChangeItemType(VideoType.STORY)}
                      style={{ marginRight: 20 }}
                    />
              
                  <Radio
                    label="Video"
                    checked={format==Format.VIDEO }
                    disabled={(readOnlyUser || disabledVideoRadio)}
                    onClick={() => onChangeItemType(VideoType.VIDEO)}
                    style={{ marginRight: 20 }}
                  />
                   <Radio
                    label="Carousel"
                    checked={format === Format.CAROUSEL }
                    disabled={readOnlyUser || disabledCarouselRadio}
                    onClick={() => onChangeFormat(Format.CAROUSEL)}
                    style={{ marginRight: 20 }}
                  />
                </div>
                
              </>
            )}
           
            <Select
                label="Content type"
                isDisabled={readOnlyUser}
                style={{
                  flexBasis: matches ? '100%' : 350,
                  marginTop: matches ? 10 : 0,
                  marginRight: matches ? 0 : 20,
                }}
                placeholder="Select a content type"
                optionsSort="asc"
                isClearable={false}
                onChange={(option: any) => onChangeProductType(option ? option.value : null)}
                options={allProductTypes
                  .filter((prodType) => prodType.format === format)
                  .map((prodType) => ({
                    value: prodType.id,
                    label: prodType.name,
                  }))}
                value={
                  selectedProductType
                    ? { value: selectedProductType.id, label: selectedProductType.name }
                    : null
                }
            />

            <Select
                  label="Platform"
                  isDisabled={readOnlyUser}
                  style={{
                    flexBasis: matches ? '100%' : 350,
                    // flexBasis: !matches || isStory ? '50%' : '100%',
                    marginRight: matches ? 0 : 20,
                    marginTop: matches ? 10 : 0,
                  }}
                  placeholder="Select platform type"
                  isClearable={false}
                  isSearchable={false}
                  optionsSort="asc"
                 
                  onChange={(option: any) => onChangePlatformType(option.value)}
                  options={allPlatformType.map((ct) => ({
                    value: ct,
                    //label: DescriptionCutsMap[ct],
                    label: ct,
                  }))}
                  value={platformType ? { value: platformType, label: platformType } : null}
            />

            { (isContentReel && format != Format.CAROUSEL ) && (<CheckBox
                  checked={isReel? isReel : false}
                  onClick={() => handleIsReel(isReel? !isReel : true)}
                  label="Reels"
                  style={{ marginRight: matches ? 0 : 20, position: 'relative', top: matches ? 0 : 10 }}
                  disabled={readOnlyUser}
              />)}
            
            <Select
                  label="Project"
                  isDisabled={readOnlyUser}
                  style={{
                    flexBasis: matches ? '100%' : 350,
                    // flexBasis: !matches || isStory ? '50%' : '100%',
                    marginRight: matches ? 0 : 200,
                    marginTop: matches ? 10 : 0,
                  }}
                  placeholder="Select the project"
                  isClearable={false}
                  isSearchable={false}
                  optionsSort="asc"
                
                  onChange={(option: any) => onChangeProject(option ? option.value : null)}
                  options={allProjects.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                  value={selectedProject ? { value: selectedProject.id, label: selectedProject.name } : null}
            />

          </RowForm>
          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
          <div
              style={{
                display: 'flex',
                marginRight: '30px',
                position: 'relative',
                top: matches ? 0 : 10,
                marginTop: matches ? 10 : 0,
                width: '35%',
                borderRadius: '0px'
              }}
            >
              <Multiselect
                style={{
                    multiselectContainer: {  fontFamily: "BuenosAires", width: '100%', background: '#fff'},
                    searchBox: { borderRadius: '0px' },
                    chips: { background: '#6B2DD5' , borderRadius: '0px', fontFamily: "BuenosAires"},
                    option: {background: 'rgba(107,32,218,0.95)',  opacity: '.9', color:'white', fontFamily: "BuenosAires"},
                }}
                
                placeholder='Boolean labels'
                options={allBooleans} // Options to display in the dropdown
                showCheckbox={true}
                selectedValues={booleansId} // Preselected value to persist in dropdown
                onSelect={(option: any) => onAddBoolean(option ? option : null)} // Function will trigger on select event
                onRemove={(option: any) => onAddBoolean(option ? option : null)} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                closeIcon="cancel"
                disable={readOnlyUser}
              />
            </div>

            { (selectedProject?.name.toLowerCase() =='freeda' && isBranded) /*contentType == ContentType.BRANDED*/ && ( 
                <Select
                  label= "brand*"
                  isDisabled={readOnlyUser}
                  style={{
                    flexBasis: matches ? '100%' : 350,
                    marginRight: matches ? 0 : 20,
                    marginTop: matches ? 10 : 0,
                  }}
                  placeholder="Select a brand"
                  optionsSort="asc"
                  
                  onChange={(option: any) => ( onChangeBrand(option ? option.value : null)) }
                  options={allBrands.map((brand) => ({
                    value: brand.id,
                    label: brand.name,
                  }))}
                  value={selectedBrand ? { value: selectedBrand.id, label: selectedBrand.name } : null}
                />
            )}
            {(selectedProject?.name.toLowerCase() =='freeda' && isBranded) /*contentType == ContentType.BRANDED*/ && ( 
                <Select
                  label="campaign*"
                  isDisabled={readOnlyUser}
                  style={{
                    flexBasis: matches ? '100%' : 350,
                    marginRight: matches ? 0 : 20,
                    marginTop: matches ? 10 : 0,
                  }}
                  placeholder="Select a campaign"
                  optionsSort="asc"
                  onChange={(option: any) => onChangeCampaign(option ? option.value : null)}
                  options={allCampaignsFiltered.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  value={selectedCampaign ? { value: selectedCampaign.id, label: selectedCampaign.name } : null}
                />
            )}
            {(selectedProject?.name.toLowerCase() =='freeda' && !isBranded) && ( 
                <Select
                    label="editorial campaign"
                    isDisabled={readOnlyUser}
                    style={{
                      flexBasis: matches ? '100%' : 350,
                      marginRight: matches ? 0 : 20,
                      marginTop: matches ? 10 : 0,
                    }}
                    placeholder="Select an editorial campaign"
                    optionsSort="asc"
                    onChange={(option: any) => onChangeEditorialCampaign(option ? option.value : null) }
                    options={allEditorialCampaignsFiltered.map((c) => ({
                      value: c.id,
                      label: c.name,
                    }))}
                    value={selectedEditorialCampaign ? { value: selectedEditorialCampaign.id, label: selectedEditorialCampaign.name } : null}
                /> 
            )}      
          </RowForm>

          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
            <Select
              label="Series*"
              isDisabled={readOnlyUser}
              style={{ flexBasis: matches ? '100%' : 350, marginRight: matches ? 0 : 30, marginTop: matches ? 10 : 0 }}
              placeholder="Select a series"
              optionsSort="asc"
              onChange={(option: any) => onChangeSerie(option ? option.value : null)}
              options={allSeriesFiltered.map((s) => ({
                value: s.id,
                label: s.name,
              }))}
              value={selectedSerie ? { value: selectedSerie.id, label: selectedSerie.name } : null}
            />
            <Select
              label="Topic*"
              isDisabled={readOnlyUser}
              style={{ flexBasis: matches ? '100%' : 350, marginTop: matches ? 10 : 0 , marginRight: matches ? 0 : 30}}
              placeholder="Select a topic"
              optionsSort="asc"
              onChange={(option: any) => onChangeTopic(option ? option.value : null)}
              options={allTopics.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              value={selectedTopic ? { value: selectedTopic.id, label: selectedTopic.name } : null}
            />
            <Select
              label="Product"
              isDisabled={readOnlyUser}
              style={{ flexBasis: matches ? '100%' : 350, marginTop: matches ? 10 : 0 }}
              placeholder="Select a product"
              optionsSort="asc"
              onChange={(option: any) => onChangeProduct(option ? option.value : null)}
              options={allProductsFiltered.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              value={selectedProduct ? { value: selectedProduct.id, label: selectedProduct.name } : null}
            />
          </RowForm>

          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
            <Select
              label="Genre"
              isDisabled={readOnlyUser}
              style={{ flexBasis: matches ? '100%' : 350, marginRight: matches ? 0 : 30, marginTop: matches ? 10 : 0 }}
              placeholder="Select a genre"
              optionsSort="asc"
              onChange={(option: any) => onChangeGenre(option ? option.value : null)}
              options={allGenres.map((s) => ({
                value: s.id,
                label: s.name,
              }))}
              value={selectedGenre ? { value: selectedGenre.id, label: selectedGenre.name } : null}
            />
            <Select
              label="Objective*"
              isDisabled={readOnlyUser}
              style={{ flexBasis: matches ? '100%' : 350, marginTop: matches ? 10 : 0 , marginRight: matches ? 0 : 30}}
              placeholder="Select an objective"
              optionsSort="asc"
              onChange={(option: any) => onChangeObjective(option ? option.value : null)}
              options={allObjectives.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              value={selectedObjective ? { value: selectedObjective.id, label: selectedObjective.name } : null}
            />
            <Select
              label="Temperature*"
              isDisabled={readOnlyUser}
              style={{ flexBasis: matches ? '100%' : 350, marginTop: matches ? 10 : 0 }}
              placeholder="Select a temperature"
              optionsSort="asc"
              onChange={(option: any) => onChangeTemperature(option ? option.value : null)}
              options={allTemperatures.map((t) => ({
                value: t.id,
                label: t.name,
              }))}
              value={selectedTemperature ? { value: selectedTemperature.id, label: selectedTemperature.name } : null}
            />
          </RowForm>
          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
            <Autocomplete
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Keywords
                </div>
              }
              styleWrapper={{ width: matches ? '100%' : '25%' }}
              placeholder="Type your keyword and press Enter"
              optionsSort="asc"
              items={allKeywordsFiltered.map((k) => ({ value: k.id, label: k.name }))}
              currentSelectedItem={keywords.map((k) => ({ value: k.id, label: k.name }))}
              onSelect={({ value, label }) => onChangeAddedKeywords({ id: value, name:label })}
              onUnselect={({ value, label }) => onChangeRemovedKeywords({ id: value, name:label })}
              onAdd={onAddNewKeywords}
              aligment="column"
              noOptions={false}
              style={{display: readOnlyUser? 'none' : 'true'}}
              renderSelections={(selectedKeywords, handleDelete) => (
                <>
                  {(keywords.length > 0 || newKeywords.length > 0) && (
                    <ChipList
                      style={{
                        width: matches ? '100%' : '60%',
                        top: matches ? 5 : 21,
                        marginBottom: readOnlyUser? 20 : 0,
                        marginLeft: matches || readOnlyUser?  0 : 3,
                        zIndex: 0
                        
                      }}
                    >
                      {selectedKeywords.map((keyword: Option) => (
                        <Chip
                          key={keyword.value}
                          label={keyword.label}
                          onClick={() => {
                            handleDelete(keyword);
                          }}
                          isClearable={!readOnlyUser}
                        />
                      ))}
                      {newKeywords.map((keywordLabel) => (
                        <Chip
                          key={keywordLabel}
                          label={keywordLabel}
                          onClick={() => {
                            onRemoveNewKeywords(keywordLabel);
                          }}
                          isClearable={!readOnlyUser}
                        />
                      ))}
                    </ChipList>
                  )}
                </>
              )}
            />
          </RowForm>
        </>
      )}
    </Media>
  );
};

export { ContentInfo };
