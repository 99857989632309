import * as React from 'react';
import { Query } from 'react-apollo';
import { LIST_CONTENT_TO_MERGE } from '../../../apollo/queries/queries2.0/ListContentToMerge';
import ListContentToMergeQueryT from '../../../types/types2.0/graphql2.0/ListContentToMergeQuery';
import { CustomQueryProps } from '../../../types/graphql/utils';

class TypedQuery extends Query<ListContentToMergeQueryT, {}> {}

export const ListContentToMergeQuery: React.FC<CustomQueryProps<ListContentToMergeQueryT>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={LIST_CONTENT_TO_MERGE} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
