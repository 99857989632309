import * as React from 'react';

const DownloadMediaDialogContext = React.createContext({
  showDialog: false,
  mediaId: '',
  onOpen: (mediaId: string) => console.warn('Please implement DialogContext.onOpen '),
  onClose: () => console.warn('Please implement DialogContext.onClose')
});

class DownloadMediaDialogProvider extends React.Component<
  {},
  { showDialog: boolean; mediaId: string }
> {
  constructor(props: {}) {
    super(props);
    this.state = {
      showDialog: false,
      mediaId: ''
    };
  }

  onOpen = (mediaId: string) => this.setState({ showDialog: true, mediaId });

  onClose = () => this.setState({ showDialog: false, mediaId: '' });

  render() {
    return (
      <DownloadMediaDialogContext.Provider
        value={{ ...this.state, onOpen: this.onOpen, onClose: this.onClose }}
      >
        {this.props.children}
      </DownloadMediaDialogContext.Provider>
    );
  }
}

export { DownloadMediaDialogProvider, DownloadMediaDialogContext };
