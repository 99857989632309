import * as React from 'react';
import Media from 'react-media';
import { flatMap } from 'lodash';
import { Button, Icon, Typography, ButtonIcon, Dialog } from '@freeda/react-components';

import { Dialogable } from '../../../types/Dialogable';
//import { ParentContent } from '../../../types/types2.0/ParentContent';
import { Country } from '../../../types/graphql/Country';
import { ItemType } from '../../../types/ItemType';
import { CutType } from '../../../types/CutType';

//import { getFirstAvailablePlatformContentType } from '../../../utils/contentUtils';
import { download } from '../../../utils/downloadMedia';
import { CountrySelection } from '../../../components/ui/CountrySelection';
import { ImageSection } from './ImageSection';
import { VideoSection } from './VideoSection';
import { StorySection } from './StorySection';

import { ParentContentSelection } from '../../../types/types2.0/ParentContentSelection';
import { PlatformType } from '../../../types/types2.0/PlatformType';



type Props = Dialogable & {
  fullScreen?: boolean;
  content: Array<ParentContentSelection>;
};

type DownloadableItem = Array<{ thumbnailUrl: string; storagePath: string; title: string }>;

type GroupedDownloadableItemTypes = { [I in keyof typeof ItemType]?: DownloadableItem };

type GroupedDownloadable = { [C in keyof typeof Country]?: GroupedDownloadableItemTypes };

interface State {
  selectedTranslationCountry: Country;
  selectedItemType: ItemType;
  downloadableMedia: GroupedDownloadable;
  downloadImage: boolean;
  downloadVideo: boolean;
  downloadStory: boolean;
}

const downloadMedia = (
  downloadImage: boolean,
  downloadVideo: boolean,
  downloadStory: boolean,
  objectUrl: DownloadableItem,
  //videoUrl: DownloadableItem,
  //storyUrl: DownloadableItem
) => {
  let urls = [];
  if (downloadImage) {
    urls.push(...objectUrl.map(d => d.storagePath));
  }
  if (downloadVideo) {
    urls.push(...objectUrl.map(d => d.storagePath));
  }

  if (downloadStory) {
    urls.push(...objectUrl.map(d => d.storagePath));
  }
  download(urls);
};

class DownloadMultiMediaDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    //alert('DownloadMultiMediaDialogAAAAA')
    const localizations = flatMap(this.props.content.map(m => m.localizations));
    console.log('ilocalizations____', localizations)

    const distributionCountries = Array.from(new Set(localizations.map(t => t.distributionCountry)));

    const itemTypes: Array<string> = Array.from(
      new Set(flatMap(localizations.map(t => t.platforms)).map(i => i.itemType))
    );

    console.log('itemTypes 1____', itemTypes)
    const downloadableMedia: GroupedDownloadable = distributionCountries.reduce<
      GroupedDownloadable
    >((previousCountries, currentCountry) => {
      return {
        ...previousCountries,
        [currentCountry]: itemTypes.reduce<GroupedDownloadableItemTypes>(
          (previousItemTypes, currentItemType) => {
            const filteredItems = flatMap(
              localizations.filter(t => t.distributionCountry === currentCountry).map(t => t.platforms)
            ).filter(i => i.itemType === currentItemType);

            if (filteredItems.length === 0) {
              return previousItemTypes;
            }

            return {
              ...previousItemTypes,
              [currentItemType]: filteredItems.map(({ storagePath, thumbnailUrl , title, mimeType}) => ({
                storagePath,
                thumbnailUrl,
                title,
                mimeType
              }))
            };
          },
          {}
        )
      };
    }, {});

    console.log('___downloadableMedia___1' , downloadableMedia)
    const userCountry = localStorage.getItem('country') as Country;

    const localContent =
        localizations.find(t => t.distributionCountry === userCountry) || localizations[0];
       
    console.log('___localContent___1' , localContent)

    /*const availableCutTypes = Object.keys(
      downloadableMedia[localContent.distributionCountry]!
    ).filter(it => it.indexOf('VIDEO') >= 0) as Array<ItemType>;*/
    

    /*const selectedItemType = getFirstAvailablePlatformContentType(
      (localContent.platforms[0].itemType )
    );*/
    let mimeType = localContent.platforms[0].mimeType.indexOf('image')>=0 ? 'IMAGE' : 'VIDEO';
    let platformType = mimeType.concat('_').concat(localContent.platforms[0].itemType)
    let selectedItemType = ItemType[platformType]
    
    console.log('___selectedItemType____1', selectedItemType)
   

    /*const availablePlatform = Object.keys(downloadableMedia[localContent.distributionCountry]!).filter(
      it => it!=null
    ) as Array<PlatformType>;
    console.log('___availablePlatform____1', availablePlatform)*/

    /*const selectedItemType = getFirstAvailablePlatformContentType(availableCutTypes);

    const videoUrl =
      downloadableMedia[localContent.distributionCountry]![selectedItemType]! || [];
    
    const imageUrl =
      downloadableMedia[localContent.distributionCountry]![ItemType.IMAGE]! || [];
    
    const storyUrl =
      downloadableMedia[localContent.distributionCountry]![ItemType.VIDEO_INSTAGRAM_STORY]! || [];*/

    /*const downloadImage = imageUrl.length > 0;
    const downloadVideo = videoUrl.length > 0;
    const downloadStory = storyUrl.length > 0;*/

    const downloadImage = false;
    const downloadVideo = false;
    const downloadStory = false;


    this.state = {
      selectedTranslationCountry: localContent.distributionCountry,
      selectedItemType,
      downloadableMedia,
      downloadImage,
      downloadVideo,
      downloadStory
    };
  }

  handleSelectedTranslationChange = (country: Country) => {
    const { downloadableMedia } = this.state;
    let platform = downloadableMedia[country]!
    let platformType;
    if( platform[PlatformType['INSTAGRAM']] ){//insta
      platformType = PlatformType['INSTAGRAM']

    }
    else if( platform[PlatformType['FACEBOOK']] ){//fb
      platformType = PlatformType['FACEBOOK']

    }
    else if(platform[PlatformType['TIKTOK']] ){ //tk
      platformType = PlatformType['TIKTOK']

    }
    else if(platform[PlatformType['LINKEDIN']] ){ //lk
      platformType = PlatformType['LINKEDIN']

    }
    else{//yt
      platformType = PlatformType['YOUTUBE']

    }
    let mimeType =   platform[platformType][0].mimeType.indexOf('video')>=0 ? 'VIDEO' : 'IMAGE'
    let selectedItemType = ItemType[mimeType.concat('_').concat(platformType)]

    
    this.setState({
      downloadImage: platform[platformType][0].mimeType.indexOf('image')>= 0 ? true : false,
      downloadVideo: platform[platformType][0].mimeType.indexOf('video')>=0 ? true : false,
      downloadStory: false,
      selectedTranslationCountry: country,
      selectedItemType: selectedItemType
    });
  };

  handleSelectedCutType = (cutType: CutType) => {
    this.setState({
      selectedItemType:  ItemType[ ('VIDEO_').concat(cutType) ] 
    });
  };

  handleDownloadImage = () => {
    this.setState(state => ({
      downloadImage: !state.downloadImage
    }));
  };

  handleDownloadVideo = () => {
    this.setState(state => ({
      downloadVideo: !state.downloadVideo
    }));
  };

  handleDownloadStory = () => {
    this.setState(state => ({
      downloadStory: !state.downloadStory
    }));
  };

  render() {
    const { open, onClose } = this.props;
    const {
      selectedTranslationCountry,
      selectedItemType,
      downloadableMedia,
      downloadImage,
      downloadVideo,
      downloadStory
    } = this.state;

    //console.log('downloadableMedia___', downloadableMedia)
    //console.log('selectedItemType___', selectedItemType)
    //console.log('selectedTranslationCountry___',selectedTranslationCountry)

    
    const localizations = flatMap(this.props.content.map(m => m.localizations));

    const availablePlatform = Object.keys(downloadableMedia[selectedTranslationCountry]!).map(
      it => it
    ) as Array<PlatformType>;
    
    const distributionCountries = Array.from(new Set(localizations.map(t => t.distributionCountry)));
    
    let selectedItemTypeArr = selectedItemType.split('_');
    let objectUrl : { thumbnailUrl: string; storagePath: string; title:string}[] =[];
    let isImageUrl= false
    let isVideoUrl= false

    /*if(selectedItemTypeArr[0].indexOf('image') >=0){
      isImageUrl= true
    }else{
      isVideoUrl=true
    }*/

    let platform=downloadableMedia[selectedTranslationCountry]![selectedItemTypeArr[1]!][0]
    console.log('platformy___',platform)

    if(platform.mimeType.indexOf('image')>=0) isImageUrl= true
    else isVideoUrl=true

    objectUrl.push({
      thumbnailUrl: platform.thumbnailUrl,
      storagePath: platform.storagePath,
      title: platform.title,
    })

    console.log(objectUrl)
    //let videoUrl = downloadableMedia[selectedTranslationCountry]![selectedItemTypeArr[1] ]! || [];
    //let imageUrl = downloadableMedia[selectedTranslationCountry]![ItemType.IMAGE]! || [];
    //let storyUrl = downloadableMedia[selectedTranslationCountry]![ItemType.VIDEO_INSTAGRAM_STORY]! || [];
   
    //const type = downloadableMedia[selectedTranslationCountry]![selectedItemType]! || [];

    

    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <Dialog
            openDialog={open}
            style={{
              width: matches ? '100vw' : 600,
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 30
            }}
            onClose={onClose}
            fullScreen={matches}
            disableEscapeKeyUp
            rootIdAttributes="root"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                maxHeight: 60
              }}
            >
              <Typography variantName="subtitle" textColor="#6B20DA">
                Download Content
              </Typography>
              <ButtonIcon onClick={onClose} style={{ width: 45, height: 45 }}>
                <Icon iconName="close" style={{ fontSize: 35 }} />
              </ButtonIcon>
            </div>
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 25,
                  marginTop: 15
                }}
              >
                <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                  DISTRIBUTION COUNTRY
                </Typography>
                <CountrySelection
                  countries={distributionCountries}
                  selectedCountry={selectedTranslationCountry}
                  onSelection={this.handleSelectedTranslationChange}
                />
              </div>
              
                {isImageUrl && <ImageSection
                  imageUrl={objectUrl}
                  downloadImage={downloadImage}
                  onChangeDownloadImage={this.handleDownloadImage}
                />}
             
              
                {isVideoUrl && <VideoSection
                  videoUrl={objectUrl}
                  availableCutTypes={availablePlatform}
                  onChangeCutType={this.handleSelectedCutType}
                  selectedCutType={PlatformType[selectedItemTypeArr[1]]}
                  downloadVideo={downloadVideo}
                  onChangeDownloadVideo={this.handleDownloadVideo}
                />}
             
                {false && <StorySection
                  storyUrl={objectUrl}
                  downloadStory={downloadStory}
                  onChangeDownloadStory={this.handleDownloadStory}
                />}
             
            </div>

            <div
              style={{
                float: matches ? 'left' : 'right',
                width: matches ? '100%' : ''
              }}
            >
              <Button
                icon={{
                  position: 'left',
                  iconName: 'download',
                  iconColor: '#ffffff'
                }}
                disabled={!downloadImage && !downloadVideo && !downloadStory}
                style={{ width: !matches ? '' : '100%', margin: 0 }}
                onClick={() => {
                  downloadMedia(
                    downloadImage,
                    downloadVideo,
                    downloadStory,
                    objectUrl
                    
                  );
                }}
              >
                Download
              </Button>
            </div>
          </Dialog>
        )}
      </Media>
    );
  }
}

export default DownloadMultiMediaDialog;
