import * as React from 'react';
import { Location } from 'history';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props {
  location: Location;
}

type PropsWithRouterProps = Props & RouteComponentProps<{}>;

interface State {
  location: Location | null;
}

const CurrentMediaPageLocationContext = React.createContext<State>({
  location: null
});

class CurrentMediaPageLocation extends React.Component<PropsWithRouterProps, State> {
  constructor(props: PropsWithRouterProps) {
    super(props);
    this.state = {
      location: null
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { location } = nextProps;
    if (
      location &&
      (location.pathname === '/' ||
        location.pathname.indexOf('/my-media') >= 0)
    ) {
      this.setState({ location });
    }
  }

  render() {
    return (
      <CurrentMediaPageLocationContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </CurrentMediaPageLocationContext.Provider>
    );
  }
}

export const CurrentMediaPageLocationProvider = withRouter<PropsWithRouterProps, any>(
  CurrentMediaPageLocation
);
export { CurrentMediaPageLocationContext };
