import gql from 'graphql-tag';
//import { PARENT_CONTENT } from './ParentContentFragment';
//import { PARENT_CONTENT_LIST } from './ParentContentFragmentList';
//$productType: [IdInput]
const username = localStorage.getItem('username');

const LIST_MY_CONTENT = gql`
  query listMyContent(
    $skip: Int
    $first: Int
    $keywords: [Keywords]
    $title: String
    $brand: MetadataWhereInput
    $serie: MetadataWhereInput

    $macroTopic: String
    $genre: ID
    $objective: ID
    $temperature: ID
    $campaign: ID
    $editorialCampaign: ID
    $product:ID

    $topic: MetadataWhereInput
    $contentType_in: [ContentType]
    $format_in: [FormatDao]
    $productionCountry: CountryEnumDao
    $language: LanguageEnumDao
    $members: [Members]
    $contributors: [Contributors]
    $createdAtDates: createdAtDates
    $distributionCountry: CountryEnumDao
    $itemType: ItemType
    $project: ID
    $reel: Boolean
    $productType: [IdInput]
    
    $completion:CompletionEnum
  ) {
    listParentContents(
      skip: $skip
      first: $first
      orderBy: updatedAt_DESC
      where: {
        brand: $brand
        serie: $serie
        macroTopic: $macroTopic
        genre: $genre
        objective: $objective
        temperature: $temperature
        campaign: $campaign
        editorialCampaign: $editorialCampaign
        product:$product
        topic: $topic
        contentType_in: $contentType_in
        format_in: $format_in
        productType: $productType
        keywords: $keywords
        title: $title
        productionCountry: $productionCountry
        language: $language
        members: $members
        contributors: $contributors
        createdAtDates : $createdAtDates
        distributionCountry: $distributionCountry
        locals_some: { members_contains: "${username}" }
        itemType: $itemType
        project: $project
        reel: $reel
       
        completion:$completion
      }
    ) {
      edges {
        node {
          id
          contentType
          title
          format
          productionCountry
          completed
         
          localizations {
            id
            distributionCountry
            members {
              id
              name
            }
            contributors {
              id
              name
            }
            platforms {
              id
              creator
              mimeType
              storagePath
              thumbnailUrl
              videoLength
              itemType
              components{
                storagePath
                thumbnailUrl
                title
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
    }
  }

`;

export { LIST_MY_CONTENT };
