export enum LanguageCountry {
  ARA = 'ARA',
  CHI = 'CHI',
  ESP = 'ESP',
  ENG = 'ENG',
  FRE = 'FRE',
  GER = 'GER',
  ITA = 'ITA',
  JAP = 'JAP',
  KOR = 'KOR',
  POR = 'POR',
  RUS = 'RUS'
}

export interface Language {
  copy?: LanguageCountry;
  sound?: LanguageCountry;
  subtitle?: LanguageCountry;
}
