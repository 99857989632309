import * as React from 'react';
import { StoriesWrapper, FrameWrapper } from './styles';
import { FramePreview } from '../FramePreview';
import { PlatformContent } from '../../../types/types2.0/PlatformContent';
import { sortBy } from 'lodash';
import { Typography } from '@freeda/react-components';

interface Props {
  platformContent: PlatformContent;
  selectedComponentIds: Array<string>;
  isIgStories : Boolean,
  onSelectFrame: (frameId: string) => void;
}

type StoryFrameT = {
  type: 'frame' | 'story';
  id: string;
  title: string ;
  thumbnailUrl: string | null;
  storagePath: string;
  mimeType: string;
  position?: number;
};

const DownloadFramesSelection: React.FC<Props> = ({
  platformContent,
  onSelectFrame,
  isIgStories,
  selectedComponentIds
}) => {
  const story = platformContent;
  const components = sortBy(platformContent.components, 'position');

  const availableFrames: Array<StoryFrameT> = [
    {
      type: 'story',
      id: story.id,
      title: /* TODO story.title*/ '*****',
      thumbnailUrl: story.thumbnailUrl,
      storagePath: story.storagePath,
      mimeType: story.mimeType
    },
    ...components.map<StoryFrameT>(({ id, title, thumbnailUrl, storagePath, mimeType, position }) => ({
      type: 'frame',
      id,
      title,
      thumbnailUrl,
      storagePath,
      mimeType,
      position
    }))
  ];

  return (
    <StoriesWrapper style={{ paddingLeft: 15 }}>
      {availableFrames.map(component => (
        <FrameWrapper
          //style={{ paddingLeft: 20 }}
          key={component.id}
          isselected={String(Boolean(selectedComponentIds.find(id => id === component.id)))}
          onClick={() => onSelectFrame(component.id)}
        >
          <FramePreview
          
            mimeType={component.mimeType}
            frameUrl={{
              storagePath: component.storagePath,
              thumbnailUrl: component.thumbnailUrl
            }}
          />
          
          { isIgStories && (
            <Typography variantName="italic-label" style={{fontSize:9}} >
              <>{component.type === 'story' ? 'Story' : component.position! + 1}</>
            </Typography>
          )}

        </FrameWrapper>
      ))}
    </StoriesWrapper>
  );
};

export { DownloadFramesSelection };
