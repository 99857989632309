import * as React from 'react';
//import { parse } from 'query-string';
import { ButtonIcon, Icon, Typography } from '@freeda/react-components';

import { Country as DistributionCountry, Country } from '../types/graphql/Country';
import { CutSelectionStep } from '../components/layout/EditMediaPage/steps/newVideoCut/CutSelectionStep2.0';
import UploadCutStep from '../components/layout/EditMediaPage/steps/newVideoCut/UploadCutStep';
import { MergeCutStep } from '../components/layout/EditMediaPage/steps/newVideoCut/MergeCutStep2.0';
import { WizardStep } from '../components/ui/WizardStep';
import { HeaderEditMedia } from '../components/ui/HeaderEditMedia';
import { ItemType } from '../types/ItemType';
import { CutType } from '../types/CutType';
import { EnhancedLocalContent } from '../types/types2.0/EnhancedContent';
import { EditStep } from './EditMediaPage2';
import { ParentContent } from '../types/types2.0/ParentContent';
import { Format } from '../types/types2.0/Format';

type Props = {
  parentId: string;
  format:string;
  projectId: string;
  formatType:Format;
  localizations: Array<EnhancedLocalContent>;
  distributionCountry:Country,
  addItems: (
    selectedItemType: ItemType,
    selectedCutType: CutType,
    selectedCountry: Country,
    
  ) => (file: File) => void;
  changeEditStep: (editStep: EditStep) => void;
  onAfterMerge: (newRoot: ParentContent, newItemType: ItemType) => void;
};

type State = {
  stepIndex: number;
  newItemType: ItemType | null;
  newCutType: CutType | null;
};

class UploadNewCutPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stepIndex: 0,
      newItemType: null,
      newCutType: null
    };
  }
  getSteps = (
    selectedTranslationCountry: DistributionCountry,
    addItems: (
      selectedItemType: ItemType,
      selectedCutType: CutType,
      selectedCountry: DistributionCountry,
      mimeType?: string,
    ) => (file: File) => void
  ) => {
    const { parentId, format, formatType, projectId, localizations, changeEditStep, onAfterMerge } = this.props;
    
    
    const { newItemType, newCutType } = this.state;
    const localContent =
      localizations.find(t => t.distributionCountry === selectedTranslationCountry) ||
      localizations[0];

    const itemType = localContent.platforms.map(i => i.itemType);
    const mimeType = localContent.platforms[0].mimeType.indexOf('video')>=0 ? 'VIDEO': 'IMAGE';
    
    //console.log('format in uploadNewCutPage__', format, formatType)
    
    let remainingCuts = Object.keys(ItemType).filter(
      ct => !itemType.find(itemT => itemT === ct) && ct.indexOf(mimeType)>=0
    ) as Array<ItemType>;

    remainingCuts= formatType==Format.INSTANT_ARTICLES ? remainingCuts.filter(function(value, index, arr){ 
         return value != ItemType.IMAGE_INSTAGRAM})
      : remainingCuts
    
    remainingCuts= formatType==Format.CAROUSEL ? remainingCuts.filter(function(value, index, arr){ 
        return value != ItemType.VIDEO_TIKTOK && value!=ItemType.VIDEO_YOUTUBE})
    : remainingCuts

    remainingCuts= formatType==Format.INSTAGRAM_STORY ? remainingCuts.filter(function(value, index, arr){ 
      return value == ItemType.VIDEO_INSTAGRAM || value == ItemType.IMAGE_INSTAGRAM_STORY})
    : remainingCuts

    
    /*const remainingCuts = Object.keys(CutType).filter(
      ct => !itemType.find( itemT => itemT === ct )
    ) as Array<ItemType>;*/
      
    //console.log('remainingCuts', remainingCuts)

    return [
      {
        label: 'Select typology',
        completed: true,
        component: (
          <CutSelectionStep
            onSelection = {(newItem: ItemType) =>
              this.setState(
                {
                  newItemType: newItem,
                  newCutType: CutType[newItem]
                },
                () => this.incrementStep()
              )
            }
            itemTypes={remainingCuts}
          />
        )
      },
      {
        label: 'Select content source',
        completed: true,
        component: (
          <UploadCutStep
            mimeType={localContent.platforms[0].mimeType}
            goToFileSelection={() => this.setState({ stepIndex: 2 })}
            onAddItem={ addItems(newItemType!, newCutType!, selectedTranslationCountry)}
            changeEditStep={changeEditStep}
          />
        )
      },
      {
        label: 'Upload new platform',
        completed: true,
        component: (
          <MergeCutStep
            mediaItemTypes={localContent.platforms.map(i => ({
              id: i.id,
              itemType: ItemType[i.itemType] 
            }))}
            itemType={newItemType}
            toExclude={parentId}
            format={format}
            mimeType = {mimeType}
            projectId = {projectId}
            distributionCountry={selectedTranslationCountry}
            onAfterMerge={onAfterMerge}
          />
        )
      }
    ];
  };

  incrementStep = () => this.setState(state => ({ stepIndex: state.stepIndex + 1 }));

  decrementStep = () => this.setState(state => ({ stepIndex: state.stepIndex - 1 }));

  render() {
    const { stepIndex } = this.state;
    const { /*localizations,*/ changeEditStep, addItems, distributionCountry } = this.props;

    //const queryParams = parse(window.location.search);

   /* const selectedTranslationCountry = (queryParams.country ||
      localizations[0].distributionCountry) as DistributionCountry;*/
      /*const localContent =
      localizations.find(t => t.distributionCountry === selectedTranslationCountry) ||
      localizations[0]; */
    //alert(distributionCountry)

    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100vw'
        }}
      >
        <HeaderEditMedia
          backElement={
            stepIndex === 0 ? (
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                onClick={() => changeEditStep('EDIT')}
              >
                <ButtonIcon style={{ marginRight: 5 }}>
                  <Icon iconName="arrow-left" />
                </ButtonIcon>
                <Typography
                  variantName="label"
                  textColor="#6B20DA"
                  style={{ fontSize: 14, cursor: 'pointer' }}
                >
                  Back
                </Typography>
              </div>
            ) : (
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                onClick={() => {
                  this.decrementStep();
                }}
              >
                <ButtonIcon style={{ marginRight: 5 }}>
                  <Icon iconName="arrow-left" />
                </ButtonIcon>
                <Typography
                  variantName="label"
                  textColor="#6B20DA"
                  style={{ fontSize: 14, cursor: 'pointer' }}
                >
                  Back
                </Typography>
              </div>
            )
          }
        />
        <WizardStep
          title="Upload new platform"
          activeStep={this.state.stepIndex}
          steps={this.getSteps(distributionCountry, addItems)}
        />
      </div>
    );
  }
}

export { UploadNewCutPage };
