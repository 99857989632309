import styled, { css } from 'styled-components';
import { HTMLProps } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  color: #000000;
`;

const BlockingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 1px 0px;
  border-color: #cccccc;
  margin-top: 40px;
  height: 100%;
`;

const ListMediaWrapper = styled(ContentWrapper)`
  width: calc(100vw - 200px);
  @media (max-width: 1020px) {
    width: calc(100vw - 100px);
  }
`;

const Content = styled.div<{ disabled?: boolean } & HTMLProps<HTMLDivElement>>`
  position: relative;
  padding: 95px 70px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    min-height: 120px;
  }

  & > img {
    width: 110px;
  }

  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
      &:hover {
        background-color: #d7fff4;
      }
    `};
`;

const Step = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export { Wrapper, BlockingOverlay, ContentWrapper, Content, Step, ListMediaWrapper };
