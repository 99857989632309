import * as React from 'react';
import moment from 'moment-timezone';
import { difference } from 'lodash';
import Media from 'react-media';
import { ButtonIcon, InteractiveAvatar, Typography , Tooltip} from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { Img, Video, InfoWrapper, OtherCutsWrapper, ButtonStyled, PreviewWrapper } from './styles';
//import { TeamMember } from '../../../types/TeamMember';
import { User } from '../../../types/types2.0/User';
import UploadInput from '../../ui/UploadInput';
import { CountrySelection } from '../../ui/CountrySelection';
import { Country } from '../../../types/graphql/Country';
//import { getUsers } from '../../../utils/getUsers';
import { getUsers } from '../../../utils/getUsers';
import { VideoCutSelection } from '../../ui/VideoCutSelection2';
import { ItemType } from '../../../types/ItemType';
import { CutType } from '../../../types/CutType';
import { EditStep } from '../../../pages/EditMediaPage2';
import { DragDrop } from '../../ui/DragDrop2.0';
//import { PlatformContent } from '../../../types/types2.0/PlatformContent';
import { EnhancedPlatformContent } from '../../../types/types2.0/EnhancedContent';
import { Format } from '../../../types/types2.0/Format';
import { CSSTransition } from 'react-transition-group';
import { CloneMedia } from './CloneMedia';
import { CloneMediaDialog } from '../../../components/layout/CloneMediaDialog';
const imgPlaceholder = require('../../../assets/placeholder-img.jpg');


interface Props {
  readOnlyUser:boolean;
  platformContent: EnhancedPlatformContent;
  mimeType: string;
  format: Format, 
  itemType: ItemType;
  url: string;
  newFile: boolean;
  titlePreview:string,
  allTeamMembers:Array<User>;
  countries: Array<Country>;
  selectedCountry: Country;
  onOpenDeleteDialog: Function;
  itemTypes: Array<ItemType>;
  idPlatformContents:Array<string>;
  selectedCut: ItemType;
  selectedComponentId: string | null;
  onFileSelection: (newFile: File, clone:boolean) => void;
  onChangeSelectedCountry: (country: Country) => void;
  onChangeSelectedCut: (itemType: ItemType, idPlatform:string) => void;
  changeEditStep: (editStep: EditStep) => void;
  onAddComponents: (files: Array<File>, platformId:string) => void;
  clonePlatform: (/*platforms: Array<string>,*/platform:string, platformToCLone:EnhancedPlatformContent) => void;
  removeClonePlatform:(platform:string) => void;
  newFileUploadProgress: number;
  onSave: (
    save: (
      parentOptions: any,
      alreadyExistingLocalizationsOptions: any,
      alreadyExistingPlatformsOptions: any,
      newTranslationsOptions: any
    ) => void
  ) => void;
  onSelectComponent: (componentId: string | null) => void;
  onUpdatePosition: (orderedFramesId: Array<string>) => void;
}




const Preview: React.FC<Props> = ({
  readOnlyUser,
  platformContent,
  mimeType,
  format,
  itemType,
  url,
  newFile,
  titlePreview,
  allTeamMembers,
  countries,
  selectedCountry,
  itemTypes,
  idPlatformContents,
  selectedCut,
  selectedComponentId,
  changeEditStep,
  onFileSelection,
  onChangeSelectedCountry,
  onOpenDeleteDialog,
  onChangeSelectedCut,
  onAddComponents,
  clonePlatform,
  removeClonePlatform,
  newFileUploadProgress,
  onSave,
  onSelectComponent,
  onUpdatePosition,
}) => {
  

  const fileExists = () =>{
    try{
      var req = new XMLHttpRequest();
      req.open('HEAD', url, false);
      req.send();
      return req.status==200;
    }
    catch(e){
      console.log('ERROR IN CHECK S3 FILE')
    }
    return viewButton
  }
  let viewButton = true

  let fileNameCompressed:any = platformContent.storagePath.substring( platformContent.storagePath.lastIndexOf("/")+1 )
  fileNameCompressed = fileNameCompressed.split('.')

  if(fileNameCompressed.length==2){
     viewButton = fileExists()
  }
  const creatorInfo = getUsers(allTeamMembers, platformContent.creator ? platformContent.creator: null)[0];

  const showDeleteButton  =  (platformContent.creator == localStorage.getItem('name') || platformContent.updatedBy == localStorage.getItem('name')) && !newFile;

  const lastUpdaterInfo = getUsers(allTeamMembers, platformContent.updatedBy || null)[0];

  const selectedComponent = platformContent.components
  ? platformContent.components.find((f) => f.id === selectedComponentId)
  : null;
  
  mimeType = selectedComponent && selectedComponent.mimeType ? selectedComponent.mimeType : mimeType
  const frameIsImage = selectedComponent && selectedComponent.mimeType.indexOf('image') >= 0;
  //const itemIsVideo = mimeType.indexOf('video') >= 0;
  const itemIsImage = mimeType.indexOf('image') >= 0;
  const mustShowImage = itemIsImage || frameIsImage;
  //const isIgStories = itemType && itemType.indexOf('INSTAGRAM_STORY') >= 0;
  //const isVideoCut = itemIsVideo && itemType && itemType.indexOf('VIDEO_') >= 0;
  const isIgStories = format && format.indexOf('INSTAGRAM_STORY') >= 0;
  const isCarousel = format && format.indexOf('CAROUSEL') >= 0;
  //const isReel = itemIsVideo && itemType && itemType.indexOf('VIDEO_INSTAGRAM') >= 0
  //console.log('format________****', format)
  //console.log('itemTypes________****', itemTypes)
  //console.log('selectedCut________****', selectedCut)
  //console.log('isIgStories________****', isIgStories)
  //console.log('URL________****', url)
  //console.log('selectedComponent________****', selectedComponent)
  //console.log('mustShowImage________****', itemIsImage, frameIsImage)

//|| !platformContent!.localization?.id
  const [loadingImg, setLoadingImg] = React.useState<boolean>(true);
  const [openCLonePanel, setOpenCLonePanel] = React.useState<boolean>(false);
  const [openCLoneDialog, setOpenCLoneDialog] = React.useState<boolean>(false);

  let [fileReplace, setFileReplace] = React.useState<File>();

  const handleImgLoaded = () => setLoadingImg(false);
  let createdAt : any = moment(platformContent.createdAt).format() 
  let updatedAt : any = moment(platformContent.updatedAt).format() 
  

  let utcOffsetcreatedAt = moment.parseZone(createdAt).utcOffset();
  let utcOffsetupdatedAt = moment.parseZone(updatedAt).utcOffset();

  updatedAt = moment(updatedAt).add(utcOffsetupdatedAt,'minutes').format("YYYY-MM-DD h:mm a")
  createdAt = moment(createdAt).add(utcOffsetcreatedAt,'minutes').format("YYYY-MM-DD h:mm a")

  const onOpenCloneMedia = () => {
    setOpenCLonePanel(true)
  };

  const onOpenCloneDialog = () => {
    setOpenCLoneDialog(true)
  };

  const onCloseCloneDialog = () => {
    setOpenCLoneDialog(false)
  };

  return (
    
    <Media query={{ maxWidth: 1085 }}>
      {(matches: boolean) => (
        <PreviewWrapper
          style={{
            justifyContent: isIgStories || isCarousel ? 'space-between' : '',
          }}
        >
          
          <div 
            style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: 10,
            
          }}>
            
            { titlePreview !=null  &&
                 <Tooltip
                 referenceChildren={
                 
                  <Typography  variantName="italic-label" style={{ marginRight: 10 , color: '#6B2DD5'}}>
                        { titlePreview!.length>55 ? titlePreview!.substring(0,55).concat('...') : titlePreview} 
                  </Typography>
                }
                 tooltipChildren={
                   <div>
                     {titlePreview}
                   </div>
                 }
                 position = "auto"
                 referenceStyle = {{ display: 'flex', alignItems: 'left',  marginRight: 10}}
                 tooltipStyle = {{  width: (titlePreview.length>50 ? 500 : 400), top: 4,  left: 20,  color:'black'    }}
               />
              }
          </div>

          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingTop: isIgStories || isCarousel ? 5 : '',
                paddingBottom: isIgStories || isCarousel ? 3 : '',
              }}
            >
              <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                DISTRIBUTION COUNTRY
              </Typography>
              <CountrySelection
                countries={countries}
                selectedCountry={selectedCountry}
                onSelection={onChangeSelectedCountry}
              />
            
              {!readOnlyUser && 
                  <ButtonIcon
                    iconName="upload"
                    iconColor={Colors.ROSSINO}
                    style={{ padding: '0px 4px', marginLeft: 10, width: 40, height: 40 }}
                    disabled={difference(Object.keys(Country), countries).length === 0}
                    onClick={() => changeEditStep('NEW_COUNTRY')}
                  />
              }
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
              {fileNameCompressed.length==2 && 
                <Typography  variantName="text" style={{  color: 'red'}}>
                  WARNING: This is a COMPRESSED version of the original file. 
                  Please copy the path of the uncompressed file in the 'source folder' field so you can retrieve it when needed.
                </Typography>
              
              }
                {loadingImg && <Img src={imgPlaceholder} />}
                
                {mustShowImage ? (
                  <Img src={url} isVisible={!loadingImg} onLoad={handleImgLoaded} />
                ) : ( 
                  <Video src={url} controls />
                )}

              
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      whiteSpace: 'nowrap',
                    }}
                  >
                      <div>
                      {!matches ? (
                        <ButtonStyled
                          icon={{
                            position: 'left',
                            iconName: 'delete',
                          }}
                          flat
                          onClick={() => onOpenDeleteDialog()}
                          disabled={(readOnlyUser || !showDeleteButton || !viewButton)}
                        >
                          Delete
                        </ButtonStyled>
                      ) : (
                        <ButtonIcon
                          style={{ width: 40, height: 40 }}
                          iconColor={Colors.GRIGIO}
                          iconName="delete"
                          onClick={() => onOpenDeleteDialog()}
                          disabled={ (readOnlyUser || !showDeleteButton || !viewButton) }
                        />
                      )}
                    </div>
                      <div>
                      <CSSTransition in={openCLonePanel} unmountOnExit timeout={{ enter: 300, exit: 500 }}>
                          {(status) => ( 
                            <CloneMedia
                              open={openCLonePanel}
                              onClose={() => {
                                setOpenCLonePanel(false);
                              }}
                              platformContent={platformContent!}
                              //localization={platformContent!.localization?.id!}
                              itemIsImage={itemIsImage}
                              format={format}
                              status={status}
                              clonePlatform={clonePlatform}
                              removeClonePlatform={removeClonePlatform}
                              
                              newFileUploadProgress={newFileUploadProgress}
                              onSave={onSave}
                            />
                        )}      
                      </CSSTransition>

                      {!matches ? (
                          <ButtonStyled
                            style={{ position: 'relative' }}
                            icon={{
                              position: 'left',
                              iconName: "media" ,
                            }}
                            flat
                            disabled={(readOnlyUser || isIgStories || !viewButton /*|| !platformContent!.localization?.id*/)}
                            onClick={async () => {
                              onOpenCloneMedia()
                            }}
                          >
                            <>
                              Clone
                            </>
                          </ButtonStyled>
                        ) : (
                          <div style={{ position: 'relative', cursor: 'pointer' }}>
                              <ButtonIcon
                                style={{ width: 40, height: 40 }}
                                iconColor={Colors.GRIGIO}
                                iconName="move-to"
                                disabled={readOnlyUser || isIgStories || !viewButton /*|| !platformContent!.localization?.id*/}
                                onClick={async () => {
                                  onOpenCloneMedia()
                                }}
                              />
                          </div>
                        )}
                    </div>
                      <div>
                      <CSSTransition in={openCLoneDialog} unmountOnExit timeout={{ enter: 300, exit: 500 }}>
                            {(status) => ( 
                              <CloneMediaDialog
                                open = {openCLoneDialog}
                                onClose = {onCloseCloneDialog}
                                fileIn ={fileReplace!}
                                onFileSelection={onFileSelection} 
                              />
                          )}      
                      </CSSTransition>

                      {!matches ? (
                          <ButtonStyled
                            style={{ position: 'relative' }}
                            icon={{
                              position: 'left',
                              iconName: 'replace',
                            }}
                            flat
                            disabled={(readOnlyUser || newFile || !viewButton)}
                          >
                            <>
                              Replace
                              {(!readOnlyUser && !newFile) && (
                                <UploadInput
                                    style={{ height: 40, width: 120, left: 0, top: 0 }}
                                    id="file-upload"
                                    type="file"
                                    accept={mimeType}
                                    onChange={(e) => {
                                      if (e.target.files) {

                                        if(itemTypes.length>1){
                                          setFileReplace(e.target.files[0])
                                          onOpenCloneDialog()
                                        }
                                        else{
                                          onFileSelection(e.target.files[0], false);
                                        }
                                      }
                                    }}
                                  />
                              )}
                            </>
                          </ButtonStyled>
                        ) : (
                          <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <ButtonIcon
                              style={{ width: 40, height: 40 }}
                              iconColor={Colors.GRIGIO}
                              iconName="replace"
                              disabled={readOnlyUser || newFile || !viewButton}
                            />
                              <UploadInput
                                style={{ height: 40, width: 40, top: 0 }}
                                id="file-upload"
                                type="file"
                                accept={mimeType}
                                onChange={(e) => {
                                  if (e.target.files) {
                                    if( itemTypes.length > 0 ){
                                      setFileReplace(e.target.files[0])
                                      onOpenCloneDialog()
                                    }
                                    else{
                                      onFileSelection(e.target.files[0], false);
                                    }
                                  }
                                }}
                              />
                          </div>
                        )}
                    </div>
                  </div>
              
              
              
              </div>
              <InfoWrapper>
                {creatorInfo ? (
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
                    <div style={{ marginRight: 10 }}>
                      <Typography variantName="caption">Created by</Typography>
                        {!newFile && (
                          <Typography variantName="caption">
                            {createdAt!}
                          </Typography>
                        )}
                        
                    </div>
                    <InteractiveAvatar name={creatorInfo.name} size={40} />
                  </div>
                ) : null}
                {lastUpdaterInfo ? (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 10 }}>
                      <Typography variantName="caption">Last modified by</Typography>
                      <Typography variantName="caption">
                       {updatedAt!}
                      </Typography>
                    </div>
                    <InteractiveAvatar name={lastUpdaterInfo.name} size={40} />
                  </div>
                ) : null}
              </InfoWrapper>
            </div>
           
           
            { (
              <OtherCutsWrapper>
                  <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                    other platforms
                  </Typography>
                  
                  <VideoCutSelection
                    itemTypes={itemTypes}
                    selectedCut={ selectedCut}
                    onSelection={onChangeSelectedCut}
                    idPlatformContents={idPlatformContents}
                  />
                  {!readOnlyUser && 
                      <ButtonIcon
                          iconName="upload"
                          iconColor={Colors.ROSSINO}
                          style={{ padding: '0px 4px', marginLeft: 10, width: 40, height: 40 }}
                          onClick={() => changeEditStep('NEW_CUT')}
                          disabled={
                            difference(
                              Object.keys(CutType),
                              itemTypes.map((i) => CutType[i])
                            ).length === 0
                          }
                      />
                }
              </OtherCutsWrapper>
            )}
          </div>

          {( isIgStories || isCarousel) && (
              <DragDrop
                readOnlyUser={readOnlyUser}
                onAddComponents={onAddComponents}
                onSelectComponent={onSelectComponent}
                onUpdatePosition={onUpdatePosition}
                selectedComponentId={selectedComponentId}
                platformContent={platformContent as EnhancedPlatformContent}
                isStories = {isIgStories}
              />
          )}
        </PreviewWrapper>
      )}
    </Media>
  );
};

export { Preview };
