import gql from 'graphql-tag';

const CREATE_LOCAL_CONTENT = gql`
  mutation createLocalContent(
    $parent:ID!,
    $distributionCountry: CountryEnumDao!
    $contributors: UpdateObjectStructure, 
    $members: UpdateObjectStructure, 
    $noSubtitle: Boolean
    $languageSubtitle: LanguageEnumDao
    $languageCopy: LanguageEnumDao
    $creator: ID!
    $platforms: CreatePlatformInput
  ) {
    createLocalContent(
      parent:$parent,
      distributionCountry: $distributionCountry
      contributors: $contributors
      members: $members
      noSubtitle: $noSubtitle
      languageSubtitle: $languageSubtitle
      languageCopy: $languageCopy
      creator: $creator
      platforms: $platforms
    ) {
      id
    }
  }
`;

export { CREATE_LOCAL_CONTENT };
