import * as React from 'react';
import { Wrapper, StyledStepper } from './styles';

interface Props {
  title: string;
  activeStep: number;
  steps: Array<{ label: string; component: React.ReactElement<any>; completed: boolean }>;
}

const WizardStep: React.FC<Props> = ({ activeStep, steps, title }) => {
  return (
    <Wrapper>
      <div style={{ textAlign: 'center' }}>{title}</div>
      <StyledStepper steps={steps} activeStep={activeStep} />
    </Wrapper>
  );
};

export { WizardStep };
