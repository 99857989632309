import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@freeda/react-components';
import { InputStyled, FilterWrapper } from './styles';
import { SelectBrandCategory } from './SelectBrandCategory2.0';
import { SelectMacroTopic } from './SelectMacroTopic';
import { SelectBrand } from './SelectBrand';

import { MetadataType } from '../../../types/MetadataType';
import { getFilterContext } from '../../../utils/metadataUtils2.0';
//import { MultiSelectProductTypes } from './MultiSelectProductTypes';

import { MultiSelectProjects } from './MultiSelectProject';
//import { MultiSelectCategory } from './MultiSelectCategory';


interface Props {
  activeMetadataTable: MetadataType;
}

const MetadataFilter: React.FC<Props> = ({ activeMetadataTable,  }) => {
  
  const isMobile = useMediaQuery({ maxWidth: 768 });

  
  const {
    searchValue,
    onChangeSearchValue,
    clearFilter,
    onChangeBrandCategoryName,
    selectedBrandCategoryName,

    selectedMacroTopicName,
    onChangeMacroTopicName,

    selectedBrand,
    onChangeBrand,

    onChangeProjectIds,
    selectedProjectIds,
  } = React.useContext(getFilterContext(activeMetadataTable));

  const placeholder =  'Search by name';
  
  return (
    <FilterWrapper>
      <InputStyled
        placeholder={placeholder}
        iconName="search"
        style={{ flexBasis: !isMobile ? '230px' : '100%' }}
        onChange={(e) => onChangeSearchValue( e.target.value ? e.target.value.replace(/^./, e.target.value[0].toUpperCase()) : '')}
        value={searchValue}
        onClearValue={() => onChangeSearchValue('')}
      />
      {activeMetadataTable === MetadataType.BRANDS && (
        <SelectBrandCategory
          style={{
            marginLeft: !isMobile ? 30 : 0,
            width: !isMobile ? 230 : '100%',
          }}
          onChangeBrandCategoryName={onChangeBrandCategoryName}
          selectedBrandCategoryName={selectedBrandCategoryName}
        />
      )}
      {
        activeMetadataTable === MetadataType.CAMPAIGNS && (
        <SelectBrand
          style={{
            marginLeft: !isMobile ? 30 : 0,
            width: !isMobile ? 230 : '100%',
          }}
          onChangeBrand={onChangeBrand}
          selectedBrand={selectedBrand}
        />

      )
      }
      {(activeMetadataTable === MetadataType.SERIES || activeMetadataTable === MetadataType.PRODUCTS || 
        activeMetadataTable === MetadataType.KEYWORDS || activeMetadataTable === MetadataType.EDITORIAL_CAMPAIGNS) && (
        <MultiSelectProjects
          style={{
            marginLeft: !isMobile ? 30 : 0,
            width: !isMobile ? 230 : '100%',
          }}
          onChangeProjectIds={onChangeProjectIds}
          selectedProjectIds={selectedProjectIds}
        />
      )}
     
       {activeMetadataTable === MetadataType.TOPICS && (
        <SelectMacroTopic
          style={{
            marginLeft: !isMobile ? 30 : 0,
            width: !isMobile ? 230 : '100%',
          }}
          onChangeMacroTopicName={onChangeMacroTopicName}
          selectedMacroTopicName={selectedMacroTopicName}
        />
      )} 

      <Button flat style={{ marginLeft: !isMobile ? 10 : 0 }} onClick={clearFilter}>
        Clear filter
      </Button>
    </FilterWrapper>
  );
};

export { MetadataFilter };
