import * as React from 'react';
import { PreviewVideo, PreviewImage } from './styles';

interface Props {
  mimeType: string;
  frameUrl: { thumbnailUrl: string | null; storagePath: string; newUrl?: string | null };
}

const FramePreview: React.FC<Props> = ({ mimeType, frameUrl }) => {
  const isVideo = mimeType.indexOf('video') >= 0;

  const videoProps = frameUrl.thumbnailUrl
    ? { poster: frameUrl.thumbnailUrl }
    : { src: frameUrl.newUrl || frameUrl.storagePath };

  return (
    <div>
      {isVideo ? (
        <PreviewVideo key={frameUrl.storagePath} {...videoProps}>
          <source src={frameUrl.newUrl || frameUrl.storagePath} type={mimeType} />
        </PreviewVideo>
      ) : (
        <PreviewImage 
          key={frameUrl.storagePath}
          src={frameUrl.newUrl || frameUrl.thumbnailUrl || frameUrl.storagePath}
        />
      )}
    </div>
  );
};

export { FramePreview };
