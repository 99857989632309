import styled from 'styled-components';

const UploadInput = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  padding: 0;
  border: 0;
  cursor: pointer;
`;

export default UploadInput;
