import styled, { css } from 'styled-components';
import { scrollbarHorizontalStyle } from '../ScrollBarStyle';

const StoriesWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  overflow-x: auto;
  ${scrollbarHorizontalStyle};
`;

const Label = styled.span`
  font-size: 10px;
  text-transform: uppercase;
  padding-top: 10px;
`;

const FrameWrapper = styled.div<{ isselected: string } & React.HTMLProps<HTMLDivElement>>`
  cursor: pointer;
  display: flex;
  height: 75px;
  width: 44px;
  align-items: center;
  box-sizing: border-box;
  border: rgb(242, 242, 242, 0) solid 2px;
  flex-direction: column;
  margin-right: 10px;
  margin-bottom: 30px;
  ${props =>
    props.isselected === 'true' &&
    css`
      border: ${props.theme.azzurrino} solid 2px;
    `};
`;

export { StoriesWrapper, Label, FrameWrapper };
