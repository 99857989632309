import * as React from 'react';

import MarkIcon from '../../ui/MarkIcon';
import { FileTitle, ListItemStyled } from './styles';
import { Spinner, ListItemText } from '@freeda/react-components';

interface Props {
  mimeType: string;
  previewUrl: string;
  title: string;
  progress: number;
}

const FileUploadItem: React.FC<Props> = ({ mimeType, previewUrl, title, progress }) => (
  <ListItemStyled>
    <div style={{ display: 'flex', alignItems: 'center', width: '400px' }}>
      {mimeType.indexOf('image') >= 0 && (
        <img style={{ width: 40, height: 'auto' }} src={previewUrl} />
      )}
      {mimeType.indexOf('video') >= 0 && (
        <video style={{ width: 40, height: 'auto' }} src={previewUrl} />
      )}
      <ListItemText
        primary={<FileTitle progress={progress}>{title}</FileTitle>}
        secondary={progress === 100 ? 'Content uploaded successfully' : ''}
      />
    </div>
    <span style={{ marginRight: 10, width: '42px' }}>{`${progress}%`}</span>
    {progress === 100 ? (
      <div style={{ width: 40, height: 40 }}>
        <MarkIcon width={40} height={40} color="#7CFFDD" />
      </div>
    ) : (
      <Spinner size="small" borderColor="#6B20DA" />
    )}
  </ListItemStyled>
);

export default FileUploadItem;
