import * as React from 'react';

import { Wrapper, BlockingOverlay, ContentWrapper, Content } from '../styles';
import { ItemType } from '../../../../../../types/ItemType';
import { VideoCutsMap } from '../../../../../../utils/videoCutsMap';
//import { PlatformsCutMap} from '../../../../../../utils/videoCutsMap';

interface Props {
  itemTypes: Array<ItemType>;
  onSelection: (itemType: ItemType) => void;
}

const CutSelectionStep: React.FC<Props> = ({ itemTypes, onSelection }) => (
  <Wrapper>
    <ContentWrapper>
      {Object.keys(VideoCutsMap).map((v: string) => {

        const found = Boolean(itemTypes.find(item => item === v));
        
        if(found){
          return (
            <Content onClick={() => found && onSelection(v as ItemType)} disabled={!found} key={v}>
              {!found && <BlockingOverlay />}
              <img src={VideoCutsMap[v]} />
            </Content>
          );
        }
        else{
          return
        }
          

      })}
    </ContentWrapper>
  </Wrapper>
);

export { CutSelectionStep };
