import gql from 'graphql-tag';


const DELETE_PLATFORM_CONTENT = gql`
  mutation deletePlatformContent($id: ID!) {
    deletePlatformContent(id: $id) {
      deletedPlatformId
      parentDeleted
      localDeleted
      affectedCountry
    }
  }
`;

export { DELETE_PLATFORM_CONTENT };
