import * as React from 'react';

import { booleanFilters } from '../types/FilterType';
import { BrandCategory } from '../types/types2.0/BrandCategory';
import { flatten } from 'lodash';
import { Brand } from '../types/types2.0/Brand';
import SearchMediaVariables from '../types/types2.0/SearchMediaVariables';
import { getVariables } from '../utils/getResearchVariables2.0';
import { ProductType } from '../types/types2.0/ProductType';
import { Format } from '../types/types2.0/Format';
import { PlatformType } from '../types/types2.0/PlatformType';
import { Country } from '../types/graphql/Country';
import { LanguageCountry } from '../types/Language';
import { UpdateDateRange } from '../types/UpdateDateRange';


const initialState = {
  searchValue: '',
  isEditorial: false,
  isBranded: false,
  reel:false,
  selectedCategoryId: null,
  selectedBrandId: null,
  selectedProductTypeIds: [],
  selectedProjectId: null,
  selectedSerieId: null,
  selectedTopicId: null,
  
  selectedGenreId:null,
  selectedCampaignId:null,
  selectedEditorialCampaignId:null,
  selectedBrandCampaignId:null,
  selectedObjectiveId:null,
  selectedMacroTopicName: null,
  selectedTemperatureId: null,
  selectedProductId:null,

  selectedLanguage: null,
  selectedFormat: [],
  selectedPlatformType:null,
  selectedProductionCountry: null,
  selectedDistributionCountry: null,
  selectedTeamMembers: [],
  selectedContributors: [],
  updateDateRange: null,
  viewOtherFilters:false,
  notCompleted:undefined, // false
  notMapped:undefined, // false
  
  selectedCompletion:null
  
};

type FilterContextT = {
  filterValue: State;
  callbacks: {
    clearFilter: () => void;
    onTextFilterChange: (searchValue: string) => void;
    onCategorySelection: (categoryId: string) => void;
    onBrandSelection: (brandId: string) => void;
    onProductTypeSelection: (productTypeId: Array<string>) => void;
    onProjectSelection: (projectId: string) => void;
    onPlatformTypeSelection: (platformId: PlatformType) => void;
    onSerieSelection: (serieId: string) => void;
    onMacroTopicSelection:(macroTopicName:string)=>void;
    onEditorialCampaignSelection:(campaignId: string) => void,
    onBrandCampaignSelection:(campaignId: string) => void,
    onGenreSelection:(genreId:string) =>void,
    onObjectiveSelection:(objectiveId:string)=>void,
    onTemperatureSelection:(temperatureId:string)=>void,
    onProductSelection:(productId:string)=>void,
    onTopicSelection: (topicId: string) => void;
    onLanguageSelection: (language: LanguageCountry) => void;
    onFormatSelection: (formats: Array<Format>, productTypes: Array<ProductType>) => void;
    onProductionCountrySelection: (country: Country) => void;
    onDistributionCountrySelection: (country: Country) => void;
    onToggleBooleanFilter: (filter: booleanFilters) => void;
    onChangeMember: (id: string) => void;
    onChangeContributors: (id: string) => void;
    onChangeUpdateDateRange: (updateDateRange: UpdateDateRange) => void;
    getBrands: (
      brandCategories: Array<BrandCategory>,
      selectedCategoryId: string | null
    ) => Array<Brand>;
    getProductTypes: (
      productTypes: Array<ProductType>,
      formats: Array<Format> | null
    ) => Array<ProductType>;
    onClickOtherFilters:(view:boolean)=>void;
    //onClickNotCompleted:(view:boolean)=>void;
    onCompletionSelection:(completionId: string) => void;
  };
  getQueryVariables: () => SearchMediaVariables;

  
};

const FilterContext = React.createContext<FilterContextT>({
  filterValue: {
    ...initialState
  },
  callbacks: {
    clearFilter: () => console.warn('Please implement clearFilter'),
    onTextFilterChange: (searchValue: string) =>
      console.warn('Please implement onTextFilterChange'),
    onCategorySelection: (categoryId: string) =>
      console.warn('Please implement onCategorySelection'),
    onBrandSelection: (brandId: string) => console.warn('Please implement onBrandSelection'),
    onProductTypeSelection: (productTypeId: Array<string>) =>
      console.warn('Please implement onProductTypeSelection'),
    onPlatformTypeSelection: (platformType: string) =>
      console.warn('Please implement onProductTypeSelection'),
    onProjectSelection: (projectId: string) => console.warn('Please implement onProjectSelection'),
    onSerieSelection: (serieId: string) => console.warn('Please implement onSerieSelection'),
    onMacroTopicSelection:(macroTopicName:string) =>console.warn('Please implement onMacroTopicSelection'),
    onEditorialCampaignSelection:(campaignId: string) => console.warn('Please implement onEditorialCampaignSelection'),
    onBrandCampaignSelection:(campaignId: string) => console.warn('Please implement onBrandCampaignSelection'),
    onGenreSelection:(genreId:string) => console.warn('Please implement onGenreSelection'),
    onObjectiveSelection:(objectiveId:string) => console.warn('Please implement onObjectiveSelection'),
    onTemperatureSelection:(temperatureId:string) => console.warn('Please implement onTemperatureSelection'),
    onProductSelection:(productId:string) => console.warn('Please implement onProductSelection'),
    onTopicSelection: (topicId: string) => console.warn('Please implement onTopicSelection'),
    onLanguageSelection: (language: string) => console.warn('Please implement onLanguageSelection'),
    onFormatSelection: (formats: Array<Format>, productTypes: Array<ProductType>) =>
      console.warn('Please implement onFormatSelection'),
    onProductionCountrySelection: (country: Country) =>
      console.warn('Please implement onProductionCountrySelection'),
    onDistributionCountrySelection: (country: Country) =>
      console.warn('Please implement onDistributionCountrySelection'),
    onToggleBooleanFilter: (filter: booleanFilters) =>
      console.warn('Please implement ', filter),
    onChangeMember: (id: string) => console.warn('Please implement onChangeMember'),
    onChangeContributors: (id: string) => console.warn('Please implement onChangeContributor'),
    onChangeUpdateDateRange: (updateDateRange: UpdateDateRange) =>
      console.warn('Please implement onChangeUpdateDateRange'),
    getBrands: (brandCategories: Array<BrandCategory>, selectedCategoryId: string | null) => [],
    getProductTypes: (productTypes: Array<ProductType>, formats: Array<string> | null) => [],
    onClickOtherFilters:(view:boolean) =>console.warn('Please implement onClickOtherFilters'),
    //onClickNotCompleted:(view:boolean) =>console.warn('Please implement onClickNotCompleted')
    onCompletionSelection: (completionId: string) => console.warn('Please implement onCompletionSelection'),

  },
  getQueryVariables: () => ({}),
});



interface State {
  searchValue: string;
  isEditorial: boolean;
  isBranded: boolean;
  reel:boolean;
  selectedCategoryId: string | null;
  selectedBrandId: string | null;
  selectedProductTypeIds: Array<string> | null;
  selectedProjectId: string | null;
  selectedSerieId: string | null;
  
  selectedGenreId: string | null;
  selectedEditorialCampaignId: string | null;
  selectedBrandCampaignId : string | null;
  selectedTemperatureId: string|null;
  selectedProductId: string|null;

  selectedObjectiveId: string | null,
  selectedTopicId: string | null;
  selectedMacroTopicName: string | null;

  selectedLanguage: LanguageCountry | null;
  selectedFormat: Array<Format>;
  selectedPlatformType: PlatformType | null;
  selectedProductionCountry: Country | null;
  selectedDistributionCountry: Country | null;
  selectedTeamMembers: Array<string>;
  selectedContributors: Array<string>;
  updateDateRange: UpdateDateRange;
  viewOtherFilters:boolean;
  //notCompleted:boolean| undefined;
  //notMapped:boolean| undefined;
  selectedCompletion:string|null;
}

//class FilterProvider extends React.FC<State> {
class FilterProvider extends React.Component<{}, State> {
  
  
  constructor(props: {}) {
    super(props);
    this.state = { ...initialState };
    
  }

  clearFilter = () => {
    this.setState( { ...initialState} )
  };

  onTextFilterChange = (searchValue: string) => this.setState({ searchValue });

  onCategorySelection = (categoryId: string) =>
    this.setState({
      selectedCategoryId: categoryId,
      selectedBrandId: null
    });

  onBrandSelection = (brandId: string) =>
    this.setState({
      selectedBrandId: brandId
    });

  onProductTypeSelection = (productType: Array<string>) =>{
    this.setState({
      selectedProductTypeIds: productType
    })
  };

  onProjectSelection = (projectId: string) =>
    this.setState({
      selectedProjectId: projectId
  });

  onSerieSelection = (serieId: string) =>
    this.setState({
      selectedSerieId: serieId
  });

  onTopicSelection = (topicId: string) =>{
    this.setState({
      selectedTopicId: topicId
  })};

  onCompletionSelection = (completionId: string) =>{
    this.setState({
      selectedCompletion: completionId
  })};

  onMacroTopicSelection = (macroTopicName: string) =>{
      this.setState({
        selectedMacroTopicName: macroTopicName,
        selectedTopicId: null
  })};
  
  onEditorialCampaignSelection = (campaignId: string) =>{
    this.setState({
      selectedEditorialCampaignId: campaignId
  })};

  onBrandCampaignSelection = (campaignId: string) =>{
    this.setState({
      selectedBrandCampaignId: campaignId
    })};
  
  onGenreSelection= (genreId: string) =>{
    this.setState({
      selectedGenreId: genreId
    })};

  onTemperatureSelection= (temperatureId: string) =>{
      this.setState({
        selectedTemperatureId: temperatureId
    })};
  
  onProductSelection= (productId: string) =>{
      this.setState({
        selectedProductId: productId
    })};
  
  onObjectiveSelection= (objectiveId: string) =>{
      this.setState({
        selectedObjectiveId: objectiveId
    })}; 

  onClickOtherFilters = (view: boolean) =>{
        this.setState({
          viewOtherFilters: !view
        })};  
  
 /* onClickNotCompleted = (view: boolean) =>{
    console.log('onClickNotCompleted', view)
     this.setState({
        notCompleted: !view
  })};  */

  onLanguageSelection = (language: LanguageCountry) =>
    this.setState({
      selectedLanguage: language
    });

  onProductionCountrySelection = (country: Country) =>
    this.setState({
      selectedProductionCountry: country
    });

  onDistributionCountrySelection = (country: Country) =>
    this.setState({
      selectedDistributionCountry: country
    });

  onChangeTeamMembers = (teamMemberId: string) => {
    const exists = this.state.selectedTeamMembers.find(id => teamMemberId === id);

    this.setState(state => ({
      selectedTeamMembers: exists
        ? state.selectedTeamMembers.filter(memberId => memberId !== teamMemberId)
        : [teamMemberId, ...state.selectedTeamMembers]
    }));
  };

  onChangeContributors = (contributorId: string) => {
    const exists = this.state.selectedContributors.find(id => contributorId === id);

    this.setState(state => ({
      selectedContributors: exists
        ? state.selectedContributors.filter(id => contributorId !== id)
        : [contributorId, ...state.selectedContributors]
    }));
  };

  onFormatSelection = (formats: Array<Format>, productTypes: Array<ProductType>) => {
    //alert('CHECK QUI')
    //console.log('productTypes______', productTypes)
    //const selectedProductType = productTypes.find(p => this.state.selectedProductTypeId === p.id);
   // console.log('selectedProductType____', selectedProductType)
   // let selectedProductTypeId = null;
    
   /* if (selectedProductType && formats.indexOf(selectedProductType.format) >= 0) {
      selectedProductTypeId = selectedProductType.id;
    }*/

    this.setState({
      selectedFormat: formats,
     // selectedProductTypeId
    });
  };

  onPlatformTypeSelection = (platformType:PlatformType) => {
    this.setState({
      selectedPlatformType: platformType,
      reel : (platformType!=PlatformType.INSTAGRAM && platformType!=PlatformType.FACEBOOK) ? false: this.state.reel
    });
  };

  onChangeUpdateDateRange = (updateDateRange: UpdateDateRange) => {
    this.setState({
      updateDateRange
    });
  };

  toggleBooleanFilter = (filter: booleanFilters) => {
    this.setState({ ...this.state, [filter]: !this.state[filter] });
  };

  getBrands = (brandCategories: Array<BrandCategory>, selectedCategoryId: string | null) => {
    
    return flatten(
      brandCategories
        .filter(category => {
          if (selectedCategoryId !== null) {
            return category.name === selectedCategoryId;
          }
          return true;
        })
        .map(category => category.brand)
    );
  };

  getProductTypes = (productTypes: Array<ProductType>, formats: Array<string> | null) => {
    if (!formats || formats.length === 0) {
      return productTypes;
    }

    return productTypes.filter(productType => formats.indexOf(productType.format) >= 0);
  };

  getQueryVariables = () => {
    return getVariables(
      this.state.searchValue,
      this.state.selectedBrandId,
      this.state.selectedSerieId,
      this.state.selectedTopicId,
      this.state.selectedMacroTopicName,
      this.state.isEditorial,
      this.state.isBranded,
      this.state.reel,
      this.state.selectedProductTypeIds,
      this.state.selectedLanguage,
      this.state.selectedFormat,
      this.state.selectedProductionCountry,
      this.state.selectedDistributionCountry,
      this.state.selectedTeamMembers,
      this.state.selectedContributors,
      this.state.updateDateRange,
      this.state.selectedProjectId,
      this.state.selectedPlatformType,

      this.state.selectedEditorialCampaignId,
      this.state.selectedBrandCampaignId,
      this.state.selectedGenreId,
      this.state.selectedObjectiveId,
      this.state.selectedTemperatureId,
      this.state.selectedProductId,
     // this.state.notCompleted,
     // this.state.notMapped,
      this.state.selectedCompletion
    );
    
  };

  
  render() {
    return (
        <FilterContext.Provider
          value={{
            filterValue: { ...this.state},
            callbacks: {
              clearFilter: this.clearFilter,
              onTextFilterChange: this.onTextFilterChange,
              onCategorySelection: this.onCategorySelection,
              onBrandSelection: this.onBrandSelection,
              onProductTypeSelection: this.onProductTypeSelection,
              onProjectSelection: this.onProjectSelection,
              onSerieSelection: this.onSerieSelection,
              onMacroTopicSelection: this.onMacroTopicSelection,
              onEditorialCampaignSelection: this.onEditorialCampaignSelection,
              onBrandCampaignSelection: this.onBrandCampaignSelection,
              onGenreSelection:this.onGenreSelection,
              onObjectiveSelection:this.onObjectiveSelection,
              onTemperatureSelection:this.onTemperatureSelection,
              onProductSelection:this.onProductSelection,
              onTopicSelection: this.onTopicSelection,
              onLanguageSelection: this.onLanguageSelection,
              onPlatformTypeSelection:this.onPlatformTypeSelection,
              onFormatSelection: this.onFormatSelection,
              onProductionCountrySelection: this.onProductionCountrySelection,
              onDistributionCountrySelection: this.onDistributionCountrySelection,
              onToggleBooleanFilter: this.toggleBooleanFilter,
              onChangeMember: this.onChangeTeamMembers,
              onChangeContributors: this.onChangeContributors,
              onChangeUpdateDateRange: this.onChangeUpdateDateRange,
              getBrands: this.getBrands,
              getProductTypes: this.getProductTypes,
              onClickOtherFilters:this.onClickOtherFilters,
              onCompletionSelection: this.onCompletionSelection,
              //onClickNotCompleted:this.onClickNotCompleted
            },
            getQueryVariables: this.getQueryVariables,
          }}
        >
        {this.props.children}
        </FilterContext.Provider>
    );
  }
}

export { FilterContext, FilterProvider };
