import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';

const placeHolderShimmer = keyframes`
  from {
    background-position: -467px 0
  }
  to {
    background-position: 467px 0
  }
`;

export const animationBackground = css`
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  background: linear-gradient(to right, #f2f2f2 5%, #eeeeee 20%, #f2f2f2 40%);
`;

const Input = styled.div`
  flex-basis: 20%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  background: #eeeeee;
  position: relative;
`;

const PlaceholderInput: React.FC<{ style?: React.CSSProperties }> = ({ style, children }) => (
  <Input style={{ ...style }}>{children}</Input>
);

export { PlaceholderInput };
