import * as React from 'react';
import { Typography, ButtonIcon } from '@freeda/react-components';

interface Props {
  title: string;
  onClose: () => void;
}

const DialogHeader: React.FC<Props> = ({ title, onClose }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15
      }}
    >
      <Typography variantName="subtitle" textColor="#6B20DA">
        {title}
      </Typography>
      <ButtonIcon iconName="close" onClick={onClose} />
    </div>
  );
};

export { DialogHeader };
