import * as React from 'react';
import { Dialogable } from '../types/Dialogable';
//import { Spinnerone } from '../components/ui/Spinnerone';
import DownloadMultiMediaDialog from '../components/layout/DownloadMultiMediaDialog2.0';
//import { ListContentByIds } from '../components/queries/queries2.0/ListContentByIds';

import { ParentContentSelection } from '../types/types2.0/ParentContentSelection';

export const DownloadMultiMediaDialogContainer: React.FC<
  Dialogable & { contents: Array<ParentContentSelection> }
> = ({ open, onClose, contents }) => {
  /*return (
    <ListContentByIds variables={{ ids: contents }}>
      
      
      {listMediaQueryResult => {
        if (listMediaQueryResult.error) {
          Raven.captureException(listMediaQueryResult.error);
          return 'Error...';
        }

        if (
          listMediaQueryResult.loading ||
          !listMediaQueryResult.data ||
          Object.keys(listMediaQueryResult.data).length === 0
        ) {
          return <Spinnerone />;
        }

        const content = listMediaQueryResult.data!.listParentContents.edges.map(e => e.node);
        alert('DownloadMultiMediaDialogContainer containers')
        return <DownloadMultiMediaDialog open={open} onClose={onClose} content={content} />;
      }}
    
    
    </ListContentByIds>
  );*/
  console.log('contents multiDialog__', contents)
  return <DownloadMultiMediaDialog open={open} onClose={onClose} content={contents} />;
};
