import * as React from 'react';
import { parse } from 'query-string';

import { ListSlackReceiversQuery } from '../components/queries/ListSlackReceiversQuery';
import { Dialogable } from '../types/Dialogable';
import ShareMediaDialog from '../components/layout/ShareMediaDialog2.0';
import { SendSlackMessageMutation } from '../components/mutations/SendSlackMessageMutation';
import { FeedbackContext } from '../providers/FeedbackProvider';
import { GenerateExternalLinkMutation } from '../components/mutations/GenerateExternalLinkMutation';
import { Spinnerone } from '../components/ui/Spinnerone';
import { SlackReceiver } from '../types/SlackReceiver';

//import { MediaRootQuery } from '../components/queries/MediaRootQuery';
import { ParentContentQuery } from '../components/queries/queries2.0/ParentContentQuery';
import { Country } from '../types/graphql/Country';
//import { PlatformContent } from '../types/types2.0/PlatformContent';

export const ShareMediaDialogContainer: React.FC<
  Dialogable & {
    contentId: string;
    showCountryCutItem: boolean;
    idPlatformContent :string ;
  }
> = ({ open, onClose, contentId, showCountryCutItem , idPlatformContent}) => {
  const queryParams = parse(window.location.search);
  //alert('ShareMediaDialogContainer '+idPlatformContent)
  

  return (
    <ParentContentQuery variables={{ id: contentId }}>
      {mediaQueryResult => {
        if (mediaQueryResult.error) {
          Raven.captureException(mediaQueryResult.error);
          return 'Error...';
        }

        return (
          <ListSlackReceiversQuery>
            {listSlackReceviersResult => {
              if (listSlackReceviersResult.error) {
                return 'Error...';
              }

              let slackReceivers: Array<SlackReceiver> = [];

              if (
                listSlackReceviersResult.data &&
                Object.keys(listSlackReceviersResult.data).length > 0
              ) {
                slackReceivers = [...listSlackReceviersResult.data.listSlackReceivers.users];

                const channels = listSlackReceviersResult.data.listSlackReceivers.channels;

                if (channels) {
                  slackReceivers = [...slackReceivers, ...channels];
                }
              }

              if (
                mediaQueryResult.loading ||
                !mediaQueryResult.data ||
                Object.keys(mediaQueryResult.data).length === 0 ||
                listSlackReceviersResult.loading
              ) {
                return <Spinnerone />;
              }

              const userCountry = localStorage.getItem('country') as Country;

              const content = mediaQueryResult.data.getParentContent;

              const selectedLocalization =
                content.localizations.find(t => t.distributionCountry === userCountry) ||
                content.localizations[0];

              const localization =
                (content &&
                  content.localizations.find(t => t.distributionCountry === queryParams.country)) ||
                  selectedLocalization;

              const platform =
                (content &&
                  localization &&
                  localization.platforms.find(i => i.itemType === queryParams.cut)) ||
                  localization.platforms[0];

              /*if(idPlatformContent){
                console.log('********idPlatformContent***********', idPlatformContent)
                platform =
                  (content &&
                    localization &&
                    localization.platforms.find(i => i.id === idPlatformContent)) ||
                    localization.platforms[0] ;
              }*/

              return (
                <GenerateExternalLinkMutation>
                  {generateExternalLink => {
                    return (
                      <SendSlackMessageMutation>
                        {(sendSlackMessage, result) => {
                          return (
                            <FeedbackContext.Consumer>
                              {({ onOpen }) => (
                                <ShareMediaDialog
                                  open={open}
                                  onClose={onClose}
                                  showCountryCutItem={showCountryCutItem}
                                  content={content}
                                  contentId={content!.id}
                                  platformId={idPlatformContent}
                                  mediaMimeType={platform.mimeType}
                                  slackReceivers={slackReceivers}
                                  slackSharingLoading={result.loading}
                                  countries={content.localizations.map(t => t.distributionCountry)}
                                  generateExternalLink={async (itemId: string): Promise<string> => {
                                    const extLinkResult = await generateExternalLink({
                                      variables: {
                                            platformId: itemId //IDPLATFORM
                                      }
                                    });

                                    return extLinkResult && extLinkResult.data
                                      ? extLinkResult.data.result.externalId
                                      : '';
                                  }}
                                  shareOnSlack={async (variables: any, cb: () => void) => {
                                    
                                    await sendSlackMessage(variables);
                                    if (result.error) {
                                      onOpen('Error!', 'error');
                                    } else {
                                      cb();
                                      onOpen('Content SHARED on slack', 'notification');
                                    }
                                  }}
                                />
                              )}
                            </FeedbackContext.Consumer>
                          );
                        }}
                      </SendSlackMessageMutation>
                    );
                  }}
                </GenerateExternalLinkMutation>
              );
            }}
          </ListSlackReceiversQuery>
        );
      }}
    </ParentContentQuery>
  );
};
