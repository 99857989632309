import gql from 'graphql-tag';
//import { PARENT_CONTENT } from '../../../apollo/queries/queries2.0/ParentContentFragment';

//$productionCountry: CountryEnumDao
const UPDATE_PARENT_CONTENT = gql`
  mutation updateParentContent(
    $id: ID!, 
    $format: ID!, 
    $contentType: ContentType, 
    $title: String, 
    $brand: ID, 
    $serie: ID, 
    $topic: ID, 
    $keywords:UpdateObjectStructure, 
    $languageSound: LanguageEnumDao, 
    $onlyMusic: Boolean, 
    $sources: String, 
    $project: ID, 
    $campaign:ID,
    $temperature: ID,
    $product: ID,
    $genre: ID,
    $objective: ID,
    $booleans: [ID],
    $editorialCampaign: ID,
    $updatedBy:ID!
   

  ) {
    updateParentContent(
      id: $id
      format: $format
      contentType: $contentType
      title: $title
      brand: $brand
      serie: $serie
      topic: $topic
      keywords: $keywords
      languageSound: $languageSound
      onlyMusic: $onlyMusic
      sources: $sources
      project: $project
      campaign: $campaign
      temperature: $temperature
      product: $product
      genre: $genre
      objective: $objective
      booleans: $booleans
      editorialCampaign: $editorialCampaign
      updatedBy: $updatedBy
    ) {
      id
    }
  }
  
`;

export { UPDATE_PARENT_CONTENT };
