import * as React from 'react';
import styled from 'styled-components';
import { SmallText } from './styles';

const Checkbox = styled.div`
  width: 15px;
  height: 15px;
  background: #eeeeee;
  margin-right: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlaceholderCheckbox: React.FC<{ style?: React.CSSProperties }> = ({ style }) => (
  <CheckboxWrapper style={{ ...style }}>
    <Checkbox />
    <SmallText />
  </CheckboxWrapper>
);

export { PlaceholderCheckbox };
