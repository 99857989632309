import * as React from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const MarkIcon: React.FC<Props> = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
  >
    <path d="M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z" />
  </svg>
);

MarkIcon.defaultProps = {
  width: 24,
  height: 24,
  color: 'rgba(0, 0, 0, 0.54)'
};

export default MarkIcon;
