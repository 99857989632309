import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dialog, Typography, Button } from '@freeda/react-components';
import { Dialogable } from '../../../types/Dialogable';
import { DialogHeader } from '../../ui/DialogHeader';
import { Colors } from '@freeda/react-components/lib/theme';
import { ButtonWrapperDialog } from '../../ui/ButtonWrapperDialog';
import DeleteAllMediaDialog from '../../../containers/DeleteAllMediaButton';


type Props = Dialogable & {
 
  contentId:string
};

class DeleteMediaDialog extends React.Component<Props & RouteComponentProps<{}>> {
  render() {
    const { open, onClose, contentId} = this.props;
   
    return (
      <Dialog
        onClose={onClose}
        openDialog={open}
        rootIdAttributes="root"
        style={{ width: 350, paddingLeft: 30 }}
      >
        <DialogHeader title="Delete content" onClose={onClose} />
        <Typography variantName="text" textAlign="left" textColor={Colors.GRIGIO}>
            Are you sure you want to delete the content and all its parts?
        </Typography>
        <ButtonWrapperDialog>
            <Button action="undo" onClick={() => onClose()}>
              Close
            </Button>

            <DeleteAllMediaDialog
              parentId={contentId}
              onCloseDialog={onClose}
            />
        </ButtonWrapperDialog>
      </Dialog>
    );
  }
}

const WithRouterDeleteMediaDialog = withRouter<Props & RouteComponentProps<{}>, any>(DeleteMediaDialog);

export { WithRouterDeleteMediaDialog as DeleteMediaDialog };