import * as React from 'react';
import { capitalize , concat} from 'lodash';
import Media from 'react-media';
import { AvatarList } from '../EditMediaPage/styles';
import { FilterWrapper, Field, FieldWithSeparator, TeamMemberWrapper, Row } from './styles';
import { Serie } from '../../../types/types2.0/Serie';
import { BrandCategory } from '../../../types/types2.0/BrandCategory';
import { ProductType } from '../../../types/types2.0/ProductType';
import { User } from '../../../types/types2.0/User';
import { Brand } from '../../../types/types2.0/Brand';
import { FilterContext } from '../../../providers/FilterProvider2.0';
import { Format } from '../../../types/types2.0/Format';
import { Topic } from '../../../types/types2.0/Topic';
import { LanguageCountry } from '../../../types/Language';


import {
  Select,
  Autocomplete,
  CheckBox,
  InteractiveAvatar,
  DividerSection,
} from '@freeda/react-components';
import { TransitionStatus } from 'react-transition-group/Transition';
import {
  FetchedItemsCounterContext
} from '../../../providers/FetchedItemsCounterProvider';
import { Project } from '../../../types/types2.0/Project';
import { PlatformType } from '../../../types/types2.0/PlatformType';
import { MacroTopic } from '../../../types/types2.0/MacroTopic';
import { Label } from '../../../types/types2.0/Label';
import { Campaign } from '../../../types/types2.0/Campaign';


interface Props {
  brandCategories: Array<BrandCategory>;
  brands: Array<Brand>;
  macroTopics:Array<MacroTopic>;
  topics: Array<Topic>;
  productTypes: Array<ProductType>;
  teamMembers: Array<User>;
  contributors: Array<User>;
  projects:Array<Project>;
  genres:Array<Label>;
  temperatures:Array<Label>;
  objectives:Array<Label>;
  showFilterMobile: boolean;
  transitionStatus?: TransitionStatus;
  closeFilter: () => void;
}

const AdvancedFilter: React.FC<Props> = ({
  brandCategories,
  brands,
  macroTopics,
  topics,
  productTypes,
  teamMembers,
  contributors,
  projects,
  genres,
  temperatures,
  objectives,
}) => (
  
  <FetchedItemsCounterContext.Consumer>

      { fetchedItemsCounterContext => (

      <FilterContext.Consumer>
      {({ callbacks, filterValue }) => {
            
        const {
          isEditorial,
          isBranded,
          reel,
          selectedCategoryId,
          selectedBrandId,
          selectedSerieId,
          selectedTopicId,
          selectedGenreId,
          selectedBrandCampaignId,
          selectedEditorialCampaignId,
          selectedObjectiveId,
          selectedMacroTopicName,
          selectedTemperatureId,
          selectedProductId,
          selectedProductTypeIds,
          selectedProjectId,
          selectedTeamMembers,
          selectedContributors,
          selectedLanguage,
          selectedProductionCountry,
          selectedDistributionCountry,
          selectedFormat,
          selectedPlatformType,
          viewOtherFilters,
          //notCompleted, //MISSING_LABEL
          //notMapped ,//MISSING_URL
          selectedCompletion
        } = filterValue;

        const {
          getBrands,
          getProductTypes,
          onBrandSelection,
          onCategorySelection,
          onLanguageSelection,
          onFormatSelection,
          onProductionCountrySelection,
          onDistributionCountrySelection,
          onProductTypeSelection,
          onProjectSelection,
          onPlatformTypeSelection,
          onSerieSelection,
          onMacroTopicSelection,
          onEditorialCampaignSelection,
          onBrandCampaignSelection,
          onGenreSelection,
          onObjectiveSelection,
          onTemperatureSelection,
          onProductSelection,
          onTopicSelection,
          onToggleBooleanFilter,
          onChangeMember,
          onChangeContributors,
          onClickOtherFilters,
          onCompletionSelection,
          //onClickNotCompleted
        } = callbacks;

        let myMedia = window.location.pathname.includes('my-media') ? true : false

        let selectedCategory = brandCategories.find((bc) => bc.name === selectedCategoryId);
        let selectedBrand = brands.find((b) => b.id === selectedBrandId);
        const selectedTopic = topics.find((t) => t.id === selectedTopicId);
        const selectedMacroTopic = macroTopics.find((mc) => mc.name==selectedMacroTopicName)
        const selectedProject = projects.find((p) => p.id === selectedProjectId);
        const selectedPlatform = selectedPlatformType ? PlatformType[selectedPlatformType] : null
        
        const selectedGenre = genres.find((g) => g.id==selectedGenreId)
        const selectedObjective = objectives.find((g) => g.id==selectedObjectiveId)
        const selectedTemperature = temperatures.find((t) => t.id==selectedTemperatureId)

        //product type
        let selectedProductType: any = null
        if( selectedProductTypeIds ){
            selectedProductType = productTypes
              .map((mId) => {
                const find = selectedProductTypeIds.find((id:any) => id === mId.id);
                if (!find) {
                  return null;
                }
                return {
                  value: mId.id,
                  label: mId.name,
                };
              })
              .filter((m) => m !== null);
        }

        //brands
       // let allBrandsFiltered: Brand[] = brands
        let brandCategoriesFiltered:BrandCategory[]= brandCategories


        let allSeriesFiltered: Serie[] = []
        let allCampaignFiltered: Campaign[]= []
        let allEditorialCampaignFiltered: Label[]= []

       /* let allProjectsProductsFiltered:Label[] = products
                                                      .filter((t) => t.name == 'All Projects')
                                                      .map((p) => 
                                                          p.product.map((pp) => pp)
                                                      )[0];*/
       // let allProjectsProductsFiltered: Label[] = []                                          
        let allProductsFiltered : Label[] = [];
        
        //by project
        let noproject = projects.find((p) => p.name === 'All Projects')
        let project: any;
        if(selectedProjectId )
              project = projects.find((p) => p.id === selectedProjectId) 
        
        allSeriesFiltered =  selectedProjectId ? concat( noproject?.series!, project?.series!) : noproject?.series!
        allProductsFiltered = selectedProjectId ? concat( noproject?.product!, project?.product! ) : noproject?.product! ;
        
        allCampaignFiltered = selectedProjectId ? concat( noproject?.campaigns!, project?.campaigns!) : noproject?.campaigns!
        allEditorialCampaignFiltered = selectedProjectId ? concat( noproject?.editorialCampaigns!, project?.editorialCampaigns!) : noproject?.editorialCampaigns!
        
        //no brands no brandscampaigns 
        if( selectedProject!.name != 'Freeda' ){
          brandCategoriesFiltered = []
          selectedBrand=undefined
          selectedCategory=undefined
          allCampaignFiltered = []
        }

        //series
        const selectedSerie = allSeriesFiltered.find((s) => s.id === selectedSerieId);
       
        
        //product
        const selectedProduct = allProductsFiltered.find((t) => t.id==selectedProductId)
       
        //EditorialCampaign
        let selectedEditorialCampaign = allEditorialCampaignFiltered.find((ed) => ed.id == selectedEditorialCampaignId)

        //brandCampaign
        if( selectedBrand ){
          allCampaignFiltered = allCampaignFiltered.filter((t) => t.brand[0].id == selectedBrand!.id);
        }else{
          allCampaignFiltered = [];
        }
        let selectedBrandCampaign = allCampaignFiltered.find((s) => s.id === selectedBrandCampaignId);
        
        //topic
        let allTopicsFiltered: Topic[] = topics
        if(selectedMacroTopic){
          allTopicsFiltered = topics.filter((t) => t.macroTopic == selectedMacroTopic.name);
        }

        //users
        const selectedMembers = selectedTeamMembers
          .map((mId) => {
            const findedMember = teamMembers.find((member) => member.id === mId);
            if (!findedMember) {
              return null;
            }
            return {
              value: findedMember.id,
              label: findedMember.name,
            };
          })
          .filter((m) => m !== null);

        const selectedContributorsFields = selectedContributors
          .map((cId) => {
            const findedContributor = contributors.find((contributor) => contributor.id === cId);
            if (!findedContributor) {
              return null;
            }
            return {
              value: findedContributor.id,
              label: findedContributor.name,
            };
          })
          .filter((m) => m !== null);

        //qui
        const completion = [
          {id:"MISSING_LABEL", name:"Missing labels"},
          {id:"MISSING_URL", name:"Missing url"},
          {id:"ANY_MISSING_INFO", name:"Any missing info"}
        ]
        let completionFiltered:any
        if(selectedCompletion){
          completionFiltered = completion.filter((t) => t.id == selectedCompletion)[0];
        }
        
        return (
          <FilterWrapper>
            <Media query={{ maxWidth: 768 }}>
              {(matches: boolean) => (
                <Media query={{ maxWidth: 1340 }}>
                  {(matches1340: boolean) => {
                    const filter = (
                      <div
                        style={{
                          padding: '15px 20px',
                          display: 'flex',
                          flexDirection: matches ? 'column' : 'row',
                          flexWrap: matches ? 'wrap' : 'inherit',
                          width: '100%',
                          boxSizing: 'border-box',
                          paddingBottom: matches ? 80 : 20,
                        }}
                      >

                        <Row>
                          <Field basis="12%">
                              <Select
                                label="Project"
                                placeholder="Select project"
                                optionsSort="asc"
                                onChange={(option: any) => {
                                  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                  fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                  onProjectSelection(option ? option.value : null)
                                }}
                                options={ projects.map((f) => ({
                                  value: f.id,
                                  label: f.name,
                                }))}
                                value={
                                  selectedProject
                                  ? { value: selectedProject.id, label: selectedProject.name }
                                  : null
                                }
                              />
                            </Field>
                          
                          
                          <Field basis="16%">
                            <Select
                              label="Format"
                              isMulti
                              placeholder="Select format"
                              optionsSort="asc"
                              isSearchable
                              onChange={(option: any) => {
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onFormatSelection(
                                  option ? option.map((o: any) => o.value as Format) : [],
                                  productTypes
                                );

                              }}
                              options={Object.keys(Format).map((f) => ({
                                value: f,
                                label: capitalize(f),
                              }))}

                              value={selectedFormat.map((f) => ({ value: f, label: capitalize(f) }))}
                            />
                          </Field>
                          <Field basis="21%">
                            <Select
                              label="Content Type"
                              isMulti
                              placeholder="Select content type"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onProductTypeSelection(option ? option.map((o: any) => o.value) : [])
                                }
                              }
                              options={getProductTypes(productTypes, selectedFormat).map(
                                (productType) => ({
                                  value: productType.id,
                                  label: `${productType.format} - ${productType.name}`,
                                })
                              )}
                              value={selectedProductType? selectedProductType.map((f:any) => ({ value: f.value, label: capitalize(f.label) })): null}
                             
                            />
                          </Field>
                          <Field basis="12%">
                            <Select
                              label="Platform"
                              placeholder="Select platform"
                              optionsSort="asc"
                              isSearchable={false}
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onPlatformTypeSelection(option ? option.value : null)
                              }}
                              options={Object.keys(PlatformType).map((l) => ({
                                value: l,
                                label: l,
                              }))}
                              value={ 
                                selectedPlatform ? { value: selectedPlatform, label: selectedPlatform }
                                : null

                              }
                            />
                          </Field>

                          { (selectedPlatform== PlatformType.INSTAGRAM || selectedPlatform== PlatformType.FACEBOOK)  && (
                            <Field basis="5%" 
                              style={{
                                display: 'flex',
                                paddingRight: 0 ,
                                alignItems: 'center',
                                position: 'relative',
                                top: matches || matches1340 ? 0 : 10,
                                flexWrap: matches1340 ? 'wrap' : 'unset',
                              }}>
                              <CheckBox
                                    checked={reel}
                                    onClick={() => { 
                                      fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")(); 
                                      fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                      onToggleBooleanFilter('reel') }}
                                    label="Reel"
                                    style={{
                                      boxSizing: 'border-box',
                                      marginBottom: matches ? 6 : 0,
                                    }}
                                  />
                            </Field>
                          )}
                          <Field basis="10%">
                            <Select
                              label="Prod."
                              placeholder="Select country"
                              optionsSort="asc"
                              isSearchable={false}
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onProductionCountrySelection(option ? option.value : null)
                              }}
                              options={[
                                { value: 'IT', label: 'IT' },
                                { value: 'ES', label: 'ES' },
                                { value: 'UK', label: 'UK' },
                                { value: 'DE', label: 'DE' },
                              ]}
                              value={
                                selectedProductionCountry
                                  ? {
                                      value: selectedProductionCountry,
                                      label: selectedProductionCountry,
                                    }
                                  : null
                              }
                            />
                          </Field>
                          <Field basis="10%">
                            <Select
                              label="Distrib."
                              placeholder="Select country"
                              optionsSort="asc"
                              isSearchable={false}
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onDistributionCountrySelection(option ? option.value : null)
                              }
                              }
                              options={[
                                { value: 'IT', label: 'IT' },
                                { value: 'ES', label: 'ES' },
                                { value: 'UK', label: 'UK' },
                                { value: 'DE', label: 'DE' },
                                { value: 'FR', label: 'FR' }
                              ]}
                              value={
                                selectedDistributionCountry
                                  ? {
                                      value: selectedDistributionCountry,
                                      label: selectedDistributionCountry,
                                    }
                                  : null
                              }
                            />
                          </Field>
                          
                          <Field basis="12%" >
                            <Select
                              label="Language"
                              placeholder="Select language"
                              optionsSort="asc"
                              isSearchable
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onLanguageSelection(option ? option.value : null)
                              }}
                              options={Object.keys(LanguageCountry).map((l) => ({
                                value: l,
                                label: l,
                              }))}
                              value={
                                selectedLanguage
                                  ? {
                                      value: selectedLanguage,
                                      label: selectedLanguage,
                                    }
                                  : null
                              }
                            />
                          </Field>
                        </Row>
                        <DividerSection dividerColor="#ffffff" style={{ width: '100%' }} />
                        <Row>
                          <Field basis="5%"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                              top: matches || matches1340 ? 0 : 10,
                              flexWrap: matches1340 ? 'wrap' : 'unset',
                            }}
                          >
                            <CheckBox
                              checked={isEditorial}
                              onClick={() => { fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")(); fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")(); onToggleBooleanFilter('isEditorial') }}
                              label="Editorial"
                              style={{
                                paddingRight: 10,
                                boxSizing: 'border-box',
                                marginBottom: matches ? 6 : 0,
                              }}
                            />
                            <CheckBox
                              checked={isBranded}
                              onClick={() => {fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")(); fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")(); onToggleBooleanFilter('isBranded')} }
                              label="Branded"
                              style={{ marginBottom: matches ? 6 : 0 }}
                            />
                          </Field>
                          <Field basis="18%">
                            <Select
                              label="Brand industry"
                              placeholder="Select brand industry"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onCategorySelection(option ? option.value : null)
                              }}
                              options={brandCategoriesFiltered.map((category) => ({
                                value: category.name,
                                label: category.name,
                              }))}
                              value={
                                selectedCategory
                                  ? { value: selectedCategory.name, label: selectedCategory.name }
                                  : null
                              }
                            />
                          </Field>
                          <Field basis="18%">
                            <Select
                              label="Brand"
                              placeholder="Select brand"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onBrandSelection(option ? option.value : null)
                               }
                              }
                              options={getBrands(brandCategoriesFiltered, selectedCategoryId).map(
                                (brand) => ({
                                  value: brand.id,
                                  label: brand.name,
                                })
                              )}
                              value={
                                selectedBrand
                                  ? { value: selectedBrand.id, label: selectedBrand.name }
                                  : null
                              }
                            />
                          </Field>
                          <Field basis="17%">
                            <Select
                              label="Series"
                              placeholder="Select series"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onSerieSelection(option ? option.value : null)
                              }}
                              options={allSeriesFiltered.map((s) => ({
                                value: s.id,
                                label: s.name,
                              }))}
                              value={
                                selectedSerie
                                  ? { value: selectedSerie.id, label: selectedSerie.name }
                                  : null
                              }
                            />
                          </Field>
                          <Field basis="16%" >
                            <Select
                              label="Macro Topic"
                              placeholder="Select macrotopic"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onMacroTopicSelection(option ? option.value : null)
                              }}
                              options={macroTopics.map((t) => ({
                                value: t.name,
                                label: t.name,
                              }))}
                              value={
                                selectedMacroTopic
                                  ? { value: selectedMacroTopic.name, label: selectedMacroTopic.name }
                                  : null
                              }
                            />
                          </Field>

                          <Field basis="16%" style={{ paddingRight: 0 }}>
                            <Select
                              label="Topic"
                              placeholder="Select topic"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onTopicSelection(option ? option.value : null)

                              }}
                              options={allTopicsFiltered.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))}
                              value={
                                selectedTopic
                                  ? { value: selectedTopic.id, label: selectedTopic.name }
                                  : null
                              }
                            />
                          </Field>
                         
                        </Row>
                        <DividerSection dividerColor="#ffffff" style={{ width: '100%' }} />
                       
                        
                        <Field
                          basis={ myMedia ? "37%" : "43%" }
                          style={{
                            marginRight: !matches ? 10 : 0,
                            width: !matches ? '' : 'calc(100vw - 40px)',
                            marginTop: 5,
                          }}
                        >
                          <TeamMemberWrapper>
                            <Autocomplete
                              label="Team"
                              placeholder="Select team"
                              optionsSort="asc"
                              flexBasis="100%"
                              currentSelectedItem={
                                selectedMembers as Array<{
                                  value: string;
                                  label: string;
                                }>
                              }
                              items={teamMembers.map((m) => ({
                                value: m.id,
                                label: m.name,
                              }))}
                              onSelect={(member) => {fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                                  fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                                              onChangeMember(member.value) }}
                              onUnselect={(member) => {fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                                        fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                                              onChangeMember(member.value)}}
                              
                              renderSelections={(members: any, handleDelete) => (
                                <AvatarList>
                                  {members.map((member: any) => (
                                    <InteractiveAvatar
                                      size={40}
                                      key={member.value}
                                      name={member.label.replace('.','')}
                                      avatarUrl={member.picture}
                                      handleDelete={() => {
                                        handleDelete(member);
                                      }}
                                    />
                                  ))}
                                </AvatarList>
                              )}
                              internalOpen
                            />
                          </TeamMemberWrapper>
                        </Field>
                        <FieldWithSeparator
                          basis={ myMedia ? "30%" : "40%" }
                          style={{
                            marginRight: !matches ? 5 : 0,
                            marginTop: selectedTeamMembers.length > 0 && matches ? 25 : 5,
                            width: !matches ? '' : 'calc(100vw - 40px)',
                          }}
                        >
                          <TeamMemberWrapper>
                            <Autocomplete
                              label="Contributors"
                              styleWrapper={{ marginLeft: !matches ? 8 : 0 }}
                              placeholder="Select contr."
                              optionsSort="asc"
                              flexBasis="100%"
                              currentSelectedItem={
                                selectedContributorsFields as Array<{
                                  value: string;
                                  label: string;
                                }>
                              }
                              items={contributors.map((m) => ({
                                value: m.id,
                                label: m.name,
                              }))}
                              onSelect={(contributor) => { fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")(); 
                                                            fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                                              onChangeContributors(contributor.value)}}
                              onUnselect={(contributor) => { fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                                              fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                                              onChangeContributors(contributor.value)}}
                              renderSelections={(contributorList: any, handleDelete) => (
                                <AvatarList>
                                  {contributorList.map((contributor: any) => (
                                   
                                    <InteractiveAvatar
                                      size={40}
                                      key={contributor.value}
                                      name={contributor.label}
                                      handleDelete={() => {
                                        handleDelete(contributor);
                                      }}
                                    />
                                  ))}
                                </AvatarList>
                              )}
                              internalOpen
                            />
                          </TeamMemberWrapper>
                        </FieldWithSeparator>
                        
                        {myMedia &&
                         <FieldWithSeparator basis="20%">
                            <Select
                             style={{
                              paddingLeft: 7,
                              
                            }}
                                label="Missing info"
                                placeholder="Select"
                                optionsSort="asc"
                                onChange={(option: any) =>{
                                  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                  fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                  onCompletionSelection(option ? option.value : null)
                                }}
                                options={completion.map((t) => ({
                                  value: t.id,
                                  label: t.name,
                                }))}
                                value={
                                  completionFiltered
                                    ? { value: completionFiltered.id, label: completionFiltered.name }
                                    : null
                                }
                            />
                          </FieldWithSeparator>
                        }

                        <FieldWithSeparator basis="5%" >
                          <CheckBox
                              checked={viewOtherFilters}
                              onClick={() => {  onClickOtherFilters(viewOtherFilters)}}
                              label="Other filters"
                              style={{
                                paddingLeft: 7,
                                boxSizing: 'border-box',
                                marginTop: matches ? 6 : 20,
                              }}
                          />
                        </FieldWithSeparator>
                        
                        
                        {viewOtherFilters && <DividerSection dividerColor="#ffffff" style={{ width: '100%' }} />}
                        {viewOtherFilters && 
                          <Row>
                            <Field basis="18%">
                            <Select
                              label="Editorial Campaign"
                              placeholder="Select"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onEditorialCampaignSelection(option ? option.value : null)
                               }
                              }
                              options={allEditorialCampaignFiltered.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))}
                              value={selectedEditorialCampaign
                                ? { value: selectedEditorialCampaign.id, label: selectedEditorialCampaign.name }
                                : null}
                            />
                          </Field>
                          <Field basis="18%">
                            <Select
                              label="Brand Campaign"
                              placeholder="Select"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onBrandCampaignSelection(option ? option.value : null)

                               }
                              }
                              options={allCampaignFiltered.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))}
                              value={selectedBrandCampaign
                                ? { value: selectedBrandCampaign.id, label: selectedBrandCampaign.name }
                                : null}
                            />
                          </Field>

                          <Field basis="16%">
                            <Select
                              label="Genre"
                              placeholder="Select"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onGenreSelection(option ? option.value : null)
                               }
                              }
                              options = {genres.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))}
                              value={selectedGenre
                                ? { value: selectedGenre.id, label: selectedGenre.name }
                                : null}
                            />
                          </Field>
                          <Field basis="16%">
                            <Select
                              label="Objective"
                              placeholder="Select"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onObjectiveSelection(option ? option.value : null)
                               }
                              }
                              options={objectives.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))}
                              value={selectedObjective
                                ? { value: selectedObjective.id, label: selectedObjective.name }
                                : null}
                            />
                          </Field>
                          <Field basis="16%">
                            <Select
                              label="Temperature"
                              placeholder="Select"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onTemperatureSelection(option ? option.value : null)
                               }
                              }
                              options={temperatures.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))}
                              value={selectedTemperature
                                ? { value: selectedTemperature.id, label:  selectedTemperature.name }
                                : null}
                            />
                          </Field>
                          <Field basis="16%">
                            <Select
                              label="Product"
                              placeholder="Select"
                              optionsSort="asc"
                              onChange={(option: any) =>{
                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")();
                                onProductSelection(option ? option.value : null)
                               }
                              }
                              options= { allProductsFiltered.map((t) => ({
                                value: t.id,
                                label: t.name,
                              }))
                              }
                              value={selectedProduct
                                ? { value: selectedProduct.id, label:  selectedProduct.name }
                                : null}
                            />
                          </Field>

                          </Row>
                        }


                      
                      </div>
                    );

                    return  filter
                  }}
                </Media>
              )}
            </Media>
          </FilterWrapper>
        );




    }}
  </FilterContext.Consumer>

      )}
  </FetchedItemsCounterContext.Consumer>


);

export default AdvancedFilter;

/**
 * {myMedia &&
                          <FieldWithSeparator basis="5%">
                            <CheckBox
                                checked={notCompleted}
                                onClick={() => {  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                onToggleBooleanFilter('notCompleted')}}
                                label="Not completed"
                                style={{
                                  paddingLeft: 7,
                                  boxSizing: 'border-box',
                                  marginTop: matches ? 6 : 20,
                                }}
                            />
                          </FieldWithSeparator>
                        }

                        {myMedia && 
                          <FieldWithSeparator basis="5%">
                            <CheckBox
                                checked={notMapped}
                                onClick={() => {  fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();
                                onToggleBooleanFilter('notMapped')}}
                                label="Not mapped"
                                style={{
                                  paddingLeft: 7,
                                  boxSizing: 'border-box',
                                  marginTop: matches ? 6 : 20,
                                }}
                            />
                          </FieldWithSeparator>
                        }
 */
