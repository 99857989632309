import * as React from 'react';
import styled from 'styled-components';
import { renderPreview } from '../utils/renderPreview';
import { Monogramma } from '../components/ui/Monogramma';
import { ItemType } from '../types/ItemType';

const PreviewWrapper = styled.div`
  height: calc(100vh - 64px);
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const TitleWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.primary};
  color: #ffffff;
  font-size: 20px;
  padding: 20px 14px;
  padding-left: 20px;
  box-sizing: border-box;
`;

interface Props {
  title: string;
  itemType: ItemType | null;
  src: string;
  mimeType: string;
  loading: boolean;
}

const ExternalPreviewPage: React.FC<Props> = ({ title, itemType, src, mimeType, loading }) => (
  <PreviewWrapper onContextMenu={(e) => e.preventDefault()} onDragStart={(e) => e.preventDefault()}>
    <Monogramma style={{ width: '110px', top: '6px', right: '-38px' }} />
    {renderPreview(loading, itemType, mimeType, src, false)}
    <TitleWrapper>{title}</TitleWrapper>
  </PreviewWrapper>
);

export { ExternalPreviewPage };
