import * as React from 'react';
import { StoriesWrapper, StoriesWithSeparator, SingleFramesWrapper, FrameWrapper } from './styles';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import UploadInput from '../UploadInput';
import { sortBy } from 'lodash';
import { EnhancedPlatformContent } from '../../../types/types2.0/EnhancedContent';
import { Frame } from './frame';
import { FramePreview } from '../FramePreview';
import { ButtonIcon, Icon, Typography } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';

type ComponentsData = {
  rows: { [k: string]: Array<{ id: string; content: any }> };
  rowOrder: Array<string>;
};

interface Props {
  readOnlyUser:boolean;
  selectedComponentId: string | null;
  platformContent: EnhancedPlatformContent;
  isStories: boolean;
  onAddComponents: (files: Array<File>, platformId:string) => void;
  onSelectComponent: (componentId: string | null) => void;
  onUpdatePosition: (orderedComponentsId: Array<string>) => void;
  //addCarouselComponent: (platform: EnhancedPlatformContent) => void;

}

interface State {
  componentsData: ComponentsData;
}

const getComponentsData = (story: EnhancedPlatformContent) => {
  //console.log('getComponentsData___', story)
  const sortedAvailableFrames = sortBy(story.components, 'position');
  const componentsData: ComponentsData = {
    rows: {
      components: sortedAvailableFrames.map(({ id, mimeType, thumbnailUrl, storagePath, newUrl }) => ({
        id,
        content: (
          <FramePreview mimeType={mimeType} frameUrl={{ thumbnailUrl, storagePath, newUrl }} />
        )
      }))
    },
    rowOrder: ['story', 'components']
  };

  return componentsData;
};

class DragDrop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      componentsData: getComponentsData(this.props.platformContent)
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      componentsData: getComponentsData(nextProps.platformContent)
    });
  }

  onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const row = Array.from(this.state.componentsData.rows[source.droppableId]);
    const [sourceFrame] = row.splice(source.index, 1);

    row.splice(destination.index, 0, sourceFrame);

    this.setState(
      state => ({
        componentsData: {
          ...state.componentsData,
          rows: { ...this.state.componentsData.rows, [source.droppableId]: row }
        }
      }),
      () => this.props.onUpdatePosition(this.state.componentsData.rows.components.map(f => f.id))
    );
  };

  render() {
    const { selectedComponentId, onAddComponents, onSelectComponent , readOnlyUser/*addCarouselComponent*/} = this.props;
    const story = this.props.platformContent;
    const components = this.state.componentsData.rows.components;
    
    //QUI
    //se non è story allora devo unire ai component che arrivo anche quello di platform

    /*if(!this.props.isStories){
      addCarouselComponent(this.props.platformContent)
    }*/
   

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <StoriesWrapper>
          
        {( this.props.isStories && <StoriesWithSeparator>
            
           
            <Typography variantName="italic-label" style={{ paddingBottom: 10 }}>
                Stories
            </Typography>

            <div style={{ display: 'flex', height: 70, alignItems: 'center' }}>
              <FrameWrapper
                style={{ cursor: 'pointer' }}
                isselected={String(selectedComponentId === null)}
                onClick={() => onSelectComponent(null)}
              >
                <FramePreview
                  mimeType={story.mimeType}
                  frameUrl={{
                    newUrl: story.newUrl,
                    storagePath: story.storagePath,
                    thumbnailUrl: story.thumbnailUrl
                  }}
                />
              </FrameWrapper>
            </div>
          </StoriesWithSeparator>
        )}

          <SingleFramesWrapper style={ { paddingTop: this.props.isStories ? '12px':'0px', paddingLeft: this.props.isStories ? '20px':'0px'} } >
          <Typography variantName="italic-label" style={{ paddingBottom: 10 }}>
              Single frames
          </Typography>
          <div
              style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                top: '-3px',
                height: 110
              }}
            >

            <Droppable droppableId="components" direction="horizontal">
                {droppableProvided => (
                  <div
                    ref={droppableProvided.innerRef as any}
                    {...droppableProvided.droppableProps}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      overflowX: 'auto',
                      height: '100%'
                    }}
                  >
                  {( !this.props.isStories && 
                    <FrameWrapper
                      style={{ cursor: 'pointer', paddingRight: 50 }}
                      isselected={String(selectedComponentId === null)}
                      onClick={() => onSelectComponent(null)}
                    >
                      <FramePreview
                        mimeType={story.mimeType}
                        frameUrl={{
                          newUrl: story.newUrl,
                          storagePath: story.storagePath,
                          thumbnailUrl: story.thumbnailUrl
                        }}
                      />
                </FrameWrapper>
                    
                  )} 
                    {components.map((component, index) => (
                      <Frame
                        key={component.id}
                        draggableId={component.id}
                        index={index}
                        component={component}
                        isStory={this.props.isStories}
                        style={{ marginRight: 10 }}
                        numberFrame={components.indexOf(component) + 1}
                        isDragDisabled={readOnlyUser}
                        isselected={String(selectedComponentId === component.id)}
                        onSelectComponent={() => onSelectComponent(component.id)}
                      />
                    ))}
                    {!readOnlyUser && 
                      <ButtonIcon
                        style={{
                          width: 40,
                          height: 40,
                          cursor: 'pointer',
                          position: 'relative',
                          top: '-5px'
                        }}
                      >
                        <Icon iconName="upload" iconColor={Colors.ROSSINO} />
                        <UploadInput
                          style={{ height: 40, width: 40 }}
                          id="file-upload"
                          type="file"
                          multiple
                          accept="video/mp4,video/quicktime,image/png,image/jpeg"
                          onChange={e => {
                            if (e.target.files) {
                              const files = e.target.files;
                              onAddComponents(Array.from(files), this.props.platformContent.id);
                            }
                          }}
                        />
                      </ButtonIcon>
                    }
                    {droppableProvided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </SingleFramesWrapper>
        </StoriesWrapper>
      </DragDropContext>
    );
  }
}

export { DragDrop };
