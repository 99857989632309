export enum MetadataType {
  BRANDS = 'Brands',
  CAMPAIGNS = 'Campaigns',
  SERIES = 'Series',
  TOPICS = 'Topics',
  FORMATS = 'Formats',
  BOOLEANS = 'Booleans',
  TEMPERATURES = 'Temperatures',
  OBJECTIVES = 'Objectives',
  GENRES = 'Genres',
  PRODUCTS = 'Products',
  KEYWORDS = 'Keywords',
  EDITORIAL_CAMPAIGNS= 'Editorial_campaigns'
 
}

export enum LabelType {
  BRANDS = 'BRAND',
  CAMPAIGNS = 'CAMPAIGN',
  SERIES = 'SERIES',
  TOPICS = 'TOPIC',
  
  FORMATS = 'FORMATS',
  
  BOOLEANS = 'BOOLEAN',
  TEMPERATURES = 'TEMPERATURE',
  OBJECTIVES = 'OBJECTIVE',
  GENRES = 'GENRE',
  PRODUCTS = 'PRODUCT',
  KEYWORDS = 'KEYWORDS',
  EDITORIAL_CAMPAIGNS= 'EDITORIAL_CAMPAIGN'
 
}
