import * as React from 'react';
import styled from 'styled-components';
import { PlaceholderHr, BigText, MediaPlaceholder } from './styles';

const imgPlaceholder = require('../../../assets/placeholder-img.jpg');

const PreviewPageWrapper = styled.div`
  position: fixed;
  top: 43px;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: calc(100vh - 108px);
  display: grid;
  grid-template-columns: 25% 50% 25%;
`;

const InfoMedia = styled.div`
  position: relative;
  display: flex;
  flex-basis: 25%;
  height: 100%;
`;

const MediaWrapper = styled.div`
  display: grid;
  position: relative;
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #ebebeb;
  margin-right: 15px;
`;

const PlaceholderPreviewPage: React.FC = () => (
  <PreviewPageWrapper>
    <InfoMedia>
      <div style={{ padding: '28px 20px 0px', width: '100%', boxSizing: 'border-box' }}>
        <BigText style={{ width: '190px', background: '#ebebeb' }} />
        <PlaceholderHr
          style={{ background: '#ebebeb', marginTop: 15, marginBottom: 20, width: '190px' }}
        />
        <Circle />
      </div>
    </InfoMedia>
    <MediaWrapper>
      <MediaPlaceholder src={imgPlaceholder} />
    </MediaWrapper>
  </PreviewPageWrapper>
);

export { PlaceholderPreviewPage };
