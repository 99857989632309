import * as React from 'react';
import Media from 'react-media';
import { RouteComponentProps, Redirect } from 'react-router';
import { Location } from 'history';
import { parse } from 'query-string';
import auth from './utils/auth';
import { RouteT } from './types/RouteT';
import MediaPageContainer from './containers/MediaPage2.0';
//import ExternalPreviewPageContainer from './containers/ExternalPreviewPage2.0';
import { MediaPreviewContainer } from './containers/MediaPreview2.0';
import LoginPage from './pages/LoginPage';
import { LastMediaPageLocationContext } from './providers/LastMediaPageLocationProvider';
import { ListContentQuery as  AllContentQuery } from './components/queries/queries2.0/ListContentQuery';
import { ListMyContentQuery } from './components/queries/queries2.0/ListMyContentQuery';
import { WarningNotCompletedPanel } from './containers/WarningNotCompletedPanel2.0';
import { ParentContentSelection } from './types/types2.0/ParentContentSelection';
import { ParentContent } from './types/types2.0/ParentContent';
import { FetchedItemsCounterContext } from './providers/FetchedItemsCounterProvider';
import { EditMediaPageContainer } from './containers/EditMediaPage2.0';
import { Country } from './types/graphql/Country';
import { ItemType } from './types/ItemType';
import { MetadataPage } from './pages/MetadataPage2.0';
import {MyComponent} from './containers/ExternalPreviewPage2.0' 



const getRoutes = (
  selected: Array<ParentContentSelection>,
  onCardSelected: (parentContent: ParentContent) => void,
  deselectAll: () => void
): Array<RouteT> => {
  return [
    {
      path: '/',
      exact: true,
      showSidebar: true,
      withDropzone: true,
      private: true,
      component: (
        <MediaPageContainer
          fetchedItemsCounterType="allContentPageFetchedItems"
          orderInfo="Ordered by last updated"
          selectedMedia={selected}
          onCardSelected={onCardSelected}
          deselectAll={deselectAll}
          ContentQuery={ AllContentQuery}
        />
      ),
    },
    {
      path: '/my-media',
      showSidebar: true,
      withDropzone: true,
      private: true,
      component: (
        <MediaPageContainer
          fetchedItemsCounterType="myMediaPageFetchedItems"
          orderInfo="Ordered by last updated"
          renderWarning={() => <WarningNotCompletedPanel />}
          selectedMedia={selected}
          onCardSelected={onCardSelected}
          deselectAll={deselectAll}
          ContentQuery={ListMyContentQuery}
        />
      ),
    },
    
    {
      path: '/metadata/:id',
      showSidebar: true,
      private: true,
      component: <MetadataPage />,
    },
    {
      path: '/login',
      showSidebar: false,
      component: () => {
        return auth.isAuthenticated() ? <Redirect to="/" /> : <LoginPage login={auth.login} />;
      },
    },
    {
      path: '/gallery/:id',
      exact: true,
      private: true,
      showSidebar: false,
      component: (props: RouteComponentProps<{ id: string }>) => {
        const queryString = parse(window.location.search);

        const distributionCountry = (queryString.country as Country) || null;
        const itemType = (queryString.cut as ItemType) || null;

        const componentPosition = queryString.component ? Number(queryString.component) : null;

        return (
          <LastMediaPageLocationContext.Consumer>
            {(lastMediaPageLocationContext) => (
              <FetchedItemsCounterContext.Consumer>
                {({ allContentPageFetchedItems, myMediaPageFetchedItems, myPinPageFetchedItems }) => (
                  <Media query={{ maxWidth: 768 }}>
                    {(matches: boolean) =>
                      /*matches ? (
                       
                        <MobileMediaGalleryContainer
                          id={props.match.params.id}
                          backTo={
                            lastMediaPageLocationContext.location
                              ? lastMediaPageLocationContext.location.pathname
                              : '/'
                          }
                          allContentPageFetchedItems={allContentPageFetchedItems}
                          myMediaPageFetchedItems={myMediaPageFetchedItems}
                          //myPinPageFetchedItems={myPinPageFetchedItems}
                        />
                      ) : */
                      (
                        <MediaPreviewContainer
                          id={props.match.params.id}
                          distributionCountry={distributionCountry}
                          itemType={itemType}
                          componentPosition={
                            componentPosition && componentPosition > 0 ? componentPosition - 1 : componentPosition
                          }
                          backTo={
                            lastMediaPageLocationContext.location
                              ? lastMediaPageLocationContext.location.pathname
                              : '/'
                          }
                          allContentPageFetchedItems={allContentPageFetchedItems}
                          myMediaPageFetchedItems={myMediaPageFetchedItems}
                         // myPinPageFetchedItems={myPinPageFetchedItems}
                        />
                      )
                    }
                  </Media>
                )}
              </FetchedItemsCounterContext.Consumer>
            )}
          </LastMediaPageLocationContext.Consumer>
        );
      },
    },
    {
      path: '/preview',
      private: false,
      showSidebar: false,
      component:   ({ location }: { location: Location }) =>  {
        const h = location.search.substring(location.search.indexOf('=') + 1);

        if (!h || h === '') {
          return <div>File not found :(</div>;
        }
        return <MyComponent externalId={h}/>;
      },
    },
    {
      path: '/edit-media/:id',
      exact: true,
      private: true,
      showSidebar: false,
      component: (props: RouteComponentProps<{ id: string }>) => {
        return <EditMediaPageContainer mediaId={props.match.params.id} />;
      },
    },
  ];
};

export { getRoutes };




