import styled from 'styled-components';

const NoMediaFoundWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 40% 20% 40%;
  height: calc(100vh - 460px);

  @media (max-width: 900px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 600px) {
    grid-template-columns: 100%;
  }
`;

const Central = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 40% 60%;
  padding: 20px;
  box-sizing: border-box;
  @media (max-width: 900px) {
    display: none;
  }
`;

const DesktopCornice = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  @media (max-width: 900px) {
    display: none;
  }
`;

const BigCornice = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 300px;
  align-self: center;
  justify-self: center;
  @media (max-width: 600px) {
    max-height: 230px;
    align-self: auto;
    padding-top: 20px;
    transition: max-height 0.4s ease;
  }
`;

const MobileCornice = styled.img`
  display: none;
  @media (max-width: 900px) {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export { NoMediaFoundWrapper, Central, BigCornice, DesktopCornice, MobileCornice };
