import styled from 'styled-components';

export const WarningPanel = styled.div`
  border: ${props => `1px solid ${props.theme.rossino}`};
  color: ${props => props.theme.rossino};
  font-weight: bold;
  font-size: 22px;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;

  @media (max-width: 768px) {
    margin-left: 6px;
    margin-right: 6px;
  }
`;


export const WarningPublicationPanel = styled.div`
  color: ${props => props.theme.rossino};
  font-weight: bold;
  font-size: 15px;
  margin-top: 20px;
`;
