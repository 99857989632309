import * as React from 'react';

import { Icon, Badge } from '@freeda/react-components';

import { CountNotCompletedContentQuery } from '../components/queries/queries2.0/CountNotCompletedContentQuery';

const NotCompletedBadge: React.FC<{ iconColor?: string }> = ({ children, iconColor }) => (
  <CountNotCompletedContentQuery fetchPolicy="network-only">
    {({ loading, data }) => {
      
      if (loading) {
        return <Icon iconName="upload" iconColor={iconColor || 'rgba(0,0,0,0.54)'} />;
      }


      let counter = 0

      if(data && data.listParentContents){
        counter = data!.listParentContents.aggregate.count;
      }
      

      if (counter === 0) {
        return <Icon iconName="upload" iconColor={iconColor || 'rgba(0,0,0,0.54)'} />;
      }

      return (
        <Badge
          iconName="upload"
          iconColor={iconColor || 'rgba(0,0,0,0.54)'}
          count={counter}
          style={{ cursor: 'pointer', top: -11 }}
        />
      );
    }}
  </CountNotCompletedContentQuery>
);

export { NotCompletedBadge };
