import * as React from 'react';
import { WrapperLateralPanel, BackgroundLayer } from '../../ui/LateralPanel';
import { TransitionStatus } from 'react-transition-group/Transition';
import { BackButton } from '../../ui/BackButton';
import { DividerSection, Typography } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { FeedbackContext } from '../../../providers/FeedbackProvider';
import { useKeyUpEsc } from '../../../hooks/useKeyUpEsc';
import { LabelWrapper } from '../ShareMediaDialog2.0/styles';
//import { UpdateLocalContentMutation } from '../../mutations/mutations2.0/UpdateLocalContentMutation';
//import {SetUpdatedBy} from '../../../components/mutations/mutations2.0/SetUpdatedBy';
//import { PlatformContent } from '../../../types/types2.0/PlatformContent';
import { PlatformsCutMap } from '../../../utils/videoCutsMap';
import { ItemType } from '../../../types/ItemType';
import { Cuts } from '../../ui/VideoCutSelection2/styles';
//import { asyncForEach } from '../../../utils/asyncForEach';
import { Format } from '../../../types/types2.0/Format';
import { ButtonWrapper } from './styles';
import SaveCloneButton from '../../../containers/SaveCloneButton';
import { EnhancedPlatformContent } from '../../../types/types2.0/EnhancedContent';

interface Props {
  open: boolean;
  onClose: (edit?: boolean) => void;
  platformContent: EnhancedPlatformContent;
  itemIsImage: boolean;
  //localization: string;
  format:Format;
  status: TransitionStatus;
  clonePlatform: (platform:string, platformToCLone:EnhancedPlatformContent) => void;
  removeClonePlatform: (platform:string) => void;
  newFileUploadProgress: number;
  onSave: (
    save: (
      parentOptions: any,
      alreadyExistingLocalizationsOptions: any,
      alreadyExistingPlatformsOptions: any,
      newTranslationsOptions: any
    ) => void
  ) => void;
}


const CloneMedia: React.FC<Props> = ({
  open,
  onClose,
  platformContent,
  itemIsImage,
  //localization,
  format,
  status,
  clonePlatform,
  removeClonePlatform,
  newFileUploadProgress,
  onSave

}) => {

  const [state, setState] = React.useState({  
    platforms: [] as Array<string>
  });

  //console.log('type__', platformContent.itemType)

  const addPlatform = (
        value: string
      ) => {
        let arr : Array<string> = state.platforms;

        if(arr.includes(value)){
            arr.splice(arr.indexOf(value), 1);
            removeClonePlatform(value)
        }
        else{
          arr.push(value)
          clonePlatform(value, platformContent)
        }
        return arr
  }

  useKeyUpEsc(onClose);

  return (
    <FeedbackContext.Consumer>
      {({ onOpen }) => (
          <>
            <BackgroundLayer
              open={open}
              onClick={() => onClose()}
              className={`background-${status}`}
            />
            <WrapperLateralPanel
              open={open}
              className={`wrapper-${status}`}
              style={{ justifyContent: 'start' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <BackButton onClick={() => onClose()} />

                <ButtonWrapper>
                  <SaveCloneButton
                    newFileUploadProgress={newFileUploadProgress}
                    onSave={onSave}
                    //clonePlatform = {clonePlatform} 
                    platforms = {state.platforms} 
                    //platformContent ={platformContent}
                  />
                </ButtonWrapper>
              </div>

              <div style={{ padding: 15 }}>
                <LabelWrapper>
                    <Typography
                      variantName="title"
                      style={{
                        color: Colors.PURPLE,
                        fontSize:  14,
                        marginTop:10
                      }}
                    >
                      Choose the platforms on which to clone the content
                    </Typography>
                </LabelWrapper>
              </div>

            <DividerSection dividerColor={Colors.GRIGINOlight} style={{ width: '100%' }} />

            <div style={{ padding: 15, display: 'flex',  alignItems: 'center'}}>

                  {format != Format.INSTANT_ARTICLES &&  platformContent.itemType!= "INSTAGRAM" &&
                      <Cuts
                        key="INSTAGRAM"
                        onClick={() =>  setState({...state, platforms: addPlatform("INSTAGRAM") })}
                        isselected={String(state.platforms.includes("INSTAGRAM") )}
                        style={{marginLeft:10}}
                      >
                        <img src={PlatformsCutMap.IMAGE_INSTAGRAM} />
                      </Cuts>
                  }
                  
                  {platformContent.itemType!= "FACEBOOK" &&
                  <Cuts
                      key="FACEBOOK"
                      onClick={() => setState({...state, platforms: addPlatform("FACEBOOK") })}
                      isselected={String(state.platforms.includes("FACEBOOK") )}
                      style={{marginLeft:10}}
                    >
                      <img src={PlatformsCutMap.IMAGE_FACEBOOK} />
                  </Cuts>
                  }
                  { (!itemIsImage || format === Format.CAROUSEL) && platformContent.itemType!= "TIKTOK" &&
                    <Cuts
                        key="TIKTOK"
                        onClick={() => setState({...state, platforms: addPlatform("TIKTOK") })}
                        isselected={String(state.platforms.includes("TIKTOK") )}
                        style={{marginLeft:10}}
                      >
                        <img src={PlatformsCutMap[ItemType.VIDEO_TIKTOK ]} />
                    </Cuts>
                  }

                  {!itemIsImage && format != Format.CAROUSEL && platformContent.itemType!= "YOUTUBE" &&
                    <Cuts
                        key="YOUTUBE"
                        onClick={() => setState({...state, platforms: addPlatform("YOUTUBE") })}
                        isselected={String(state.platforms.includes("YOUTUBE") )}
                        style={{marginLeft:10}}
                      >
                        <img src={PlatformsCutMap[ItemType.VIDEO_YOUTUBE ]} />
                    </Cuts>
                  } 
                    {platformContent.itemType!= "LINKEDIN" &&
                    <Cuts
                        key="LINKEDIN"
                        onClick={() => setState({...state, platforms: addPlatform("LINKEDIN") })}
                        isselected={String(state.platforms.includes("LINKEDIN") )}
                        style={{marginLeft:10}}
                      >
                        <img src={PlatformsCutMap.IMAGE_LINKEDIN} />
                    </Cuts>
                  }
              </div>
            </WrapperLateralPanel>
          </>
                      

          
      )}
    </FeedbackContext.Consumer>
  );
};

export { CloneMedia };


/**   <ButtonIcon
                                iconName="save"
                                disabled={disabledSaveButton || updateResult.loading}
                                loading={updateResult.loading}
                                iconColor={Colors.ROSSINO}
                                onClick={async () => {
                                 // clonePlatform(state.platforms, platformContent)
                                 // onOpen('Wait for the creation process.. ! 😎 The page will be reloaded ..', 'notification');
                                 // onClose(true);
                                
                                  /*try {

                                      let allVariables = []
                                      for (let platform of state.platforms) {
                                        
                                          let variables = {
                                              id: localization,
                                              updatedBy : userId,
                                              platforms: {
                                                create: {
                                                  creator: userId,
                                                  title: platformContent.title,
                                                  videoLength: platformContent.videoLength,
                                                  mimeType: platformContent.mimeType,
                                                  itemType: platform,
                                                  //instantArticleUrl : platformContent.instantArticleUrl == "" ? null : platformContent.instantArticleUrl,
                                                  storagePath: platformContent.storagePath
                                                }
                                            }
                                          }
                                          if( platformContent.components && platformContent.components.length > 0 ) { 
                                              variables.platforms.create['components']= {
                                                create: platformContent.components.map(f => ({
                                                  mimeType: f.mimeType,
                                                  title: f.title,
                                                  position: f.position,
                                                  videoLength: f.videoLength,
                                                  storagePath: f.storagePath
                                                }))
                                              }
                                          }
                                          allVariables.push(variables)
                                        }

                                        await asyncForEach(
                                          allVariables,
                                          async (option: any) => {
                                            await updateLocalContentMutation({
                                              variables: option,
                                            });
                                          }
                                        );
                                        onOpen('New Platforms SAVED ! 😎 The page will be reloaded ..', 'notification');
                                        onClose(true);

                                       await setUpdatedBy({
                                          variables: {
                                              id:platformContent.parent!.id, 
                                              updatedBy: userId }
                                        });

                                        setTimeout(function () {
                                          window.location.reload()
                                        }, 2500);

                                  } catch (err) {
                                    onOpen(
                                      `Oops, something went wrong 😔 : `.concat(err.message),
                                      'error'
                                    );
                                  }
                                }}
                                /> */