import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  height: 32px;
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 8px;
  }
`;

const Flag = styled.div<{ isSelected: boolean } & React.HTMLProps<HTMLImageElement>>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  border: rgb(242, 242, 242) solid 2px;

  ${(props) =>
    props.isSelected &&
    css`
      border: 2px solid ${props.theme.azzurrino};
    `};

  & > img {
    width: 31px;
    height: 31px;
  }
`;

export { Wrapper, Flag };
