import gql from 'graphql-tag';
const username = localStorage.getItem('username');
const country = localStorage.getItem('country');

const CREATE_PARENT_CONTENT = gql`
  mutation createParentContent($title: String!, $mimeType: String!, $sources: String!, $videoLength: Int) {
    createParentContent(
      storagePath: $sources
      mimeType: $mimeType
      title: $title,
      videoLength: $videoLength, 
      distributionCountry: ${country}
      members: "${username}" ) {
          id
          format
          contentType
          title
          sources
          keywords {
            id
            name
          }
          productType {
            id
            name
            format
          }
          brand {
            id
            name
          }
          serie {
            id
            name
          }
          topic {
            id
            name
          }
          project {
            id
            name
          }
          
          productionCountry
          languageSound
          onlyMusic
          creator
          createdAt
          updatedAt
          updatedBy
          completed
          localizations {
            id
            parent {
              id
            }
            distributionCountry
            creator
            members {
              id
              name
            }
            contributors {
              id
              name
            }
            noSubtitle
            subtitles
            languageCopy
            languageSubtitle
            creator
            createdAt
            updatedAt
            updatedBy
      
            platforms {
              id
              title
              parent{
                id
              }
              localization{
                id
              }
              itemType
              mimeType
              storagePath
              thumbnailUrl
              sources
              videoLength
              creator
              createdAt
              updatedBy
              updatedAt
              isReel
              components {
                id
                parent{
                  id
                }
                localization{
                  id
                }
                platform{
                  id
                }
                title
                position
                storagePath
                mimeType
                thumbnailUrl
                videoLength
                creator
                createdAt
                updatedBy
                updatedAt
              }
            }
          }
      }
  }
  
`;

export { CREATE_PARENT_CONTENT };
