import * as React from 'react';
//import { createContext, useState } from "react";


export type FetchedCounterType =
  | 'allMediaPageFetchedItems'
  | 'allContentPageFetchedItems'
  | 'myMediaPageFetchedItems'
  | 'myPinPageFetchedItems';

type State = {
  allMediaPageFetchedItems: number;
  allContentPageFetchedItems: number;
  myMediaPageFetchedItems: number;
  myPinPageFetchedItems: number;
};

const FetchedItemsCounterContext = React.createContext<
  State & {
    updateMediaFetchedCounter: (key: FetchedCounterType) => (counter: number) => void,
    resetCounter: (key: FetchedCounterType) => () =>void;
  }
>({
  allMediaPageFetchedItems: 0,
  allContentPageFetchedItems: 0,
  myMediaPageFetchedItems: 0,
  myPinPageFetchedItems: 0,
  updateMediaFetchedCounter: (key: FetchedCounterType) => (counter: number) => console.log('Please implement updateMediaFetchedCounter'),
  resetCounter: (key: FetchedCounterType) => () => console.log('Please implement resetState')
});

//const FetchedItemsCounterContextFilter = React.createContext<typeof FetchedItemsCounterContext>({resetCounter} as typeof FetchedItemsCounterContext);


class FetchedItemsCounterProvider extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      allMediaPageFetchedItems: 0,
      allContentPageFetchedItems: 0,
      myMediaPageFetchedItems: 0,
      myPinPageFetchedItems: 0
    };
  }

  resetCounter = (
    key: 'allMediaPageFetchedItems' | 'myMediaPageFetchedItems' | 'myPinPageFetchedItems' | 'allContentPageFetchedItems'
  )=>()=> {
    console.log('RESETCOUNTER')
    this.setState(state => ({
      ...state,
      [key]: 0
    }))
  }

  updateMediaFetchedCounter = (
    key: 'allMediaPageFetchedItems' | 'myMediaPageFetchedItems' | 'myPinPageFetchedItems' | 'allContentPageFetchedItems'
  ) => (counter: number) =>
    this.setState(state => ({
      ...state,
      [key]: state[key] + counter
    }));

  render() {
    return (
      <FetchedItemsCounterContext.Provider
        value={{ ...this.state, updateMediaFetchedCounter: this.updateMediaFetchedCounter , resetCounter: this.resetCounter}}
      >
        {this.props.children}
      </FetchedItemsCounterContext.Provider>
    );
  }
}

export { FetchedItemsCounterProvider,FetchedItemsCounterContext};
