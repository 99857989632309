import * as React from 'react';
import {DeleteParentMutation} from '../components/mutations/mutations2.0/DeleteParentContentMutation'
import client from '../apollo/clientTobe';
import { FeedbackContext } from '../providers/FeedbackProvider';
import { CurrentMediaPageLocationContext } from '../providers/CurrentMediaPageLocationProvider';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button } from '@freeda/react-components';
import { ITEMS_PER_PAGE } from '../utils/constants';
import ListContentQuery from '../types/types2.0/graphql2.0/ListContentQuery';
//import { LIST_CONTENT } from '../apollo/queries/queries2.0/ListContent';
//import { LIST_MY_CONTENT } from '../apollo/queries/queries2.0/ListMyContent';

interface Props {
  parentId: string;
  onCloseDialog: () => void;
  
}

const DeleteAllMediaDialog: React.FC<Props & RouteComponentProps<{}>> = ({
  parentId,
  onCloseDialog,
}) => (

 
  <CurrentMediaPageLocationContext.Consumer>
    {() => (
      <FeedbackContext.Consumer>
        {({ onOpen }) => (
          <DeleteParentMutation>
            {(deleteParentContent, deleteResult) => (
              
              <Button
                disabled={deleteResult.loading}
                loading={deleteResult.loading}
                onClick={async () => {
                 try{
                  await deleteParentContent({
                    variables: { id: parentId },
                    
                    update: () => {
                      
                      if (deleteResult.error) {
                        onOpen(deleteResult.error.message, 'error');
                      } 
                      else {
                        onOpen('Content DELETED! The list will be reloaded ..', 'notification');
                        onCloseDialog();

                        setTimeout(function () {
                          window.location.reload()
                         }, 3000);
                      }

                    },
                  });
                 }
                 catch(e){
                    onOpen(e instanceof Error ? e.message : "", 'error');
                    onCloseDialog();
                 }
                }}
              >
                I’m sure, delete it
              </Button>

           )}
          </DeleteParentMutation>
        )}
      </FeedbackContext.Consumer>
    )}
  </CurrentMediaPageLocationContext.Consumer>
);

export default withRouter<Props & RouteComponentProps<{}>, any>(DeleteAllMediaDialog);
