import * as React from 'react';
import ApolloProvider from 'react-apollo/ApolloProvider';

//import client from '../apollo/client';
import clientTobe from '../apollo/clientTobe'
import { ShareMediaDialogProvider } from './ShareDialogProvider2.0';
import { FeedbackProvider } from './FeedbackProvider';
import { FilterProvider } from './FilterProvider2.0';
import { FetchedItemsCounterProvider } from './FetchedItemsCounterProvider';
import { DownloadMediaDialogProvider } from './DownloadDialogProvider2.0';
import { DownloadMultiMediaDialogProvider } from './DownloadMultiDialogProvider2.0';
import { FreedaThemeProvider } from '@freeda/react-components';
import { SidebarProvider } from './SidebarProvider';
import { BrandsFilterProvider } from './BrandsFilterProvider2.0';
import { TopicsFilterProvider } from './TopicsFilterProvider2.0';
import { LabelsFilterProvider } from './LabelsFilterProvider2.0';
import { CampaignsFilterProvider } from './CampaignsFilterProvider';

const ProvidersWrapper: React.FC = ({ children }) => (
  <FreedaThemeProvider>
    <ApolloProvider client={clientTobe}>
      <SidebarProvider>
        <FetchedItemsCounterProvider>
          <ShareMediaDialogProvider>
            <DownloadMediaDialogProvider>
              <DownloadMultiMediaDialogProvider>
                <FeedbackProvider>
                  <FilterProvider>
                    <BrandsFilterProvider>
                      <TopicsFilterProvider>
                        <LabelsFilterProvider> 
                          <CampaignsFilterProvider>{children}</CampaignsFilterProvider>
                        </LabelsFilterProvider>
                      </TopicsFilterProvider>
                    </BrandsFilterProvider>
                  </FilterProvider>
                </FeedbackProvider>
              </DownloadMultiMediaDialogProvider>
            </DownloadMediaDialogProvider>
          </ShareMediaDialogProvider>
        </FetchedItemsCounterProvider>
      </SidebarProvider>
    </ApolloProvider>
  </FreedaThemeProvider>
);

export { ProvidersWrapper };
