import * as React from 'react';
import styled from 'styled-components';

import Sidebar from '../components/layout/Sidebar';
import { FilterContext } from '../providers/FilterProvider2.0';
import SearchMediaVariables from '../types/types2.0/SearchMediaVariables';

const ContentWrapper = styled.div`
  @media (min-width: 769px) {
    padding-left: 100px;
    padding-right: 15px;
    padding-top: 15px;
  }

  @media (max-width: 768px) {
    padding-top: 55px;
    box-sizing: border-box;
  }
`;

const WithSidebar = (
  onUpload: (variables: SearchMediaVariables) => (files: Array<File>) => any,
  Component: React.ComponentType<any> | React.ReactElement<any>
) => (
  <FilterContext.Consumer>
    {({ getQueryVariables }) => {
      const variables = getQueryVariables();
      return (
        <>
          <Sidebar onUpload={onUpload(variables)} />
          <ContentWrapper id="content-wrapper">
            {Component instanceof Function ? <Component /> : Component}
          </ContentWrapper>
        </>
      );
    }}
  </FilterContext.Consumer>
);

export { WithSidebar };
