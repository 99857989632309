import * as React from 'react';
import { Query } from 'react-apollo';
import { CustomQueryProps } from '../../../types/graphql/utils';
import ListBrandsQuery from '../../../types/types2.0/graphql2.0/ListBrands';
import ListFormatsQuery from '../../../types/types2.0/graphql2.0/ListFormats';
import listLabels from '../../../types/types2.0/graphql2.0//ListLabels';
import { MetadataType, LabelType } from '../../../types/MetadataType';
import { getListMetadataQuery } from '../../../utils/metadataUtils2.0';
//import clientTobe from '../../../apollo/clientTobe';

class TypedQuery extends Query<ListBrandsQuery | listLabels | ListFormatsQuery, {}> {}

export const ListMetadataQuery: React.FC<
  CustomQueryProps<ListBrandsQuery | listLabels | ListFormatsQuery> & {
    metadataType: MetadataType;
  }
> = ({ metadataType, children, ...others }) => {
  
  const query = getListMetadataQuery(metadataType);

  if( metadataType!=MetadataType.BRANDS && metadataType!=MetadataType.FORMATS ){
    others!.variables!.category = LabelType[metadataType.toUpperCase()]
  }

  return (
    <TypedQuery /*client={clientTobe}*/ query={query} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
