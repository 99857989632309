import styled from 'styled-components';
import { zIndex } from '../../../utils/zIndex';
import { Button } from '@freeda/react-components';

const BarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background: ${props => props.theme.darkPrimary};
  font-size: 20px;
  color: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  z-index: ${zIndex.BarWrapper};
  height: 60px;

  transition: bottom 0.4s;

  &.wrapper-entering {
    bottom: -65px;
  }
  &.wrapper-entered {
    bottom: 0px;
  }
  &.wrapper-exiting {
    bottom: -65px;
  }
  &.wrapper-exited {
    bottom: -65px;
  }

  @media (max-width: 768px) {
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  & > button {
    width: 48px;
    height: 48px;
  }

  & > button > span {
    &:hover {
      color: #ff0a43;
    }
  }

  @media (min-width: 769px) {
    margin-left: 25px;
  }
`;

const DeselectButton = styled(Button)`
  && {
    background: transparent;
    height: 48px;
    margin: 0;
    color: #7cffdd;
    font-weight: 500;
    &:hover {
      color: #ff0a43;
      background: rgba(0, 0, 0, 0.08);
    }
    &:active {
      background: transparent;
    }
  }
`;

export { BarWrapper, IconsWrapper, DeselectButton };
