import * as React from 'react';
import Media from 'react-media';
import { Colors } from '@freeda/react-components/lib/theme';
import { Tooltip, Icon, Button, Input, Typography, Select } from '@freeda/react-components';

import {
  TeamMemberWrapper,
  FormControl,
  LabelWrapper,
  InputWrapper,
  InputWrapperStyled
} from './styles';
import { TextArea } from '../../ui/TextArea';
import { SlackReceiver } from '../../../types/SlackReceiver';

interface Props {
  contentId:string;
  title: string;
  slackReceivers: Array<SlackReceiver>;
  slackSharingLoading: boolean;
  mediaUrl: string;
  storagePath: string;
  shareOnSlack: (variables: any, cb: () => void) => void;
  onClose: () => void;
}

interface State {
  contentId:string;
  title: string;
  comment: string;
  slackReceiverId: string | null;
}

class WithTeamMemberForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      contentId: props.contentId,
      title: props.title,
      comment: '',
      slackReceiverId: null
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({
      title: nextProps.title
    });
  }

  handleChangeTitle = (title: string) => this.setState({ title });
  handleChangeSlackReceiver = (slackReceiverId: string) => this.setState({ slackReceiverId });
  handleChangeComment = (comment: string) => this.setState({ comment });

  render() {
    const {
      mediaUrl,
      slackReceivers,
      shareOnSlack,
      slackSharingLoading,
      storagePath,
      onClose
    } = this.props;
    const { contentId, title, slackReceiverId, comment } = this.state;


    const selectedUser = slackReceivers
      ? slackReceivers.find(receiver => receiver.id === slackReceiverId)
      : null;

    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <TeamMemberWrapper>
            <FormControl>
              <LabelWrapper>
                <Typography
                  variantName="label"
                  style={{
                    color: '#000000',
                    fontSize: matches ? 14 : 12,
                    textTransform: 'capitalize'
                  }}
                >
                  Title
                </Typography>
              </LabelWrapper>
              <InputWrapperStyled style={{ flexBasis: matches ? '70%' : '80%' }}>
                <Input value={title || ''} onChange={e => this.handleChangeTitle(e.target.value)} />
              </InputWrapperStyled>
            </FormControl>
            <FormControl>
              <LabelWrapper>
                <Typography
                  variantName="label"
                  style={{
                    color: '#000000',
                    fontSize: matches ? 14 : 12,
                    textTransform: 'capitalize'
                  }}
                >
                  Share with
                </Typography>
              </LabelWrapper>
              <InputWrapper>
                <Select
                  placeholder="Select a user"
                  onChange={(option: any) =>
                    this.handleChangeSlackReceiver(option ? option.value : null)
                  }
                  options={
                    slackReceivers &&
                    slackReceivers.map(receiver => ({
                      value: receiver.id,
                      label: receiver.real_name || receiver.name
                    }))
                  }
                  value={selectedUser ? { value: selectedUser.id, label: selectedUser.name } : null}
                />
              </InputWrapper>
            </FormControl>
            <FormControl>
              <LabelWrapper>
                <Typography
                  variantName="label"
                  style={{
                    color: '#000000',
                    fontSize: matches ? 14 : 12,
                    textTransform: 'capitalize'
                  }}
                >
                  Add Comment
                </Typography>
              </LabelWrapper>
              <InputWrapper>
                <TextArea
                  placeholder="Enter a comment"
                  value={comment}
                  style={{ height: 125 }}
                  onChange={e => this.handleChangeComment(e.target.value)}
                />
              </InputWrapper>
            </FormControl>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              {!matches && (
                <Tooltip
                  referenceChildren={<Icon iconName="info" iconColor={Colors.GRIGINOlight} />}
                  tooltipChildren={
                    <div>
                      Be sure to invite <span style={{ fontWeight: 'bolder' }}>"Peggy" </span>
                      bot to your channel before sharing media to it
                    </div>
                  }
                  position="left"
                  referenceStyle={{ display: 'flex', alignItems: 'center', marginRight: 15 }}
                  tooltipStyle={{ width: 250, top: 4 }}
                />
              )}
              <Button
                icon={{
                  position: 'left',
                  iconName: 'reply',
                  iconColor: '#ffffff'
                }}
                style={{
                  width: matches ? '100%' : '',
                  margin: 0,
                  marginRight: 5,
                  whiteSpace: 'nowrap'
                }}
                loading={slackSharingLoading}
                disabled={slackReceiverId === null || slackSharingLoading}
                onClick={() =>
                  shareOnSlack(
                    {
                      variables: {
                        contentId,
                        receiverId: slackReceiverId,
                        title,
                        link: mediaUrl,
                        text: comment,
                        storagePath: storagePath
                      }
                    },
                    onClose
                  )
                  
                }
              >
                Share content
              </Button>
            </div>
          </TeamMemberWrapper>
        )}
      </Media>
    );
  }
}

export { WithTeamMemberForm };
