import * as React from 'react';
import { FieldWrapper } from './styles';
import { Select } from '@freeda/react-components';
import { ListProjects } from '../../queries/queries2.0/ListProject';
import { includes } from 'lodash';

interface Props {
  selectedProjectIds: Array<string>;
  onChangeProjectIds: (ids: Array<string>) => void;
  style?: React.CSSProperties;
  label?: string;
  isClearable?: boolean;
  disabled?: boolean;
}

const MultiSelectProjects: React.FC<Props> = ({
  selectedProjectIds,
  onChangeProjectIds,
  style,
  label,
  isClearable,
  disabled,
}) => {
  return (
    <ListProjects>
      {(listProjectsResult) => {
        const dataNotAvailable =
          listProjectsResult.loading ||
          !listProjectsResult.data ||
          Object.keys(listProjectsResult.data).length === 0;

        const Projects = dataNotAvailable ? [] : listProjectsResult.data!.listProjects;

        const options = Projects.map((pT) => ({
          value: pT.id,
          label: pT.name,
        }));

        const selectedProjects = options.filter((bC) =>
          includes(selectedProjectIds, bC.value)
        );
        
        return (
          <FieldWrapper style={style}>
            <Select
              placeholder="Select project" 
              label={label}
              isDisabled={dataNotAvailable || disabled}
              style={{ padding: 0 }}
              optionsSort="asc"
              isClearable={isClearable}
              onChange={(option: any) =>{
                  console.log(option)
                  onChangeProjectIds(option ? option.map((pT: any) => pT.value) : [])

                }
              }
              options={options}
              isMulti
              value={
                selectedProjects
                  ? selectedProjects.map((pT) => ({ value: pT.value, label: pT.label }))
                  : null
              }
            />
          </FieldWrapper>
        );
      }}
    </ListProjects>
  );
};

export { MultiSelectProjects };
