import gql from 'graphql-tag';

const LIST_SLACK_RECEIVERS = gql`
  query listSlackReceivers {
    listSlackReceivers {
      users {
        id
        name
        real_name
      }
      channels {
        id
        name
      }
    }
  }
`;

export { LIST_SLACK_RECEIVERS };
