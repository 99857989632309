import { DateRange } from '../types/DateRange';
import moment from 'moment';

export const getDateRange = (dateRange: DateRange): { from: string; to: string } => {
  switch (dateRange) {
    case DateRange.LAST_WEEK:
      return {
        from: moment()
          .subtract(1, 'weeks')
          .startOf('day')
          .format(),
        to: moment().format()
      };
    case DateRange.LAST_MONTH:
      return {
        from: moment()
          .subtract(1, 'months')
          .startOf('day')
          .format(),
        to: moment().format()
      };
    case DateRange.LAST_YEAR:
      return {
        from: moment()
          .subtract(1, 'years')
          .startOf('day')
          .format(),
        to: moment().format()
      };
    case DateRange.LAST_24H:
    default:
      return {
        from: moment()
          .subtract(1, 'days')
          .startOf('day')
          .format(),
        to: moment().format()
      };
  }
};
