import * as React from 'react';

import auth from '../../utils/auth';
import { useHistory } from 'react-router-dom';

const LogoutButton: React.FC = ({ children }) => {
  const history = useHistory();

  return (
    <div
      style={{ display: 'flex', alignItems: 'center' }}
      onClick={() => {
        auth.logout();
        history.push('/');
      }}
    >
      {children}
    </div>
  );
};

export default LogoutButton;
