import * as React from 'react';
//import styled from 'styled-components';
//import { renderPreview } from '../utils/renderPreview';
import { Monogramma } from '../components/ui/Monogramma';
//import { ItemType } from '../types/ItemType';
//import { /*Img,*/ VideoPreview } from '../utils/renderPreview';
import styled, { css } from 'styled-components';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Content} from '../utils/contentUtils'


const TitleWrapper = styled.div`
  bottom: 0;
  margin: 0 auto;
  justify-self: center;
  text-align: center;
  color: #6B2DD5 !important;
  font-size: 20px;
  padding: 20px 14px;
  box-sizing: border-box;
  
`;

const mediaStyle = css`
  justify-self: center;
  align-self: center;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 450px;
  padding: 20px 1px 10px 1px;
  margin-bottom: 25px;
`;

const Img = styled.img`
  ${mediaStyle};
`;

const Video = styled.video`
  ${mediaStyle};
`;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    //slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    //slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    //slidesToSlide: 1 // optional, default to 1.
  }
};


interface Props {
  contents: Array<Content>|null;
}

class MyGallery extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    //return <ImageGallery items={images} />;
    const {contents} = this.props;
    
    return(
      
        <div>
            <Monogramma style={{ width: '110px', top: '6px', right: '-38px' }} />

                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={true}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1800000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  focusOnSelect={true}
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  //deviceType={this.props.deviceType}
                  //dotListClass="custom-dot-list-style"
                  //itemClass="carousel-item-padding-40-px"
                >
                    {contents!.map((s) => (
                      <div style={{
                        display: 'grid'
                      }}>
                        {
                          s.mimeType=='video/mp4' ? (
                            <Video src={s.storagePath} controls />
                            ):(
                            <Img src={s.storagePath} />

                          )
                        }
                          <TitleWrapper>{s.title}</TitleWrapper>
                        
                      </div>
                    ))}
              </Carousel>
          </div>
        )
    }
}
export {MyGallery};

/**
 * 
 * position: fixed;
 * margin-top: 700px;
 *  display:flex !important;
    align-items: center !important;
    justify-content: center;
 * 
 *  margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;           
    flex-direction: column; 
 * 
       */