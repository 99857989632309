import * as React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Icon } from '@freeda/react-components';
import { zIndex } from '../../utils/zIndex';

const freedaLogo = require('../../assets/freeda_logo.png');

const LogoQuoteWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 18px;
`;

const HeartIcon = styled(Icon)`
  && {
    position: relative;
    top: 2px;
  }
`;

const FreedaLogo = styled.img`
  height: auto;
  width: 125px;
  top: 18px;
  right: 3px;
  z-index: ${zIndex.FreedaLogo};
  @media (max-width: 768px) {
    position: fixed;
    width: 100px;
    top: 5px;
  }
`;

const TechQuote = styled.span`
  font-size: 12px;
  color: #000000;
`;

const Header: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <LogoQuoteWrapper style={style}>
      {!isMobile && (
        <TechQuote>
          Designed with <HeartIcon iconName="favorite" iconSize="xsmall" iconColor="#FF0A43" /> by
          Dati Team
        </TechQuote>
      )}
      <FreedaLogo src={freedaLogo} />
    </LogoQuoteWrapper>
  );
};

export { Header };
