import styled, { css } from 'styled-components';
import { Button } from '@freeda/react-components';

export const StateButton = styled(Button)<{ isActive: boolean }>`
  && {
    min-width: unset;
    white-space: nowrap;
  }
  ${props =>
    props.isActive
      ? css`
          border-bottom: 2px solid rgb(255, 10, 67);
        `
      : css`
          color: ${props.theme.secondary};
        `};
`;
