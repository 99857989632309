import gql from 'graphql-tag';

const SEND_SLACK_MESSAGE = gql`
  mutation sendSlackMessage(
    $contentId:ID!
    $receiverId: String!
    $title: String!
    $link: String!
    $text: String!
    $storagePath: String!
  ) {
    sendSlackMessage(
      metadata: { receiverId: $receiverId}
      message: { title: $title, link: $link, text: $text }
      mediaItem: { storagePath: $storagePath , contentId: $contentId }
    ) {
      ok
      channel
      ts
    }
  }
`;

export { SEND_SLACK_MESSAGE };
