import { GetPutS3SignedUrlQuery } from './../types/graphql/GetPutS3SignedUrlQuery';
import { GetTmpAwsCredentialsQuery } from './../types/graphql/GetTmpAwscredentialsQuery';
import { getPutS3SignedUrl } from '../apollo/utils/getPutS3SignedUrl';
import { getTmpAwsCredentials } from '../apollo/utils/getTmpAwsCredentials';
import { ApolloQueryResult } from 'apollo-client';
import { FilePreview } from '../types/FilePreview';
import { getVideoDuration } from '../utils/contentUtils';
import axios from 'axios';
import { Upload } from "@aws-sdk/lib-storage";

import {
  //CreateMultipartUploadCommand,
  S3Client,
  //UploadPartCommand,
  //CompleteMultipartUploadCommand,
} from "@aws-sdk/client-s3";

/*const buildClient = () => {
  return new S3Client({
    region: '', 
  credentials:{
    accessKeyId:'',
    secretAccessKey:''
  }});
};

const multipartUpload = async (bucket:any, key:any) => {
  const command = new CreateMultipartUploadCommand({
      Bucket: bucket,
      Key: key,
  });
  const client = buildClient();
  const response = await client.send(command);

  return response.UploadId;
}

const uploadPart = async (
  uploadId:any,
  partDetails:any,
  partArray:any=[],
) => {
  const command = new UploadPartCommand({
      UploadId: uploadId,
      Bucket: partDetails.bucket,
      Key: partDetails.key,
      PartNumber: partDetails.partNumber,
      Body: partDetails.body,
  });
  const client = buildClient();
  const response = await client.send(command);
  console.log(response)

  if (response['$metadata'].httpStatusCode === 200) {
      partArray.push({
          PartNumber: partDetails!.partNumber!,
          ETag: response.ETag 
      });
  }
}

const completeMultipartUpload = async (bucket:any, key:any, uploadId:any, parts:any) => {
  console.log(parts)
  const command = new CompleteMultipartUploadCommand({
      Bucket: bucket,
      Key: key,
      UploadId: uploadId,
      MultipartUpload: {
          Parts: parts
      }
  });
  const client = buildClient();
  const response = await client.send(command);

  console.log(response);
}

const buildAndUpload = async (bucket:string, key:string) => {
  // Start uploading
  const uploadId = await multipartUpload(bucket, key);
  const partArray:any[] = [];
  //Upload parts
  for (let i = 0; i < 5; i++) {
      const part = {
          bucket,
          key,
          body: (i + '').repeat(1024 * 1024 * 50), // 5 MB is required, except for the last part.
          partNumber: (i + 1)
      }

      await uploadPart(uploadId, part, partArray);
  }

  //Lets complete uploads
  await completeMultipartUpload(bucket, key, uploadId, partArray);
}*/

const s3UploadMultipart = async (
    file: File,
    callback: (percentage: number) => void,
    createMedia?: (title: string, mimeType: string, storagePath: string, videoLength:number|null) => void,
    onSuccess?: (url: string) => void
  )=>{

    try{
      let duration = 0 
      const previewUrl = URL.createObjectURL(file);
      const media = new Audio(previewUrl);
      media.addEventListener("loadeddata", () => {
        duration = media.duration;
      });
      
      const { name, type, lastModified } = file;
      let blob = new Blob([file], { type: type });
      let blob2 = blob.slice(0, 10000000) //prendo 10 MB per creare il thumbnail
      let file2 = new File([blob2], name, {type:type, lastModified:lastModified})

      const res: ApolloQueryResult<{
        getTmpAwsCredentials: GetTmpAwsCredentialsQuery;
      }> = await getTmpAwsCredentials();
    
      if (res.errors || !res.data) {
        throw new Error('Cannot get tmp credentials to upload multipart file :(');
      }
      let nameMediaUrl;
      let storagePath:any;
    
      
  
      await s3Upload(file2, type, true, callback, async (mediaUrl: string) => {
        nameMediaUrl = mediaUrl.substring( mediaUrl.lastIndexOf("/")+1 )
        storagePath = mediaUrl
        if(createMedia){
          duration = duration | 0
          console.log('duration', duration)
          await createMedia(name.replace(/\.[^/.]+$/, ''), type, mediaUrl, duration); //passare info di file compress
        }
       
      })
      const parallelUploads3 = new Upload({
          client: new S3Client({ 
            region: process.env.REACT_APP_AWS_REGION , 
            credentials:{
                accessKeyId: res.data.getTmpAwsCredentials.accessKeyId,
                secretAccessKey: res.data.getTmpAwsCredentials.secretAccessKey ,
                sessionToken: res.data.getTmpAwsCredentials.sessionToken
            }
            }),
            params: {Bucket: process.env.REACT_APP_BUCKET, Key: nameMediaUrl, Body: blob, ContentType: type, ContentDisposition:type}, //params
            tags: [], // optional tags
            queueSize: 5, // optional concurrency configuration
            partSize: 1024 * 1024 * 10, // optional size of each part, in bytes, at least 10MB
            leavePartsOnError: false // optional manually handle dropped parts
          });
     
        parallelUploads3.on("httpUploadProgress", (progress:any) => {
            //console.log('PERCENTAGE .. ',progress);
            var percentage = Math.trunc((progress.loaded / progress.total) * 100)
            if(progress.loaded === progress.total && onSuccess ){
              onSuccess(storagePath)
            }
            callback(percentage);
        });

        await parallelUploads3.done();
      
    
  }
  catch(e){
    throw e
  }
}

const s3Upload = async (
  file: File,
  type: string,
  multipart: boolean,
  onUpdateProgress: (progress: number) => void,
  onSuccess: (url: string) => void
) => {
  const result: ApolloQueryResult<{
    getPutS3SignedUrl: GetPutS3SignedUrlQuery;
  }> = await getPutS3SignedUrl(type, multipart);
    
  if (result.errors) {
      throw new Error('Cannot generate a signed url :(');
    }

    if (!result.data) {
      throw new Error('Cannot generate a signed url :(');
    }

    const s3SignedUrl = result.data.getPutS3SignedUrl;
    //console.log(s3SignedUrl)

    const blob = new Blob([file], { type: type });
   
    await axios.put(
            s3SignedUrl.signedUrl, 
            blob, 
            {
              headers:{
                'Content-type': type,
                'Content-Disposition': 'attachment'
              },
              onUploadProgress: ProgressEvent  => {
                if(multipart){
                  onUpdateProgress(1);
                }
                else{
                  if (ProgressEvent.lengthComputable) {
                    var percentage = Math.trunc((ProgressEvent.loaded / ProgressEvent.total) * 100);
                    onUpdateProgress(percentage);
                  }
                }
                
              }
            })
            .then(res => {
                if (res.status === 200) {
                  onSuccess(s3SignedUrl.mediaUrl);
                }
            })
            .catch(err => {
              console.log('Errore Upload to S3 ', err);
            })

}

const upload = async (
  file: File,
  createMedia: (title: string, mimeType: string, storagePath: string, videoLength:number|null) => void,
  callback: (percentage: number) => void,
  onLocalUpload: (preview: FilePreview) => void
) => {
  
  const { name, type , size, lastModified } = file;
  const isImage = type.indexOf('image') >= 0;
  let length: number | null;
  const previewUrl = URL.createObjectURL(file);
  onLocalUpload({ previewUrl, title: name, progress: 0, mimeType: type });
  
  if(isImage){
    try {
      s3Upload(file, type, false, callback, async (mediaUrl: string) => {
        await createMedia(name.replace(/\.[^/.]+$/, ''), type, mediaUrl, length?length:null);
      });
    } catch (err) {
      console.log('errore upload : ', err)
    }
  }
  else{
        if(size <= parseInt( process.env.REACT_APP_FILE_SIZE! ) ){ //800MB 800000000
          length = await getVideoDuration(file).then(function(length){
            return length;
          });
        
          if(length && length>0 && length.toString().indexOf('.')>=0) {
            let arr = length.toString().split('.')
            length = parseInt(arr[0])
          }
          try {
            s3Upload(file, type, false, callback, async (mediaUrl: string) => {
              await createMedia(name.replace(/\.[^/.]+$/, ''), type, mediaUrl, length?length:null);
            });
          } catch (err) {
            console.log('errore upload : ', err)
          }
      }
      else{

          try {
            await s3UploadMultipart(file, callback, createMedia, undefined)
            document.getElementById('closeUpload')!.click()
          } catch (e) {
            console.log('ERROR IN UPLOADMULTIPART ', e);
          }

    }
  }
  
};

export { s3Upload, upload , s3UploadMultipart};
