import * as React from 'react';
import { ListItemText } from '@freeda/react-components';

import { Box } from './styles';

const MediaBox: React.FC<{
  previewUrl: { thumbnailUrl: string | null; storagePath: string };
  title: string;
  mimeType: string;
  selected: boolean;
}> = ({ previewUrl, title, mimeType, selected }) => {
  const videoProps = previewUrl.thumbnailUrl
    ? { poster: previewUrl.thumbnailUrl }
    : { src: previewUrl.storagePath };

  return (
    <Box style={{ background: selected ? '#d7fff4' : '' }}>
      {mimeType.indexOf('image') >= 0 && (
        <img
          style={{ width: 40, height: '40' }}
          src={previewUrl.thumbnailUrl || previewUrl.storagePath}
        />
      )}
      {mimeType.indexOf('video') >= 0 && (
        <video style={{ width: 40, height: '40' }} {...videoProps}>
          <source src={previewUrl.storagePath} type={mimeType} />
        </video>
      )}
      <ListItemText primary={<span style={{ wordBreak: 'break-word' }}>{title}</span>} />
    </Box>
  );
};

export { MediaBox };
