import gql from 'graphql-tag';

const GET_USERS = gql`
  query listUsers {
    listUsers {
        id
        name
    }
  }
`;

export { GET_USERS };
