import styled from 'styled-components';

const TeamMemberWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 0px;
  overflow-y: hidden;
  & > div:not(:last-child) {
    margin-bottom: 17px;
  }
`;

const MediaPreviewWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-top: 4px;
  margin-bottom: 4px;

  @media (max-width: 768px) {
    height: 20%;
  }
`;

const ExternalWrapper = styled.div`
  display: flex;
  padding-top: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
  }
`;

const FormControl = styled.div`
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  width: 100%;
  margin-left: 0px;
  padding-right: 5px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 17px;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 20%;
  text-align: right;

  text-align: right;

  @media (max-width: 768px) {
    text-align: left;
    flex-basis: 30%;
  }
`;

const InputWrapperStyled = styled.div<{ height?: number }>`
  & > div > div > input {
    border-color: #999999;
    color: ${props => props.theme.secondary};
    height: ${props => props.height}px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 80%;
  @media (max-width: 768px) {
    flex-basis: 70%;
  }
`;

const InfoTooltipWrapper = styled.div`
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  TeamMemberWrapper,
  MediaPreviewWrapper,
  ExternalWrapper,
  FormControl,
  LabelWrapper,
  InputWrapper,
  InfoTooltipWrapper,
  InputWrapperStyled
};
