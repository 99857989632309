import { ParentContent } from '../../types/types2.0/ParentContent';
import { EnhancedComponentContent, EnhancedLocalContent, EnhancedParentContent, EnhancedPlatformContent } from '../../types/types2.0/EnhancedContent';
import { ItemType } from '../../types/ItemType';
import { VideoType } from '../../types/VideoType';
import { CutType } from '../../types/CutType';
import { PlatformContent } from '../../types/types2.0/PlatformContent';
import { flatMap } from 'lodash';
//import { getEntityTypeMutationVariable } from '../../components/layout/EditMediaPage/helpers';
import { s3Upload, s3UploadMultipart } from '../../api/upload';
import { Country } from '../../types/graphql/Country';
import { ComponentContent } from '../../types/types2.0/ComponentContent';

import { getItemTypeFromString /*getFirstAvailablePlatformContentType2, getItemTypesFromString */} from '../../utils/contentUtils';
import { Format } from '../../types/types2.0/Format';
import { PlatformType } from '../../types/types2.0/PlatformType';
//import { RefreshSpan } from '../../components/RefreshSpan';



type NewFile = { id: string; file: File; type: 'platform' | 'component' };
type FilePath = { id: string; storagePath: string; type: 'platform' | 'component' };

//const username = localStorage.getItem('username');

//funzione che ha come parametro parent e ritorna un EnhancedParentContent
export const buildEnhancedParentContent = (parent: ParentContent): EnhancedParentContent => {
  return {
    ...parent,
    addedKeyword: [],
    removedKeyword: [],
    newKeyword: []
  };
};

//funzione che ha come parametro paret e ritorna un array di locaContent
export const buildEnhancedLocalContent = (
  parent: ParentContent
): Array<EnhancedLocalContent> => {
  const firstItem = parent.localizations[0].platforms[0];
  const isImage = firstItem.mimeType.indexOf('image') >= 0;
    
  const videoType =
        parent.format === Format.INSTAGRAM_STORY
          ? VideoType.STORY
          : isImage
            ? null
            : VideoType.VIDEO;

  return parent.localizations.map(t => {
    return {
      ...t,
      addedContributors: [],
      removedContributors: [],
      newContributors: [],

      addedMembers: [],
      removedMembers: [],
      newMembers: [],
      isUpdate: false,
      isNew: false,
      videoType,
      platforms: t.platforms.map(i => ({
        ...i,
        cutType: i.itemType.indexOf('VIDEO_') >= 0 ? (CutType[i.itemType] as CutType) : null,
        newUrl: null,
        newFile: null,
        originalItemType: getItemTypeFromString(i.mimeType, i.itemType),
        country: t.distributionCountry,
        isNew: false,
        isUpdate: false,
        components: i.components.map(f => ({
          ...f,
          newUrl: null,
          newFile: null,
          isNew: false,
          isUpdate: false,
        }))
      }))
    };
  });
};

//mergia la localizzazione con le platforms e aggiunge nuovi campi 
export const withMergedItemLocalization = (
  selectedLocalizationCountry: Country,
  parent: ParentContent,
  newItemType: ItemType
) => (localization: EnhancedLocalContent): EnhancedLocalContent => {
  
    if (localization.distributionCountry === selectedLocalizationCountry) {
      const updatedLocalization = parent.localizations.find(
        t => t.distributionCountry === selectedLocalizationCountry
      )!;
      let arr = newItemType.split('_')
      const newItem = updatedLocalization.platforms.find(i => i.itemType === (arr && arr.length>0 ? arr[1] : newItemType) )!;

      return {
        ...localization,
        platforms: [
          ...localization.platforms,
          {
            ...newItem,
            cutType: CutType[newItemType] as CutType,
            newUrl: null,
            newFile: null,
            originalItemType: getItemTypeFromString(newItem!.mimeType, newItem!.itemType) , 
            country: updatedLocalization.distributionCountry,
            isNew: false,
            isUpdate:true,
            components: newItem.components && newItem.components.length == 0 ? [] : getComponents(newItem.components)
          }
        ]
      };
    }

  return localization;
};


export const getComponents = (
  arrComponents:  Array<ComponentContent>
) => {
  let arr:any=[]
  arr = arrComponents.map(c => ({
      ...c,
      newUrl: null,
      newFile: null,
      isNew: false
   
  }))
  return arr;
};

//storagePath tipi su  component e platform
const getStoragePath = (
  o: PlatformContent | ComponentContent,
  type: 'platform' | 'component',
  filePaths?: Array<FilePath>
) => {
  let storagePath = o.storagePath;
  if (filePaths) {
    const newPath = filePaths.find(p => p.id === o.id && p.type === type);
    if (newPath) {
      storagePath = newPath.storagePath;
    }
  }
  return storagePath;
};

//QUI SALVO Aggiungere i nuovi field sui tipo di contenuto
//media:quello che arriva , root:quello che viene modificato, localizations:localizzazione che cambia
export const save = (
  media: ParentContent,
  root: EnhancedParentContent,
  localizations: Array<EnhancedLocalContent>,
  onNewFileUploadProgress: (id: string, progress: number) => void
) => async (
    onSave: (
      rootOptions: any,
      alreadyExistingTranslationsOptions: any,
      alreadyExistingItemsOptions: any,
      newTranslationsOptions: any
    ) => void
  ) => { 
  
  console.log('CONTENT_OLD_', media)
  console.log('CONTENT_NEW_', root)

  const userId = localStorage.getItem('userId') != '' ? localStorage.getItem('userId') : null;
  const contentType = root.contentType;
  const newLocalizations = localizations.filter(t => t.isNew);

  const alreadyExistingLocalizations = localizations.filter(t => !t.isNew);

  const alreadyExistingPlatforms = flatMap(
    //alreadyExistingLocalizations.map(t => t.platforms.filter(i => !i.isNew))
    alreadyExistingLocalizations.map(t => t.platforms.filter(i => i.isUpdate))
  );
  const updateLocalizations  = localizations.filter(t => t.isUpdate);

  const saveMedia = (filePaths?: Array<FilePath>) => {
  
      onSave(
        {
            variables: {
              id: media.id,
              contentType,
              title: root.title,
              format: root.productType ? root.productType.id : null,
              brand: root.brand ? root.brand.id : null,
              campaign: root.campaign ? root.campaign.id : null,
              serie: root.serie ? root.serie.id : null,
              topic: root.topic ? root.topic.id : null,
              project: root.project ? root.project.id : undefined,
              temperature: root.temperature ? root.temperature.id : null,
              product: root.product ? root.product.id : null,
              genre: root.genre ? root.genre.id : null,
              objective: root.objective ? root.objective.id : null,
             
              booleans: root.booleans ? root.booleans.map(b => b.id) : null,
              editorialCampaign: root.editorialCampaign ? root.editorialCampaign.id : null,
              languageSound: root.languageSound,
              completed : root.completed,
              onlyMusic: root.onlyMusic,
              sources: root.sources,
             // productionCountry:root.productionCountry,
              updatedBy : userId,
              keywords: {
                connect: root.addedKeyword.map(k => ({ id: k.id })),
                disconnect: root.removedKeyword.map(k => ({ id: k.id })).filter(removedKW => {
                  // if a removed keyword is added in the same cycle (it does not exists on the server)
                  // It has to be removed from the removedKewyords array. The server cannot accept to
                  // disconnect a keyword that is not connected.
                  if (media.keywords) {
                    return media.keywords.find(originalKW => originalKW.id === removedKW.id);
                  }
                  return false;
                }),
                create: root.newKeyword.map(name => ({ name }))
              }
            }
        },
        //alreadyExistingLocalizations
        updateLocalizations.map(t => ({
          variables: {
            id: t.id,
            languageCopy: t.languageCopy,
            languageSubtitle: t.languageSubtitle,
            noSubtitle: t.noSubtitle,
            updatedBy : userId,
  
            members: {
              connect: t.addedMembers.map(c => ({ id: c.id })),
              disconnect: t.removedMembers.map(c => ({ id: c.id })).filter(removedC => {
                if (t.members) {
                  return t.members!.find(originalC => originalC.id === removedC.id);
                }
                return false;
              }),
              create: t.newMembers.map(name => ({
                name: name
              }))
            },
  
            contributors: {
              connect: t.addedContributors.map(c => ({ id: c.id })),
              disconnect: t.removedContributors.map(c => ({ id: c.id })).filter(removedC => {
                if (t.contributors) {
                  return t.contributors!.find(originalC => originalC.id === removedC.id);
                }
                return false;
              }),
              create: t.newContributors.map(contributorName => ({
                name: contributorName
              }))
            },
            distributionCountry: t.distributionCountry,
            platforms: {
              create: t.platforms.filter(i => i.isNew).map(i => ({
                creator: userId,
                title: i.title,
                videoLength: i.videoLength,
                mimeType: i.mimeType,
                itemType: i.itemType,
                sources: root.sources,
                isReel: i.isReel,
                instantArticleUrl : i.instantArticleUrl == "" ? null : i.instantArticleUrl,
                storagePath: getStoragePath(i, 'platform', filePaths),
                components: {
                  create: i.components.filter(f => f.isNew).map(f => ({
                    mimeType: f.mimeType,
                    title: f.title,
                    position: f.position,
                    videoLength:f.videoLength,
                    storagePath: getStoragePath(f, 'component', filePaths)
                    
                  })),
                  update: i.components.filter(f => !f.isNew).map(f => ({
                      id: f.id,
                      title: f.title,
                      position: f.position,
                      videoLength:f.videoLength,
                      storagePath: getStoragePath(f, 'component', filePaths)
                      //fileUploadedAt
                  }))
                }
                
              }))
            }
          }
        })),
  
        alreadyExistingPlatforms.map(i => ({
          variables: {
            id: i.id,
            storagePath: getStoragePath(i, 'platform', filePaths),
            sources: root.sources,
            instantArticleUrl :  i.instantArticleUrl == "" ? null : i.instantArticleUrl,
            itemType: i.itemType, 
            mimeType: i.mimeType,
            title: i.title, 
            isReel: i.isReel,
            videoLength: i.videoLength,
            awsReplace : i.awsReplace,
            updatedBy : userId,
  
            components: {
              create: i.components.filter(f => f.isNew).map(f => ({
                mimeType: f.mimeType,
                title: f.title,
                position: f.position,
                videoLength:f.videoLength,
                storagePath: getStoragePath(f, 'component', filePaths)
                
              })),
              update: i.components.filter(f => !f.isNew).map(f => ({
                  id: f.id,
                  title: f.title,
                  position: f.position,
                  awsReplace: f.awsReplace,
                  videoLength:f.videoLength,
                  storagePath: getStoragePath(f, 'component', filePaths)
                  //fileUploadedAt
                
              }))
            }
          }
        })),

        newLocalizations.map(t => ({
          variables: {
            parent: media.id ,
            languageCopy: t.languageCopy,
            languageSubtitle: t.languageSubtitle,
            noSubtitle: t.noSubtitle,
            creator: userId,
  
            members: {
              connect: t.addedMembers.map(c => ({ id: c.id })),
              create: t.newMembers.map(name => ({
                name: name
              }))
            },
  
            contributors: {
              connect: t.addedContributors.map(c => ({ id: c.id })),
              create: t.newContributors.map(contributorName => ({
                name: contributorName
              }))
            },
  
            distributionCountry: t.distributionCountry,
            platforms: {
              create: t.platforms.map(i => ({
                creator: userId,
                //qui update
                //updatedBy : userId,
                title: i.title,
                mimeType: i.mimeType,
                itemType: i.itemType, 
                sources: root.sources,
                videoLength:i.videoLength,
                isReel: i.isReel,
                instantArticleUrl :  i.instantArticleUrl == "" ? null : i.instantArticleUrl,
                storagePath: getStoragePath(i, 'platform', filePaths),
                components: {
                  create: i.components.filter(f => f.isNew).map(f => ({
                    mimeType: f.mimeType,
                    title: f.title,
                    position: f.position,
                    videoLength:f.videoLength,
                    storagePath: getStoragePath(f, 'component', filePaths)
                    
                  }))
                }
                
              }))
            }
          }
        }))
      );
    
   
  };

  const newPlatformFiles: Array<NewFile> = flatMap(localizations.map(t => t.platforms))
    .filter(i => i.newFile !== null)
    .map<NewFile>(i => ({ id: i.id, file: i.newFile!, type: 'platform' }));
  


  const newComponentsFiles: Array<NewFile> = flatMap(alreadyExistingPlatforms.map(i => i.components))
    .filter(f => f.newFile !== null)
    .map<NewFile>(f => ({ id: f.id, file: f.newFile!, type: 'component' }));
  

  //all new
  const newLocalization : Array<EnhancedLocalContent> = localizations.filter(t => t.isNew==true)
  let newPlatform: Array<EnhancedPlatformContent> =  flatMap(newLocalization.map(t => t.platforms))
  let newComponents: Array<EnhancedComponentContent> =  flatMap(newPlatform.map(t => t.components))
  const newComponentsPlatformFiles: Array<NewFile> = newComponents
    .filter(f => f.newFile !== null)
    .map<NewFile>(f => ({ id: f.id, file: f.newFile!, type: 'component' }));
  
  
  //old localization new platform and components
   newPlatform =  flatMap(localizations.map(t => t.platforms))
   newComponents =  flatMap(newPlatform.map(t => t.components))
 
  const newPlatformNewComponentsFiles: Array<NewFile> = newComponents
    .filter(f => f.newFile !== null)
    .map<NewFile>(f => ({ id: f.id, file: f.newFile!, type: 'component' }));



  let uploadPromises: Array<Promise<any>> = [];
  let newFilePaths: Array<FilePath> = [];

  [...newPlatformFiles, ...newPlatformNewComponentsFiles , ...newComponentsFiles, ...newComponentsPlatformFiles].forEach(itemFile => {

    if( itemFile.file.size > parseInt( process.env.REACT_APP_FILE_SIZE! ) ){
      uploadPromises.push(
        new Promise((resolve, reject) => {
          s3UploadMultipart(
            itemFile.file,
            (progress: number) => onNewFileUploadProgress(itemFile.id, progress),
            undefined,
            (storagePath: string) =>
            resolve(newFilePaths.push({ id: itemFile.id, storagePath, type: itemFile.type }))
          );
        })
      );
    }
    else{
      uploadPromises.push(
        new Promise((resolve, reject) => {
          s3Upload(
            itemFile.file,
            itemFile.file.type,
            false,
            (progress: number) => onNewFileUploadProgress(itemFile.id, progress),
            (storagePath: string) =>
              resolve(newFilePaths.push({ id: itemFile.id, storagePath, type: itemFile.type }))
          );
        })
      );
    }
  
  });

  if (uploadPromises.length > 0) {
    Promise.all(uploadPromises).then(() => saveMedia(newFilePaths));
  } else {
    saveMedia();
  }
};

export const changePath = (
  mediaId: string,
  selectedLocalizationCountry: Country,
  selectedItemType: ItemType
): void => {
  let link = `/edit-media/${mediaId}?country=${selectedLocalizationCountry}`;
  
  const cut = CutType[selectedItemType];
  
  if (cut) {
    window.history.replaceState(window.history.state, link, `${link}&cut=${cut}`);
    
  } else {
    window.history.replaceState(window.history.state, link, link);
  }
 
};



export const changePlatform = (
  mediaId: string,
  selectedLocalizationCountry: Country,
  selectedPlatformType: PlatformType,
  mimeType :string,
): void => {
  let link = `/edit-media/${mediaId}?country=${selectedLocalizationCountry}`;
  const cut = mimeType.concat('_').concat(PlatformType[selectedPlatformType]);
  
  if (cut) {
    window.history.replaceState(window.history.state, link, `${link}&cut=${cut}`);
  } else {
    window.history.replaceState(window.history.state, link, link);
  }
   
};

