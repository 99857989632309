import * as React from 'react';
import { Mutation } from 'react-apollo';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { MERGE_CONTENT } from '../../../apollo/mutation/mutation2.0/MergeContent';
import { CustomMutationProps } from '../../../types/graphql/utils';

class TypedMutation extends Mutation<{ mergeContent: ParentContent }> {}

export const MergeContentMutation: React.FC<CustomMutationProps<{ mergeContent: ParentContent }>> = ({
  children,
  ...others
}) => {
  return (
    <TypedMutation mutation={MERGE_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
