import gql from 'graphql-tag';



const CREATE_LABEL = gql`
  mutation createLabel($name: String!, $category: LabelCategory!, $macroTopic: String, $project: [ProjectInput] ) {
    createLabel(name: $name, category: $category, projectList: $project, macroTopic: $macroTopic) {
      id
      name
      macroTopic
      project {
        id
        name
      }
      brand {
        id
        name
      }
    }
  }
`;

/*const CREATE_SERIE = gql`
  mutation createSerie($name: String!, $project: [ProjectInput]) {
    createSerie(name: $name, project: $project) {
      id
      name
      project {
        id
        name
      }
    }
  }
`;*/


const CREATE_TOPIC = gql`
  mutation createTopic($name: String!, $macroTopic: String, $project: [ProjectInput]) {
    createTopic(name: $name, macroTopic: $macroTopic, project: $project ) {
      id
      name
      macroTopic
      project {
        id
        name
      }
    }
  }
`;

export { CREATE_TOPIC, CREATE_LABEL};
/**
const CREATE_BRAND = gql`
  mutation createBrand($name: String!, $brand_category: String!) {
    createBrand(name: $name, brand_category: $brand_category) {
      id
      name
      brand_category
    }
  }
`; */