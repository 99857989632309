import styled from 'styled-components';

const ButtonWrapperDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;

  & > button:first-child {
    margin-right: 20px;
  }
`;

export { ButtonWrapperDialog };
