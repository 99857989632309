import * as React from 'react';
import { UpdateParentContentMutation } from '../components/mutations/mutations2.0/UpdateParentContentMutation';
import { UpdateLocalContentMutation } from '../components/mutations/mutations2.0/UpdateLocalContentMutation';
import { CreateLocalContentMutation } from '../components/mutations/mutations2.0/CreateLocalContentMutation';
import { UpdatePlatformContentMutation } from '../components/mutations/mutations2.0/UpdatePlatformContentMutation';
import { FeedbackContext } from '../providers/FeedbackProvider';
import { RefreshSpan } from '../components/RefreshSpan';
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom';
import { asyncForEach } from '../utils/asyncForEach';
import { Button } from '@freeda/react-components';
import clientTobe from '../apollo/clientTobe';
import { FetchedItemsCounterContext } from '../providers/FetchedItemsCounterProvider';


interface Props {
  newFileUploadProgress: number;
  onSave: (
    save: (
      parentOptions: any,
      alreadyExistingLocalizationsOptions: any,
      alreadyExistingPlatformsOptions: any,
      newTranslationsOptions: any
    ) => void
  ) => void;
  isMediaChanged: boolean;

}

const SaveMediaButton: React.FC<Props> = ({ newFileUploadProgress, onSave, isMediaChanged}) => {
  const history = useHistory();

  const buttonId = 'savecontent'

  const [isDisabled, setDisabled] = React.useState(false);

  const onClickCommandSave = (e: KeyboardEvent) => {
    const commandOrCtrl = window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey;
    const saveButton = document.getElementById('savecontent');

    if (commandOrCtrl && e.key === '83') {
      e.preventDefault();
      if (saveButton && !isDisabled) {
        saveButton.click();
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener('keydown', onClickCommandSave, true);
    return () => {
      window.removeEventListener('keydown', onClickCommandSave, true);
    };
  }, []);

  

  return (
    <FetchedItemsCounterContext.Consumer>
      { fetchedItemsCounterContext => (
        <FeedbackContext.Consumer>
          {({ onOpen }) => (
            <UpdateParentContentMutation>
              {(updateParentContent, updateParentResult) => (
                <UpdateLocalContentMutation>
                  {(updateLocalContent, updateLocationResult) => (
                    <UpdatePlatformContentMutation>
                      {(updatePlatformContent, updatePlatformContentResult) => (
                        <CreateLocalContentMutation>
                          {(createLocalContent) => {
                            
                            const saveLoading = updateParentResult.loading || updateLocationResult.loading || updatePlatformContentResult.loading;
                              return (
                                  <>
                                    {(saveLoading || newFileUploadProgress) > 0 && (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 10,
                                          }}
                                        >
                                        {newFileUploadProgress > 0 && <span>{newFileUploadProgress}%</span>}
                                      </div>
                                    )}
                                    <Button
                                      style={{ margin: 0 }}
                                      id={buttonId}
                                      disabled={
                                        isDisabled ||
                                        !isMediaChanged ||
                                        saveLoading ||
                                        newFileUploadProgress > 0
                                      }
                                      loading={isDisabled || saveLoading || newFileUploadProgress > 0}
                                      onClick={() => {
                                        setDisabled(true);
                                      
                                        onSave(
                                          async (
                                            parentMutationOptions: any,
                                            alreadyExistingLocationsMutationOptions: any,
                                            alreadyExistingPlatformsMutationOptions: any,
                                            newLocalizationsMutationOptions: any
                                          ) => {
                                            try {
                                              
                                              await updateParentContent({
                                                variables: parentMutationOptions.variables,
                                              });

                                              await asyncForEach(
                                                alreadyExistingLocationsMutationOptions,
                                                async (option: any) => {
                                                  await updateLocalContent({
                                                    variables: option.variables,
                                                  });
                                                }
                                              );

                                              await asyncForEach(
                                                alreadyExistingPlatformsMutationOptions,
                                                async (option: any) => {
                                                  await updatePlatformContent({
                                                    variables: option.variables,
                                                  });
                                                }
                                              );

                                              await asyncForEach(
                                                newLocalizationsMutationOptions,
                                                async (option: any) => {

                                                  await createLocalContent({
                                                    variables: option.variables,
                                                  });
                                                }
                                              );
                                              
                                              onOpen('Content informations SAVED', 'notification');

                                              if(localStorage.getItem("merge") == 'merge'){
                                                setTimeout(function () {
                                                  if (history) {
                                                    clientTobe.clearStore()
                                                  }
                                                  window.location.reload()
                                                }, 2000);
                                              }
                                              else{
                                                if (history) {
                                                  clientTobe.clearStore()
                                                }
                                              
                                                fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")()
                                                fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")()
                                                history.goBack()
                                              }

                                            } catch (err) {
                                              Raven.captureException(err);
                                              onOpen(<RefreshSpan>{err instanceof Error ? err.message : "Errore"}</RefreshSpan>, 'error');
                                              return;
                                            } finally {
                                              setDisabled(false);
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </>
                                );
                          }}
                        </CreateLocalContentMutation>
                      )}
                    </UpdatePlatformContentMutation>
                  )}
                </UpdateLocalContentMutation>
              )}
            </UpdateParentContentMutation>
          )}
        </FeedbackContext.Consumer>
        )}
      </FetchedItemsCounterContext.Consumer>
  );
};

export default withRouter<Props & RouteComponentProps<{}>, any>(SaveMediaButton);
