import axios from 'axios';
import decode from 'jwt-decode';
import { stringify } from 'query-string';

type JWTPayload = {
  email: string;
  given_name: string;
  family_name: string;
  name: string;
  groups: Array<string>;
  preferred_username: string;
  resource_access: JSON;
};

type TokenResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  id_token: string;
};

const countriesMap = {
  'Freeda IT': 'IT',
  'Freeda ES': 'ES',
  'Freeda UK': 'UK'
};







class Auth {
  tokenRenewalTimeout: number | null;

  constructor() {
    this.tokenRenewalTimeout = null;
    this.scheduleRenewal();
  }

  private setSession = (response: TokenResponse) => {
    

    const payload: JWTPayload = decode(response.access_token);
    const { email, name, groups, preferred_username: user , resource_access} = payload;
    let country = 'IT'

    if(groups){
      const countryGroup = Object.keys(countriesMap).filter(group => groups.includes(group));
      country = countryGroup[0] ? countriesMap[countryGroup[0]] : 'IT';

    }
    localStorage.setItem('country', country);

    

    
    localStorage.setItem('access_token', response.access_token);
    
    localStorage.setItem('refresh_token', response.refresh_token);
    localStorage.setItem(
      'access_token_expires_at',
      String(Date.now() + response.expires_in * 1000)
    );

    localStorage.setItem(
      'refresh_token_expires_at',
      String(Date.now() + response.refresh_expires_in * 1000)
    );



    localStorage.setItem('email', email);

    localStorage.setItem('name', name);

    localStorage.setItem('username', user);

   


    if(resource_access && resource_access['media-manager']){


      let media_manage_roles=resource_access['media-manager']['roles']
      let roles:string = ""

      for (let role of media_manage_roles) {
       
        roles = roles.concat(role).concat('|');
      }
      localStorage.setItem('roles', roles);
    }
    this.scheduleRenewal();

   

  };

  login = async (
    username: string,
    password: string
  ): Promise<{ result: 'OK' | 'KO'; error?: string }> => {
    try {
      const result = await axios.post<TokenResponse>(
        `${process.env.REACT_APP_AUTH_URL!}`,
        stringify({
          client_id: 'media-manager',
          username,
          password,
          grant_type: 'password',
          client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
          scope: 'openid'

        }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      this.setSession(result.data);


     /* const resultguest = await axios.post<TokenResponse>(
        `${process.env.REACT_APP_AUTH_URL!}`,
        stringify({
          client_id: 'media-manager',
          username:'guest',
          password:'U<c.9PZdMvq8H27%',
          grant_type: 'password',
          client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
          scope: 'offline_access'

        }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      localStorage.setItem('access_token_guest', resultguest.data.access_token);*/


      return { result: 'OK' };
    } catch (err) {
      return { result: 'KO', error: err instanceof Error ? err.message : "Errore"};
    }
  };

  isAuthenticated = () => {
    // Check whether the current time is past the access token's expiry time
    let refreshTokenExpiresAt = localStorage.getItem('refresh_token_expires_at');
    if (!refreshTokenExpiresAt) {
      return false;
    }

    return new Date().getTime() < Number(JSON.parse(refreshTokenExpiresAt));
  };

  renewToken = async () => {
    try {
      if (localStorage.getItem('access_token')) {
        const result = await axios.post<TokenResponse>(
          `${process.env.REACT_APP_AUTH_URL!}`,
          stringify({
            client_id: 'media-manager',
            grant_type: 'refresh_token',
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
            refresh_token: localStorage.getItem('refresh_token')
          }),
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        );

        this.setSession(result.data);
      }

      return { result: 'OK' };
    } catch (err) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('access_token_expires_at');
      localStorage.removeItem('refresh_token_expires_at');
      localStorage.removeItem('refresh_token');

      window.location.reload();
      return { result: 'KO', error: err instanceof Error ? err.message : "Errore" };
    }
  };

  scheduleRenewal = () => {
    const accessTokenExpiresAt = JSON.parse(localStorage.getItem('access_token_expires_at')!);

    const delay = accessTokenExpiresAt - Date.now();

    if (delay > 0) {
      this.tokenRenewalTimeout = window.setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  };

  logout = () => {
    // clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('refresh_token_expires_at');
    localStorage.removeItem('refresh_token');
    
    clearTimeout(this.tokenRenewalTimeout!);
  };
}

export default new Auth();
