import gql from 'graphql-tag';

const username = localStorage.getItem('username');



const COUNT_NOT_COMPLETED_CONTENT = gql`
query countNotCompletedContent {
    listParentContents(
      where: {
        
        locals_some: { 
          members_contains: "${username}"
          parent: { completed: false }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export { COUNT_NOT_COMPLETED_CONTENT };
