import * as React from 'react';
import { Query } from 'react-apollo';
import { CustomQueryProps } from '../../../types/graphql/utils';
import { LIST_MACROTOPIC } from '../../../apollo/queries/queries2.0/ListMacroTopic';
//import ListBrandCategoriesQuery from '../../../types/types2.0/graphql2.0/ListBrandCategories';
import ListMacroTopicQuery from '../../../types/types2.0/graphql2.0/ListMacroTopic';
//import clientTobe from '../../../apollo/clientTobe';

class TypedQuery extends Query<ListMacroTopicQuery, {}> {}

export const ListMacroTopic: React.FC<CustomQueryProps<ListMacroTopicQuery>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={LIST_MACROTOPIC} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
