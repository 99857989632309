import * as React from 'react';

import {
  DeletePlatformMutation,
  onDeletePlatformContent,
} from '../components/mutations/mutations2.0/DeletePlatformContentMutation';

import { FeedbackContext } from '../providers/FeedbackProvider';
import { CurrentMediaPageLocationContext } from '../providers/CurrentMediaPageLocationProvider';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from '@freeda/react-components';

interface Props {
  parentId: string;
  platformId: string;
  shouldCloseAfterDelete: boolean;
  onCloseDialog: () => void;
  updateLocalizationsAfterDelete: () => void;
}

const DeletePlatformButton: React.FC<Props & RouteComponentProps<{}>> = ({
  parentId,
  platformId,
  shouldCloseAfterDelete,
  history,
  onCloseDialog,
  updateLocalizationsAfterDelete,
}) => (

  <CurrentMediaPageLocationContext.Consumer>
    {({ location }) => (
      <FeedbackContext.Consumer>
        {({ onOpen }) => (
          <DeletePlatformMutation>
            {(deletePlatfromContent, deleteResult) => (
              
              <Button
                disabled={deleteResult.loading}
                loading={deleteResult.loading}
                onClick={async () => {
                 // alert('CLICK')
                 
                 try{
                  await deletePlatfromContent({
                    variables: { id: platformId },
                    
                    update: onDeletePlatformContent(parentId, () => {
                      console.log("deleteResult______",deleteResult)
                      if (deleteResult.error) {
                        onOpen('Error!', 'error');
                      } else {
                        onOpen('Content DELETED', 'notification');
                        if (shouldCloseAfterDelete) {
                          history.push(location ? location.pathname : '/');
                        } else {
                          updateLocalizationsAfterDelete();
                          
                          onCloseDialog();
                          history.go(0);
                        }
                       // onOpen('Content DELETED', 'notification');
                        //window.history.go();
                      }
                    }),
                  });
                 }catch(e){
                  onOpen(e instanceof Error ? e.message : "", 'error');
                  onCloseDialog();
                  console.log(e instanceof Error ? e.message : "")
                 }
                 

                  
                }}
              >
                I’m sure, delete it
              </Button>
            )}
          </DeletePlatformMutation>
        )}
      </FeedbackContext.Consumer>
    )}
  </CurrentMediaPageLocationContext.Consumer>
);

export default withRouter<Props & RouteComponentProps<{}>, any>(DeletePlatformButton);
