import * as React from 'react';
import { FieldWrapper } from './styles';
import { Select } from '@freeda/react-components';
//import { ListBrandCategories } from '../../queries/queries2.0/ListBrandCategories';

import { ListMacroTopic } from '../../queries/queries2.0/ListMacroTopic';


interface Props {
  selectedMacroTopicName: string | null;
  onChangeMacroTopicName: (id: string | null) => void;
  style?: React.CSSProperties;
  label?: string;
  isClearable?: boolean;
  disabled?: boolean;
}

const SelectMacroTopic: React.FC<Props> = ({
  selectedMacroTopicName,
  onChangeMacroTopicName,
  style,
  label,
  isClearable,
  disabled
}) => {
  return (
    <ListMacroTopic>
      {listMacroTopicResult => {
        const dataNotAvailable =
        listMacroTopicResult.loading ||
          !listMacroTopicResult.data ||
          Object.keys(listMacroTopicResult.data).length === 0;

        const macroTopics = dataNotAvailable
          ? []
          : listMacroTopicResult.data!.listMacroTopic;

        const options = macroTopics.map(c => ({ value: c.name, label: c.name }));

        const selectedMacroTopic = options.find(bC => bC.value === selectedMacroTopicName);
        return (
          <FieldWrapper style={style}>
            <Select
              placeholder="Select macro topic"
              label={label}
              isDisabled={dataNotAvailable || disabled}
              style={{ padding: 0 }}
              optionsSort="asc"
              isClearable={isClearable}
              onChange={(option: any) => onChangeMacroTopicName(option ? option.value : null)}
              options={options}
              value={selectedMacroTopic ? selectedMacroTopic : null}
            />
          </FieldWrapper>
        );
      }}
    </ListMacroTopic>
  );
};

export { SelectMacroTopic };
