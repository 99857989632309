import * as React from 'react';
import { Query } from 'react-apollo';
import { GET_EDIT_FORM_INFO } from '../../../apollo/queries/queries2.0/GetEditFormInfo';
import GetEditInfoFormQuery from '../../../types/graphql/GetEditInfoFormQuery2.0';
import { CustomQueryProps } from '../../../types/graphql/utils';
//import clientTobe from '../../../apollo/clientTobe';

class TypedQuery extends Query<GetEditInfoFormQuery, {}> {}

export const GetEditFormInfoQuery: React.FC<CustomQueryProps<GetEditInfoFormQuery>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={GET_EDIT_FORM_INFO} {...others}>
      {result => {
       
        return children(result);
      }}
    </TypedQuery>
  );
};
