import ListContentQuery from '../../types/types2.0/graphql2.0/ListContentQuery';
import clientTobe from '../clientTobe';
import SearchMediaVariables from '../../types/types2.0/SearchMediaVariables';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';
import { CREATE_PARENT_CONTENT } from '../mutation/mutation2.0/CreateParentContent';
import { ITEMS_PER_PAGE } from '../../utils/constants';
import { LIST_CONTENT } from '../queries/queries2.0/ListContent';
import { LIST_MY_CONTENT } from '../queries/queries2.0/ListMyContent';
import { COUNT_NOT_COMPLETED_CONTENT } from '../queries/queries2.0/CountNotCompletedContent';
//import { PlatformContent } from '../../types/types2.0/PlatformContent';
import { ParentContent } from '../../types/types2.0/ParentContent';

const createParentContent = (
  filterVariables: SearchMediaVariables,
  allContentPageFetchedItems: number,
  myMediaPageFetchedItems: number
) => (title: string, mimeType: string, sources: string, videoLength:number|null) =>
  
clientTobe.mutate({
    mutation: CREATE_PARENT_CONTENT,
    variables: { title, mimeType, sources , videoLength},
    refetchQueries: [
      {
        query: COUNT_NOT_COMPLETED_CONTENT
      }
    ],
    update: (cache: DataProxy, result: FetchResult<{ createParentContent: ParentContent }>) => {
      
      const updateContentLists = (query: any, fetchedItemsCounter: number) => {
        const q = {
          query,
          variables: {
            skip: 0,
            first: ITEMS_PER_PAGE + fetchedItemsCounter,
            ...filterVariables
          }
        };

        try {
          const cachedQuery = cache.readQuery<ListContentQuery>(q)!;
          //console.log('cachedQuery*****', cachedQuery)
         // console.log('cachedQuery*****', result.data!.createParentContent)
         
         //result.data!.createParentContent.completed=false
          
         const updatedQuery = {
            listParentContents: {
              ...cachedQuery.listParentContents,
              
              edges: [
                {
                  node: { ...result.data!.createParentContent },
                },
                ...cachedQuery.listParentContents.edges
              ]
            }
          };

          cache.writeQuery({
            ...q,
            data: updatedQuery
          });
        } catch (error) {
          console.warn('No query found.');
        }
      };

      updateContentLists(LIST_CONTENT, allContentPageFetchedItems);
      updateContentLists(LIST_MY_CONTENT, myMediaPageFetchedItems);
    }
  });

export { createParentContent };
