import * as React from 'react';
import { StoriesWrapper, FrameWrapper } from './styles';
import { FramePreview } from '../FramePreview';
//import { MediaItem } from '../../../types/MediaItem';
import { PlatformContent } from '../../../types/types2.0/PlatformContent';

import { sortBy } from 'lodash';
import { Typography } from '@freeda/react-components';

interface Props {
  platformContent: PlatformContent | null;
  selectedComponentPosition: number | null;
  isIgStories: boolean | null;
  onSelectComponent: (framePosition: number | null) => void;
}

const FramesSelection: React.FC<Props> = ({ platformContent, onSelectComponent, selectedComponentPosition, isIgStories }) => {
  const story = platformContent ;
  const components = platformContent ? sortBy(platformContent.components!, 'position') : [];

  return (
    <StoriesWrapper>
      <FrameWrapper
        isselected={String(selectedComponentPosition === null)}
        onClick={() => onSelectComponent(null)}
      >
          <FramePreview
            mimeType={story? story.mimeType : ""}
            frameUrl={{
              storagePath: story ? story.storagePath : "",
              thumbnailUrl: story ? story.thumbnailUrl : ""
            }}
          />
          {isIgStories && (
            <Typography variantName="italic-label" style={{ paddingTop: 10, fontSize: 10 }}>
              Stories
            </Typography>
          )}
          {!isIgStories && (
            <Typography variantName="italic-label" style={{ paddingTop: 10, fontSize: 10 }}>
              Cover
            </Typography>
          )}
      </FrameWrapper>
     
     {components.map(component => (
        <FrameWrapper
          key={component.id}
          isselected={String(selectedComponentPosition === component.position)}
          onClick={() => onSelectComponent(component.position)}
        >
          <FramePreview
            mimeType={component.mimeType}
            frameUrl={{
              storagePath: component.storagePath,
              thumbnailUrl: component.thumbnailUrl
            }}
          />
          <Typography variantName="italic-label" style={{ paddingTop: 10, fontSize: 10 }}>
            <>{components.indexOf(component) + 1}</>
          </Typography>
        </FrameWrapper>
      ))}
    </StoriesWrapper>
  );
};

export { FramesSelection };
