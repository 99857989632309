import * as React from 'react';
import { Router, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { createBrowserHistory } from 'history';

import PrivateRoute from './components/PrivateRoute';
import { FileUploadsDialog } from './components/layout/FileUploadsDialog';

import { upload } from './api/upload';
import { WithSidebar } from './containers/WithSidebar2.0';
import { WithDropzone } from './containers/WithDropzone2.0';

import { FeedbackContext } from './providers/FeedbackProvider';


import { ShareMediaDialogContext } from './providers/ShareDialogProvider2.0';
import { ShareMediaDialogContainer } from './containers/ShareMediaDialog2.0';

import { LastMediaPageLocationProvider } from './providers/LastMediaPageLocationProvider';
import { ProvidersWrapper } from './providers/ProvidersWrapper';
import SearchMediaVariables from './types/types2.0/SearchMediaVariables';
import { createParentContent } from './apollo/utils/createContent';
import auth from './utils/auth';
import { getRoutes } from './routes2.0';
import { CurrentMediaPageLocationProvider } from './providers/CurrentMediaPageLocationProvider';
import { ParentContent } from './types/types2.0/ParentContent';
import { ParentContentSelection } from './types/types2.0/ParentContentSelection';
import { FetchedItemsCounterContext } from './providers/FetchedItemsCounterProvider';

import { DownloadMediaDialogContext } from './providers/DownloadDialogProvider2.0';
import { DownloadMediaDialogContainer } from './containers/DownloadMediaDialog2.0';


import { DownloadMultiMediaDialogContext } from './providers/DownloadMultiDialogProvider2.0';
import { DownloadMultiMediaDialogContainer } from './containers/DownloadMultiMediaDialog2.0';

import { Snackbar } from '@freeda/react-components';
import { FilePreview } from './types/FilePreview';
import { randomEmoji } from './components/layout/Sidebar/emoji';
import { FileMultipartsUploadDialog } from './components/layout/FileMultipatrsUploadsDialog';
import { ErrorsUploadDialog } from './components/layout/ErrorUploadsDialog';

const history = createBrowserHistory();

interface State {
  showUploadDialog: boolean;
  showUploadCompressDialog: boolean;
  showUploadError: boolean;
  selected: Array<ParentContentSelection>;
  previews: Array<FilePreview>;
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    localStorage.setItem('emoji', randomEmoji());

    this.state = {
      showUploadDialog: false,
      showUploadCompressDialog:false,
      showUploadError:false,
      previews: [],
      selected: [],
    };
  }

///QUI
  onUpload = (allContentPageFetchedItems: number, myMediaFetchedItemsCounter: number) => (
    variables: SearchMediaVariables
  ) => (files: Array<File>) => {
    this.setState({ showUploadDialog: true }, () => {
      if (files) {

        Array.from(files).map(async (file, i) => {  
          
          if(file.type==='image/vnd.adobe.photoshop' ||  file.type.indexOf('audio') >= 0){
            this.setState({ showUploadDialog: false, showUploadCompressDialog:false, showUploadError:true })
            return
          }

          if(file.size > parseInt( process.env.REACT_APP_FILE_SIZE! )){
            this.setState({ showUploadDialog: false, showUploadCompressDialog:true, showUploadError:false})
          }
          await upload(
            file,
            createParentContent(variables, allContentPageFetchedItems, myMediaFetchedItemsCounter),
            (percentage) => {
              this.setState((state) => {
                const filePreview: FilePreview = state.previews[i];
                filePreview.progress = percentage;
                
                return {
                  previews: [
                    ...state.previews.slice(0, i),
                    filePreview,
                    ...state.previews.slice(i + 1),
                  ],
                };
              });
            },
            (preview: FilePreview) => {
              this.setState((state) => ({
                previews: [preview, ...state.previews],
              }));
            }
          );
        });
      }
    });
  };

  onCardSelected = (parentContent: ParentContent) => {
    let isSelected = this.state.selected.find((media) => media.id === parentContent.id);
    let parentContentSelection: ParentContentSelection = {
      id: parentContent.id,
      title: parentContent.title,
      localizations: parentContent.localizations.map((t) => ({
        distributionCountry: t.distributionCountry,
        platforms: t.platforms.map((i) => ({
          url: i.storagePath,
          mimeType: i.mimeType,
          itemType: i.itemType,
          storagePath:i.storagePath,
          thumbnailUrl: i.thumbnailUrl,
          title:i.title,
          
          components: i.components?.map((c) => ({
            url: c.storagePath,
            thumbnailUrl:  c.thumbnailUrl,
            title:  c.title

           
          })),
        })),
      })),
    };

    this.setState({
      selected: isSelected
        ? this.state.selected.filter((media) => media.id !== parentContent.id)
        : [...this.state.selected, parentContentSelection],
    });
  };

  
  deselectAll = () => this.setState({ selected: [] });

  render() {
    const { selected } = this.state;

    const routes = getRoutes(selected, this.onCardSelected, this.deselectAll);

    return (
      <ProvidersWrapper>
        <FetchedItemsCounterContext.Consumer>
          {({
            allMediaPageFetchedItems,
            allContentPageFetchedItems,
            myMediaPageFetchedItems,
            myPinPageFetchedItems,
            updateMediaFetchedCounter,
          }) => (
            <Router history={history}>
              <LastMediaPageLocationProvider>
                <CurrentMediaPageLocationProvider>
                  <Switch>
                    {routes.map((route) => {
                      if (route.private) {
                        let component = route.component;
                        if (route.showSidebar) {
                          component = WithSidebar(
                            this.onUpload(allContentPageFetchedItems, myMediaPageFetchedItems),
                            component!
                          );
                        }

                        if (route.withDropzone) {
                          component = WithDropzone(
                            this.onUpload(allContentPageFetchedItems, myMediaPageFetchedItems),
                            component!
                          );
                        }

                        return (
                          <PrivateRoute
                            key={route.path}
                            exact={route.exact}
                            path={route.path}
                            isAuthenticated={auth.isAuthenticated}
                            Component={component!}
                          />
                        );
                      }

                      return (
                        <Route
                          key={route.path}
                          exact={route.exact}
                          path={route.path}
                          render={
                            route.component instanceof Function
                              ? route.component
                              : () => route.component
                          }
                        />
                      );
                    })}
                  </Switch>
                  <FileUploadsDialog
                    open={this.state.showUploadDialog}
                    onClose={() => this.setState({ showUploadDialog: false })}
                    filePreviews={this.state.previews}
                  />
                   <FileMultipartsUploadDialog
                    open={this.state.showUploadCompressDialog}
                    onClose={() => this.setState({ showUploadCompressDialog: false })}
                    filePreviews={this.state.previews}
                  />
                 <ErrorsUploadDialog
                      open={this.state.showUploadError}
                      onClose={() => this.setState({ showUploadError: false })}
                 />
                  
                  <ShareMediaDialogContext.Consumer>
                    {({ showDialog, onClose, mediaId, showCountryCutItem, idPlatformContent }) =>
                      mediaId && (
                        <ShareMediaDialogContainer
                          contentId={mediaId}
                          open={showDialog}
                          onClose={onClose}
                          showCountryCutItem={showCountryCutItem}
                          idPlatformContent={idPlatformContent}
                        />
                      )
                    }
                  </ShareMediaDialogContext.Consumer>
                  
                  <DownloadMediaDialogContext.Consumer>
                    {( { showDialog, onClose, mediaId }) =>
                      mediaId && (
                        <DownloadMediaDialogContainer
                          contentId={mediaId}
                          open={showDialog}
                          onClose={onClose}
                        />
                      )
                    }
                  </DownloadMediaDialogContext.Consumer>
                
                  <DownloadMultiMediaDialogContext.Consumer>
                    {({ showDialog, onClose, contents }) =>
                      
                      contents.length > 0 && (
                        <DownloadMultiMediaDialogContainer
                          contents={contents}
                          open={showDialog}
                          onClose={onClose}
                        />
                      )
                    }
                  </DownloadMultiMediaDialogContext.Consumer>


                  <FeedbackContext.Consumer>
                    {({ message, messageType, open, onClose }) => (
                      <Snackbar
                        typology={messageType}
                        open={open}
                        onClose={onClose}
                        label={message}
                      />
                    )}
                  </FeedbackContext.Consumer>
                </CurrentMediaPageLocationProvider>
              </LastMediaPageLocationProvider>
            </Router>
          )}
        </FetchedItemsCounterContext.Consumer>
      </ProvidersWrapper>
    );
  }
}

export default App;
