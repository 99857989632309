import * as React from 'react';
import styled from 'styled-components';
import Media from 'react-media';

//import { MediaCard } from '../types/MediaCard';
//import MediaCard from '../../src/components/layout/MediaCard';

import { ContentCard } from '../types/types2.0/ContentCard';
import ActionsBar from '../components/layout/ActionsBar2.0';
import MediaList from '../components/layout/MediaList2.0';
import { OrderStuffWrapper } from '../components/layout/Filter2.0/styles';
//import { MediaRoot } from '../types/MediaRoot';
import { ParentContent } from '../types/types2.0/ParentContent';
import { ParentContentSelection } from '../types/types2.0/ParentContentSelection';

import { DateFilter } from '../components/layout/DateFilter';
import { FilterContext } from '../providers/FilterProvider2.0';
import { FilterContainer } from '../containers/Filter2.0';
//import { TeamMember } from '../types/TeamMember';
import { User } from '../types/types2.0/User';
import { PlaceholderListMedia } from '../components/ui/Placeholders/PlaceholderListMedia';
import { PlaceholderListMediaMobile } from '../components/ui/PlaceholdersMobile/PlaceholderListMediaMobile';
import { LinearProgress } from '@freeda/react-components';
import { CSSTransition } from 'react-transition-group';
import { Header } from '../components/ui/Header';

//import { CountrySelectionWrapper } from '../components/layout/MobileMediaPreview/styles';


const MediaListWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const OrderInfo = styled.div`
  font-style: italic;
  font-size: 12px;
  color: #999999;

  @media (max-width: 768px) {
    display: none;
  }
`;

const DateFilterWrapper = styled.div`
  flex-basis: 20%;

  @media (max-width: 768px) {
    flex-grow: 1;
    margin: 0 20px;
  }
`;

interface Props {
  loading: boolean;
  media: Array<ContentCard>;
  users: Array<User>;
  renderWarning?: () => JSX.Element;
  selectedContent: Array<ParentContentSelection>;
  orderInfo: string;
  hasNextPage: boolean;
  onCardSelected: (parentContent: ParentContent) => void;
  deselectAll: () => void;
  onLoadMore: () => void;
  onOpenMultiDownload: () => void;
  cleanList:boolean;
}

interface State {
  page: null;
  showPlaceholder: boolean;
}

class MediaPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      page: null,
      showPlaceholder: true
    };
    

  }

  componentDidMount() {
    document.body.addEventListener('scroll', this.onScroll, true);
    window.addEventListener('beforeunload', this.removeScroll, true);
    document.body.scrollTo(0, Number(localStorage.scrollTop));
  }

  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.onScroll, true);
    window.removeEventListener('beforeunload', this.removeScroll, true);
  }

  removeScroll = () => localStorage.removeItem('scrollTop');
  
  

  onScroll = () => {
    const scrollTop = document.documentElement!.scrollTop || document.body.scrollTop;
    localStorage.setItem('scrollTop', String(scrollTop));
   
    const lastItem = document.querySelectorAll('[data-scroll="media-card"]:last-child').item(0);
    if (!lastItem) {
      return;
    }

    const lastItemBottom = lastItem.getBoundingClientRect().bottom;
    const viewportHeight = Math.max(
      document.documentElement!.clientHeight,
      window.innerHeight || 0
    );
    const offset = lastItemBottom - viewportHeight;
    if ( /*offset > 0 &&*/ offset < 150  && !this.props.loading && this.props.hasNextPage ) {
        this.props.onLoadMore();
    }

  };
  render() {
    const {
      media,
      users,
      renderWarning,
      selectedContent,
      onOpenMultiDownload,
      onCardSelected,
      deselectAll,
      orderInfo,
      loading,
      cleanList
    } = this.props;

    const { showPlaceholder } = this.state;
    
    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <div style={{ height: '100%' }}>
            <Header />
            <FilterContainer />
            <OrderStuffWrapper>
              <OrderInfo>{orderInfo}</OrderInfo>
              <DateFilterWrapper>
                <FilterContext.Consumer>
                  {({ callbacks, filterValue }) => (
                    <DateFilter
                      onChangeUpdateDateRange={callbacks.onChangeUpdateDateRange}
                      dateRange={filterValue.updateDateRange}
                    />
                  )}
                </FilterContext.Consumer>
              </DateFilterWrapper>
            </OrderStuffWrapper>
            {renderWarning && <div style={{ marginTop: 2 }}>{renderWarning()}</div>}
            
            <MediaListWrapper
              style={{
                marginLeft: showPlaceholder && loading && matches ? 0 : '',
                marginRight: showPlaceholder && loading && matches ? 0 : ''
              }}
            >
              {showPlaceholder && loading && !matches && <PlaceholderListMedia />}
              {showPlaceholder && loading && matches && <PlaceholderListMediaMobile />}
              <MediaList
                media={media}
                users={users}
                selectedContent={selectedContent}
                hightlightNotCompleted={Boolean(renderWarning)}
                onCardSelected={onCardSelected}
                loading={loading}
                cleanList={cleanList}
              />
            </MediaListWrapper>
            <CSSTransition
              in={selectedContent.length > 0}
              timeout={{ enter: 200, exit: 300 }}
              classNames=""
              unmountOnExit
            >
              {status => (
                <ActionsBar
                  selectedContent={selectedContent}
                  deselect={deselectAll}
                  onOpenMultiDownload={onOpenMultiDownload}
                  transitionStatus={status}
                />
              )}
            </CSSTransition>

            {loading && (
              <div style={{ position: 'fixed', width: '100%', right: 0, bottom: 0 }}>
                <LinearProgress fixedBottom />
              </div>
            )}
          </div>
        )}
      </Media>
    );
  }
}

export default MediaPage;
