import * as React from 'react';
import { FieldWrapper } from './styles';
import { Select } from '@freeda/react-components';
import {ListMetadataQuery} from '../../queries/queries2.0/ListMetadata';
import { MetadataType } from '../../../types/MetadataType';
import { Brand } from '../../../types/types2.0/Brand';

interface Props {
  selectedBrand: string | null;
  onChangeBrand: (id: string | null) => void;
  style?: React.CSSProperties;
  label?: string;
  isClearable?: boolean;
  disabled?: boolean;
}

const SelectBrand: React.FC<Props> = ({
  selectedBrand,
  onChangeBrand,
  style,
  label,
  isClearable,
  disabled
}) => {
  return (
    <ListMetadataQuery metadataType={MetadataType.BRANDS}>
      {listBrandResult  => {
        const dataNotAvailable =
        listBrandResult.loading ||
          !listBrandResult.data ||
          Object.keys(listBrandResult.data).length === 0;
          let brands: Array<Brand> = dataNotAvailable
          ? []
          : ( listBrandResult.data![`listBrands`] );

        const options = brands.map(c => ({ value: c.id, label: c.name }));
        const selectedBrandValue = options.find(bC => bC.value === selectedBrand);
        return (
          <FieldWrapper style={style}>
            <Select
              placeholder="Select brand"
              label={label}
              isDisabled={dataNotAvailable || disabled}
              style={{ padding: 0 }}
              optionsSort="asc"
              isClearable={isClearable}
              onChange={(option: any) => onChangeBrand(option ? option.value : null)}
              options={options}
              value={selectedBrandValue ? selectedBrandValue : null}
            />
          </FieldWrapper>
        );
      }}
    </ListMetadataQuery>
  );
};

export { SelectBrand };
