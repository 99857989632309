import * as React from 'react';
import { Header } from '../components/ui/Header';
import { Typography, DividerSection, ButtonIcon, Snackbar } from '@freeda/react-components';
import styled from 'styled-components';
import { transparentScrollbar } from '../components/ui/ScrollBarStyle';
import { StateButton } from '../components/ui/StateButton';
import { MetadataType } from '../types/MetadataType';
import { MetadataTable } from '../containers/MetadataTable2.0';
import { getFilterContext, buildMetadataQueryVariables} from '../utils/metadataUtils2.0';
import { MetadataFilter } from '../components/layout/MetadataFilter';
import { ListMetadataQuery } from '../components/queries/queries2.0/ListMetadata';
//import { Serie } from '../types/types2.0/Serie';
//import { Topic } from '../types/types2.0/Topic';
import { Brand } from '../types/types2.0/Brand';
import { Label } from '../types/types2.0/Label';
import { ProductType } from '../types/types2.0/ProductType';


import { CreateMetadataDialog } from '../components/layout/CreateMetadataDialog2.0';
import { CSSTransition } from 'react-transition-group';
import { EditMetadataPanel } from '../components/layout/EditMetadataPanel2.0';
import { useMediaQuery } from 'react-responsive';
import { SidebarContext } from '../providers/SidebarProvider';
//import { CreateMetadataDialogSteps } from '../components/layout/CreateMetadataDialog2.0/CreateMetadataDialogSteps';
import {
  buildMetadataTableHead,
  buildMetadataTableColumns,
  getMetadataTableFilter,
  getMetadataTableFilterNoCreation
} from '../utils/metadataTableUtils';
import { useWindowSize } from '../hooks/useWindowSize';
import { Colors } from '@freeda/react-components/lib/theme';
import { useHistory } from 'react-router-dom';
import { DeleteLabelDialog } from '../components/layout/DeleteLabelDialog';
//import { ClearFilterWrapper } from '../components/layout/Filter/styles';
//import { Format } from '../types/types2.0/Format';



const WrapperStateButton = styled.div`
  overflow-x: scroll;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  ${transparentScrollbar};
`;

const ArrowUp = styled(ButtonIcon)`
  position: absolute;
  right: 30px;
  bottom: 20px;
  background: ${(props) => props.theme.primary};

  & > i {
    font-size: 25px !important;
  }

  transition: opacity 0.3s;

  &.entering {
    opacity: 0;
  }
  &.entered {
    opacity: 0.7;
  }
  &.exiting {
    opacity: 0;
  }
  &.exited {
    opacity: 0;
  }
`;

export const WrapperTableFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0px;
  justify-content: space-between;
`;

const MetadataPage: React.FC = () => {
  const history = useHistory();

  const { windowHeight } = useWindowSize();

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { activeMetadataTable, onChangeMetadataTable } = React.useContext(SidebarContext);

  const [openCreateMetadataDialog, setOpenCreateMetadataDialog] = React.useState(false);
  const [openDeleteLabelDialog, setOpenDeleteLabelDialog] = React.useState(false);


  const [selectedMetadataId, setSelectedMentadataId] = React.useState<string | null>(null);
  const [openEditPanel, setOpenEditPanel] = React.useState<boolean>(false);
  const [openSnackbarReload, setOpenSnackbarReload] = React.useState<boolean>(false);
  const [showScrollTopButton, setShowScrollTopButton] = React.useState<boolean>(false);

  const metadataFilterContext = getFilterContext(activeMetadataTable);
  
  const {
    searchValue,
    selectedBrandCategoryName,
    selectedMacroTopicName,
    selectedBrand,
    order,
    orderBy,
    selectedProjectIds,
    clearFilter
  } = React.useContext(metadataFilterContext);

  /*const { openCreateMetadataDialogSteps, handleCreateMetadataDialog } = React.useContext(
    SidebarContext
  );*/

  const onOpenCreateMetadataDialog = () => setOpenCreateMetadataDialog(true);
  const onCloseCreateMetadataDialog = () => setOpenCreateMetadataDialog(false);

  const onOpenEditMetadata = (metadataId: string) => {
    setSelectedMentadataId(metadataId);
    setOpenEditPanel(true);
  };


  const onDeleteLabelDialog = (metadataId: string) => {
    setOpenDeleteLabelDialog(true)
    setSelectedMentadataId(metadataId);
  };

  const onCloseDeleteDialog = () => setOpenDeleteLabelDialog(false)


  let variables = buildMetadataQueryVariables(

    activeMetadataTable,
    searchValue,
    selectedBrandCategoryName,
    selectedMacroTopicName,
    selectedBrand,
    selectedProjectIds,
    order,
    orderBy
  );


  React.useEffect(() => {
    
    const onListScroll = (_: Event) => {
      setShowScrollTopButton(document.body.scrollTop > windowHeight / 2);
    };

    document.body.addEventListener('scroll', onListScroll);
    return () => {
      document.body.removeEventListener('scroll', onListScroll);
    };
  }, []);

  const onScrollUp = () => {
    document.body.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };



  const head = buildMetadataTableHead(activeMetadataTable);
  const columns = buildMetadataTableColumns(activeMetadataTable, onOpenEditMetadata, onDeleteLabelDialog);
  //console.log( window.location.pathname)
 
  return (
      <ListMetadataQuery metadataType={activeMetadataTable} variables={variables} /*fetchPolicy="cache-first"*/>
      
      
      {(listMetadataQueryResult) => {
       
        if (listMetadataQueryResult.error) {
          return `Error!! ${listMetadataQueryResult.error.message}`;
          
        }
        
        const dataNotAvailable =
          listMetadataQueryResult.loading ||
          !listMetadataQueryResult.data ||
          Object.keys(listMetadataQueryResult.data).length === 0;
        


        let allMetadata: Array<Brand | Label | ProductType> = dataNotAvailable
          ? []
          : ( activeMetadataTable==MetadataType.BRANDS ? listMetadataQueryResult.data![`list${String(activeMetadataTable)}`] 
          : (activeMetadataTable==MetadataType.FORMATS ?  listMetadataQueryResult.data![`listProductTypes`] : listMetadataQueryResult.data![`listLabels`]))
        
        //console.log('allMetadata ____', listMetadataQueryResult.data!)

        let count: number = 0;
        if (allMetadata && allMetadata.length > 0) {
          count = allMetadata.length;
        }
        let tableFilter = getMetadataTableFilter(count, onOpenCreateMetadataDialog);

        if(activeMetadataTable == MetadataType.FORMATS || activeMetadataTable == MetadataType.BRANDS || activeMetadataTable == MetadataType.CAMPAIGNS){
          tableFilter = getMetadataTableFilterNoCreation(count, onOpenCreateMetadataDialog);
        }

        const selectedMetadata = allMetadata.find((m) => m.id === selectedMetadataId);
        return (
          <>
            {openCreateMetadataDialog && (
              <CreateMetadataDialog
                metadataType={activeMetadataTable}
                open={openCreateMetadataDialog}
                onClose={onCloseCreateMetadataDialog}
                onOpenSnackbarReload={() => setOpenSnackbarReload(true)}
              />
            )}

            {openDeleteLabelDialog && (
                <DeleteLabelDialog
                        labelId={selectedMetadataId!}
                        open={openDeleteLabelDialog}
                        onClose={onCloseDeleteDialog}
                        
                        onOpenSnackbarReload={() => setOpenSnackbarReload(true)}
                />
            )}

            <Snackbar
              style={{ left: 90 }}
              typology="notification"
              open={openSnackbarReload}
              label={
                <span
                  style={{
                    fontSize: 13,
                    display: 'flex',
                    alignItems: 'center',
                    color: Colors.PURPLE,
                  }}
                >
                  {activeMetadataTable} table has been updated: &nbsp;
                  <span
                    onClick={() => window.location.reload()}
                    style={{
                      fontWeight: 700,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Reload
                  </span>
                </span>
              }
            />
           
            
            <CSSTransition in={openEditPanel} unmountOnExit timeout={{ enter: 300, exit: 500 }}>
              {(status) => (
                <EditMetadataPanel
                  open={openEditPanel}
                  onClose={(isEdited) => {
                    setOpenEditPanel(false);
                    if (isEdited) {
                      setOpenSnackbarReload(true);
                    }
                  }}
                  metadataType={activeMetadataTable}
                  selectedMetadata={selectedMetadata!}
                  status={status}
                />
              )}
            </CSSTransition>
            <div style={{ height: '100%', position: 'relative' }} id={'content'}>
              <Header style={{ top: 0, right: -15 }} />
              <Typography
                variantName="title"
                style={{
                  padding: isMobile ? 15 : '',
                  paddingBottom: isMobile ? 0 : '',
                }}
              >
                Labels
              </Typography>
              <div style={{ marginBottom: isMobile ? 0 : 20 }}>
                <DividerSection style={{ position: 'relative', top: 42 }} />
                <WrapperStateButton>
                  {Object.values(MetadataType).map((c, index) => {
                    return (
                      <StateButton
                        key={index}
                        isActive={activeMetadataTable === c}
                        flat
                        onClick={() => {
                          clearFilter()
                          onChangeMetadataTable(c);
                          setOpenSnackbarReload(false);
                          history.push(c.toLowerCase());
                          
                        }}
                      >
                        {c==MetadataType.EDITORIAL_CAMPAIGNS? 'Editorial campaigns': c}
                      </StateButton>
                    );
                  })}
                </WrapperStateButton>
              </div>
              <MetadataFilter  activeMetadataTable={activeMetadataTable} />
              
              <MetadataTable
                head={head}
                columns={columns}
                TableFilter={tableFilter}
                data={allMetadata}
                loadingData={listMetadataQueryResult.loading}
                count={count}
                FilterContext={metadataFilterContext}
              />
            </div>
            <CSSTransition
              in={showScrollTopButton}
              timeout={{ enter: 100, exit: 400 }}
              unmountOnExit
            >
              {(status) => (
                <ArrowUp
                  className={status}
                  iconName="arrow-up"
                  iconColor="white"
                  iconSize="small"
                  onClick={onScrollUp}
                />
              )}
            </CSSTransition>
          </>
        );
      }}
    </ListMetadataQuery>
  );
};

export { MetadataPage };
