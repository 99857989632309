import gql from 'graphql-tag';

const UPDATE_LABEL = gql`
    mutation updateLabel(
      $id: ID!
      $name: String!
      $category: LabelCategory!
      $macroTopic: String
      $project: [ProjectInput]

    ) {
      updateLabel(id: $id, name: $name, category: $category, macroTopic: $macroTopic, projectList: $project) {
        id
        name
        macroTopic
        project {
          id
          name
        }
        brand{
          id
          name
        }
      
      }
    }
`;

export { UPDATE_LABEL };

/**const UPDATE_SERIE = gql`
  mutation updateSerie(
    $id: ID!
    $name: String!
    $project: [ProjectInput]

  ) {
    updateSerie(id: $id, name: $name, project: $project) {
      id
      name
      project {
        id
        name
      }
     
    }
  }
`;

const UPDATE_TOPIC = gql`
  mutation updateTopic($id: ID!, $name: String!, $project: [ProjectInput]) {
    updateTopic(id: $id, name: $name, project: $project) {
      id
      name
      project {
        id
        name
      }
    }
  }
`;

const UPDATE_BRAND = gql`
  mutation updateBrand(
    $id: ID!
    $name: String
    $brand_category: String
  ) {
    updateBrand(id: $id, name: $name, brand_category: $brand_category) {
      id
      name
      brand_category
    }
  }
`; */