import * as React from 'react';
import { Mutation } from 'react-apollo';
import { CustomMutationProps } from '../../../types/graphql/utils';
import { ComponentContent } from '../../../types/types2.0/ComponentContent';
import { MASSIVE_UPDATE_COMPONENT_CONTENTS_POSITION } from '../../../apollo/mutation/mutation2.0/MassiveUpdateComponentContentsPositions';

class TypedMutation extends Mutation<{
  massiveUpdateComponentContentsPosition: Array<ComponentContent>;
}> {}

export const MassiveUpdateComponentPositions: React.FC<
  CustomMutationProps<{ massiveUpdateComponentContentsPosition: Array<ComponentContent> }>
> = ({ children, ...others }) => {
  return (
    <TypedMutation mutation={MASSIVE_UPDATE_COMPONENT_CONTENTS_POSITION} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
