import * as React from 'react';
import { Input } from '@freeda/react-components';

import { ListMediaWrapper } from '../styles';
import { WrapperStep } from './styles';
import { ListMediaToMerge } from '../../../../../../containers/ListMediaToMerge2.0';
import { ParentContent } from '../../../../../../types/types2.0/ParentContent';
import { Country } from '../../../../../../types/graphql/Country';
import MergeMediaButton from '../../../../../../containers/MergeMediaButton2.0';
import { EditStep } from '../../../../../../pages/EditMediaPage2';

interface Props {
  toExclude: string;
  format:string;
  distributionCountry: Country | null;
  projectId: string;
  mediaDistributionCountries: Array<{ id: string; distributionCountry: Country }>;
  changeEditStep: (editStep: EditStep) => void;
  onAfterMerge: (newParent: ParentContent, newDistributionCountry: Country) => void;
  mimeType: string;
}

interface State {
  searchValue: string;
  selected: ParentContent | null;
}

class MergeMediaStep extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: '',
      selected: null
    };
  }

  onChangeSearchValue = (searchValue: string) => this.setState({ searchValue, selected: null });

  render() {
    const {
      toExclude,
      format,
      distributionCountry,
      projectId,
      mediaDistributionCountries,
      onAfterMerge,
      mimeType
    } = this.props;
    const { searchValue, selected } = this.state;

    
    return (

      <WrapperStep>
      <div style={{ width: 'calc(100% - 100px)' }}>
        <Input
          placeholder="Search by title or keywords"
          value={searchValue}
          onChange={e => this.onChangeSearchValue(e.target.value)}
          iconName="search"
        />
      </div>
      <ListMediaWrapper>
          {distributionCountry && (
            <ListMediaToMerge
                toExclude={toExclude}
                selected={selected ? selected.id : null}
                searchValue={searchValue}
                format={format}
                distributionCountry ={distributionCountry}
                projectId = {projectId}
                mimeType = {mimeType}
                onSelect={media =>
                  this.setState(state => ({
                    selected: state.selected && state.selected.id === media.id ? null : media
                  }))
                }
                parentFilter={(media: Array<ParentContent>) =>
                  media.filter(
                    parent =>
                      parent.localizations.length === 1 &&
                      parent.localizations[0].platforms.length === 1 /*&&
                      parent.localizations[0].platforms[0].mimeType === mimeType &&
                      (root.localizations[0].platforms[0].itemType.indexOf('VIDEO_') >= 0 ||
                        root.localizations[0].platforms[0].itemType.indexOf('IMAGE') >= 0)*/
                  )
                }
            />)
          }
      </ListMediaWrapper>
      <div style={{ display: 'flex', alignSelf: 'flex-end', marginTop: 35 }}>
        <MergeMediaButton
          mediaDistributionCountries={mediaDistributionCountries}
          selected={selected}
          toExclude={toExclude}
          distributionCountry={distributionCountry}
          onAfterMerge={onAfterMerge}
        />
      </div>
    </WrapperStep>
    
    
    );
  }
}

export { MergeMediaStep };
