import * as React from 'react';
import styled from 'styled-components';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

const StyledNavLink = styled(NavLink)<{ isopen: string } & NavLinkProps>`
  && {
    text-decoration: none;
    width: 100%;

    @media (min-width: 768px) {
      & > li > span {
        color: ${props => props.theme.darkPrimary};
        cursor: pointer;
      }
      &.active {
        & > li > span {
          color: ${props => props.theme.fontColors.white};
        }
        & > li > div > div > span {
          color: ${props => props.theme.fontColors.white};
        }
      }
    }
  }
`;

const RouterLink = styled(Link)`
  & > *,
  *:active {
    color: ${props => props.theme.primary};
  }
  text-decoration: none;
  background: transparent;
`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  background: transparent;
`;

const ActionButtonLink = styled(Link)`
  text-decoration: none;
  background: transparent;
  *:active {
    color: #ffffff;
  }
  & span {
    color: #ffffff;
  }
`;

const RouterNavLink: React.FC<{ isopen: string } & NavLinkProps> = props => (
  <StyledNavLink {...props as any} style={{ textDecoration: 'none' }} />
);

export { RouterLink, RouterNavLink, ButtonLink, ActionButtonLink };
