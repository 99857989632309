import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FrameWrapper } from './styles';

interface Props {
  draggableId: string;
  component: { id: string; content: any };
  index: number;
  isStory:boolean;
  isselected: string;
  onSelectComponent: () => void;
  numberFrame?: number;
  isDragDisabled?: boolean;
  style?: React.CSSProperties;
}

const Frame: React.FC<Props> = ({
  draggableId,
  component,
  index,
  isStory,
  numberFrame,
  isDragDisabled,
  isselected,
  onSelectComponent,
  style
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}>
      <Draggable
        draggableId={draggableId}
        index={index}
        disableInteractiveElementBlocking
        isDragDisabled={isDragDisabled}
      >
        {provided => (
          <FrameWrapper
            ref={provided.innerRef as any}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isselected={isselected}
            onClick={() => onSelectComponent()}
          >
            {component.content}
          </FrameWrapper>
        )}
      </Draggable>
      {isStory && numberFrame && <span style={{ fontSize: '10px', position: 'relative' }}>{numberFrame}</span>}
    </div>
  );
};

export { Frame };
