import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dialog, Typography, Button } from '@freeda/react-components';
import { Dialogable } from '../../../types/Dialogable';
import SaveMediaButton from '../../../containers/SaveMediaButton2.0';
import { DialogHeader } from '../../ui/DialogHeader';
import { Colors } from '@freeda/react-components/lib/theme';
import { ButtonWrapperDialog } from '../../ui/ButtonWrapperDialog';
import { EditStep } from '../../../pages/EditMediaPage2';

type Props = Dialogable & {
  onClickButton: string;
  newFileUploadProgress: number;
  text: string;
  goBack: boolean;
  changeEditStep: (editStep: EditStep) => void;
  onSave: (
    save: (
      rootOptions: any,
      alreadyExistingTranslationsOptions: any,
      alreadyExistingItemsOptions: any,
      newTranslationsOptions: any
    ) => void
  ) => void;
  isMediaChanged: boolean;
};

class SaveMediaDialog extends React.Component<Props & RouteComponentProps<{}>> {
  render() {
    const { open, onClose, newFileUploadProgress, text, goBack, changeEditStep, onSave, history, isMediaChanged } = this.props;
    if(!goBack) localStorage.setItem('merge', 'merge');

    return (
      <Dialog
        onClose={onClose}
        openDialog={open}
        rootIdAttributes="root"
        style={{ width: 350, paddingLeft: 30 }}
      >
        <DialogHeader title="Save content" onClose={onClose} />
        <Typography variantName="text" textAlign="left" textColor={Colors.GRIGIO}>
          {text}
        </Typography>
        <ButtonWrapperDialog>
          <Button action="undo" onClick={() => goBack? history.goBack() : changeEditStep('EDIT')}>
            Don't save
          </Button>
          <SaveMediaButton
            newFileUploadProgress={newFileUploadProgress}
            onSave={onSave}
            isMediaChanged={isMediaChanged}
          />
        </ButtonWrapperDialog>
      </Dialog>
    );
  }
}

const WithRouterSaveMediaDialog = withRouter<Props & RouteComponentProps<{}>, any>(SaveMediaDialog);

export { WithRouterSaveMediaDialog as SaveMediaDialog };