import * as React from 'react';
import { CountNotCompletedContentQuery } from '../components/queries/queries2.0/CountNotCompletedContentQuery';
import { WarningPanel } from '../components/ui/WarningPanel';


const WarningNotCompletedPanel: React.FC = () => (
  
  <CountNotCompletedContentQuery fetchPolicy="network-only">
    {({ loading, error, data }) => {
      if (loading) {
        return 'Loading...';
      }

      if (error) {
        return 'Error or no contents uploaded before';
      }

      const counter = data!.listParentContents.aggregate.count;


      if (counter === 0) {
        return null;
      }

      return <WarningPanel>{`You have ${counter} contents with missing labels`}</WarningPanel>;
    }}
  </CountNotCompletedContentQuery>
);

export { WarningNotCompletedPanel };
