import * as React from 'react';

import { Wrapper, Content, ContentWrapper } from '../styles';
import UploadInput from '../../../../../../components/ui/UploadInput';
import { EditStep } from '../../../../../../pages/EditMediaPage2';

interface Props {
  onAddTranslation: (file: File) => void;
  goToFileSelection: () => void;
  mimeType: string;
  changeEditStep: (editStep: EditStep) => void;
}


/*const getVideoDuration = (file: Blob) =>
  new Promise((resolve, reject) => {
    const previewUrl = URL.createObjectURL(file);
    const reader : FileReader= new FileReader();
    reader.onload = () => {
      const media = new Audio(previewUrl);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });*/




const UploadMediaStep: React.FC<Props> = ({
  onAddTranslation,
  goToFileSelection,
  changeEditStep,
  mimeType
}) => (
  <Wrapper>
    <ContentWrapper>
      <Content onClick={goToFileSelection}>
        <div>From Content Gallery</div>
      </Content>
      
      
      <Content>
        <div>From your computer</div>
        <UploadInput
          style={{ left: 0 }}
          accept={mimeType}
          type="file"
          
          onChange={e => {
            if (e.target.files) {
              const file = e.target.files[0];
              //check se è
             
              onAddTranslation(file);
              changeEditStep('EDIT');
            }
          }}
        />
      </Content>
    </ContentWrapper>
  </Wrapper>
);

export default UploadMediaStep;
