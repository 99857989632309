import gql from 'graphql-tag';

const LIST_PROJECT = gql`
  query listProjects ($where: MetadataWhereInput, $orderBy: MetadataOrderByInput){
    listProjects (where: $where, orderBy:$orderBy){
      id
      name
    }
  }
`;

export { LIST_PROJECT };
