import gql from 'graphql-tag';
//import { LOCAL_CONTENT } from '../../../apollo/queries/queries2.0/LocalContentFragment';

const UPDATE_LOCAL_CONTENT = gql`
  mutation updateLocalContent(
    $id: ID!,
    $members: UpdateObjectStructure, 
    $contributors: UpdateObjectStructure, 
    $distributionCountry: CountryEnumDao, 
    $noSubtitle: Boolean, 
    $languageSubtitle: LanguageEnumDao, 
    $languageCopy: LanguageEnumDao, 
    $updatedBy:ID!,
    $platforms: CreatePlatformInput
   
  ) {
    updateLocalContent(
      id: $id
      members: $members
      contributors: $contributors
      distributionCountry: $distributionCountry
      noSubtitle: $noSubtitle
      languageSubtitle: $languageSubtitle
      languageCopy: $languageCopy
      updatedBy: $updatedBy
      platforms: $platforms

    ) {
      id
    }
  }
 
`;

export { UPDATE_LOCAL_CONTENT };
