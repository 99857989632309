import * as React from 'react';
import Media from 'react-media';
import moment from 'moment';
import { Typography, ButtonIcon, Icon, Dialog, Button, DayPicker } from '@freeda/react-components';

import { Dialogable } from '../../../types/Dialogable';
import { UpdateDateRange } from '../../../types/UpdateDateRange';

interface Props {
  onApplyFilter: (dateRange: UpdateDateRange) => void;
  onChangeDate: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
  from: Date | null;
  to: Date | null;
}

const DateRangeFilterDialog: React.FC<Props & Dialogable> = ({
  open,
  onClose,
  onApplyFilter,
  onChangeDate,
  from,
  to
}) => {
  const selectedMonthAndYear = from ? moment(from) : null;

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <Dialog
          openDialog={open}
          onClose={onClose}
          rootIdAttributes="root"
          fullScreen={matches}
          style={{
            paddingBottom: 25,
            paddingLeft: 25,
            paddingRight: 25,
            height: !matches ? 530 : ''
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 15
            }}
          >
            <Typography variantName="subtitle" textColor="#6B20DA">
              Customised data range
            </Typography>
            <ButtonIcon onClick={onClose} style={{ width: 45, height: 45 }}>
              <Icon iconName="close" style={{ fontSize: 35 }} />
            </ButtonIcon>
          </div>
          <DayPicker
            selectedStartDate={from ? moment(from) : null}
            selectedEndDate={to ? moment(to) : null}
            selectedMonth={selectedMonthAndYear}
            selectedYear={selectedMonthAndYear}
            onChangeDateRange={(startDate, endDate) => onChangeDate(startDate, endDate)}
          />
          <div
            style={{
              display: 'flex',
              marginTop: 25,
              alignItems: 'center',
              position: 'absolute',
              bottom: 30,
              right: 23
            }}
          >
            <Button
              style={{ margin: 0 }}
              disabled={from === null && to === null}
              onClick={() => {
                onApplyFilter({ from, to });
                onClose();
              }}
            >
              Apply date filter
            </Button>
          </div>
        </Dialog>
      )}
    </Media>
  );
};

export { DateRangeFilterDialog };
