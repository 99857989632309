import * as React from 'react';
import styled from 'styled-components';

const TooltipArea = styled.div`
  width: 70%;
  height: calc(100vh - 100px);
  background: rgb(249, 249, 249);
  position: absolute;
  z-index: 1;
  opacity: 0.5;
  right: 26px;
  
  
`;

const DisabledLayer: React.FC<{ visible: boolean }> = ({ visible }) => {
  return <>{visible && <TooltipArea />}</>;
};

export { DisabledLayer };
