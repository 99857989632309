export enum ItemType {
  
  IMAGE='IMAGE',
  IMAGE_INSTAGRAM = 'IMAGE_INSTAGRAM',
  IMAGE_TIKTOK = 'IMAGE_TIKTOK',
  IMAGE_INSTAGRAM_CAROUSEL = 'IMAGE_INSTAGRAM_CAROUSEL',
  IMAGE_TIKTOK_CAROUSEL = 'IMAGE_TIKTOK_CAROUSEL',



  IMAGE_FACEBOOK = 'IMAGE_FACEBOOK',
  IMAGE_LINKEDIN = 'IMAGE_LINKEDIN',
  IMAGE_INSTANT_ARTICLE = 'IMAGE_INSTANT_ARTICLE',
  IMAGE_INSTAGRAM_STORY = 'IMAGE_INSTAGRAM_STORY',

  
  VIDEO_FACEBOOK = 'VIDEO_FACEBOOK',
  VIDEO_INSTAGRAM = 'VIDEO_INSTAGRAM',
  VIDEO_YOUTUBE = 'VIDEO_YOUTUBE',
  VIDEO_INSTAGRAM_TV = 'VIDEO_INSTAGRAM_TV',
  VIDEO_INSTAGRAM_STORY = 'INSTAGRAM_STORY',
  VIDEO_LINKEDIN = 'VIDEO_LINKEDIN',
  VIDEO_TIKTOK = 'VIDEO_TIKTOK',

  DEFAULT = 'IMAGE_INSTAGRAM'
}
