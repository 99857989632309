import * as React from 'react';
import styled from 'styled-components';
import { Dialog, Typography, ButtonIcon, Icon } from '@freeda/react-components';

import { Dialogable } from '../../../types/Dialogable';
import { FilePreview } from '../../../types/FilePreview';
const occhiali = require('../../../assets/occhiali.gif');

const WomanWrapper = styled.div`
  max-width: 550px;
  min-height: 247px;

  & > img {
    width: 100%;
    height: auto;
  }
`;

const ErrorsUploadDialog: React.FC<Dialogable> = ({
  open,
  onClose
}) => (
  <Dialog
    style={{ width: 550, maxHeight: 700 }}
    openDialog={open}
    onClose={onClose}
    rootIdAttributes="root"
    disableEscapeKeyUp
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        maxHeight: 60
      }}
    >
       
      <Typography variantName="subtitle" textColor="#6B20DA">
        File uploading
      </Typography>
     
      <ButtonIcon id="closeUpload" onClick={onClose}>
        <Icon iconName="close" style={{ fontSize: 35 }} />
      </ButtonIcon>
    </div>

    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        maxHeight: 60
      }}
    >
      <Typography variantName="text" textColor="red">
        ERROR: file format not supported in Peggy ! 
      </Typography>
    </div>


    <WomanWrapper>
      <img src={occhiali} />
    </WomanWrapper>
  
  </Dialog>
);

export { ErrorsUploadDialog };
