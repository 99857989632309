import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@freeda/react-components';

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Hr = styled.hr`
  width: 100%;
  border-bottom-style: solid;
  border-bottom: #c8c8c8;
  border-bottom-width: 1px;
  padding-left: 20px;
  position: relative;
  top: 1px;
`;

interface Props {
  label?: string;
  style?: React.CSSProperties;
}

const DividerSection: React.FC<
  Props & { style?: React.CSSProperties } & React.HTMLProps<HTMLDivElement>
> = ({ label, style, className }) => {
  return (
    <DividerWrapper style={style} className={className}>
      {label && (
        <Typography variantName="italic-label" style={{ marginRight: 10 }}>
          <>{label}</>
        </Typography>
      )}
      <Hr />
    </DividerWrapper>
  );
};

export { DividerSection };
