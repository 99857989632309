import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';



//const whitelist = ['mediaFromExternalLink'];
const https = require('https')
//let fetchOptions = { agent: new https.Agent({ rejectUnauthorized: true }) }

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL?.concat("graphql"),
  fetch: (uri: string, options: any) => {
    if (options) {
      //const { operationName } = JSON.parse(options.body as string);
      //if (!whitelist.find(o => o === operationName)) {
        const token = localStorage.getItem('access_token');
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`
        };
      //}
    }
    return fetch(uri, options);
  },
  fetchOptions: {
   agent: new https.Agent({
      rejectUnauthorized: false,
    }),
   
  },
  //credentials: 'same-origin'
});

const cache = new InMemoryCache();

const clientTobe = new ApolloClient({
  link: httpLink,
  //ssrMode: false,
  //connectToDevTools: true,
  cache,
  

});




export default clientTobe;
