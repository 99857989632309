import * as React from 'react';
import { PlaceholderMediaMobile } from './PlaceholderMediaMobile';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 15px;
  margin-bottom: 20px;
`;

const ListMediaWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
`;

const PlaceholderListMediaMobile: React.FC = () => (
  <Wrapper>
    <ListMediaWrapper>
      <PlaceholderMediaMobile />
      <PlaceholderMediaMobile />
      <PlaceholderMediaMobile />
      <PlaceholderMediaMobile />
      <PlaceholderMediaMobile />
      <PlaceholderMediaMobile />
    </ListMediaWrapper>
  </Wrapper>
);

export { PlaceholderListMediaMobile };
