import gql from 'graphql-tag';


const UPDATE_PLATFORM_CONTENT_PUBLICATION = gql`
      mutation updatePlatformContentPublication(
          $id: ID! 
          $repostCount: Int!
          $notes: String
          $caption: String
          $dueDate: ZonedDateTime
          $publicationUrl: [String]
          $withComponents: Boolean!
          
        ) {
          updatePlatformContentPublication(
            id: $id
            repostCount: $repostCount
            notes: $notes
            caption: $caption
            dueDate: $dueDate
            publicationURL: $publicationUrl
            withComponents: $withComponents
            
          ) {
            id
          }
      }
`;

export {UPDATE_PLATFORM_CONTENT_PUBLICATION };
