import * as React from 'react';
import { Query } from 'react-apollo';
import { CustomQueryProps } from '../../../types/graphql/utils';
import  ListProjectsQuery  from '../../../types/types2.0/graphql2.0/ListProjectsQuery';

import { LIST_PROJECT } from '../../../apollo/queries/queries2.0/ListProject';
//import clientTobe from '../../../apollo/clientTobe';
class TypedQuery extends Query<ListProjectsQuery, {}> {}

export const ListProjects: React.FC<CustomQueryProps<ListProjectsQuery>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={LIST_PROJECT} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
