import * as React from 'react';
import {
  DisclosureGroup,
} from "@freeda/design-system";
import "@buildo/bento-design-system/lib/index.css";
import "@buildo/bento-design-system/lib/defaultTheme.css";
import { WrapperLateralPanel, BackgroundLayer } from '../../ui/LateralPanel';
import { TransitionStatus } from 'react-transition-group/Transition';
import { BackButton } from '../../ui/BackButton';
import { Button, ButtonIcon, Icon, Input, Tooltip, Typography } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { Format } from '../../../types/types2.0/Format';
import { FeedbackContext } from '../../../providers/FeedbackProvider';
import { useKeyUpEsc } from '../../../hooks/useKeyUpEsc';
import { EnhancedPlatformContent } from '../../../types/types2.0/EnhancedContent';
import { LabelWrapper } from '../ShareMediaDialog2.0/styles';
import { Project } from '../../../types/types2.0/Project';

import 'react-calendar-datetime-picker/dist/index.css'
import { UpdatePlatformContentPublicationMutation } from '../../mutations/mutations2.0/UpdatePublishPlatformContentMutation';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone';
import { asyncForEach } from '../../../utils/asyncForEach';
import { WarningPublicationPanel } from '../../ui/WarningPanel';
import { FramePreview } from '../../ui/FramePreview';
//import { PreviewImage } from '../DownloadMultiMediaDialog2.0/styles';


interface Props {
  open: boolean;
  onClose: (edit?: boolean) => void;
  readOnlyUser: boolean;
  platformContent:EnhancedPlatformContent;
  localization:string;
  project:Project;
  format:Format,
  status: TransitionStatus;
}


const PublicationPanel: React.FC<Props> = ({
  open,
  onClose,
  readOnlyUser,
  platformContent,
  localization,
  project,
  format,
  status
}) => {

  const isIgStories = format && format.indexOf('INSTAGRAM_STORY') >= 0;
  const isScheduleNull = platformContent.schedule? false : true


  let [disabledSaveButton, setDisabledButton] = React.useState<boolean>(true);
  useKeyUpEsc(onClose);

  const [state, setState] = React.useState({  
          id:platformContent.id,
          withComponents: false,
          scheduleOut: platformContent.schedule? platformContent.schedule! : [],
          originalSchedule:  platformContent.schedule? JSON.parse(JSON.stringify(platformContent.schedule!)) : [],
          item: [] as Array<any>
    });

  const convertDate = (dateIn:Date,time:string, noOffset?:boolean) : any => {

    try{
        let format: string = (time =='CET' ? "YYYY-MM-DDTHH:mm": "YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
        let dueDate : string = moment(dateIn).format(format) 
        let utcOffsetdueDate= moment.parseZone(moment(dateIn).format()).utcOffset();

        if(noOffset){
          return moment(dueDate).toDate().toISOString()
        }
      
        if(time=='UTC')
          return moment(dueDate).subtract(utcOffsetdueDate,'minutes').toDate().toISOString()
        else
          return moment(dueDate).add(utcOffsetdueDate,'minutes').format(format)
    }
    catch(ex){
      console.log('Error in parsing date')
    }
  }

  const setValue = (index:number, key:string, value:any) : any => {
     
      let el = document.getElementById(key+index)!
      if(el !== null){

        if(key === 'caption'){
          el.textContent =  value
        }
        else if(key === 'notes'){
          el.setAttribute('value', value)
        }
        else if(key === 'dueDate'){
          el.setAttribute('value', value)
        }
        else if(key === 'publicationUrl'){
          el.setAttribute('value', value)
        }
        else if(key.startsWith('componentsPublicationUrl')){
          el.setAttribute('value', value)
        }

      }
  } 

  const add = (index:number, removeBtn?:boolean) : any => {

    let framesUrl: any[] = []
    if( isIgStories ){
      
      platformContent.components.forEach(function(item, indexComponent){

        framesUrl.push(
          <div key={"componentsPublicationUrl"+indexComponent} style={{marginTop: 13,  display: 'flex', position: 'relative'}}>
             
             <FramePreview
                  mimeType={item.mimeType}
                  frameUrl={{
                    newUrl: item.newUrl,
                    storagePath: item.storagePath,
                    thumbnailUrl: item.thumbnailUrl
                  }}
              />
            
              <Input
                id = {"componentsPublicationUrl"+index+indexComponent}
                label= {"Publication URL frame " + (indexComponent+1)}
                onChange = {(ev) => ( setField(index, "componentsPublicationUrl"+indexComponent, ev.target.value) )}
                type="text"
                placeholder="Write URL"
                disabled={readOnlyUser}
                style={{width: '100%', marginLeft:3, marginTop:6}}
              />
          </div>
        )
      })
    }
    
    
    let newSchedule = { 
        title: "Schedule "+(index+1),
        children: <div>
                  {removeBtn && 
                      <div style={{display: 'flex',justifyContent: 'right'}}>
                          <ButtonIcon
                            iconName='delete'
                            iconSize='medium'
                            style={{
                              display: 'contents'
                            }}
                            onClick={() => removeSchedule(index)}
                          >
                          </ButtonIcon>
                      </div>
                  }
                  <div style={{marginTop: 4}}>
                      <LabelWrapper  style={{fontSize: '12px', marginBottom: 15, color:'#272626'}}>
                            Caption
                      </LabelWrapper>
                      <textarea
                        id={"caption"+index}
                        key={"caption"+index}
                        onChange={(ev) => ( setField(index, "caption", ev.target.value) )}
                        placeholder = "Write a caption"
                        disabled = {readOnlyUser}
                        style = {{width:'100%', height:'100%', borderStyle:'solid', color:'#6B2DD5', borderColor:'#6B2DD5'}}
                      />
                  </div>
                  <div style={{marginTop: 17, width: '100%'}}>
                      <LabelWrapper>
                          <Typography
                            variantName="label"
                            style={{fontSize:  12, marginBottom:15, textTransform: 'capitalize'}}
                          >
                            Due date and time (Central European Time (CET))
                          </Typography>
                      </LabelWrapper>
                      <div style={{
                          display: 'flex',
                          position: 'relative',
                        }}>
                          <input id={"dueDate"+index} key={"dueDate"+index} type="datetime-local" onChange={(ev) => ( setField(index, "dueDate", ev.target.value) )}></input>
                          <ButtonIcon iconName='delete' iconSize='small' onClick={(ev) => ( setField(index, "dueDate", "") )}></ButtonIcon>
                      </div>
                </div>
                <div style={{marginTop: 17}}>
                    <LabelWrapper  style={{fontSize: '12px', marginBottom: 15, color:'#272626'}}>
                    Additional notes
                    </LabelWrapper>
                    <Input
                      id = {"notes"+index}
                      key={"notes"+index}
                      onChange = {(ev) => ( setField(index, "notes", ev.target.value) )}
                      type = "text"
                      placeholder = "Write notes"
                      disabled = {readOnlyUser}
                      style = {{width: '100%'}}
                    />
                </div>
                <div style = {{marginTop: 10}}>
                  <Tooltip
                    referenceChildren={<Icon iconName="info" iconColor={Colors.GRIGINOmedium} />}
                    tooltipChildren={
                      <div style ={{margin: 0 }}>
                        Be sure to read Peggy's documentation to get the Publication URL
                      </div>
                    }
                    position = "left"
                    referenceStyle={{  alignItems: 'center'}}
                    tooltipStyle={{ width: 300, top: 4}}
                  />
                </div>
                {( !isIgStories )  && (
                  <div style = {{marginTop: 5}}>
                    <LabelWrapper  style={{fontSize: '12px', marginBottom: 15, color:'#272626'}}>
                    Publication URL
                    </LabelWrapper>
                      <Input
                        id = {"publicationUrl"+index}
                        key={"publicationUrl"+index}
                        onChange = {(ev) => ( setField(index, "publicationUrl", ev.target.value) )}
                        type = "text"
                        placeholder = "Write URL"
                        disabled = {readOnlyUser}
                        style = {{width: '100%'}}
                      />
                  </div>
                )}
                { isIgStories  && (framesUrl)}
            </div>
        }
        
        let i:any[] = state.item
        i.push(newSchedule)
        setState({...state, item:i})
  }

  const setField = (index:number, key:string, value:any) : any => {
   
    let scheduleOut: any[] = state.scheduleOut
    if(key.startsWith('componentsPublicationUrl')){

      let indexComponent = key.split('componentsPublicationUrl')
      //let indexComponent = key.substr(key.length - 1)
      scheduleOut![index]!['componentsPublicationUrl']![ indexComponent[1] ] = value

    }
    else{
      scheduleOut![index]![key]! = value
    }
    scheduleOut![index]!['save']! = true

    if(key==='dueDate'){
      scheduleOut![index]!['updateDueDate']! = true
    }

    setState({...state, scheduleOut: scheduleOut })
    setValue(index,key,value)

    setDisabledButton(false)
  }

  const removeSchedule = (index:number) : any => {
    let scheduleOut: any[] = state.scheduleOut
    scheduleOut.splice(index, 1);
    setState({...state, scheduleOut: scheduleOut })

    let i: any[] = state.item
    i.splice(index, 1)
    setState({...state, item: i})
  }

  
  const addNewSchedule = () : any => {

    let scheduleOut: any[] = state.scheduleOut
    scheduleOut.push({
        "caption" : "",
        "notes" : "",
        "publicationUrl" : "",
        "dueDate" : null,
        "repostCount" : state.scheduleOut.length,
        "componentsPublicationUrl" : []
      })
      setState({...state, scheduleOut: scheduleOut })
      let i = state.item
      add( i.length , true)
  }


  React.useEffect(() => {

      if( isIgStories ){
        state.withComponents = true
      }

      if( state.scheduleOut.length > 0 ){
        state.scheduleOut.forEach(function(item:any, index:number){
            add(index)
          })
      }
      else{
        addNewSchedule()
      }
  }, []);
  
  
  if (document.readyState == 'complete') {
     try{
       
          if( state.scheduleOut.length > 0 ){
            
              state.scheduleOut.forEach(function(item:any, index:number){
                setValue(index, "caption",item.caption)
                setValue(index, "notes",item.notes)
                setValue(index, "publicationUrl",item.publicationUrl)

                if(item.dueDate !== null){
                  setValue(index, "dueDate", convertDate(new Date(item.dueDate), 'CET') )
                }

                if(item['componentsPublicationUrl'] != null && item['componentsPublicationUrl'].length>0){
                    item['componentsPublicationUrl'].forEach(function(value: any, indexComponent: number){
                      setValue(indexComponent, "componentsPublicationUrl"+index, value)
                  })
                }
              })
        }
     }catch(e){
       console.log('DOM not ready yet '+e)
     }
 }


  return (
    <FeedbackContext.Consumer>
      {({ onOpen }) => (
        <UpdatePlatformContentPublicationMutation>
        {(updatePlatformContentPublicationMutation, updateResult) => {
          return (
              <>
              <div id='publicationPanel'>
                <BackgroundLayer
                  open={open}
                  onClick={() => onClose()}
                  className={`background-${status}`}
                />
                <WrapperLateralPanel
                  open={open}
                  className={`wrapper-${status}`}
                  style={{ justifyContent: 'start' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <BackButton onClick={() => onClose()} />
                    
                    <ButtonIcon
                      iconName="save"
                      disabled={disabledSaveButton || updateResult.loading}
                      loading={updateResult.loading}
                      iconColor={Colors.ROSSINO}
                      onClick={async () => {
                        try {
                          
                          let error:any;
                          
                          state.scheduleOut.forEach(function(item:any, index:number){

                            if(item.save!==null && item.save===true){
                                
                                if(item.caption === '' && item.notes === '' && (item.dueDate === "" || item.dueDate === null) 
                                                            && item.publicationUrl === "" && item.componentsPublicationUrl.length==0 ){
                                      error= `Not possible to save an empty schedulation !! 😔 .. Have a check to the schedule `+(index+1)
                                }

                                else if(state.withComponents === false && state.originalSchedule[index] && state.originalSchedule[index]['publicationUrl'] 
                                                                    && state.originalSchedule[index]['publicationUrl'] !== ""  && item.publicationUrl === "" ){
                                      error= `Not possible to blank the publication URL saved before !! 😔 .. Have a check to the schedule `+(index+1)
                                }

                                else if(state.withComponents === false && item.publicationUrl !== "" && (item.dueDate === "" || item.dueDate===null)){
                                    error = `Due Date is mandatory if you set publication Url !! 😔 .. Have a check to the schedule `+(index+1)
                                }

                                else if(state.withComponents === true && item.dueDate === '' && item.componentsPublicationUrl.length>0){
                                    error = `Due Date is mandatory if you set frame publication Urls !! 😔 .. Have a check to the schedule `+(index+1)
                                }

                                else if( state.withComponents === true && item.componentsPublicationUrl.length > 0 ){
                                    let emptyElement = item.componentsPublicationUrl.filter( (p:any) => p == '' );
                                    if(emptyElement.length!=0 && emptyElement.length != item.componentsPublicationUrl.length){
                                        error = `Insert all frame Publication Urls ! 😔 .. Have a check to the schedule `+(index+1)
                                    } 
                                }
                              
                            }
                          })
                          
                          if(error){
                              onOpen(error, 'error');
                              platformContent.schedule = state.originalSchedule
                              return;
                          }
                          else{

                            await asyncForEach(
                              state.scheduleOut,
                              async (item: any,indexAsync:number|undefined) => {

                                if(item.save!==null && item.save===true) {

                                  let vars = {
                                    id: state.id,
                                    repostCount: item.repostCount,
                                    notes: item.notes, 
                                    caption: item.caption,
                                    withComponents : state.withComponents,
                                    publicationUrl: state.withComponents ? item.componentsPublicationUrl : 
                                                                            item.publicationUrl === "" ? [] : [item.publicationUrl],
                                    dueDate :  (item.dueDate ===  "" || item.dueDate === null) ? null : 
                                                                            item.updateDueDate === true ? 
                                                                                convertDate( new Date (item.dueDate) , 'UTC') :
                                                                                convertDate( new Date (state.originalSchedule[indexAsync!]!['dueDate']!) , 'UTC', true)                                        
                                  }
                                  //console.log(vars)
                                  await updatePlatformContentPublicationMutation({
                                      variables: vars
                                    })
                                }
                              }
                            );
  
                            state.scheduleOut.forEach(function(item:any, index:number){

                                if(item.updateDueDate === true){
                                  item.dueDate =  (item.dueDate ===  "" || item.dueDate === null) ? null : moment(item.dueDate).add( new Date().getTimezoneOffset(), 'minutes' ).format("YYYY-MM-DDTHH:mm")
                                }
                                delete item['save']
                                delete item['updateDueDate']
                            })
  
                            onOpen('Content informations SAVED ! 😎', 'notification');
                            
                            platformContent.schedule = state.scheduleOut
                            onClose(true);

                          }
                          
                          
                        } catch (err) {
                          onOpen(
                            `Oops, something went wrong 😔 : `.concat(err instanceof Error ? err.message : ""),
                            'error'
                          );
                        }
                      }}
                    />
                  </div>

                  <div style={{ padding: 15 }}>
                    <LabelWrapper>
                        <Typography
                          variantName="title"
                          style={{
                            color: Colors.PURPLE,
                            fontSize:  14 ,
                            textTransform: 'capitalize'
                          }}
                        >
                          Publication details
                        </Typography>
                    </LabelWrapper>
                  </div>

                  <div  style={{width: '100%', marginTop: 15, display: 'flex'}}>
                      <Input
                        label="Platform"
                        value={platformContent.itemType}
                        type="text"
                        placeholder=""
                        disabled={true}
                        style={{width: '30%', marginRight: 10}}
                      />
                      {platformContent.isReel && 
                          <LabelWrapper>
                            <Typography
                              variantName="label"
                            >
                              Reels
                            </Typography>
                        </LabelWrapper>
                        }
                      <Input
                        label="Project"
                        value={project?.name}
                        type="text"
                        placeholder=""
                        disabled={true}
                        style={{width: '50%', marginRight: 10}}
                      />
                      <Input
                        label="Country"
                        value={localization}
                        type="text"
                        placeholder=""
                        disabled={true}
                        style={{width: '10%'}}
                      />
                  </div>
                  {isScheduleNull && 
                    <div>
                      <WarningPublicationPanel>{`You must save content before to add a publication info`}</WarningPublicationPanel>
                    </div>
                  }

                  {!isScheduleNull && 
                  <div>
                    <div style={{ marginTop:10, marginBottom:9}}>
                        <Button      
                            id='add'
                            style={{width:'50%'} }
                            onClick={async () => {
                              addNewSchedule()
                            }}
                          > Add new Schedule
                        </Button>
                      </div>
                      <div>
                        <DisclosureGroup items={ state.item.length > 0 ? state.item :  [{ title: " ",children: <div></div>, initialIsOpen:  true }] }></DisclosureGroup>
                      </div>
                  </div>
                        
                  }  
                  
                  

                </WrapperLateralPanel>
                </div>
              </>
          )}}
        </UpdatePlatformContentPublicationMutation>
      )}
    </FeedbackContext.Consumer>
  );
};

export { PublicationPanel };

/*let schedule = [
        {
            "caption":"Post caption! Answer poll",
            "notes":"Tag talent and add poll",
            "dueDate":"2023-01-20T23:00",
            "repostCount":0,
            "publicationUrl":"",
            "createdAt":"2023-01-31T11:40:41.051",
            "updatedAt":"2023-01-31T11:40:41.051",
            "componentsPublicationUrl": ["url1", "url2"]
      },
      {
            "caption":"Post caption! Answer poll schedule2",
            "notes":"Tag talent and add poll schedule2",
            "dueDate":"2023-06-20T23:00",
            "repostCount":1,
            "publicationUrl":"",
            "createdAt":"2023-02-20T11:40:41.051",
            "updatedAt":"2023-02-20T11:40:41.051",
            "componentsPublicationUrl": ["url4", "url5"]
      }
  ] */
  
  /*let schedule = [
    {
       "createdAt" : new Date("2021-09-12T12:33:39.000Z"),
       "updatedAt" : new Date("2021-09-03T10:12:42.000Z"),
       "caption" : "Original caption!",
       "notes" : "Some original notes...",
       "repostCount":0,
       "publicationUrl":"p1",
       "dueDate" : new Date("2021-09-27T13:30:00.470Z")
     },
    {
       "createdAt" : new Date("2022-09-12T12:33:39.000Z"),
       "updatedAt" : new Date("2022-09-03T10:12:42.000Z"),
       "caption" : "This is a repost! #repost",
       "notes" : "Re-tag talent for repost.",
       "repostCount":1,
       "publicationUrl":"p2",
       "dueDate" : new Date("2022-09-27T13:30:00.470Z")   
     }
    ]*/


