import * as React from 'react';

const FeedbackContext = React.createContext<{
  open: boolean;
  message: string | React.ReactElement<any>;
  messageType: MessageType;
  onOpen: (message: string | React.ReactElement<any>, type: MessageType) => void;
  onClose: () => void;
}>({
  open: false,
  message: '',
  messageType: 'notification',
  onOpen: (message: string | React.ReactNode, type: MessageType) =>
    console.warn('Please implement FeedbackProvider.onOpen'),
  onClose: () => console.warn('Please implement FeedbackProvider.onClose')
});

export type MessageType = 'notification' | 'error' | 'download';

interface State {
  open: boolean;
  message: string | React.ReactElement<any>;
  messageType: MessageType;
}

class FeedbackProvider extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      open: false,
      message: '',
      messageType: 'notification'
    };
  }

  onOpen = (message: string | React.ReactElement<any>, type: MessageType) =>
    this.setState({ open: true, message, messageType: type });
  onClose = () => this.setState({ open: false, message: '', messageType: 'notification' });

  render() {
    return (
      <FeedbackContext.Provider
        value={{ ...this.state, onOpen: this.onOpen, onClose: this.onClose }}
      >
        {this.props.children}
      </FeedbackContext.Provider>
    );
  }
}

export { FeedbackProvider, FeedbackContext };
