import * as React from 'react';
import Media from 'react-media';
import { Input, Button, ButtonIcon } from '@freeda/react-components';

import { FilterContext } from '../../../providers/FilterProvider2.0';
import { Field, FreeFilterWrapper, ClearFilterWrapper } from './styles';
import {
  FetchedItemsCounterContext
} from '../../../providers/FetchedItemsCounterProvider';

interface Props {
  showFilter?: () => void;
}

const FreeFilter: React.FC<Props> = ({ showFilter }) => (
  
  <FilterContext.Consumer>
    {({ callbacks, filterValue }) => (
    
    <FetchedItemsCounterContext.Consumer>
      { fetchedItemsCounterContext => (

        <Media query={{ maxWidth: 768 }}>
          {(matches: boolean) => (
            <FreeFilterWrapper>
            
              <Field
                basis="30%"
                mobileBasis="80%"
                style={{ marginRight: 0, paddingRight: 0, marginTop: matches ? 0 : '' }}
              >
                <Input
                  placeholder="Search by title or keywords"
                  onChange={e => {fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")();fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")(); callbacks.onTextFilterChange(e.target.value);}}
                  value={filterValue.searchValue}
                  iconName="search"
                  onClearValue={() => callbacks.onTextFilterChange('')}
                />
              </Field>
              <ClearFilterWrapper>
                <Button flat onClick={() => {fetchedItemsCounterContext.resetCounter("allContentPageFetchedItems")(); fetchedItemsCounterContext.resetCounter("myMediaPageFetchedItems")(); callbacks.clearFilter()}} style={{ margin: 0 }}>
                  Clear filter
                </Button>
              </ClearFilterWrapper>
              {matches && (
                <ButtonIcon
                  iconName="filter-list"
                  onClick={e => showFilter && showFilter()}
                  iconColor="#6B20DA"
                />
              )}
            </FreeFilterWrapper>
          )}
        </Media>

      )}
    
    </FetchedItemsCounterContext.Consumer>

      
    )}
  </FilterContext.Consumer>
);

export { FreeFilter };
