import * as React from 'react';
import Media from 'react-media';

import { FreeFilter } from './FreeFilter';
import AdvancedFilter from './AdvancedFilter';
import { BrandCategory } from '../../../types/types2.0/BrandCategory';
import { Brand } from '../../../types/types2.0/Brand';
//import { Serie } from '../../../types/types2.0/Serie';
import { ProductType } from '../../../types/types2.0/ProductType';
//import { TeamMember } from '../../../types/TeamMember';
import { User } from '../../../types/types2.0/User';
//import { Contributor } from '../../../types/Contributor';
import { Topic } from '../../../types/types2.0/Topic';
import { Label } from '../../../types/types2.0/Label';

import { CSSTransition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { Project } from '../../../types/types2.0/Project';
import { MacroTopic } from '../../../types/types2.0/MacroTopic';
//import { Campaign } from '../../../types/types2.0/Campaign';
//import { Product } from '../../../types/types2.0/Product';

interface Props {
  brandCategories: Array<BrandCategory>;
  brands: Array<Brand>;
  //series: Array<Serie>;
  macroTopics:Array<MacroTopic>
  topics: Array<Topic>;
  productTypes: Array<ProductType>;
  teamMembers: Array<User>;
  contributors: Array<User>;
  projects: Array<Project>;
  //campaigns : Array<Campaign>,
  //editorialCampaigns : Array<Campaign>,
  temperatures: Array<Label>;
  objectives: Array<Label>;
  genres: Array<Label>;
  //products: Array<Product>;
}

// only for mobile
interface State {
  showFilter: boolean;
}

class Filter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showFilter: false
    };
  }

  toggleShowFilter = () =>
    this.setState(
      state => ({ showFilter: !state.showFilter }),
      () => {
        if (this.state.showFilter) {
          document.body.style.overflowY = 'hidden';
        } else {
          document.body.style.overflowY = 'auto';
        }
      }
    );

  render() {
    const {
      brandCategories,
      brands,
     // series,
      macroTopics,
      topics,
      productTypes,
      teamMembers,
      contributors,
      projects,
      genres,
      temperatures,
      objectives,
     // campaigns,
      //editorialCampaigns,
      //products

    } = this.props;
   // console.log(brandCategories)
   // console.log(brands)
   // console.log(projects)

    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => {
          const advancedFilter = (status?: TransitionStatus) => {
            return (
              <AdvancedFilter
                brandCategories={brandCategories}
                brands={brands}
                //series={series}
                macroTopics={macroTopics}
                topics={topics}
                productTypes={productTypes}
                teamMembers={teamMembers}
                contributors={contributors}
                projects={projects}
                genres={genres}
                temperatures={temperatures}
                objectives={objectives}
                closeFilter={this.toggleShowFilter}
                showFilterMobile={this.state.showFilter}
                transitionStatus={status}
                //products={products}
                //campaigns={campaigns}
                //editorialCampaigns={editorialCampaigns}
              />
            );
          };

          if (!matches) {
            return (
              <>
                <FreeFilter />
                {advancedFilter()}
              </>
            );
          }

          return (
            <>
              <FreeFilter showFilter={() => this.toggleShowFilter()} />
              <CSSTransition in={this.state.showFilter} timeout={300} classNames="" unmountOnExit>
                {status => advancedFilter(status)}
              </CSSTransition>
            </>
          );
        }}
      </Media>
    );
  }
}

export default Filter;
