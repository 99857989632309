import * as React from 'react';
import Dropzone from 'react-dropzone';

import { Overlay } from './styles';

const boccaAperta = require('../../../assets/bocca_aperta.svg');
const boccaChiusa = require('../../../assets/bocca_chiusa.svg');

interface Props {
  enqueue: Function;
}

interface State {
  dropzoneActive: boolean;
  showClosedMouth: boolean;
}

class FullScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dropzoneActive: false,
      showClosedMouth: false
    };
  }

  onDragEnter = () => this.setState({ dropzoneActive: true, showClosedMouth: false });
  onDragLeave = () => this.setState({ dropzoneActive: false });

  onDrop = (accepted: Array<File>, rejected: Array<File>) => {
    this.setState({ showClosedMouth: true }, () => {
      setTimeout(() => {
        this.setState({ dropzoneActive: false }, () => {
          this.props.enqueue(accepted);
        });
      }, 1000);
    });
  };

  render() {
    const { dropzoneActive, showClosedMouth } = this.state;

    const overlay = (
      <Overlay>{!showClosedMouth ? <img src={boccaAperta} /> : <img src={boccaChiusa} />}</Overlay>
    );

    return (
      <Dropzone
        disableClick
        style={{ position: 'relative', height: '100%' }}
        accept={''}
        onDrop={this.onDrop}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
      >
        {dropzoneActive && overlay}
        {this.props.children}
      </Dropzone>
    );
  }
}

export default FullScreen;
