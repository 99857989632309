import gql from 'graphql-tag';
//import { PARENT_CONTENT } from './ParentContentFragment';

const GET_PARENT_CONTENT = gql`
  query getParentContent($id: ID!) {
    getParentContent(id: $id) {
      id
      format
      contentType
      title
      sources
      keywords {
        id
        name
      }
      productType {
        id
        name
        format
      }
      brand {
        id
        name
      }
      campaign {
        id
        name
      }
      serie {
        id
        name
      }
      topic {
        id
        name
      }
      project {
        id
        name
      }
      product{
        id
        name
      }
      genre{
        id
        name
      }
      temperature{
        id
        name
      }
      objective{
        id
        name
      }
      booleans{
        id
        name
      }
      editorialCampaign{
        id
        name
      }
      productionCountry
      languageSound
      onlyMusic
      creator
      createdAt
      updatedAt
      updatedBy
      localizations {
        id
        parent {
          id
        }
        distributionCountry
        creator
        members {
          id
          name
        }
        contributors {
          id
          name
        }
        noSubtitle
        subtitles
        languageCopy
        languageSubtitle
        creator
        createdAt
        updatedAt
        updatedBy
  
        platforms {
          id
          title
          parent{
            id
          }
          localization{
            id
          }
          itemType
          mimeType
          storagePath
          thumbnailUrl
          sources
          videoLength
          creator
          createdAt
          updatedBy
          updatedAt
          isReel
          instantArticleUrl
          schedule {
            caption
            notes
            repostCount
            publicationUrl
            componentsPublicationUrl
            dueDate
            createdAt
            updatedAt
          }

          components {
            id
            parent{
              id
            }
            localization{
              id
            }
            platform{
              id
            }
            title
            position
            storagePath
            mimeType
            thumbnailUrl
            videoLength
            creator
            createdAt
            updatedBy
            updatedAt
          }
        }
      }


    }
  }
  
`;

export { GET_PARENT_CONTENT };
