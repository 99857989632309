import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Media from 'react-media';

import { StyledCard } from './styles';

interface Props {
  notCompleted?: boolean;
  mediaId?: string;
  isSelected?: boolean;
  renderMain?: (showOverlay: boolean) => React.ReactNode;
  renderOverlay?: () => React.ReactNode;
  onSelection?: Function;
  //cleanList:boolean;
}

type PropsWithRouterProps = Props & RouteComponentProps<{}>;

interface State {
  showOverlay: boolean;
}

class MediaCard extends React.Component<PropsWithRouterProps, State> {
  constructor(props: PropsWithRouterProps) {
    super(props);
    this.state = {
      showOverlay: false
    };

    
  }


  componentDidMount() {
  // console.log('componentDidMount')
  

  }

  componentWillUnmount() {
    //console.log(' unmount');
   
    
  }

  componentDidUpdate() {
   // console.log(' update');
  }
  

  render() {

    const { showOverlay } = this.state;
    const { renderMain, renderOverlay, notCompleted, mediaId, history } = this.props;
    

    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <StyledCard
            data-testid="media-card"
            data-scroll="media-card"
            onClick={() => {
              if (matches && history) {
                history.push(`/gallery/${mediaId}?country=${localStorage.getItem('country')}`);
              }
            }}
            highlighted={String(notCompleted)}
            onMouseEnter={() => !matches && this.setState({ showOverlay: true })}
            onMouseLeave={() => !matches && this.setState({ showOverlay: false })}
          >
            {renderMain!(showOverlay)}
            {showOverlay && renderOverlay!()}
          </StyledCard>
        )}
      </Media>
    )
  }
}

export default withRouter<Props & RouteComponentProps<{}>, any>(MediaCard);
