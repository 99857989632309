import * as React from 'react';

import { ParentContentQuery } from '../components/queries/queries2.0/ParentContentQuery';
import MediaPreview from '../components/layout/MediaPreview2.0';
import { RouterLink as Link } from '../components/ui/RouterLink';
import { LIST_CONTENT } from '../apollo/queries/queries2.0/ListContent';
import { ITEMS_PER_PAGE} from '../utils/constants';
import { LIST_MY_CONTENT } from '../apollo/queries/queries2.0/ListMyContent';
import { ShareMediaDialogContext } from '../providers/ShareDialogProvider2.0';
//import { ListMyPinsQuery } from '../components/queries/ListMyPinsQuery';
//import { addPinToCache, removePinFromCache } from '../components/mutations/PinMutation';
import { FilterContext } from '../providers/FilterProvider2.0';
//import { LIST_MY_PINNED_MEDIA } from '../apollo/queries/ListMyPinnedMedia';
//import { PinHelper } from '../components/helpers/PinHelper';

import { ParentContent } from '../types/types2.0/ParentContent';
import ListContentQuery from '../types/types2.0/graphql2.0/ListContentQuery';
import { download } from '../utils/downloadMedia';
import { Country } from '../types/graphql/Country';
import { ItemType } from '../types/ItemType';
//import { BreakingChangeType } from 'graphql';

const queries = {
  '/': LIST_CONTENT,
  '/my-media': LIST_MY_CONTENT
};

export const MediaPreviewContainer: React.FC<{
  id: string;
  distributionCountry: Country | null;
  itemType: ItemType | null;
  componentPosition: number | null;
  backTo: string;
  allContentPageFetchedItems: number;
  myMediaPageFetchedItems: number;
  //myPinPageFetchedItems: number;
}> = ({
  id,
  distributionCountry,
  itemType,
  componentPosition,
  backTo,
  allContentPageFetchedItems,
  myMediaPageFetchedItems,
  //myPinPageFetchedItems
}) => {

  return (
    <FilterContext.Consumer>
      {({ getQueryVariables }) => {
        const filterVariables = getQueryVariables();
       
        let content: ParentContent | null = null;

        //let isPinned: boolean = false;

        let loadingMedia = false;
        //let loadingIsPinned = false;

        let fetchedItemsCounter: number;

        switch (backTo) {
          case '/my-media':
            fetchedItemsCounter = myMediaPageFetchedItems;
            break;
          /*case '/my-pins':
            fetchedItemsCounter = myPinPageFetchedItems;*/
            break;
          case '/':
            
          default:
            fetchedItemsCounter = allContentPageFetchedItems;
        }

        return (
          <ParentContentQuery fetchPolicy="no-cache" variables={{ id }}>
            {({ loading, error, data, client }) => {
              
              if (error) {
                return 'Error...';
              }
              if (loading || !data || Object.keys(data).length === 0) {
                loadingMedia = true;
              } else {
                loadingMedia = false;
                content = data.getParentContent;
              }

              let noQuery = false;
              let ids: Array<string> = [];
              let idIndex: number = -1;
              let previousId: string | null = null;
              let nextId: string | null = null;        
              
             
              try {
                const queryResult = client.readQuery<ListContentQuery>({
                  query: queries[backTo],
                  variables: {
                    skip: fetchedItemsCounter,
                    first: ITEMS_PER_PAGE,
                    ...filterVariables
                  }
                });
                //console.log('HOFATTO LA  QUERY__',queryResult!.listParentContents.edges)
                ids = queryResult!.listParentContents.edges.map(edge => edge.node.id);
                idIndex = ids.indexOf(id);

                previousId = ids[idIndex - 1];
                nextId = ids[idIndex + 1];
              } catch (error) {
                console.log('error', error)
                console.warn(`Cannot find query for path ${backTo}`);
                noQuery = true;
              }

              /*if(noQuery){
                return 'ERROR IN LOADING PREVIEW ...'
              }*/
              return (
                <ShareMediaDialogContext.Consumer>
                {shareMediaDialogActions => (

                  <MediaPreview
                    content={content}
                    idPlatformContent={null}

                    
                    distributionCountry={distributionCountry}
                    nextId={nextId}
                    previousId={previousId}
                    itemType={itemType}
                    componentPosition={componentPosition}
                    //isPinned={isPinned}
                    loading={loadingMedia}
                    renderPreviousLink={(children: React.ReactNode) =>
                      noQuery || !previousId ? null : (
                        <Link
                          to={`/gallery/${previousId}?country=${distributionCountry}`}
                        >
                          {children}
                        </Link>
                      )
                    }
                    renderNextLink={(children: React.ReactNode) =>
                      noQuery || !nextId ? null : (
                        <Link
                          to={`/gallery/${nextId}?country=${distributionCountry}`}
                        >
                          {children}
                        </Link>
                      )
                    }
                    renderBackToGallery={(children: React.ReactNode) => (
                      <Link to={backTo.indexOf('gallery') >= 0 ? '/' : backTo}>
                        {children}
                      </Link>
                    )}
                    onDownload={(storagePath: string) => {
                      download([storagePath]);
                    }}
                    onForward={(mediaTranslationId: string, idPlatformContent:string) =>
                      shareMediaDialogActions.onOpen(mediaTranslationId, false, idPlatformContent!)
                    }
                    /*onPin={async () => {
                      await pin({
                        variables: {
                          pins: {
                            connect: [{ id }]
                          }
                        },
                        update: addPinToCache(filterVariables)
                      });
                      if (pinResult.error) {
                        onOpen('Error!', 'error');
                      } else {
                        onOpen('Media PINNED', 'notification');
                      }
                    }}*/
                    /*onUnpin={async () => {
                      await pin({
                        variables: {
                          pins: {
                            disconnect: [{ id: id }]
                          }
                        },
                        update: removePinFromCache([id], filterVariables)
                      });
                      if (pinResult.error) {
                        onOpen('Error!', 'error');
                      } else {
                        onOpen('Media UNPINNED', 'notification');
                      }
                    }
                  }*/
                    editPageLocation={`/edit-media/${id}`}
                  />
                )}
              </ShareMediaDialogContext.Consumer>
              //////////////////////////////////////
              );
            }}
          </ParentContentQuery>
        );
      }}
    </FilterContext.Consumer>
  );
};
