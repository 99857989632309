import * as React from 'react';
import { AppBar, FirstPartMobile, SidebarListItem, SubMenuIconTransition } from './styles';
import {
  ButtonIcon,
  DrawerTemporary,
  List,
  Icon,
  ListItemText,
  Typography,
  DividerSection,
} from '@freeda/react-components';
import { zIndex } from '../../../utils/zIndex';
import { NotCompletedBadge } from '../../../containers/NotCompletedBadge2.0';
import LogoutButton from '../../ui/LogoutButton';
import { RouterNavLink as NavLink } from '../../ui/RouterLink';
import { Colors } from '@freeda/react-components/lib/theme';
import { getLabelColor } from '.';
import MetadataIcon from '../../ui/MetadataIcon';
import { SidebarContext } from '../../../providers/SidebarProvider';
import { MetadataType } from '../../../types/MetadataType';

interface Props {
  open: boolean;
  onOpen: () => void;
  changeOpenSidebar: (isOpen: boolean) => void;
  emoji: string;
  removeScrollTop: () => void;
}

const SidebarMobile: React.FC<Props> = ({
  open,
  onOpen,
  changeOpenSidebar,
  emoji,
  removeScrollTop,
}) => {
  const { handleCreateMetadataDialog, onChangeMetadataTable } = React.useContext(SidebarContext);

  const isMediaGalleryClicked = window.location.pathname === '/';
  const isMyMediaClicked = window.location.pathname === '/my-media';
  const isSavedClicked = window.location.pathname === '/my-pins';
  const isMetadataClicked = window.location.pathname.includes('/metadata');

  const mobileLabelColor = 'rgba(255, 255, 255, 0.5)';

  return (
    <>
      <AppBar>
        <div>
          <ButtonIcon
            iconName="menu"
            aria-label="open drawer"
            onClick={() => onOpen()}
            style={{ width: 48, height: 48 }}
          />
        </div>
      </AppBar>

      <DrawerTemporary
        isOpen={open}
        openWidth={300}
        closeWidth={0}
        zIndex={zIndex.SidebarMobile}
        changeOpenSidebar={(isOpen) => changeOpenSidebar(isOpen)}
        style={{ zIndex: zIndex.SidebarMobile }}
      >
        <FirstPartMobile>
          <SidebarListItem
            backgroundHover={Colors.PURPLE}
            open={open}
            style={{ height: 80, margin: 0, marginTop: 25, userSelect: 'none' }}
          >
            <Typography variantName="title" style={{ fontSize: 30 }}>
              {emoji}
            </Typography>
            <ListItemText
              primary={
                <>
                  <Typography
                    variantName="text"
                    textColor={mobileLabelColor}
                    style={{ paddingBottom: 2 }}
                  >
                    Welcome,
                  </Typography>
                  <Typography
                    variantName="text"
                    textColor={mobileLabelColor}
                    style={{
                      marginRight: 15,
                      fontSize: 16,
                      fontWeight: 600,
                      paddingTop: 2,
                    }}
                  >
                    {localStorage.getItem('name')!}
                  </Typography>
                </>
              }
            />
          </SidebarListItem>
          <SidebarListItem open={open} style={{ margin: 0, marginBottom: 10 }}>
            <LogoutButton>
              <ButtonIcon
                iconName="logout"
                iconColor={mobileLabelColor}
                style={{ cursor: 'pointer', margin: 0, padding: 0 }}
              />
              <ListItemText
                primary={
                  <Typography
                    variantName="text"
                    textColor={mobileLabelColor}
                    style={{ cursor: 'pointer' }}
                  >
                    Logout
                  </Typography>
                }
              />
            </LogoutButton>
          </SidebarListItem>
          <DividerSection dividerColor={Colors.WHITE} style={{ width: 'calc(100% - 35px)' }} />
        </FirstPartMobile>

        <List style={{ margin: 0, padding: '10px 0px' }}>
          <NavLink onClick={() => changeOpenSidebar(false)} to="/" isopen={String(open)}>
            <SidebarListItem open={open} onClick={removeScrollTop}>
              <Icon
                iconName="media"
                style={{ cursor: 'pointer' }}
                iconColor={getLabelColor(isMediaGalleryClicked, mobileLabelColor)}
              />
              <ListItemText
                primary={
                  <Typography
                    variantName="table-header"
                    textColor={getLabelColor(isMediaGalleryClicked, mobileLabelColor)}
                  >
                    Media gallery
                  </Typography>
                }
              />
            </SidebarListItem>
          </NavLink>

          <NavLink onClick={() => changeOpenSidebar(false)} to="/my-media" isopen={String(open)}>
            <SidebarListItem open={open} onClick={removeScrollTop}>
              <NotCompletedBadge iconColor={getLabelColor(isMyMediaClicked, mobileLabelColor)} />
              <ListItemText
                primary={
                  <Typography
                    variantName="table-header"
                    textColor={getLabelColor(isMyMediaClicked, mobileLabelColor)}
                  >
                    My Media
                  </Typography>
                }
              />
            </SidebarListItem>
          </NavLink>

          <NavLink onClick={() => changeOpenSidebar(false)} to="/my-pins" isopen={String(open)}>
            <SidebarListItem open={open} onClick={removeScrollTop}>
              <Icon
                iconName="bookmark-full"
                style={{ cursor: 'pointer' }}
                iconColor={getLabelColor(isSavedClicked, mobileLabelColor)}
              />
              <ListItemText
                primary={
                  <Typography
                    variantName="table-header"
                    textColor={getLabelColor(isSavedClicked, mobileLabelColor)}
                  >
                    Saved
                  </Typography>
                }
              />
            </SidebarListItem>
          </NavLink>

          <NavLink
            onClick={() => changeOpenSidebar(false)}
            to="/metadata/brands"
            isopen={String(open)}
          >
            <SidebarListItem
              open={open}
              style={{ zIndex: 1 }}
              onClick={() => {
                removeScrollTop();
                onChangeMetadataTable(MetadataType.BRANDS);
              }}
            >
              <MetadataIcon color={getLabelColor(isMetadataClicked, mobileLabelColor)} />
              <ListItemText
                primary={
                  <Typography
                    variantName="table-header"
                    textColor={getLabelColor(isMetadataClicked, mobileLabelColor)}
                  >
                    Metadata
                  </Typography>
                }
              />
            </SidebarListItem>
          </NavLink>

          <SubMenuIconTransition
            onClick={() => {
              handleCreateMetadataDialog(true);
              changeOpenSidebar(false);
            }}
            style={{ width: 'auto', top: 0 }}
          >
            <ButtonIcon
              iconName="add"
              iconColor={getLabelColor(isMetadataClicked, mobileLabelColor)}
            />
            <ListItemText
              primary={
                <Typography
                  variantName="text"
                  textColor={getLabelColor(isMetadataClicked, mobileLabelColor)}
                  style={{ cursor: 'pointer' }}
                >
                  New
                </Typography>
              }
            />
          </SubMenuIconTransition>
        </List>
      </DrawerTemporary>
    </>
  );
};

export default SidebarMobile;
