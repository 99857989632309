import { ItemType } from '../types/ItemType';

const facebook = require('../assets/cutType/facebook.svg');
const instagram = require('../assets/cutType/instagram.svg');
const instagramTv = require('../assets/cutType/instagramTv.svg');
const youtube = require('../assets/cutType/youtube.svg');
const linkedin = require('../assets/cutType/in.svg');
const tiktok = require('../assets/cutType/tiktok.svg');

export const VideoCutsMap = {

  [ItemType.VIDEO_FACEBOOK]: facebook,
  [ItemType.IMAGE_FACEBOOK]: facebook,
  [ItemType.VIDEO_INSTAGRAM]: instagram,
  [ItemType.IMAGE_INSTAGRAM]: instagram,

  [ItemType.VIDEO_YOUTUBE]: youtube,
  //[ItemType.VIDEO_INSTAGRAM_TV]: instagramTv,
  [ItemType.VIDEO_LINKEDIN]: linkedin,
  [ItemType.IMAGE_LINKEDIN]: linkedin,
  //[ItemType.IMAGE_INSTAGRAM_STORY ] :instagram, //??
  [ItemType.VIDEO_TIKTOK]: tiktok,
  [ItemType.IMAGE_TIKTOK]: tiktok
};



export const PlatformsCutMap = {
  [ItemType.IMAGE_INSTAGRAM ]: instagram,
  [ItemType.IMAGE_INSTAGRAM_CAROUSEL] : instagram,
  [ItemType.IMAGE_TIKTOK_CAROUSEL] : tiktok,
  [ItemType.IMAGE_TIKTOK] : tiktok,
  [ItemType.IMAGE_FACEBOOK] :facebook,
  [ItemType.IMAGE_LINKEDIN ] :linkedin,
  [ItemType.IMAGE_INSTAGRAM_STORY ] :instagram,

  [ItemType.VIDEO_FACEBOOK]: facebook,
  [ItemType.VIDEO_INSTAGRAM]: instagram,
  [ItemType.VIDEO_YOUTUBE]: youtube,
  [ItemType.VIDEO_INSTAGRAM_TV]: instagramTv,
  [ItemType.VIDEO_LINKEDIN]: linkedin,
  [ItemType.VIDEO_TIKTOK]: tiktok
};

export const DescriptionCutsMap = {

  [ItemType.IMAGE_INSTAGRAM ]: 'Instagram',
  [ItemType.IMAGE_INSTAGRAM_CAROUSEL] : 'Instagram',
  [ItemType.IMAGE_TIKTOK_CAROUSEL] : 'TikTok',
  [ItemType.IMAGE_TIKTOK] : 'TikTok',

  [ItemType.IMAGE_FACEBOOK] :'Facebook',
  [ItemType.IMAGE_LINKEDIN ] :'LinkedIn',
  [ItemType.IMAGE_INSTAGRAM_STORY ] :'Instagram',

  [ItemType.VIDEO_FACEBOOK]: 'Facebook',
  [ItemType.VIDEO_INSTAGRAM]: 'Instagram',
  [ItemType.VIDEO_YOUTUBE]: 'Youtube',
  [ItemType.VIDEO_INSTAGRAM_TV]: 'Instagram TV',
  [ItemType.VIDEO_LINKEDIN]: 'LinkedIn',
  [ItemType.VIDEO_TIKTOK]: 'TikTok',
};
