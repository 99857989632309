import styled from 'styled-components';
import { Button, ButtonIcon, InteractiveAvatar } from '@freeda/react-components';

import { zIndex } from '../../../utils/zIndex';

const StyledCard = styled.div<{ highlighted: string } & React.HTMLProps<HTMLDivElement>>`
  position: relative;
  background: #ffffff;
  width: 270px;
  height: 270px;
  border-width: 1px;
  border-color: ${props =>
    props.highlighted === 'true' ? props.theme.rossino : props.theme.azzurrino};
  border-style: solid;
  cursor: pointer;

  && {
    box-shadow: 0px 0px 0px 0px;
    border-radius: 0px;
  }

  @media (max-width: 768px) {
    width: 135px;
    height: 135px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  margin-left: 15px;
  margin-right: 15px;

  & > div:not(last-child) {
    margin-right: 3px;
  }
`;

const AuthorAvatar = styled(InteractiveAvatar)`
  && {
    width: 30px;
    height: 30px;
    font-size: 13px;
    font-style: italic;
    font-weight: bold;
    color: #000000;
    background-color: #d8d8d8;
  }
`;

const Content = styled.div`
  background: #ffffff;
  width: 100%;
  height: 89px;
  position: absolute;
  bottom: 0px;
  left: 0px;

  @media (max-width: 768px) {
    height: 75px;
  }
`;

const ImagePreview = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const VideoPreview = styled.video`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const TitleWrapper = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  & > p {
    word-break: break-all;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

const OverlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(91, 24, 190, 0.8);
  z-index: ${zIndex.OverlayWrapper};
`;

const ActionButtons = styled.div`
  position: absolute;
  display: flex;
  top: 10px;
  right: 5px;
`;

const ActionIcon = styled(ButtonIcon)`
  && {
    margin-right: 3px;
    & > i {
      color: #ffffff;
    }
  }
`;

const EditContentButton = styled(Button)`
  && {
    width: 220px;
    font-size: 16px;
    background-color: ${props => props.theme.azzurrinoTransparent};
    font-weight: bold;
    &:hover {
      background: ${props => props.theme.azzurrinoTransparent};
    }
    &:active {
      background-color: rgba(184, 255, 237, 0.6);
      background-size: 100%;
      transition: background 0s;
    }
  }
`;

const SelectedHoverWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(91, 24, 190, 0.8);
  z-index: ${zIndex.SelectedHoverWrapper};
`;

const VideoLengthWrapper = styled.div`
  margin-left: auto;
  margin-right: 15px;
  align-self: flex-end;
`;

export {
  StyledCard,
  AvatarWrapper,
  AuthorAvatar,
  Content,
  ImagePreview,
  VideoPreview,
  TitleWrapper,
  OverlayWrapper,
  ActionButtons,
  ActionIcon,
  EditContentButton,
  SelectedHoverWrapper,
  VideoLengthWrapper
};
