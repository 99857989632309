import styled from 'styled-components';
import { zIndex } from '../../../utils/zIndex';

const Overlay = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(91, 24, 190, 0.8);
  z-index: ${zIndex.Overlay};
`;

export { Overlay };
