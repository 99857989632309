import * as React from 'react';
import { ListContentToMergeQuery } from '../components/queries/queries2.0/ListContentToMergeQuery';
import { ParentContent } from '../types/types2.0/ParentContent';
import { MediaToMergeList } from '../components/layout/EditMediaPage/steps/newCountry/MergeMediaStep2.0/MediaToMergeList';
import { Country } from '../types/graphql/Country';

interface Props {
  toExclude: string;
  format?: string;
  distributionCountry: Country;
  projectId: string;
  mimeType: string;
  selected: string | null;
  searchValue: string;
  parentFilter?: (mediaList: Array<ParentContent>) => Array<ParentContent>;
  onSelect: (media: ParentContent) => void;
}

const ListMediaToMerge: React.FC<Props> = ({
  toExclude,
  format,
  distributionCountry,
  projectId,
  mimeType,
  selected,
  searchValue,
  parentFilter,
  onSelect
}) => {
  const keywords =  searchValue
    .split(' ')
    .map(name => ({ name }
    ))
    .filter(param => param.name !== ''); 

  const title = searchValue  ;
 
  return (
    <ListContentToMergeQuery fetchPolicy="no-cache" variables={{ toExclude, title, keywords, format , distributionCountry, projectId, mimeType}}>
      {({ loading, error, data }) => {
        if (error) {
          return 'Error!';
        }

        const media: Array<{ node: ParentContent }> =
          !data || !data.listContentToMerge ? [] : data.listContentToMerge.edges;

        const mediaList = media.map(m => m.node);
        const filteredMediaList = parentFilter ? parentFilter(mediaList) : mediaList;

        return (
          <MediaToMergeList mediaList={filteredMediaList} selected={selected} onSelect={onSelect} />
        );
      }}
    </ListContentToMergeQuery>
  );
};

export { ListMediaToMerge };
