import styled from 'styled-components';
import { Stepper } from '@freeda/react-components';
import { Stepper as StepperProp } from '@freeda/react-components/lib/Stepper';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  padding: 45px 0px 35px;
  box-sizing: border-box;
  font-size: 24px;
  color: #000000;
`;

const StyledStepper = styled(Stepper)<StepperProp.Props>`
  && {
    padding: 0px 120px;
    margin: 55px 0px;
    box-sizing: border-box;
    @media (max-width: 1000px) {
      padding: 0px 50px;
    }
    @media (max-width: 768px) {
      padding: 0px 20px;
    }
  }
`;

export { Wrapper, StyledStepper };
