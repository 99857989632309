export const zIndex = {
  BarWrapper: 2005,
  PaperMenu: 2020,
  Overlay: 2001,
  FilterWrapper: 2015,
  OverlayWrapper: 1090,
  SelectedHoverWrapper: 1094,
  Sidebar: 2000,
  Cane: 1098,
  CheckBoxWrapper: 1085,
  Monogramma: 1097,
  StyledListItemIcon: 1090,
  Snackbar: 2026,
  Spinnerone: 2015,
  FreedaLogo: 1097,
  Dialog: 2025,
  AppBar: 1096,
  Select: 1095,
  InteractiveAvatar: 2030,
  Tooltip: 2030,
  SidebarMobile: 2010,
  LateralPanel: 2006
};
