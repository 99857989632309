import * as React from 'react';
import styled from 'styled-components';

const MediaWrapper = styled.div`
  width: 135px;
  height: 135px;
  background: #f5f5f5;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
`;

const Check = styled.div`
  width: 15px;
  height: 15px;
  border: 3px solid #e6e6e6;
`;

const PlaceholderMediaMobile: React.FC = () => (
  <MediaWrapper>
    <Check />
  </MediaWrapper>
);

export { PlaceholderMediaMobile };
