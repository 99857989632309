export enum CutType {
  
  VIDEO_FACEBOOK = 'VIDEO_FACEBOOK',
  VIDEO_INSTAGRAM = 'VIDEO_INSTAGRAM',
  VIDEO_INSTAGRAM_STORY = 'VIDEO_INSTAGRAM_STORY',
  VIDEO_YOUTUBE = 'VIDEO_YOUTUBE',
  VIDEO_INSTAGRAM_TV = 'VIDEO_INSTAGRAM_TV',
  VIDEO_LINKEDIN = 'VIDEO_LINKEDIN',
  VIDEO_TIKTOK = 'VIDEO_TIKTOK',

  IMAGE_FACEBOOK = 'IMAGE_FACEBOOK',
  IMAGE_INSTAGRAM = 'IMAGE_INSTAGRAM',
  IMAGE_LINKEDIN = 'IMAGE_LINKEDIN',
  IMAGE_INSTAGRAM_STORY = 'IMAGE_INSTAGRAM_STORY',
  IMAGE_TIKTOK = 'IMAGE_TIKTOK',
}
