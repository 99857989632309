import * as React from 'react';
import { MetadataType } from '../types/MetadataType';
//import { Brand } from '../types/Brand';
import { Typography, ButtonIcon, Button } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
//import { Serie } from '../types/Serie';
//import { Topic } from '../types/Topic';
import { Serie } from '../types/types2.0/Serie';
import { Campaign } from '../types/types2.0/Campaign';
import { Topic } from '../types/types2.0/Topic';
import { Brand } from '../types/types2.0/Brand';
import { WrapperTableFilter } from '../pages/MetadataPage2.0';
import { Label } from '../types/types2.0/Label';
import {ProductType} from '../types/types2.0/ProductType'
import {Roles} from './roles'

export const buildMetadataTableHead = (metadataType: MetadataType) => {
  const brandHead = {
    desktop: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'industry', label: 'Industry', sortable: false },
     /* { id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' }*/
    ],
    mobile: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'industry', label: 'Industry', sortable: false },
     /* { id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' }*/
    ]
  };

  const serieHead = {
    desktop: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'project', label: 'Project', sortable: false },
      { id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' },
      { id: 'delete', label: 'Delete', sortable: false, resizable: true, width: '80px' }
    ],
    mobile: [
      { id: 'name', label: 'Name', sortable: true },
      //{ id: 'productTypes', label: 'Product types', sortable: false },
      //{ id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' }
    ]
  };

  const campaignHead = {
    desktop: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'brand', label: 'Brand', sortable: false },
    ],
    mobile: [
      { id: 'name', label: 'Name', sortable: true },
    ]
  };

  const formatHead = {
    desktop: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'category', label: 'Category', sortable: false },
      //{ id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' }

    ],
    mobile: [
      { id: 'name', label: 'Name', sortable: false },
    ]
  };

  const topicHead = {
    desktop: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'macroTopic', label: 'Macro Topic', sortable: false },
      { id: 'project', label: 'Project', sortable: false },

      { id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' },
      { id: 'delete', label: 'Delete', sortable: false, resizable: true, width: '80px' }
    ],
    mobile: [
      { id: 'name', label: 'Name', sortable: true },
      //{ id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' }
    ]
  };


  const labelHead = {
    desktop: [
      { id: 'name', label: 'Name', sortable: true },
      { id: 'project', label: 'Project', sortable: false },
      { id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' },
      { id: 'delete', label: 'Delete', sortable: false, resizable: true, width: '80px' }
    ],
    mobile: [
      { id: 'name', label: 'Name', sortable: true },
      //{ id: 'productTypes', label: 'Product types', sortable: false },
      //{ id: 'edit', label: 'Edit', sortable: false, resizable: true, width: '80px' }
    ]
  };

  const head = {
    [MetadataType.BRANDS]: brandHead,
    [MetadataType.SERIES]: serieHead,
    [MetadataType.TOPICS]: topicHead,
    [MetadataType.CAMPAIGNS]: campaignHead,

    [MetadataType.BOOLEANS]: labelHead,
    [MetadataType.EDITORIAL_CAMPAIGNS]: labelHead,
    [MetadataType.FORMATS]: formatHead,
    [MetadataType.GENRES]: labelHead,
    [MetadataType.KEYWORDS]: labelHead,
    [MetadataType.OBJECTIVES]: labelHead,
    [MetadataType.PRODUCTS]: labelHead,
    [MetadataType.TEMPERATURES]: labelHead

  };

  return head[metadataType];
};

export const buildMetadataTableColumns = (
  metadataType: MetadataType,
  onOpenEditMetadata: (metadataId: string) => void,
  onDeleteLabelDialog: (metadataId: string) => void
) => {

  //qui
  const roles:string = localStorage.getItem('roles')!;
  const change:boolean = roles.includes(Roles['admin'])

  const brandColumns = [
    {
      id: 'name',
      render: (brand: Brand) => <Typography variantName="table-body">{brand.name}</Typography>
    },
    {
      id: 'category',
      render: (brand: Brand) => (
        <Typography variantName="table-body">{brand.brand_category}</Typography>
      )
    },
    {
      id: 'edit',
      render: (metadata: { id: string }) => (
        <ButtonIcon
          iconName="edit"
          iconColor={Colors.GRIGINO}
          onClick={() => onOpenEditMetadata(metadata.id)}
        />
      )
    }
  ];

  const serieColumns = [
    {
      id: 'name',
      render: (serie: Serie) => <Typography variantName="table-body">{serie.name}</Typography>
    },
   {
      id: 'project',
      render: (serie: Serie) => (
        <Typography variantName="table-body">
       
         { (serie.project && serie.project.length>0) ? 
            serie.project.map(pT =>  pT ?  `${pT.name}` :'').join(', ') 
            : 'All projects'
          }

         
        </Typography>
      )
    },
    {
      id: 'edit',
      render: (metadata: { id: string }) => (
        
          <ButtonIcon
            iconName={change ? "edit": "close"} //remove
            iconColor={Colors.GRIGINO}
            onClick={() => change ? onOpenEditMetadata(metadata.id) : null}
          />
          
        
        
      )
    },
    {
      id: 'delete',
      render: (metadata: { id: string }) => (
        
          <ButtonIcon
            iconName={change ? "delete": "close"} //remove
            iconColor={Colors.GRIGINO}
            onClick={() => change ? onDeleteLabelDialog(metadata.id) : null}
          />
      )
    }
  ];


  const labelColumns = [
    {
      id: 'name',
      render: (l: Label) => <Typography variantName="table-body">{l.name}</Typography>
    },
   {
      id: 'project',
      render: (l: Label) => (
        <Typography variantName="table-body">
       
         { (l.project && l.project.length>0) ? 
            l.project.map(pT =>  pT ?  `${pT.name}` :'').join(', ') 
            : 'All projects'
          }

         
        </Typography>
      )
    },
    {
      id: 'edit',
      render: (metadata: { id: string }) => (
        <ButtonIcon
            iconName={change ? "edit": "close"} //remove
            iconColor={Colors.GRIGINO}
            onClick={() => change ? onOpenEditMetadata(metadata.id) : null}
          />
      )
    },
    {
      id: 'delete',
      render: (metadata: { id: string }) => (
        
          <ButtonIcon
            iconName={change ? "delete": "close"} //remove
            iconColor={Colors.GRIGINO}
            onClick={() => change ? onDeleteLabelDialog(metadata.id) : null}
          />
      )
    }
  ];

  const formatColumns = [
    {
      id: 'name',
      render: (l: Label) => <Typography variantName="table-body">{l.name}</Typography>
    },
   {
      id: 'category',
      render: (l: ProductType) => (
        <Typography variantName="table-body">
          { (l.format ) ?  l.format  : '' }
        </Typography>
      )
    },
    {
      id: 'edit',
      render: (metadata: { id: string }) => (
        <ButtonIcon
          iconName="edit"
          iconColor={Colors.GRIGINO}
          //onClick={() => onOpenEditMetadata(metadata.id)}
        />
      )
    }
  ];

  const campaignColumns = [
    {
      id: 'name',
      render: (campaign: Campaign) => <Typography variantName="table-body">{campaign.name}</Typography>
    },
   {
      id: 'brand',
      render: (campaign: Campaign) => (
        <Typography variantName="table-body">
       
         { (campaign.brand && campaign.brand.length>0) ? 
            campaign.brand.map(pT =>  pT ?  `${pT.name}` :'').join(', ') 
            : ''
          }
        </Typography>
      )
    },
    {
      id: 'edit',
      render: (metadata: { id: string }) => (
        <ButtonIcon
          iconName="edit"
          iconColor={Colors.GRIGINO}
          //onClick={() => void()}
        />
      )
    }
  ];

  const topicColumns = [
    {
      id: 'name',
      render: (item: Topic) => <Typography variantName="table-body">{item.name}</Typography>
    },
    {
      id: 'macroTopic',
      render: (topic: Topic) => (
        <Typography variantName="table-body">
          { (topic.macroTopic != null) ? topic.macroTopic: ''}
        </Typography>
      )
    },

    {
      id: 'project',
      render: (topic: Topic) => (
        <Typography variantName="table-body">
          { (topic.project != null && topic.project.length>0) ? topic.project.map(pT => `${pT.name}`).join(', '): 'All Projects'}
        </Typography>
      )
    },
    {
      id: 'edit',
      render: (metadata: { id: string }) => (
        <ButtonIcon
            iconName={change ? "edit": "close"} //remove
            iconColor={Colors.GRIGINO}
            onClick={() => change ? onOpenEditMetadata(metadata.id) : null}
          />
      )
    },
    {
      id: 'delete',
      render: (metadata: { id: string }) => (
        
          <ButtonIcon
            iconName={change ? "delete": "close"} //remove
            iconColor={Colors.GRIGINO}
            onClick={() => change ? onDeleteLabelDialog(metadata.id) : null}
          />
      )
    }
  ];

  const columns = {
    [MetadataType.BRANDS]: brandColumns,
    [MetadataType.SERIES]: serieColumns,
    [MetadataType.CAMPAIGNS]: campaignColumns,
    [MetadataType.TOPICS]: topicColumns,

    [MetadataType.BOOLEANS]: labelColumns,
    [MetadataType.EDITORIAL_CAMPAIGNS]: labelColumns,
    [MetadataType.FORMATS]: formatColumns,
    [MetadataType.GENRES]: labelColumns,
    [MetadataType.KEYWORDS]: labelColumns,
    [MetadataType.OBJECTIVES]: labelColumns,
    [MetadataType.PRODUCTS]: labelColumns,
    [MetadataType.TEMPERATURES]: labelColumns
  };

  return columns[metadataType];
};

export const getMetadataTableFilter = (
  count: number,
  onOpenCreateMetadataDialog: () => void
): JSX.Element => {
  
  const roles:string = localStorage.getItem('roles')!;
  const change:boolean = roles.includes(Roles['admin'])

  return (
    <WrapperTableFilter>
      <Typography variantName="italic-label" style={{ textTransform: 'initial' }}>
        <>Number of items: {count}</>
      </Typography>
    
      {change && <Button onClick={onOpenCreateMetadataDialog}>Add new</Button>}
    </WrapperTableFilter>
  );
};

export const getMetadataTableFilterNoCreation = (
  count: number,
  onOpenCreateMetadataDialog: () => void
): JSX.Element => {
  return (
    <WrapperTableFilter>
      <Typography variantName="italic-label" style={{ textTransform: 'initial' }}>
        <>Number of items: {count}</>
      </Typography>
    </WrapperTableFilter>
  );
};

