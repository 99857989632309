import gql from 'graphql-tag';

const GET_EDIT_FORM_INFO = gql`
  query editDialog {
    
      editDialog{
        
        allProductTypes{
          id
          name
          format
        }

        allBrands{
          id
          name
        }

        allCampaigns{
          id
          name
          brand{
            id
            name
            brand_category
          }
        }

        allEditorialCampaigns{
          id
          name
        }

        allSeries {
          id
          name
          project{
            id
            name
          }
        }
        allTopics{
          id
          name
        }
        allKeywords {
          id
          name
          project{
            id
            name
          }
        }
        allTeamMembers {
            id
            name

        }
        allContributors {
          id
          name
        }

        allProjects {
          id
          name
        }
        allObjectives{
          id
          name
        }

        allProducts{
          id
          name
          project{
            id
            name
          }
        }

        allTemperatures{
          id
          name
        }

        allGenres{
          id
          name
        }

        allEditorialCampaigns{
          id
          name
          project{
            id
            name
          }
        }

        allBooleans{
          id
          name
        }

        
    }
  }
`;

export { GET_EDIT_FORM_INFO };
