import * as React from 'react';
import MediaPage from '../pages/MediaPage2.0';
import { ParentContent } from '../types/types2.0/ParentContent';

//import { ListMyPinsQuery } from '../components/queries/ListMyPinsQuery';
//import { addPinToCache, removePinFromCache } from '../components/mutations/PinMutation';
import { ITEMS_PER_PAGE } from '../utils/constants';

import { get } from 'lodash';
import { difference } from 'lodash';
import { FilterContext } from '../providers/FilterProvider2.0';
import { ParentContentSelection } from '../types/types2.0/ParentContentSelection';

import {
  FetchedCounterType,
  FetchedItemsCounterContext
} from '../providers/FetchedItemsCounterProvider';

//import { RefreshSpan } from '../components/RefreshSpan';
import { DownloadMultiMediaDialogContext } from '../providers/DownloadMultiDialogProvider2.0';
import { GetUsersQuery } from '../components/queries/queries2.0/GetUsersQuery';
import { CustomQueryProps } from '../types/graphql/utils';
import  ListContentQuery from '../types/types2.0/graphql2.0/ListContentQuery'
//import  ListContentToMergeQuery from '../types/types2.0/graphql2.0/ListContentQuery'
import { isEmpty } from 'lodash';

interface Props {
  selectedMedia: Array<ParentContentSelection>;
  fetchedItemsCounterType: FetchedCounterType;
  onCardSelected: (parentContent: ParentContent) => void;
  deselectAll: () => void;
  ContentQuery: React.FC<CustomQueryProps<ListContentQuery>>;
  renderWarning?: () => JSX.Element;
  orderInfo: string;
}

class MediaPageContainer extends React.Component<Props> {

  render() {
    const {
      onCardSelected,
      selectedMedia,
      fetchedItemsCounterType,
      deselectAll,
      ContentQuery,
      renderWarning,
      orderInfo
    } = this.props;
    
    let mediaAll: Array<{node:ParentContent}> = []
    return (
      <DownloadMultiMediaDialogContext.Consumer>
        {multiDownloadAction => (
          <FetchedItemsCounterContext.Consumer> 
            
            { fetchedItemsCounterContext => (

              <FilterContext.Consumer>

                {({ getQueryVariables }) => {

                  const filterVariables = getQueryVariables();
                  return (
                    <ContentQuery
                      variables={{
                        skip: fetchedItemsCounterContext[fetchedItemsCounterType],
                        first: ITEMS_PER_PAGE,
                        ...filterVariables
                      }}
                      notifyOnNetworkStatusChange = {true}
                      fetchPolicy={isEmpty(filterVariables)? "cache-first" : "network-only"}
                    >

                      {mediaQueryResult => {
                        
                        if (mediaQueryResult.error) {
                          return `Error in loading contents !! ${mediaQueryResult.error.message}`;
                        }
                        const data = mediaQueryResult.data;
                        const hasNextPage =  get(data, 'listParentContents.pageInfo.hasNextPage', false) ;
                        
                        //QUI
                        
                        if(!mediaQueryResult.loading){
                            
                            const media: Array<{ node: ParentContent }> =
                                                              !data || !data.listParentContents ? [] : data.listParentContents.edges;

                            if(fetchedItemsCounterContext[fetchedItemsCounterType] == 0){
                              mediaAll = []
                            }
                          
                            if( media.length>0 || (media.length==0 && fetchedItemsCounterContext[fetchedItemsCounterType] > 0 ) ){ 
                                  let differenceAll = difference(media, mediaAll)
                                  console.log('LIST skip/first/difference', fetchedItemsCounterContext[fetchedItemsCounterType], ITEMS_PER_PAGE, differenceAll.length)

                                  mediaAll = [
                                    ...mediaAll,
                                    ...differenceAll
                                  ]
                                }
                                else{ //non ci sono contentuti
                                  mediaAll = []
                            }
                            //console.log("prova",fetchedItemsCounterContext[fetchedItemsCounterType], media.length, mediaAll.length)
                            
                       }
                        
                        const cleanList = fetchedItemsCounterContext[fetchedItemsCounterType]==0 && !isEmpty(filterVariables) && mediaQueryResult.loading; 
                        
                        if( cleanList ){
                          mediaAll = []
                        }
                        
                                          
                        return (
                          <GetUsersQuery>

                            {getUserQueryResult => {
                              if (getUserQueryResult.error) {
                                console.log('ERRORE ', getUserQueryResult.error)
                                
                              }
                             
                              const userQueryData = getUserQueryResult.data;
                              const users:any =
                                !getUserQueryResult.loading &&
                                userQueryData &&
                                userQueryData.listUsers
                                  ? userQueryData.listUsers
                                  : [];


                                return (
                                  <MediaPage
                                    renderWarning={renderWarning}
                                    media={mediaAll
                                      .filter(m =>  m.node.localizations && m.node.localizations.length > 0 )
                                      .map(m => ({
                                        ...m.node,
                                        selected: false
                                      }))}
                                    users={users}
                                    onCardSelected={onCardSelected}
                                    deselectAll={deselectAll}
                                    
                                    loading={
                                      mediaQueryResult.loading 
                                    }
                                    selectedContent={selectedMedia}
                                    orderInfo={orderInfo}
                                    hasNextPage={hasNextPage}
                                    cleanList={cleanList}

                                    //onDownload={rootId => downloadMediaDialogContext.onOpen(rootId)}
                                    onOpenMultiDownload={ ()=> multiDownloadAction.onOpen(selectedMedia) }
                                   
                                    onLoadMore={() => {
                                      console.log('ON LOAD MORE')
                                      mediaQueryResult.fetchMore({
                                        variables: {
                                          skip: fetchedItemsCounterContext[fetchedItemsCounterType],
                                          first: ITEMS_PER_PAGE,
                                          keywords: filterVariables.keywords,
                                          title: filterVariables.title,
                                          serie: filterVariables.serie,
                                          brand: filterVariables.brand,
                                          contentType_in: filterVariables.contentType_in,
                                          project: filterVariables.project,
                                          reel:filterVariables.reel,
                                          productType: filterVariables.productType,
                                          language: filterVariables.language,
                                          members: filterVariables.members,
                                          contributors: filterVariables.contributors,
                                          macroTopic:filterVariables.macroTopic,
                                          topic: filterVariables.topic,
                                          productionCountry: filterVariables.productionCountry,
                                          distributionCountry: filterVariables.distributionCountry,
                                          itemType: filterVariables.itemType,
                                          temperature:filterVariables.temperature,
                                          genre:filterVariables.genre,
                                          objective:filterVariables.objective,
                                          campaign:filterVariables.campaign,
                                          editorialCampaign:filterVariables.editorialCampaign,
                                          product:filterVariables.product,
                                          completion:filterVariables.completion
                                        },

                                        updateQuery: (prev, { fetchMoreResult }) => {
                                          
                                          if (!fetchMoreResult) {
                                            return prev;
                                          }
                                       
                                          fetchedItemsCounterContext.updateMediaFetchedCounter(
                                            fetchedItemsCounterType
                                          )(ITEMS_PER_PAGE);
                                          

                                          return Object.assign({}, prev.listParentContents.edges, {
                                            listParentContents: {
                                              ...fetchMoreResult.listParentContents,
                                              edges: [
                                                ...prev.listParentContents.edges,
                                                ...fetchMoreResult.listParentContents.edges
                                              ]
                                            }
                                          });
                                        }
                                      });
                                    }}
                                  />
                                );
                            }}
                          </GetUsersQuery>
                        );
                      }}
                    </ContentQuery>
                  );
                }}
                
              </FilterContext.Consumer>
            )}
          </FetchedItemsCounterContext.Consumer>
        )}
      </DownloadMultiMediaDialogContext.Consumer>
    );
  }
}

export default MediaPageContainer;
