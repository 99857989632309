import * as React from 'react';
import { Order } from '../types/Order';
import { MetadataFilterContext, InitialStateFilterContext } from './BrandsFilterProvider2.0';

const initialState: InitialStateFilterContext = {
  searchValue: '',
  selectedBrandCategoryName: null,
  selectedMacroTopicName: null,
  selectedBrand:null,
  selectedProjectIds: [],
  order: 'ASC',
  orderBy: 'name'
};

const TopicsFilterContext = React.createContext({} as MetadataFilterContext);

const TopicsFilterProvider: React.FC = ({ children }) => {
  const [searchValue, setSearchValue] = React.useState<string>(initialState.searchValue);
  
  const [selectedBrandCategoryName, setBrandCategoryName] = React.useState<string | null>(
    initialState.selectedBrandCategoryName
  );

  const [selectedMacroTopicName, setMacroTopicName] = React.useState<string | null>(
    initialState.selectedMacroTopicName
  );

  const [selectedBrand, setBrand] = React.useState<string | null>(
    initialState.selectedBrand
  );

  const [selectedProjectIds, setProjectIds] = React.useState<Array<string>>(
    initialState.selectedProjectIds
  );
 
  const [order, setOrder] = React.useState<Order>(initialState.order);
  const [orderBy, setOrderBy] = React.useState<string | null>(initialState.orderBy);

  const onChangeSearchValue = (value: string) => setSearchValue(value);

  const onChangeBrandCategoryName = (name: string | null) => setBrandCategoryName(name);

  const onChangeProjectIds = (ids: Array<string>) => setProjectIds(ids);
  const onChangeMacroTopicName = (name: string | null) => setMacroTopicName(name);
  const onChangeBrand = (name: string | null) => setBrand(name);




  const onChangeMetadataOrder = (orderBy: string | null, order: Order) => {
    setOrder(order);
    setOrderBy(orderBy);
  };

  const clearFilter = () => {
    setSearchValue(initialState.searchValue);
    setBrandCategoryName(initialState.selectedBrandCategoryName);
    setMacroTopicName(initialState.selectedMacroTopicName);
  };

  return (
    <TopicsFilterContext.Provider
      value={{
        searchValue,
        selectedBrandCategoryName,
        selectedMacroTopicName,
        selectedBrand,
        selectedProjectIds,
        order,
        orderBy,
        onChangeSearchValue,
        onChangeBrandCategoryName,
        onChangeMacroTopicName,
        onChangeBrand,
        onChangeMetadataOrder,
        onChangeProjectIds,
        clearFilter
      }}
    >
      {children}
    </TopicsFilterContext.Provider>
  );
};

export { TopicsFilterProvider, TopicsFilterContext };
