import * as React from 'react';
import Media from 'react-media';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, Input } from '@freeda/react-components';

import { ExternalWrapper, InputWrapperStyled } from './styles';
import { FeedbackContext } from '../../../providers/FeedbackProvider';

interface Props {
  externalLink: string | null;
}
const WithExternalForm: React.FC<Props> = ({ externalLink }) => (
  <Media query={{ maxWidth: 768 }}>
    {(matches: boolean) => (
      <FeedbackContext.Consumer>
        {({ onOpen }) => (
          <ExternalWrapper>
            <div style={{ flexBasis: !matches ? '70%' : '' }}>
              {matches && (
                <div style={{ marginBottom: matches ? 10 : '' }}>
                  Copy and paste the link below to share the media with external people
                </div>
              )}
              <InputWrapperStyled height={35}>
                <Input value={externalLink || ''} disabled />
              </InputWrapperStyled>
            </div>
            <CopyToClipboard
              onCopy={() => onOpen('Link COPIED!', 'notification')}
              text={externalLink || ''}
            >
              <Button
                style={{
                  width: matches ? '100%' : 150,
                  flexBasis: !matches ? '30%' : '',
                  marginTop: matches ? 10 : 0,
                  marginBottom: 0
                }}
              >
                Copy link
              </Button>
            </CopyToClipboard>
          </ExternalWrapper>
        )}
      </FeedbackContext.Consumer>
    )}
  </Media>
);

export { WithExternalForm };
