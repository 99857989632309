import gql from 'graphql-tag';

//qui
const DELETE_PARENT_CONTENT = gql`
  mutation deleteParentContent($id: ID!) {
    deleteParentContent(id: $id) {
      parentDeleted
    }
  }
`;

export { DELETE_PARENT_CONTENT };
