import { ComponentContent } from '../types/types2.0/ComponentContent';
import { PlatformContent } from '../types/types2.0/PlatformContent';
import { EnhancedParentContent, EnhancedPlatformContent } from '../types/types2.0/EnhancedContent';
import { ItemType } from '../types/ItemType';
import { EnhancedLocalContent } from '../types/types2.0/EnhancedContent';
import { Country } from '../types/graphql/Country';
import { omit } from 'lodash';
import { LocalContent } from '../types/types2.0/LocalContent';
import { Label } from '../types/types2.0/Label';


export interface Content {
  title: string;
  mimeType: string;
  storagePath: string;
  itemType: string; 
  //localizations: Array<LocalContent>;
}


export const removeSchedule = (platform:EnhancedPlatformContent) : any =>{
    let del = delete platform["schedule"]
    console.log('removeSchedule', del)
    return del
}


export const stringifyMedia = (
  media: EnhancedParentContent,
  locations: Array<EnhancedLocalContent>
) : any =>{
   
  let locNoSchedule = locations
                      .map(t => ({
                        ...t,
                        platforms: t.platforms
                          .map(p=> omit(p, 'schedule'))
                      }))
 
  return JSON.stringify({
    parent: omit(media, 'localizations'),
    locations: locNoSchedule
      /*.map(t => ({
        ...t,
        members: t.members ? t.members : null,
        platforms: t.platforms
          .map(p => ({
            ...p,
            components: p.components
              .map(c => ({
                ...c,
              }))
          }))
          .map(p=> omit(p, 'schedule'))
      }))*/
      .map(t => omit(t, 'updatedAt')),
    addedKeywords: [],
    removedKeywords: [],
    newKeywords: []
  });
}


 

export const getFirstAvailablePlatformContentType = (
  
  itemsItemType?: Array<ItemType>,
  cutParams?: string
): ItemType => {
  
  const cutTypeArray = Object.keys(ItemType);
  let firstAvailableItemType: ItemType;
  for (let idx = 0; idx < cutTypeArray.length; idx++) {
    const itemType = itemsItemType?.find(it => it === cutTypeArray[idx]);
    if (itemType) {
      firstAvailableItemType = itemType;
      break;
    }
  }
  return itemsItemType?.find(it => it === cutParams) || firstAvailableItemType!;
};

export const getFirstAvailablePlatformContentType2 = (
  mimeType:Array<string>,
  itemsItemType: Array<string>,
  cutParams?: string
): ItemType => {
  
  const cutTypeArray = Object.keys(ItemType);
  let firstAvailableItemType: ItemType;
  let itemsItemMimeType : Array<ItemType> = [];

  try{  
      for (let i = 0; i < mimeType.length; i++) {
        let mime = mimeType[i].indexOf('video') >=0? 'VIDEO' : 'IMAGE'
        
        itemsItemType[i].indexOf('VIDEO') >=0 || itemsItemType[i].indexOf('IMAGE') >=0 ?
          itemsItemMimeType.push( ItemType[ itemsItemType[i].toString() ] )
        :
          itemsItemMimeType.push ( ItemType[ mime.concat('_'.concat(itemsItemType[i].toString()) )] )

      }

      if(localStorage.getItem('cut') !== null){
        const itemType = itemsItemMimeType?.find(it => it === localStorage.getItem('cut'));
        if (itemType) {
          firstAvailableItemType = itemType;
        }
       localStorage.removeItem('cut')
      }
      else{
        for (let idx = 0; idx < cutTypeArray.length; idx++) {
          const itemType = itemsItemMimeType?.find(it => it === cutTypeArray[idx]);
          
          if (itemType) {
            firstAvailableItemType = itemType;
            break;
          }
        }
      }
      
  } 
  catch(e){
    console.log('ERROR : ', e)

  }
  
  return itemsItemMimeType?.find(it => it === cutParams) || firstAvailableItemType!;

};

export const getItemTypeFromString = (
  mimeType?:string,
  itemsItemType?: string,
  ): ItemType => {
    try{
      if(mimeType && itemsItemType){
        let mime = mimeType.indexOf('video') >=0 ? 'VIDEO' : 'IMAGE'
        return ( ItemType[ mime.concat('_'.concat(itemsItemType) )] );
      }
    }
    catch(e){
      console.log('ERROR ', e)
    }
    return ItemType["DEFAULT"] 
  }



  export const getItemTypesFromString = (
    mimeType:Array<string>,
    itemsItemType: Array<string>,
    ): Array<ItemType> => {
      let itemsItemMimeType : Array<ItemType> = [];

      try{
        for (let i = 0; i < mimeType.length; i++) {
          let mime = mimeType[i].indexOf('video') >= 0 ? 'VIDEO' : 'IMAGE'
          itemsItemType[i].indexOf('VIDEO') >=0 || itemsItemType[i].indexOf('IMAGE') >=0 ?
          itemsItemMimeType.push( ItemType[ itemsItemType[i].toString() ] )
          :
          itemsItemMimeType.push ( ItemType[ mime.concat('_'.concat(itemsItemType[i].toString()) )] );
        }
      }
      catch(e){
        console.log('ERROR ', e)
      }
      
      return itemsItemMimeType;
  
    };

    

export const getLocalContent = <T extends LocalContent>(
  locals: Array<T>,
  selectedTranslationCountry: Country
): T =>
  locals.find(t => t.distributionCountry === selectedTranslationCountry) || locals[0];


/*export const getPlatformContent = <T extends LocalContent, I extends PlatformContent>(
  localContent: T,
  selectedItemType: ItemType
): I => {
    console.log('getPlatformContent')
    console.log('localContent', selectedItemType)
    const mediaItem =
      localContent.platforms?.find(i => 
        selectedItemType === ItemType[ (i.mimeType.indexOf('video') >= 0 ? 'VIDEO' : 'IMAGE').concat('_'.concat(i.itemType))]
      ) 
      ||
  
      localContent.platforms?.find(i =>
        i.itemType.includes( getFirstAvailablePlatformContentType2( localContent.platforms?.map((i) => i.mimeType), localContent.platforms?.map(item => item.itemType) )) 
      );

    return mediaItem as I;
};*/


export const getPlatformContent = <T extends LocalContent, I extends PlatformContent>(
  localContent: T,
  selectedItemType: ItemType,
  idPlatform:string | null
): I => {
    
    
    if(idPlatform){
      return localContent.platforms?.find(i =>  i.id === idPlatform ) as I;
    }
    
    
    else{
      let mediaItem =
                  localContent.platforms?.find(i => 
                    selectedItemType === ItemType[ (i.mimeType.indexOf('video') >= 0 ? 'VIDEO' : 'IMAGE').concat('_'.concat(i.itemType))] ) 
     
     if(!mediaItem) {
        let out= getFirstAvailablePlatformContentType2( localContent.platforms?.map((i) => i.mimeType), localContent.platforms?.map(item => item.itemType) )
      
        let aar_out = out.split('_')
        mediaItem = localContent.platforms?.find(i =>
          i.itemType === aar_out[1] || i.itemType === out
        );
     }
     return mediaItem as I;
    }

    //return mediaItem as I;
};


export const getComponentContent = <T extends PlatformContent, I extends ComponentContent>(
  platformContent: T,
  componentId: string | null
): I | null => {
  if (!componentId) {
    return null;
  }
  return platformContent.components.find(f => f.id === componentId) as I;
};


export const getVideoDuration = (file: Blob) =>
  new Promise<number>((resolve, reject) => {
      
      const previewUrl = URL.createObjectURL(file);
      const media = new Audio(previewUrl);
      media.onloadedmetadata = () => resolve(media.duration);

});



export const getLabelsByPeroject = <L extends Label>(
  labels: Array<L>,
  projectId: string
): Array<L> =>{
  let allLabelsFiltered: Array<L> = []
  labels.forEach(function(value) {
    
    //se c'è project controllo l'array 
    if(value.project && value.project.length>0){
      value.project.forEach(function(project) {
        if(project.id==projectId) allLabelsFiltered.push(value)
      })
    }
    else{
      // non ho project allora è su tutti
      allLabelsFiltered.push(value)
    }
  })
  return allLabelsFiltered;
};