import * as React from 'react';
import { DividerSection } from '../../../components/ui/DividerSection';
import { PreviewVideo, PreviewVideoWrapper } from './styles';
import { CutType } from '../../../types/CutType';
//import { DescriptionCutsMap } from '../../../utils/videoCutsMap';
import { CheckBox, Select, Typography } from '@freeda/react-components';
import { PlatformType } from '../../../types/types2.0/PlatformType';


interface Props {
  videoUrl: Array<{ thumbnailUrl: string; storagePath: string }>;
  //availableCutTypes: Array<CutType>;
  availableCutTypes: Array<PlatformType>;
  selectedCutType: PlatformType;
  onChangeCutType: (cutType: CutType) => void;
  downloadVideo: boolean;
  onChangeDownloadVideo: () => void;
}

const VideoSection: React.FC<Props> = ({
  videoUrl,
  availableCutTypes,
  selectedCutType,
  onChangeCutType,
  downloadVideo,
  onChangeDownloadVideo
}) => {
  return (
    <div style={{ marginBottom: 25 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <CheckBox
          checked={downloadVideo}
          onClick={() => onChangeDownloadVideo()}
          label="Video"
          style={{ marginRight: 15 }}
        />
        <DividerSection style={{ width: '100%' }} />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '230px',
          marginBottom: 10
        }}
      >
        <Typography variantName="italic-label" style={{ marginRight: 10 }}>
          Platform
        </Typography>
        <Select
          style={{ flexBasis: '100%' }}
          placeholder="Select platform"
          isClearable={false}
          isSearchable={false}
          optionsSort="asc"
          onChange={(option: any) => onChangeCutType(option.value)}
          options={availableCutTypes.map(ct => ({
            value: ct,
            label: PlatformType[ct]
          }))}
          value={
            selectedCutType
              ? { value: selectedCutType, label: PlatformType[selectedCutType] }
              : null
          }
        />
      </div>

      <PreviewVideoWrapper>
        {videoUrl.map(video => (
          <PreviewVideo
            key={video.storagePath}
            poster={video.thumbnailUrl}
            src={video.thumbnailUrl || video.storagePath}
          />
        ))}
      </PreviewVideoWrapper>
    </div>
  );
};

export { VideoSection };
