import styled from 'styled-components';
import { scrollbarHorizontalStyle } from '../../../components/ui/ScrollBarStyle';

const PreviewImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;

  & > img:not(:last-child) {
    margin-right: 10px;
  }

  ${scrollbarHorizontalStyle};
`;

const PreviewVideoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;
  & > video:not(:last-child) {
    margin-right: 10px;
  }

  ${scrollbarHorizontalStyle};
`;

const PreviewImage = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 10px;
`;

const PreviewVideo = styled.video`
  width: 80px;
  height: 80px;
  margin-right: 10px;
`;

const PreviewStory = styled.video`
  width: auto;
  height: 80px;
  margin-right: 10px;
`;

export { PreviewImageWrapper, PreviewVideoWrapper, PreviewImage, PreviewVideo, PreviewStory };
