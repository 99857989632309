import * as React from 'react';
import { Mutation } from 'react-apollo';

import { ParentContent } from '../../../types/types2.0/ParentContent';
import { MERGE_PLATFORM_CONTENT } from '../../../apollo/mutation/mutation2.0/MergePlatformContent';
import { CustomMutationProps } from '../../../types/graphql/utils';
class TypedMutation extends Mutation<{ mergePlatformContent: ParentContent }> {}

export const MergePlatformContentMutation: React.FC<
  CustomMutationProps<{ mergePlatformContent: ParentContent }>
> = ({ children, ...others }) => {
  return (
    <TypedMutation mutation={MERGE_PLATFORM_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
