import * as React from 'react';
import { Mutation } from 'react-apollo';
import { CustomMutationProps } from '../../../types/graphql/utils';
import {
  OnCreateMetadataTopic,
  OnCreateLabel
} from '../../../types/types2.0/graphql2.0/CreateMetadata';

import { MetadataType} from '../../../types/MetadataType';
import { getCreateMetadataMutation } from '../../../utils/metadataUtils2.0';

class TypedMutation extends Mutation<
OnCreateLabel | OnCreateMetadataTopic 
> {}

export const CreateMetadata: React.FC<
  CustomMutationProps<OnCreateLabel | OnCreateMetadataTopic > & {
    metadataType: MetadataType;
  }
> = ({ children, metadataType, ...others }) => {
  const mutation = getCreateMetadataMutation(metadataType);
  
  return (
    <TypedMutation mutation={mutation} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
