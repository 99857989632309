import styled, { css } from 'styled-components';
import { HTMLProps } from 'react';

const StoriesWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  height: 120px;
  justify-self: flex-end;
`;

const StoriesWithSeparator = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px #d8d8d8;
  box-sizing: border-box;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  height: 108px;
`;

const SingleFramesWrapper = styled.div`
  padding-top: 12px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 110px;
`;

const PreviewFrameWrapper = styled.div`
  height: 70px;
  display: flex;
  flex-direction: row;
`;

const FrameWrapper = styled.div<{ isselected: string } & HTMLProps<HTMLDivElement>>`
  display: flex;
  height: 75px;
  width: 44px;
  align-items: center;
  box-sizing: border-box;
  border: rgb(242, 242, 242, 0) solid 2px
    ${props =>
      props.isselected === 'true' &&
      css`
        border: ${props.theme.azzurrino} solid 2px;
      `};
`;

export {
  StoriesWrapper,
  StoriesWithSeparator,
  SingleFramesWrapper,
  PreviewFrameWrapper,
  FrameWrapper
};
