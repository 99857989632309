import * as React from 'react';
import { Location } from 'history';
import { withRouter, RouteComponentProps } from 'react-router';

interface Props {
  location: Location;
}

type PropsWithRouterProps = Props & RouteComponentProps<{}>;

interface State {
  location: Location | null;
  mediaPageLocation: Location | null;
}

const LastMediaPageLocationContext = React.createContext<State>({
  location: null,
  mediaPageLocation: null
});

class LastMediaPageLocation extends React.Component<PropsWithRouterProps, State> {
  constructor(props: PropsWithRouterProps) {
    super(props);
    this.state = {
      location: null,
      mediaPageLocation: null
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { location } = this.props;

    if (location) {
      if (
        location.pathname.indexOf('/gallery') < 0 &&
        location.pathname.indexOf('/upload-country') < 0 &&
        location.pathname.indexOf('/edit-media') < 0
      ) {
        this.setState({ location });
      }

      if (['/', '/my-media', '/my-pins'].indexOf(location.pathname) >= 0) {
        this.setState({ mediaPageLocation: location });
      }
    }
  }

  render() {
    return (
      <LastMediaPageLocationContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </LastMediaPageLocationContext.Provider>
    );
  }
}

export const LastMediaPageLocationProvider = withRouter<PropsWithRouterProps, any>(
  LastMediaPageLocation
);
export { LastMediaPageLocationContext };
