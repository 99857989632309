import * as React from 'react';
import { Mutation } from 'react-apollo';

import { CREATE_LOCAL_CONTENT } from '../../../apollo/mutation/mutation2.0/CreateLocalContent';
import { LocalContent } from '../../../types/types2.0/LocalContent';
import { CustomMutationProps } from '../../../types/graphql/utils';
//import clientTobe from '../../../apollo/clientTobe';

class TypedMutation extends Mutation<{ createLocalContent: LocalContent }> {}

export const CreateLocalContentMutation: React.FC<
  CustomMutationProps<{ createLocalContent: LocalContent }>
> = ({ children, ...others }) => {
  return (
    <TypedMutation /*client={clientTobe}*/ mutation={CREATE_LOCAL_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
