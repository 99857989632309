import gql from 'graphql-tag';
//import { PARENT_CONTENT } from './ParentContentFragment';
//import { PARENT_CONTENT_LIST } from './ParentContentFragmentList';



const LIST_CONTENT = gql`
  query listParentContents(
    $skip: Int
    $first: Int
    $keywords: [Keywords]
    $title: String
    $brand: MetadataWhereInput
    $serie: MetadataWhereInput
    
    $macroTopic: String
    $genre: ID
    $objective: ID
    $temperature: ID
    $campaign: ID
    $editorialCampaign: ID
    $product:ID

    $topic: MetadataWhereInput
    $contentType_in: [ContentType]
    $format_in: [FormatDao]
    $productType: [IdInput]
   
    $productionCountry: CountryEnumDao
    $language: LanguageEnumDao
    $members: [Members]
    $contributors: [Contributors]
    $createdAtDates: createdAtDates
    $distributionCountry: CountryEnumDao
    $reel: Boolean
    $itemType:ItemType
    $project:ID
   
  ) {
    listParentContents(
      skip: $skip
      first: $first
      orderBy: updatedAt_DESC
      where: {
        brand: $brand
        serie: $serie
        topic: $topic
        
        macroTopic: $macroTopic
        genre: $genre
        objective: $objective
        temperature: $temperature
        campaign: $campaign
        editorialCampaign: $editorialCampaign
        product:$product

        contentType_in: $contentType_in
        format_in: $format_in
        productType: $productType
        keywords: $keywords
        title: $title
        productionCountry: $productionCountry
        language: $language
        members: $members
        contributors: $contributors
        createdAtDates : $createdAtDates
        distributionCountry: $distributionCountry
        reel: $reel
        itemType: $itemType
        project: $project
      }
    ) {
      edges {
        node {
          id
          contentType
          title
          format
          productionCountry
          completed
          localizations {
            id
            distributionCountry
            members {
              id
              name
            }
            contributors {
              id
              name
            }
            platforms {
              id
              creator
              mimeType
              storagePath
              thumbnailUrl
              videoLength
              itemType
              components{
                storagePath
                thumbnailUrl
                title
              }
              
              
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      aggregate {
        count
      }
    }
  }
`;

export { LIST_CONTENT };
