import * as React from 'react';

import {
  DeleteLabelMutation,
} from '../components/mutations/mutations2.0/DeleteLabelMutation';

import { FeedbackContext } from '../providers/FeedbackProvider';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button } from '@freeda/react-components';


interface Props {
  labelId: string;
  onCloseDialog: () => void;
  onOpenSnackbarReload: () => void;

}

const DeleteLabelButton: React.FC<Props & RouteComponentProps<{}>> = ({
  labelId,
  onCloseDialog,
  onOpenSnackbarReload,
 
}) => (

      <FeedbackContext.Consumer>
        {({ onOpen }) => (
          <DeleteLabelMutation>
            {(deleteLabel, deleteResult) => (
              
              <Button
                disabled={deleteResult.loading}
                loading={deleteResult.loading}
                onClick={async () => {
               
                  try{
                    await deleteLabel({
                      variables: { id: labelId },
  
                      update:  () => {
                        onOpen(`Label deleted correctly! 😎`, 'notification');
                        onOpenSnackbarReload();
                        onCloseDialog();
                        
                      }
                    });
                  }catch(e){
                    onOpen('Error in deleting label ! Label is associated to Peggy and Elinor contents.', 'error');
                    onCloseDialog();
                  }
                  
                  
                }}
              >
                I’m sure, delete it
              </Button>
            )}
          </DeleteLabelMutation>
        )}
      </FeedbackContext.Consumer>
 
);

export default withRouter<Props & RouteComponentProps<{}>, any>(DeleteLabelButton);
