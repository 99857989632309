export const emojiList = [
  '🧝‍♀️',
  '🧝‍♂️',
  '🧟‍♀️',
  '🧟‍♂️',
  '🧜‍♀️',
  '🧜‍♂️',
  '🧛‍♀️',
  '🧛‍♂️',
  '👩🏻‍🎤',
  '👩🏾‍🎤',
  '👨🏼‍🎤',
  '👨🏿‍🎤',
  '🧘🏻‍♀️',
  '🧘🏿‍♀️',
  '🧘🏻‍♂️',
  '🧘🏿‍♂️',
  '👩🏼‍🌾',
  '👩🏾‍🌾',
  '👨🏻‍🌾',
  '👨🏾‍🌾',
  '👩🏼‍🚀',
  '👩🏽‍🚀',
  '👨🏼‍🚀',
  '👨🏾‍🚀',
  '👨🏼‍💻',
  '👨🏽‍💻',
  '👩🏾‍💻',
  '👩🏻‍💻',
  '👨🏼‍🎨',
  '👨🏾‍🎨',
  '👩🏼‍🎨',
  '👩🏿‍🎨',
  '👩🏻‍🔧',
  '👩🏾‍🔧',
  '👨🏻‍🔧',
  '👨🏾‍🔧',
  '🕵🏼‍♀️',
  '🕵🏾‍♀️',
  '🕵🏻‍♂️',
  '🕵🏽‍♂️',
  '👩🏻‍🍳',
  '👩🏽‍🍳',
  '👨🏼‍🍳',
  '👨🏿‍🍳',
  '👩🏼‍🦱',
  '👩🏾‍🦱',
  '👨🏻‍🦱',
  '👨🏿‍🦱',
  '👩🏻‍🦰',
  '👩🏾‍🦰',
  '👨🏻‍🦰',
  '👨🏾‍🦰',
  '🧖🏻‍♀️',
  '🧖🏾‍♀️',
  '🧖🏻‍♂️',
  '🧖🏾‍♂️',
  '🦄',
  '🐵',
  '🐻',
  '🐰',
  '🦊',
  '🐱',
  '🐶',
  '🐯',
  '🐨',
  '🦁',
  '🐙',
  '🦖',
  '🐝',
  '🐹'
];

export const randomEmoji = () => emojiList[Math.floor(Math.random() * emojiList.length)];
