import * as React from 'react';
import { FieldWrapper } from './styles';
import { Select } from '@freeda/react-components';
import { ListBrandCategories } from '../../queries/queries2.0/ListBrandCategories';

interface Props {
  selectedBrandCategoryName: string | null;
  onChangeBrandCategoryName: (id: string | null) => void;
  style?: React.CSSProperties;
  label?: string;
  isClearable?: boolean;
  disabled?: boolean;
}

const SelectBrandCategory: React.FC<Props> = ({
  selectedBrandCategoryName,
  onChangeBrandCategoryName,
  style,
  label,
  isClearable,
  disabled
}) => {
  return (
    <ListBrandCategories>
      {listBrandCategoriesResult => {
        const dataNotAvailable =
          listBrandCategoriesResult.loading ||
          !listBrandCategoriesResult.data ||
          Object.keys(listBrandCategoriesResult.data).length === 0;

        const brandCategories = dataNotAvailable
          ? []
          : listBrandCategoriesResult.data!.listBrandCategories;

        const options = brandCategories.map(c => ({ value: c.name, label: c.name }));

        const selectedBrandCategory = options.find(bC => bC.value === selectedBrandCategoryName);
        //alert('selectedBrandCategory___'+ selectedBrandCategory)
        return (
          <FieldWrapper style={style}>
            <Select
              placeholder="Select industry"
              label={label}
              isDisabled={dataNotAvailable || disabled}
              style={{ padding: 0 }}
              optionsSort="asc"
              isClearable={isClearable}
              onChange={(option: any) => onChangeBrandCategoryName(option ? option.value : null)}
              options={options}
              value={selectedBrandCategory ? selectedBrandCategory : null}
            />
          </FieldWrapper>
        );
      }}
    </ListBrandCategories>
  );
};

export { SelectBrandCategory };
