//import { MediaFrame } from '../types/MediaFrame';
//import { MediaItem } from '../types/MediaItem';
import { EnhancedParentContent,  EnhancedLocalContent} from '../types/types2.0/EnhancedContent';
import { ItemType } from '../types/ItemType';
//import { EnhancedMediaTranslation } from '../types/EnhancedMedia';
import { Country } from '../types/graphql/Country';
import { omit } from 'lodash';
import { LocalContent } from '../types/types2.0/LocalContent';
import { PlatformContent } from '../types/types2.0/PlatformContent';

import { ComponentContent } from '../types/types2.0/ComponentContent';

export const stringifyContent = (
  content: EnhancedParentContent,
  localizations: Array<EnhancedLocalContent>
) =>
  JSON.stringify({
    root: omit(content, 'localizations'),
    localizations: localizations
      .map(t => ({
        ...t,
        members: t.members
          ? /*t.members
              .split(',')
              .sort()
              .join(',')*/ "TODO"
          : null
      }))
      .map(t => omit(t, 'updatedAt')),
    addedKeywords: [],
    removedKeywords: [],
    newKeywords: []
  });

export const getFirstAvailablePlatformContentType = (
  itemsItemType: Array<ItemType>,
  cutParams?: string
): ItemType => {
  const cutTypeArray = Object.keys(ItemType);
  let firstAvailableItemType: ItemType;

  for (let idx = 0; idx < cutTypeArray.length; idx++) {
    const itemType = itemsItemType.find(it => it === cutTypeArray[idx]);
    if (itemType) {
      firstAvailableItemType = itemType;
      break;
    }
  }
  return itemsItemType.find(it => it === cutParams) || firstAvailableItemType!;
};

export const getLocalContent = <T extends LocalContent>(
  localizations: Array<T>,
  selectedLocalCountry: Country
): T =>
localizations.find(t => t.distributionCountry === selectedLocalCountry) || localizations[0];

/*export const getPlatformContent = <T extends LocalContent, I extends PlatformContent>(
  localContent: T,
  selectedItemType: ItemType
): I => {
  const platformContent =
    localContent.platforms.find(i => i.itemType === selectedItemType) ||
    localContent.platforms.find(
      i =>
        i.itemType ===
        getFirstAvailablePlatformContentType(localContent.platforms.map(item => item.itemType))
    );
  return platformContent as I;
};*/

export const getComponentContent = <T extends PlatformContent, I extends ComponentContent>(
  platformContent: T,
  componentContentId: string | null
): I | null => {
  if (!componentContentId) {
    return null;
  }
  return platformContent.components.find(f => f.id === componentContentId) as I;
};
