import styled, { css } from 'styled-components';

const PreviewStyle = css`
  height: -webkit-fill-available;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PreviewImage = styled.img`
  ${PreviewStyle};
`;

const PreviewVideo = styled.video`
  ${PreviewStyle};
`;

export { PreviewImage, PreviewVideo };
