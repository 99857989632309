import * as React from 'react';
import styled from 'styled-components';
import { Dialog, Typography, ButtonIcon, Icon } from '@freeda/react-components';

import { Dialogable } from '../../../types/Dialogable';
import FileUploadItem from './FileUploadItem';
import { ListStyled } from './styles';
import { FilePreview } from '../../../types/FilePreview';

const occhiali = require('../../../assets/occhiali.gif');

const WomanWrapper = styled.div`
  max-width: 550px;
  min-height: 247px;

  & > img {
    width: 100%;
    height: auto;
  }
`;

const FileUploadsDialog: React.FC<Dialogable & { filePreviews: Array<FilePreview> }> = ({
  open,
  onClose,
  filePreviews
}) => (
  <Dialog
    style={{ width: 550, maxHeight: 700 }}
    openDialog={open}
    onClose={onClose}
    rootIdAttributes="root"
    disableEscapeKeyUp
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 10,
        maxHeight: 60
      }}
    >
      <Typography variantName="subtitle" textColor="#6B20DA">
        File uploading
      </Typography>
      <ButtonIcon onClick={onClose} style={{ width: 45, height: 45 }}>
        <Icon iconName="close" style={{ fontSize: 35 }} />
      </ButtonIcon>
    </div>
    <WomanWrapper>
      <img src={occhiali} />
    </WomanWrapper>
    <ListStyled>
      {filePreviews.map((filePreview, i) => (
        <FileUploadItem
          key={i}
          title={filePreview.title}
          mimeType={filePreview.mimeType}
          previewUrl={filePreview.previewUrl}
          progress={filePreview.progress}
        />
      ))}
    </ListStyled>
  </Dialog>
);

export { FileUploadsDialog };
