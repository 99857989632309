import * as React from 'react';
import { NoMediaFoundWrapper, Central, DesktopCornice, BigCornice, MobileCornice } from './styles';
const cornice1 = require('../../../assets/noMediaFound/cornice-1.png');
const cornice2 = require('../../../assets/noMediaFound/cornice-2.png');
const cornice3 = require('../../../assets/noMediaFound/cornice-3.png');
const cornice4 = require('../../../assets/noMediaFound/cornice-4.png');

const NoMediaFound: React.FC = () => (
  <NoMediaFoundWrapper>
    <BigCornice src={cornice1} />
    <Central>
      <DesktopCornice src={cornice2} style={{ maxHeight: '120px' }} />
      <DesktopCornice
        src={cornice3}
        style={{ maxHeight: '110px', justifySelf: 'flex-end', alignSelf: 'flex-end' }}
      />
    </Central>
    <DesktopCornice
      src={cornice4}
      style={{ maxHeight: '200px', justifySelf: 'center', alignSelf: 'center' }}
    />
    <MobileCornice
      src={cornice2}
      style={{ maxHeight: '110px', justifySelf: 'center', alignSelf: 'center' }}
    />
  </NoMediaFoundWrapper>
);

export { NoMediaFound };
