import gql from 'graphql-tag';

const GET_PUT_S3_SIGNED_URL = gql`
  query getPutS3SignedUrl($mimeType: String!, $dummyId: String, $multipart:Boolean!) {
    getPutS3SignedUrl(mimeType: $mimeType, dummyId: $dummyId, multipart:$multipart) {
      signedUrl
      mediaUrl
    }
  }
`;

export { GET_PUT_S3_SIGNED_URL };
