import * as React from 'react';
import Media from 'react-media';
import { ButtonIcon, Typography } from '@freeda/react-components';

import { BarWrapper, IconsWrapper, DeselectButton } from './styles';
import { ParentContentSelection } from '../../../types/types2.0/ParentContentSelection';

import { download } from '../../../utils/downloadMedia';
import { TransitionStatus } from 'react-transition-group/Transition';

interface Props {
  selectedContent: Array<ParentContentSelection>;
  //pinnedMediaIds: Array<string>;
  deselect: () => void;
  //onPin: (mediaIds: Array<string>) => void;
  //onUnpin: (mediaIds: Array<string>) => void;
  onOpenMultiDownload: () => void;
  transitionStatus?: TransitionStatus;
}


const ActionsBar: React.FC<Props> = ({
  selectedContent,
  //pinnedMediaIds,
  deselect,
  //onPin,
  //onUnpin,
  onOpenMultiDownload,
  transitionStatus
}) => {
  
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => {
        return (
          <BarWrapper className={`wrapper-${transitionStatus}`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {matches && (
                <ButtonIcon onClick={() => deselect()} iconName="remove" iconColor="#ffffff" />
              )}
              <Typography
                variantName="subtitle"
                textColor="#ffffff"
                style={{ whiteSpace: 'nowrap' }}
              >
                <>{`${selectedContent.length} content(s) selected`}</>
              </Typography>
              <IconsWrapper>
                
                <ButtonIcon
                  data-testid="download"
                  iconName="download"
                  iconColor="rgb(124, 255, 221)"
                  onClick={e => {
                    /*const distributionCountries = new Set(
                      flatMap(selectedContent, m => m.localizations.map(t => t.distributionCountry))
                    );*/
                    /*const itemTypes = new Set(
                      flatMap(selectedContent, m =>
                        flatMap(m.localizations, t => t.platforms.map(i => i.itemType))
                      )
                    );*/
                    
                    const files: Array<string> = [];
                      selectedContent.forEach(m => {
                        m.localizations.forEach(t => {
                          t.platforms.forEach(p => {
                            files.push(p.url);
                              p.components?.forEach(({ url }) => {
                                files.push(url);
                              });
                          });
                        } )
                      });

                      download(files);

                    
                    /*if (
                      Array.from(distributionCountries).length === 1 &&
                      Array.from(itemTypes).length === 1
                    ) {
                      const files: Array<string> = [];
                      selectedContent.forEach(m => {
                        const t = m.localizations[0];
                        t.platforms.forEach(({ url }) => {
                          files.push(url);
                        });
                      });

                      download(files);
                    } else {
                      onOpenMultiDownload();
                    }*/
                  }}
                />
              </IconsWrapper>
            </div>
            {!matches && (
              <div style={{ marginLeft: 'auto', marginRight: 20 }}>
                <DeselectButton onClick={() => deselect()}>Deselect all</DeselectButton>
              </div>
            )}
          </BarWrapper>
        );
      }}
    </Media>
  );
};

export default ActionsBar;
