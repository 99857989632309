import * as React from 'react';
import { Mutation, FetchResult } from 'react-apollo';
import { DELETE_PLATFORM_CONTENT} from '../../../apollo/mutation/mutation2.0/DeletePlatformContent';
import { Country } from '../../../types/graphql/Country';
import { DataProxy } from 'apollo-cache';
import { PARENT_CONTENT } from '../../../apollo/queries/queries2.0/ParentContentFragment';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { CustomMutationProps } from '../../../types/graphql/utils';

interface DeletePlatformContentResult {
  deletePlatformContent: {
    deletedPlatformId: string;
    parentDeleted: boolean;
    localDeleted: boolean;
    affectedCountry: Country;
  };
}

class TypedMutation extends Mutation<DeletePlatformContentResult> {}

export const DeletePlatformMutation: React.FC<CustomMutationProps<DeletePlatformContentResult>> = ({
  children,
  ...others
}) => {
  return (
    <TypedMutation mutation={DELETE_PLATFORM_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};

export const onDeletePlatformContent = (parentId: string, cb: () => void) => (
  cache: DataProxy,
  result: FetchResult<DeletePlatformContentResult>
) => {
  const { affectedCountry, deletedPlatformId, localDeleted } = result.data!.deletePlatformContent;
  
  
  const content = cache.readFragment<ParentContent>({
    id: `ParentContent:${parentId}`,
    fragment: PARENT_CONTENT
  });

  //console.log('________content__affectedCountry______', affectedCountry)
  //console.log('________content__localizationDeleted______', localDeleted)

  if (content) {
    let localizations;
    if (localDeleted) {
      localizations = content.localizations.filter(t => t.distributionCountry !== affectedCountry);
    } else {
      localizations = content.localizations.map(t => {
        if (t.distributionCountry === affectedCountry) {
          return {
            ...t,
            platforms: t.platforms.filter(i => i.id !== deletedPlatformId)
          };
        }
        return t;
      });
    }

      cache.writeFragment({
        id: `ParentContent:${parentId}`,
        fragment: PARENT_CONTENT,
        data: { ...content, localizations }
      });
  }


  cb();
};
