const download = (urls: Array<string>) => {
  const downloadNext = (i: number) => {
    if (i >= urls.length) {
      return;
    }
    var a = document.createElement('a');
    a.href = urls[i];

    if ('download' in a) {
      a.download = urls[i];
    }

    (document.body || document.documentElement).appendChild(a);
    if (a.click) {
      a.click(); // The click method is supported by most browsers.
    } else {
      window.open(urls[i]);
    }

    a.parentNode!.removeChild(a);
    setTimeout(function() {
      downloadNext(i + 1);
    }, 500);
  };

  downloadNext(0);
};

export { download };
