import * as React from 'react';
import styled from 'styled-components';
import { Avatar, SmallText } from './styles';

const TextWrapper = styled.div`
  height: 34px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 15px;
`;

const PlaceholderUploader: React.FC = () => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8, marginLeft: 5 }}>
    <TextWrapper>
      <SmallText style={{ width: '80px', background: '#e4e4e4' }} />
      <SmallText style={{ width: '110px', background: '#e4e4e4' }} />
    </TextWrapper>
    <Avatar style={{ background: '#e4e4e4' }} />
  </div>
);

export { PlaceholderUploader };
