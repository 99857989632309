import * as React from 'react';
import styled from 'styled-components';
import { PlaceholderMedia } from './PlaceholderMedia';

const ListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 10px;
  justify-items: center;
`;

const PlaceholderListMedia: React.FC = () => (
  <ListWrapper>
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
    <PlaceholderMedia />
  </ListWrapper>
);

export { PlaceholderListMedia };
