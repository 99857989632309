import * as React from 'react';
import { Mutation } from 'react-apollo';

import { SEND_SLACK_MESSAGE } from '../../apollo/mutation/SendSlackMessage';
import { CustomMutationProps } from '../../types/graphql/utils';
//import clientTobe from '../../apollo/clientTobe';

class TypedMutation extends Mutation<{}> {}

export const SendSlackMessageMutation: React.FC<CustomMutationProps<{}>> = ({
  children,
  ...others
}) => {
  return (
    <TypedMutation /*client={clientTobe}*/ mutation={SEND_SLACK_MESSAGE} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
