import 'core-js';

import * as React from 'react';
import * as Sentry from '@sentry/browser';
import * as ReactDOM from 'react-dom';
//import App from './App';
import App from './App2.0';
import './index.css';
import auth from './utils/auth';



if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://752a4019c4cd418584197fabd3df2ebc@sentry.io/1279402',
  });

  Sentry.configureScope(function (scope) {
    const username = localStorage.getItem('username');
    if (username) {
      scope.setUser({ username });
    }
  });
}

const version = localStorage.getItem('version');
const VERSION = '1.6.7';

if (!version || version !== VERSION) {
  localStorage.clear();
  localStorage.setItem('version', VERSION);
}

(async () => {
  await auth.renewToken();
  ReactDOM.render(<App />, document.getElementById('root'));
})();
