import styled from 'styled-components';

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 25% 50% 25%;
  height: calc(100vh - 108px);
`;

const ArrowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-basis: 25%;
  height: 100%;
  & a {
    align-self: center;
    position: absolute;
  }
`;

const InfoBox = styled.div`
  top: 0;
  padding: 20px 20px 0px 20px;
  width: 100%;
  box-sizing: border-box;
`;

const SelectionWrapper = styled.div`
  align-items: center;
  padding-top: 10px;
  border-top-color: #e8e8e8;
  border-top-style: solid;
  border-top-width: 1px;
  padding-bottom: 10px;
  margin-top: 10px;
`;

export { PreviewWrapper, Row, Content, ArrowWrapper, InfoBox, SelectionWrapper };
