import gql from 'graphql-tag';


const LIST_CONTENT_TO_MERGE = gql`
  query listContentToMerge(
    $toExclude: ID!
    $keywords: [Keywords]
    $title: String
    $format: ID
    $distributionCountry: CountryEnumDao!
    $projectId: ID
    $mimeType: String
  ) {
    listContentToMerge(
      orderBy: createdAt_DESC
      first: 50
      toExclude: $toExclude, 
      keywords: $keywords
      title: $title 
      format: $format
      distributionCountry: $distributionCountry
      projectId: $projectId
      mimeType: $mimeType
    ) {
      edges {
        node {
          id
          contentType
          title
          format
          productionCountry
          localizations {
            id
            distributionCountry
            members {
              id
              name
            }
            contributors {
              id
              name
            }
            platforms {
              id
              mimeType
              storagePath
              thumbnailUrl
              videoLength
            }
          }
        }
      }
    }
  }
 
`;

export { LIST_CONTENT_TO_MERGE };
