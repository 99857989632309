import * as React from 'react';
import Media from 'react-media';
import { sortBy } from 'lodash';
import { Dialog, Typography, ButtonIcon, Icon, Select, Tabs } from '@freeda/react-components';


import { Dialogable } from '../../../types/Dialogable';
import { WithTeamMemberForm } from './WithTeamMemberForm';
import { MediaPreviewWrapper } from './styles';
import { WithExternalForm } from './WithExternalForm';
import { SlackReceiver } from '../../../types/SlackReceiver';
import { Country } from '../../../types/graphql/Country';
import { ItemType } from '../../../types/ItemType';
import { CountrySelection } from '../../../components/ui/CountrySelection';
//import { MediaRoot } from '../../../types/MediaRoot';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { CutType } from '../../../types/CutType';
//import { DescriptionCutsMap } from '../../../utils/videoCutsMap';
import { VideoCutSelection } from '../../../components/ui/VideoCutSelection2';
import { /*getItemTypeFromString,*/ getItemTypesFromString } from '../../../utils/contentUtils';
//import { FramesSelection } from '../../../components/ui/FramesSelection2.0';



import { 
  getLocalContent,
  getPlatformContent, 
  getFirstAvailablePlatformContentType2

} from '../../../utils/contentUtils';


import { parse } from 'query-string';
import { StoryFrameT } from '../MobileMediaPreview';
import { getStorySelectLabel } from '../../../utils/getStorySelectLabel';
//import { Content } from '../MediaCard/styles';

const frida = require('../../../assets/frida.jpg');

type Props = Dialogable & {
  contentId: string;
  platformId:string;
  mediaMimeType: string;
  slackReceivers: Array<SlackReceiver>;
  slackSharingLoading: boolean;
  shareOnSlack: (variables: any, cb: () => void) => void;
  generateExternalLink: (itemId: string) => Promise<string>;
  fullScreen?: boolean;
  content: ParentContent;
  countries: Array<Country>;
  showCountryCutItem: boolean;
};

interface State {
  activeTab: number;
  externalLink: string | null;
  selectedTranslationCountry: Country;
  selectedItemType: ItemType;
  platformId:string| null;
  selectedComponent: StoryFrameT;
}

class ShareMediaDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const queryParams = parse(window.location.search);

    const userCountry = localStorage.getItem('country') as Country;

    const userCountryOrParamsCountry = queryParams.country || userCountry;

    const localContent =
      props.content.localizations.find(t => t.distributionCountry === userCountryOrParamsCountry) ||
      props.content.localizations[0];

    const platformContentType = getFirstAvailablePlatformContentType2(
      localContent.platforms.map(i => i.mimeType),
      localContent.platforms.map(i => i.itemType),
      queryParams.cut as string | undefined
    );
   
    const selectedComponent: StoryFrameT = queryParams.frame
      ? { type: 'frame', position: Number(queryParams.frame) - 1 }
      : { type: 'cover/story' };


    this.state = {
      activeTab: 0,
      externalLink: null,
      selectedTranslationCountry: localContent.distributionCountry,
      selectedItemType: platformContentType,
      platformId: null,
      selectedComponent
    };
  }

  handleTabChange = async (activeTab: number) => {
    if (activeTab === 1) {
      
      const localContent = getLocalContent(
        this.props.content.localizations,
        this.state.selectedTranslationCountry
      );
      const platformContent = getPlatformContent(localContent, this.state.selectedItemType, this.state.platformId);
      
      const externalLink = await this.props.generateExternalLink(platformContent.id);
      //console.log('__externalLink__', externalLink)
      //console.log('__selectedComponent__', this.state.selectedComponent)
      this.setState({
        activeTab,
        externalLink: `${process.env.REACT_APP_BASE_URL}/preview?h=${externalLink}`,
        selectedComponent: { type: 'cover/story' }
      });
    } else {
      this.setState({ activeTab });
    }
  };

  onChangeCutType = async (cutType: CutType) => {
    const { activeTab } = this.state;
    this.setState(
      {
        selectedItemType: ItemType[cutType]
      },
      async () => {
        const localContent = getLocalContent(
          this.props.content.localizations,
          this.state.selectedTranslationCountry
        );
        const platformContent = getPlatformContent(localContent, this.state.selectedItemType, null);
        
        if (activeTab === 1) {
          const externalLink = await this.props.generateExternalLink(platformContent.id);
          this.setState({
            externalLink: `${process.env.REACT_APP_BASE_URL}/preview?h=${externalLink}`
          });
        }
      }
    );
  };


  changeSelectedItemType = async (itemType: ItemType, idPlatformContent:string) => {

    const { activeTab } = this.state;
    this.setState(
      {
        selectedItemType: itemType,
        platformId: idPlatformContent
      },
      async () => {
        //const platformContent = getPlatformContent(localContent, this.state.selectedItemType, idPlatformContent);
        
        if (activeTab === 1) {
          const externalLink = await this.props.generateExternalLink(idPlatformContent);
          this.setState({
            externalLink: `${process.env.REACT_APP_BASE_URL}/preview?h=${externalLink}`
          });
        }
      }
    );
    
  };

  onChangeComponents = (selectFrame: StoryFrameT) => {
   
    this.setState({
      selectedComponent: {
        type: selectFrame.type,
        position: selectFrame.position
      }
    });

  };

  handleSelectedTranslationChange = (country: Country) => {
    const { activeTab } = this.state;
    
    this.setState(
      {
        selectedTranslationCountry: country
      },
      () => {
        const localContent = getLocalContent(
          this.props.content.localizations,
          this.state.selectedTranslationCountry
        );

        const itemType = getFirstAvailablePlatformContentType2(
          localContent.platforms.map(i => i.mimeType),
          localContent.platforms.map(i => i.itemType)
        );
        this.setState(
          {
            selectedItemType: itemType
          },
          async () => {
            const platformContent = getPlatformContent(localContent, this.state.selectedItemType, null);
            
            if (activeTab === 1) {
              const externalLink = await this.props.generateExternalLink(platformContent.id);
              this.setState({
                externalLink: `${process.env.REACT_APP_BASE_URL}/preview?h=${externalLink}`
              });
            }
          }
        );
      }
    );
  };

  getShareLink = (
    contentId: string,
    selectedCountry: Country,
    itemType: ItemType,
    selectedComponent?: StoryFrameT
  ): string => {
    let link = `${contentId}?country=${selectedCountry}`;
    //console.log('getShareLink',selectedComponent )
    if (
      selectedComponent &&
      typeof selectedComponent.position !== 'undefined' /*&&
      itemType === ItemType.VIDEO_INSTAGRAM_STORY*/
    ) {
      return `${link}&component=${selectedComponent.position! + 1}`;
    }

    const cut = CutType[itemType];
    if (cut) {
      return `${link}&cut=${cut}`;
    }

    return link;
  };

  render() {
    const {
      open,
      slackSharingLoading,
      onClose,
      shareOnSlack,
      slackReceivers,
      contentId,
      mediaMimeType,
      countries,
      showCountryCutItem,
      content
    } = this.props;

    const {
      activeTab,
      externalLink,
      selectedTranslationCountry,
      selectedItemType,
      platformId,
      selectedComponent
    } = this.state;

    const selectedLocalContent = getLocalContent(
      content.localizations,
      selectedTranslationCountry
    );

    const firstAvailableItemType = getFirstAvailablePlatformContentType2(
      selectedLocalContent.platforms.map(i => i.mimeType),
      selectedLocalContent.platforms.map(i => i.itemType)
    );

    const idPlatformContents = selectedLocalContent
      ? selectedLocalContent!.platforms.map((i) => i.id)
      : null;  

    
    const selectedPlatformContent =
      getPlatformContent(selectedLocalContent, this.state.selectedItemType, platformId) || firstAvailableItemType;

    const orderedFrame = sortBy(selectedPlatformContent.components, 'position');

    const availableFrames: Array<StoryFrameT> = [
      { type: 'cover/story' },
      ...orderedFrame.map<StoryFrameT>(({ position }) => ({ type: 'frame', position }))
    ];

    const isImage = mediaMimeType && mediaMimeType.indexOf('image') >= 0;
    const isVideo = mediaMimeType && mediaMimeType.indexOf('video') >= 0;
    //const isIgStories = isVideo && itemType && itemType.indexOf('INSTAGRAM_STORY') >= 0;

    const componentContent = selectedPlatformContent.components
      ? selectedPlatformContent.components.find(f => f.position === selectedComponent.position)
      : null;

    const components = selectedPlatformContent && selectedPlatformContent.components;
    
   
    const componentsOfTab = [
      {
        label: 'With team members',
        component: (
          <WithTeamMemberForm
            contentId= {content.id}
            title={(componentContent && componentContent.title) || content.title}
            
            mediaUrl={`${process.env.REACT_APP_BASE_URL}/gallery/${this.getShareLink(
              contentId,
              selectedTranslationCountry,
              selectedItemType,
              selectedComponent
            )}`}
            
            slackReceivers={slackReceivers}
            slackSharingLoading={slackSharingLoading}
            storagePath={(componentContent && componentContent.storagePath) || selectedPlatformContent.storagePath}
            shareOnSlack={shareOnSlack}
            onClose={onClose}
          />
        )
      },
      {
        label: 'With external people',
        component: <WithExternalForm externalLink={externalLink} />
      }
    ];

    return (
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (
          <Dialog
            openDialog={open}
            onClose={onClose}
            fullScreen={matches}
            rootIdAttributes="root"
            style={{
              width: matches ? '100vw' : 600,
              maxHeight: matches ? '100vh' : 700,
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 30
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variantName="subtitle" textColor="#6B20DA">
                Share Content
              </Typography>
              <ButtonIcon onClick={onClose} style={{ width: 45, height: 45 }}>
                <Icon iconName="close" style={{ fontSize: 35 }} />
              </ButtonIcon>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 15,
                justifyContent: showCountryCutItem ? 'space-between' : 'flex-end'
              }}
            >
              {showCountryCutItem && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                      DISTRIBUTION COUNTRY
                    </Typography>
                    <CountrySelection
                      countries={countries}
                      selectedCountry={selectedTranslationCountry}
                      onSelection={this.handleSelectedTranslationChange}
                    />
                  </div>
                </>

              )}
            </div>

            {idPlatformContents && (
              <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: 10
                  }}
                >
                  <Typography variantName="italic-label" style={{ marginRight: 10 }}>PLATFORMS </Typography>
                  <VideoCutSelection
                    itemTypes = { getItemTypesFromString(
                                    selectedLocalContent!.platforms.map((i) => i.mimeType), 
                                    selectedLocalContent!.platforms.map((i) => i.itemType)) 
                                }
                    onSelection={(it, idPlatform) => this.changeSelectedItemType(it, idPlatform)}
                    selectedCut={this.state.selectedItemType!}
                    idPlatformContents={idPlatformContents!}

                  />
              </div>
            )}
            
            {(components && components?.length>0) &&
                activeTab === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '230px'
                    }}
                  >
                    <Typography variantName="italic-label" style={{ marginRight: 10 }}>
                      frames
                    </Typography>

                    <Select
                      style={{ flexBasis: '100%' }}
                      placeholder="Select frame"
                      isClearable={false}
                      isSearchable={false}
                      onChange={(option: any) => this.onChangeComponents( JSON.parse(option.value) )}
                      options={availableFrames.map((sf, i) => ({
                        value: JSON.stringify(sf),
                        label: getStorySelectLabel(sf.type, i)
                      }))}
                      value={
                        selectedComponent
                          ? {
                              value: JSON.stringify(selectedComponent),
                              label: getStorySelectLabel(
                                selectedComponent.type,
                                selectedComponent.position! + 1
                              )
                            }
                          : null
                      }
                    />
                  </div>
                )}
          

            <MediaPreviewWrapper>
              {isImage && (
                <img
                  style={{ maxWidth: '80px', maxHeight: '80px' }}
                  src={
                    (componentContent && componentContent.storagePath) || selectedPlatformContent.storagePath || frida
                  }
                />
              )}
              {isVideo && (
                <video
                  style={{ maxWidth: '80px', maxHeight: '80px' }}
                  poster={
                    (componentContent && componentContent.thumbnailUrl) ||
                    selectedPlatformContent.thumbnailUrl ||
                    undefined
                  }
                  src={
                    (componentContent && componentContent.storagePath) || selectedPlatformContent.storagePath || frida
                  }
                >
                  <source
                    src={(componentContent && componentContent.storagePath) || selectedPlatformContent.storagePath}
                    type={mediaMimeType}
                  />
                </video>
              )}
            </MediaPreviewWrapper>
            <Tabs
              style={{ marginTop: 5 }}
              activeTab={activeTab}
              onClick={(value: any) => this.handleTabChange(value)}
              elements={componentsOfTab}
            />
          </Dialog>
        )}
      </Media>
    );
  }
}

export default ShareMediaDialog;
