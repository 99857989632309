import * as React from 'react';
import { Query } from 'react-apollo';
import CountAggregation from '../../../types/graphql/CountAggregation';
import { COUNT_NOT_COMPLETED_CONTENT } from '../../../apollo/queries/queries2.0/CountNotCompletedContent';
import { CustomQueryProps } from '../../../types/graphql/utils';
//import clientTobe from '../../../apollo/clientTobe';

class TypedQuery extends Query<{ listParentContents: CountAggregation }, {}> {}

export const CountNotCompletedContentQuery: React.FC<
  CustomQueryProps<{ listParentContents: CountAggregation }>
> = ({ children, ...others }) => {
  return (
    
    <TypedQuery /*client={clientTobe}*/ query={COUNT_NOT_COMPLETED_CONTENT} {...others}>
      
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
