import * as React from 'react';

const RefreshSpan: React.FC = ({ children }) => (
  <span>
    {children}
    &nbsp;
    <a
      style={{
        all: 'unset',
        cursor: 'pointer',
        textDecoration: 'underline',
        fontWeight: 'bold'
      }}
      onClick={() => window.location.reload()}
    >
      Refresh
    </a>
  </span>
);

export { RefreshSpan };
