import styled from 'styled-components';
import { Input } from '@freeda/react-components';

const InputStyled = styled(Input)`
  && {
    & > div > input {
      border-color: ${props => props.theme.primary};
      height: 33px;
    }
  }
`;

const FilterWrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${props => props.theme.azzurrino};
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px;
    justify-content: flex-end;

    & > div {
      margin-bottom: 20px;
    }
  }
`;

interface PropsSelectWrapper {
  basis?: string | number;
  mobileBasis?: string | number;
  style?: React.CSSProperties;
}

const FieldWrapper = styled.div<PropsSelectWrapper>`
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  width: auto;
  margin-left: 0px;
  align-items: center;
  flex-direction: row;

  flex-basis: ${props => props.basis};
  @media (min-width: 769px) {
    align-self: center;
  }
`;

const MobileLabelWrapper = styled.div`
  width: 125px;
`;

export { InputStyled, FilterWrapper, FieldWrapper, MobileLabelWrapper };
