import * as React from 'react';
import { Query } from 'react-apollo';
import { GET_PARENT_CONTENT } from '../../../apollo/queries/queries2.0/GetParentContent';
import ParentContentT from '../../../types/types2.0/graphql2.0/GetParentContentQuery';
import { CustomQueryProps } from '../../../types/graphql/utils';

class TypedQuery extends Query<ParentContentT, {}> {}

export const ParentContentQuery: React.FC<CustomQueryProps<ParentContentT>> = ({
  children,
  ...others
}) => {

  return (
    <TypedQuery  query={GET_PARENT_CONTENT} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
