import * as React from 'react';
import { Dialog, Typography, Button } from '@freeda/react-components';
import { Dialogable } from '../../../types/Dialogable';
import { DeleteButtonWrapper } from './styles';
import DeleteLabelButton from '../../../containers/DeleteLabelButton';
import { DialogHeader } from '../../ui/DialogHeader';
import { Colors } from '@freeda/react-components/lib/theme';
import { ButtonWrapperDialog } from '../../ui/ButtonWrapperDialog';


type Props = Dialogable & {
  labelId: string;
  onOpenSnackbarReload: () => void;
};

const DeleteLabelDialog: React.FC<Props> = ({
  open,
  onClose,
  labelId,
  onOpenSnackbarReload
}) => {
    return (
      <Dialog
        onClose={onClose}
        openDialog={open}
        rootIdAttributes="root"
        style={{ width: 400, paddingLeft: 30 }}
      >
        <DialogHeader title="Delete label" onClose={onClose} />
        <Typography variantName="text" textAlign="left" textColor={Colors.GRIGIO}>
          You are deleting the selected label, you will not be able to recover this item.
        </Typography>
        <ButtonWrapperDialog>
          <Button action="undo" onClick={onClose}>
            Don't do it
          </Button>
          <DeleteButtonWrapper>
            <DeleteLabelButton
              labelId={labelId}
              onCloseDialog={onClose}
              onOpenSnackbarReload={onOpenSnackbarReload}
            />
          </DeleteButtonWrapper>
        </ButtonWrapperDialog>
      </Dialog>
    );
};

export { DeleteLabelDialog };
