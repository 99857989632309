import * as React from 'react';
import { Select, CheckBox, DividerSection } from '@freeda/react-components';

import { RowForm } from './styles';
import { LanguageCountry } from '../../../types/Language';

import { MediaItem } from '../../../types/MediaItem';
//import { PlatformContent } from '../../../types/types2.0/PlatformContent';
import { LocalContent } from '../../../types/types2.0/LocalContent';
import { ParentContent } from '../../../types/types2.0/ParentContent';


import Media from 'react-media';

type EditableMediaFields = Pick<
MediaItem,
'languageSound' | 'languageCopy' | 'languageSubtitle' | 'onlyMusic' | 'noSubtitle'
>

type EditableMediaFieldsByLocal = Pick<
  LocalContent,
  'languageCopy' | 'languageSubtitle' | 'noSubtitle'

>;

type EditableMediaFieldsByParent = Pick<
  ParentContent,
  'languageSound' |  'onlyMusic' 
>;



type Props = { mimeType: string } & EditableMediaFieldsByLocal & EditableMediaFieldsByParent & {
    onChangeSoundLanguage: (lang: LanguageCountry) => void;
    onChangeCopyLanguage: (lang: LanguageCountry) => void;
    onChangeSubLanguage: (lang: LanguageCountry) => void;
    onChangeOnlyMusic: () => void;
    onChangeNoSubtitle: () => void;
    readOnlyUser:boolean;
  };

const LanguageSettings: React.FC<Props> = ({
  readOnlyUser,
  languageSound,
  languageCopy,
  languageSubtitle,
  mimeType,
  onChangeSoundLanguage,
  onChangeCopyLanguage,
  onChangeSubLanguage,
  onlyMusic,
  onChangeOnlyMusic,
  noSubtitle,
  onChangeNoSubtitle
}) => {
  const isImage = mimeType.indexOf('image') >= 0;
  const isVideo = mimeType.indexOf('video') >= 0;

  return (
    <Media query={{ maxWidth: 840 }}>
      {(matches: boolean) => (
        <>
          <DividerSection label="Language Settings" style={{ margin: '13px 0px 18px' }} />
          <RowForm style={{ marginBottom: 20, flexWrap: matches ? 'wrap' : 'nowrap' }}>
            {isImage && (
              <Select
                isDisabled={readOnlyUser}
                label="Copy"
                placeholder="Copy"
                style={{ width: matches ? '100%' : 110 }}
                optionsSort="asc"
                isSearchable
                onChange={(option: any) => onChangeCopyLanguage(option ? option.value : null)}
                options={Object.keys(LanguageCountry).map(l => ({ value: l, label: l }))}
                value={
                  languageCopy
                    ? {
                        value: languageCopy,
                        label: languageCopy
                      }
                    : null
                }
              />
            )}
            {isVideo && (
              <>
                <CheckBox
                  disabled={readOnlyUser}
                  checked={onlyMusic}
                  onClick={() => onChangeOnlyMusic()}
                  label="Only music*"
                  style={{ marginRight: 16, position: 'relative', top: matches ? 0 : 10 }}
                />
                <Select
                  
                  label="Sound (* if not 'Only music')"
                  placeholder="Select sound"
                  optionsSort="asc"
                  style={{ marginRight: matches ? 0 : 30, marginTop: matches ? 10 : 0 }}
                  isSearchable
                  onChange={(option: any) => onChangeSoundLanguage(option ? option.value : null)}
                  isDisabled={readOnlyUser || onlyMusic}
                  options={Object.keys(LanguageCountry).map(l => ({ value: l, label: l }))}
                  value={
                    languageSound
                      ? {
                          value: languageSound,
                          label: languageSound
                        }
                      : null
                  }
                />
                <CheckBox
                  checked={noSubtitle}
                  disabled={readOnlyUser/* || onlyMusic*/}
                  onClick={() => onChangeNoSubtitle()}
                  label="No subtitles*"
                  style={{
                    position: 'relative',
                    top: matches ? 0 : 10,
                    marginTop: matches ? 10 : 0
                   
                  }}
                />
                <Select
                  label="Subtitles (* if not 'No subtitle')"
                  placeholder="Select subtitles"
                  optionsSort="asc"
                  style={{ marginRight: matches ? 0 : 2, marginLeft: 12, marginTop: matches ? 10 : 0 }}
                  isSearchable
                  onChange={(option: any) => onChangeSubLanguage(option ? option.value : null)}
                  isDisabled={readOnlyUser || noSubtitle/* || onlyMusic*/}
                  options={Object.keys(LanguageCountry).map(l => ({ value: l, label: l }))}
                  value={
                    languageSubtitle
                      ? {
                          value: languageSubtitle,
                          label: languageSubtitle
                        }
                      : null
                  }
                />
                
              </>
            )}
          </RowForm>
        </>
      )}
    </Media>
  );
};

export { LanguageSettings };
