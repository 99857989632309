import * as React from 'react';
import { Country } from '../../../types/graphql/Country';
import { Flag, Wrapper } from './styles';
import { ImageMap } from '../../../utils/countriesMap';

interface Props {
  countries: Array<Country>;
  selectedCountry: Country;
  onSelection: (country: Country) => void;
}

const CountrySelection: React.FC<Props> = ({ countries, selectedCountry, onSelection }) => (
  <Wrapper>
    {Object.keys(ImageMap).map((k: string) => {
      return (
        countries.find((c) => k === c) && (
          <Flag
            onClick={() => onSelection(k as Country)}
            isSelected={k === selectedCountry}
            key={k}
          >
            <img src={ImageMap[k]} />
          </Flag>
        )
      );
    })}
  </Wrapper>
);

export { CountrySelection };
