import { GetTmpAwsCredentialsQuery } from '../../types/graphql/GetTmpAwscredentialsQuery';

import clientTobe from '../clientTobe';
import { GET_TMP_AWSCREDENTIALS } from '../queries/GetTmpAwsCredentials';

const getTmpAwsCredentials = () =>
  clientTobe.query<{ getTmpAwsCredentials: GetTmpAwsCredentialsQuery }>({
    query: GET_TMP_AWSCREDENTIALS,
    fetchPolicy: 'no-cache'
  });

export { getTmpAwsCredentials };
