import * as React from 'react';

import { Country as DistributionCountry, Country } from '../types/graphql/Country';
import { CountrySelectionStep } from '../components/layout/EditMediaPage/steps/newCountry/CountrySelectionStep';
import UploadMediaStep from '../components/layout/EditMediaPage/steps/newCountry/UploadMediaStep';
import { MergeMediaStep } from '../components/layout/EditMediaPage/steps/newCountry/MergeMediaStep2.0';
import { WizardStep } from '../components/ui/WizardStep';
import { HeaderEditMedia } from '../components/ui/HeaderEditMedia';
import { EditStep } from './EditMediaPage2';
//import { EnhancedMediaTranslation } from '../types/EnhancedMedia';
import { EnhancedLocalContent } from '../types/types2.0/EnhancedContent';
import { ParentContent } from '../types/types2.0/ParentContent';
import { ButtonIcon, Icon, Typography } from '@freeda/react-components';

type Props = {
  parentId: string;
  localizations: Array<EnhancedLocalContent>;
  projectName:string;
  projectId:string;
  format:string;
 
  changeEditStep: (editStep: EditStep) => void;
  addLocalization: (distributionCountry: DistributionCountry) => (file: File) => void;
  onAfterMerge: (newParent: ParentContent, newDistributionCountry: Country) => void;
};

type State = {
  stepIndex: number;
  newTranslationDistributionCountry: DistributionCountry | null;
};

class UploadNewCountryPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      stepIndex: 0,
      newTranslationDistributionCountry: null
    };
  }

  incrementStep = () => this.setState(state => ({ stepIndex: state.stepIndex + 1 }));

  decrementStep = () => this.setState(state => ({ stepIndex: state.stepIndex - 1 }));

  render() {
    const { stepIndex, newTranslationDistributionCountry } = this.state;
    const { parentId, localizations, projectName, format, projectId,  changeEditStep, addLocalization, onAfterMerge } = this.props;
    const translationsDistributionCountries = localizations.map(t => t.distributionCountry);

    const mimeType = localizations[0].platforms[0].mimeType
   //console.log('translationsDistributionCountries_______' ,translationsDistributionCountries)
    
    let remainingCountries = Object.keys(DistributionCountry).filter(
      dc => !translationsDistributionCountries.find(country => country === dc)
    ) as Array<DistributionCountry>;
   // console.log('remainingCountries___' ,remainingCountries)
    
    if(projectName.toLowerCase()!='goovi'){
      remainingCountries = remainingCountries.filter(function(e) { return e !== DistributionCountry['DE'] })
    }

    const steps = [
      {
        label: 'Select new Country',
        component: (
          <CountrySelectionStep
            onCountrySelection={(country: DistributionCountry) =>
              this.setState({ newTranslationDistributionCountry: country }, () =>
                this.incrementStep()
              )
            }
            countries={remainingCountries}
          />
        ),
        completed: true
      },
      {
        label: 'Select content source',
        component: (
          <UploadMediaStep
            mimeType={localizations[0].platforms[0].mimeType}
            goToFileSelection={() => this.setState({ stepIndex: 2 })}
            onAddTranslation={addLocalization(newTranslationDistributionCountry!)}
            changeEditStep={() => changeEditStep('EDIT')}
          />
        ),
        completed: true
      },
      {
        label: 'Upload/Merge Content',
        component: (
          <MergeMediaStep
            mediaDistributionCountries={localizations.map(t => ({
              id: t.id,
              distributionCountry: t.distributionCountry
            }))}
            distributionCountry={newTranslationDistributionCountry}
            toExclude={parentId}
            format={format}
            projectId = {projectId}
            changeEditStep={changeEditStep}
            onAfterMerge={onAfterMerge}
            mimeType={localizations[0].platforms[0].mimeType.indexOf('video') >=0 ? "VIDEO": "IMAGE"}
          />
        ),
        completed: true
      }
    ];

    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100vw'
        }}
      >
      
        <HeaderEditMedia
          backElement={
            stepIndex === 0 ? (
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                onClick={() => changeEditStep('EDIT')}
              >
                <ButtonIcon style={{ marginRight: 5 }}>
                  <Icon iconName="arrow-left" />
                </ButtonIcon>
                <Typography
                  variantName="label"
                  textColor="#6B20DA"
                  style={{ fontSize: 14, cursor: 'pointer' }}
                >
                  Back
                </Typography>
              </div>
            ) : (
              <div
                style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}
                onClick={() => {
                  this.decrementStep();
                }}
              >
                <ButtonIcon style={{ marginRight: 5 }}>
                  <Icon iconName="arrow-left" />
                </ButtonIcon>
                <Typography
                  variantName="label"
                  textColor="#6B20DA"
                  style={{ fontSize: 14, cursor: 'pointer' }}
                >
                  Back
                </Typography>
              </div>
            )
          }
        />
        <WizardStep title="Upload new distribution Country" activeStep={stepIndex} steps={steps} />
      </div>
    );
  }
}

export { UploadNewCountryPage };
