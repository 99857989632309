import * as React from 'react';
import { WrapperLateralPanel, BackgroundLayer } from '../../ui/LateralPanel';
import { TransitionStatus } from 'react-transition-group/Transition';
import { BackButton } from '../../ui/BackButton';
import { ButtonIcon, TitleInput, Typography } from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { MetadataType, LabelType } from '../../../types/MetadataType';
//import { SelectBrandCategory } from '../MetadataFilter/SelectBrandCategory2.0';

import { SelectMacroTopic } from '../MetadataFilter/SelectMacroTopic';
//import { Brand } from '../../../types/types2.0/Brand';
import { Topic } from '../../../types/types2.0/Topic';
//import { Serie } from '../../../types/types2.0/Serie';
import { Label } from '../../../types/types2.0/Label';
import { CSSTransition } from 'react-transition-group';
import { ErrorWrapper } from '../CreateMetadataDialog2.0/styles';
import { UpdateMetadata } from '../../mutations/mutations2.0/UpdateMetadata';
import { FeedbackContext } from '../../../providers/FeedbackProvider';
//import { getLabelMetadataAvailable } from '../../../utils/getLabelMetadataAvailable';
import { useKeyUpEsc } from '../../../hooks/useKeyUpEsc';
//import { MultiSelectProductTypes } from '../MetadataFilter/MultiSelectProductTypes';
//import { ProductType } from '../../../types/ProductType';

import { MultiSelectProjects } from '../MetadataFilter/MultiSelectProject';
import { Project } from '../../../types/types2.0/Project';
//import { difference } from 'lodash';
import { each } from 'lodash';

interface Props {
  open: boolean;
  onClose: (editMetadata?: boolean) => void;
  metadataType: MetadataType;
  status: TransitionStatus;
  selectedMetadata: Topic  | Label;
}

const EditMetadataPanel: React.FC<Props> = ({
  open,
  onClose,
  metadataType,
  status,
  selectedMetadata,
}) => {

  const isTopic = metadataType === MetadataType.TOPICS;
  const isFormat = metadataType === MetadataType.FORMATS;


  const initialMacroTopicName: any = isTopic ? selectedMetadata['macroTopic'] : null;

  //console.log('selectedMetadata______', selectedMetadata)
  //console.log('initialMacroTopicNamea______', initialMacroTopicName)

  
  const initialProjectIds: Array<string> = /*(isSerie || isTopic) && */ selectedMetadata['project']
    ? selectedMetadata['project'].map((pT: Project) => pT.id!)
    : [];


  const initialMetadataLabel: string = selectedMetadata['name'];

  const metadataName = String(metadataType).slice(0, -1);

  const [selectedMacroTopicName, setSelectedMacroTopicName] = React.useState<string | null>(
    initialMacroTopicName
  );
  const [projectIds, setprojectIds] = React.useState<Array<string>>(initialProjectIds);
  
  const [metadataLabel, setMetadataLabel] = React.useState<string>(initialMetadataLabel);
  const [availableMetadataLabel, setAvailableMetadataLabel] = React.useState<boolean>(true);
  let [disabledSaveButton, setDisabledButton] = React.useState<boolean>(true);

  useKeyUpEsc(onClose);

  React.useEffect(() => {
    const metadataLabelChanged = initialMetadataLabel !== metadataLabel;
    let saveButtonIsDisabled = disabledSaveButton;

    if(!isTopic){
        saveButtonIsDisabled = (initialProjectIds !== projectIds);
        setDisabledButton(!(metadataLabelChanged || saveButtonIsDisabled));

    }else{
        saveButtonIsDisabled = (initialMacroTopicName !== selectedMacroTopicName) || initialMacroTopicName!=null;
        setDisabledButton(!(metadataLabelChanged && saveButtonIsDisabled));
    }
  }, [ metadataLabel, projectIds, selectedMacroTopicName ]);

  const buildEditSectionByMetadataType = () => {
    const placeholder = `${metadataName} ${'name'}`;

    const titleInput = (
      <TitleInput
        label={'name'}
        placeholder={placeholder}
        value={metadataLabel}
        onChange={(e) => {
          setMetadataLabel(e.currentTarget.value);
          setAvailableMetadataLabel(true);
        }}
      />
    );

    if (isTopic) {
      return (
        <>
          <div style={{ position: 'relative' }}>
            <SelectMacroTopic
              style={{ width: '100%', marginBottom: 20 }}
              label="Macro Topic"
              isClearable={false}
              selectedMacroTopicName={selectedMacroTopicName ? selectedMacroTopicName : null}
              
              onChangeMacroTopicName={(name) => {
                if (name) {
                  setSelectedMacroTopicName(name);
                }
              }}
            />
          </div>
          
          {titleInput}
        </>
      );
    }
    else if (metadataType === MetadataType.SERIES || metadataType === MetadataType.PRODUCTS || metadataType===MetadataType.KEYWORDS 
                    || metadataType===MetadataType.EDITORIAL_CAMPAIGNS ) {
      return (
        <>
          <MultiSelectProjects
            style={{ width: '100%', marginBottom: 20 }}
            label="Projects"
            selectedProjectIds={projectIds}
            onChangeProjectIds={(ids) => setprojectIds(ids)}
          />
          {titleInput}
        </>
      );
    }
    else{
      return (
        <>
          {titleInput}
        </>
      );
    }

   

    //return titleInput;
  };

  return (
    <FeedbackContext.Consumer>
      {({ onOpen }) => (
        <UpdateMetadata metadataType={metadataType}>
          {(updateMetadata, updateMetadataResult) => {
            return (
              <>
                <BackgroundLayer
                  open={open}
                  onClick={() => onClose()}
                  className={`background-${status}`}
                />
                <WrapperLateralPanel
                  open={open}
                  className={`wrapper-${status}`}
                  style={{ justifyContent: 'start' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <BackButton onClick={() => onClose()} />
                    <ButtonIcon
                      iconName="save"
                      disabled={disabledSaveButton || updateMetadataResult.loading}
                      loading={updateMetadataResult.loading}
                      iconColor={Colors.ROSSINO}
                      
                      onClick={async () => {
                        
                        /*const brandVariablesMutation = Boolean(
                          isBrand && selectedBrandCategoryName
                        ) && {
                          
                          brand_category : selectedBrandCategoryName,
                        };*/
                        
                        /*const projectsConnected = difference(
                          projectIds,
                          initialProjectIds
                        );*/


                        const projectsConnected = each(projectIds, function(value) {
                        
                          if (initialProjectIds.indexOf(value) === -1) {
                              initialProjectIds.push(value);
                          }
                          return initialProjectIds
                        })
                        
                        
                        /*const projectsDisconnected = difference(
                          initialProjectIds,
                          projectIds
                        );*/

                        const projectsDisconnected = each(projectIds, function(value) {
                        
                            if (initialProjectIds.indexOf(value) === -1) {
                                initialProjectIds.splice(initialProjectIds.indexOf(value), 1);
                            }
                          return initialProjectIds
                        })

                        const connectProjectsVariables = projectsConnected.length > 0 && {
                          /*connect: projectsConnected.map((pT) => ({
                            id: pT,
                          })),*/
                          project: projectsConnected.map((pT) => ({
                            id: pT,
                          }))

                        };
                        
                        const disconnectProjectsVariables = projectsDisconnected.length >
                          0 && {
                          project: projectsDisconnected.map((pT) => ({
                            id: pT,
                          }))
                        };

                        /*const serieVariablesMutation = Boolean(
                          isSerie && projectIds.length > 0
                        ) && {
                          ...connectProjectsVariables,
                          ...disconnectProjectsVariables
                        };*/
                      

                        /*const topicVariablesMutation = Boolean(
                          isTopic && projectIds.length > 0
                        ) && {
                          ...connectProjectsVariables,
                          ...disconnectProjectsVariables
                        };*/

                        const labelVariablesMutation = Boolean(
                          !isFormat && projectIds.length > 0
                        ) && {
                          ...connectProjectsVariables,
                          ...disconnectProjectsVariables,
                        };

                       
                        const variablesMutation = {
                          ['name']: metadataLabel.trim(),
                          id: selectedMetadata.id,
                          category: LabelType[metadataType.toUpperCase()],
                          macroTopic : isTopic ?  selectedMacroTopicName : null,
                          //...topicVariablesMutation,
                          ...labelVariablesMutation
                        };

                        console.log('variablesMutation__', variablesMutation)

                        try {
                          /*const labelIsAvailable = await getLabelMetadataAvailable(
                            metadataType,
                            metadataLabel,
                            initialMetadataLabel
                          );*/

                          //setAvailableMetadataLabel(labelIsAvailable);

                          //if (labelIsAvailable) {
                            

                            await updateMetadata({
                              variables: variablesMutation,
                            });
                            onOpen(`Cool! ${metadataName} edited correctly! 😎`, 'notification');
                            onClose(true);
                          //}
                        } catch (err) {
                          onOpen(
                            `Oops, something went wrong when try to edit ${metadataName}! 😔`,
                            'error'
                          );
                          console.log(err);
                        }
                      }}
                    />
                  </div>
                  <div style={{ padding: 15 }}>
                    {buildEditSectionByMetadataType()}
                    <CSSTransition in={!availableMetadataLabel} timeout={0} classNames="">
                      {(trasistionStatus) => (
                        <ErrorWrapper className={trasistionStatus}>
                          <Typography variantName="error">
                            <>This {'name'} isn't available, it's already exists.</>
                          </Typography>
                          <Typography
                            variantName="text"
                            style={{ top: -2, position: 'relative', fontSize: 16 }}
                          >
                            &nbsp; 🧐
                          </Typography>
                        </ErrorWrapper>
                      )}
                    </CSSTransition>
                  </div>
                </WrapperLateralPanel>
              </>
            );
          }}
        </UpdateMetadata>
      )}
    </FeedbackContext.Consumer>
  );
};

export { EditMetadataPanel };
