import * as React from 'react';
import { Mutation } from 'react-apollo';
import { CustomMutationProps } from '../../../types/graphql/utils';
import { MetadataType, /*LabelType*/} from '../../../types/MetadataType';
import { getUpdateMetadataMutation } from '../../../utils/metadataUtils2.0';
import {
  OnUpdateLabel
} from '../../../types/types2.0/graphql2.0/UpdateMetadata';



class TypedMutation extends Mutation<OnUpdateLabel> {}

export const UpdateMetadata: React.FC<
  CustomMutationProps<OnUpdateLabel> & {
    metadataType: MetadataType;
  }
> = ({ children, metadataType, ...others }) => {
  const mutation = getUpdateMetadataMutation(metadataType);
  return (
    <TypedMutation mutation={mutation} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
