import styled, { css } from 'styled-components';
import { ListItem, List } from '@freeda/react-components';

const FileTitle = styled.span<{ progress: number }>`
  word-break: break-word;
  ${props =>
    props.progress === 100 &&
    css`
      color: ${props.theme.primary};
    `};
`;

const ListItemStyled = styled(ListItem)`
  justify-content: space-between;
  padding: 12px 0px;
  &:hover {
    background: none;
  }
`;

const ListStyled = styled(List)`
  overflow-y: overlay;
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
  max-height: 386px;
`;

export { FileTitle, ListItemStyled, ListStyled };
