import * as React from 'react';
import styled from 'styled-components';
import { PlaceholderInput } from './PlaceholderInput';
import { PlaceholderCheckbox } from './PlaceholderCheckbox';
import { Avatar, PlaceholderHr } from './styles';

const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Circle = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: #ebebeb;
  margin-left: auto;
`;

const BackgroundFilter = styled.div`
  width: 100%;
  background: #f5f5f5;
  padding: 25px 15px 30px 15px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const avatarStyle = {
  position: 'absolute' as 'absolute', // lol
  right: -55,
  width: 38,
  height: 38,
  background: '#eeeeee'
};

const PlaceholderFilter: React.FC = () => (
  <FilterWrapper>
    <SearchWrapper>
      <PlaceholderInput style={{ margin: '15px 0', flexBasis: '30%' }}>
        <Circle />
      </PlaceholderInput>
    </SearchWrapper>
    <BackgroundFilter>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PlaceholderCheckbox style={{ marginRight: 20 }} />
        <PlaceholderCheckbox />
      </div>
      <PlaceholderInput style={{ paddingRight: 20, flexBasis: '15%' }} />
      <PlaceholderInput style={{ padding: '0px 20px', flexBasis: '15%' }} />
      <PlaceholderInput style={{ padding: '0px 20px', flexBasis: '15%' }} />
      <PlaceholderInput style={{ paddingLeft: 20, flexBasis: '15%' }} />
      <PlaceholderHr />
      <PlaceholderInput />
      <PlaceholderInput />
      <PlaceholderInput />
      <PlaceholderInput />
      <PlaceholderHr style={{ background: '#f5f5f5', margin: '16px 0px' }} />
      <PlaceholderInput>
        <Avatar style={avatarStyle} />
      </PlaceholderInput>
      <PlaceholderInput>
        <Avatar style={avatarStyle} />
      </PlaceholderInput>
      <PlaceholderInput style={{ flexBasis: '15%' }} />
    </BackgroundFilter>
  </FilterWrapper>
);

export { PlaceholderFilter };
