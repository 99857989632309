import * as React from 'react';
import { Query } from 'react-apollo';
import { LIST_CONTENT } from '../../../apollo/queries/queries2.0/ListContent';
import ListContentQueryT from '../../../types/types2.0/graphql2.0/ListContentQuery';
import { CustomQueryProps } from '../../../types/graphql/utils';
//import clientTobe from '../../../apollo/clientTobe';

class TypedQuery extends Query<ListContentQueryT, {}> {}

export const ListContentQuery: React.FC<CustomQueryProps<ListContentQueryT>> = ({
  children,
  ...others
}) => {
  return (
    <TypedQuery /*client={clientTobe}*/ query={LIST_CONTENT} {...others}>
      {result => {
        return children(result);
      }}
    </TypedQuery>
  );
};
