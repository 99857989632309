import * as React from 'react';

export const usePreviousData = <T extends any>(value: T) => {
  const ref = React.useRef<T>(value);

  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
