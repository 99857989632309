import * as React from 'react';
import styled from 'styled-components';
import { Avatar, BigText } from './styles';

const MediaWrapper = styled.div`
  width: 270px;
  height: 270px;
  background: #eeeeee;
  display: flex;
`;

const InfoMedia = styled.div`
  width: 270px;
  height: 95px;
  background: #f5f5f5;
  align-self: flex-end;
  padding: 20px;
  box-sizing: border-box;
`;

const PlaceholderMedia: React.FC = () => (
  <MediaWrapper>
    <InfoMedia>
      <BigText style={{ marginBottom: '10px' }} />
      <Avatar style={{ background: '#eeeeee' }} />
    </InfoMedia>
  </MediaWrapper>
);

export { PlaceholderMedia };
