import * as React from 'react';
import { Mutation } from 'react-apollo';
import { UPDATE_PARENT_CONTENT } from '../../../apollo/mutation/mutation2.0/UpdateParentContent';
import { ParentContent } from '../../../types/types2.0/ParentContent';
import { CustomMutationProps } from '../../../types/graphql/utils';


class TypedMutation extends Mutation<{ updateParentContent: ParentContent }> {}

export const UpdateParentContentMutation: React.FC<
  CustomMutationProps<{ updateParentContent: ParentContent }>
> = ({ children, ...others }) => {
  return (
   
    <TypedMutation /*client={clientTobe}*/ mutation={UPDATE_PARENT_CONTENT} {...others}>
      {(mutation, result) => {
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
