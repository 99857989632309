import * as React from 'react';
import { Table } from '@freeda/react-components';
import { useMediaQuery } from 'react-responsive';
import { TableHeaderP } from '@freeda/react-components/lib/Table/TableHeader';
import { Brand } from '../types/types2.0/Brand';
import { Topic } from '../types/types2.0/Topic';
import { Serie } from '../types/types2.0/Serie';
import { Campaign } from '../types/types2.0/Campaign';
import { PlaceholderTable } from '../components/ui/Placeholders/PlaceholderTable';
import { MetadataFilterContext } from '../providers/BrandsFilterProvider2.0';

export type TableHead = Array<TableHeaderP.HeaderT>;

export type ResponsiveTableHead = {
  desktop: TableHead;
  mobile: TableHead;
};

export type ColumnsTable = Array<{
  id: string;
  render?: (item: any) => JSX.Element;
}>;

interface Props {
  head: ResponsiveTableHead;
  columns: ColumnsTable;
  TableFilter: JSX.Element;
  data: Array<Serie | Topic | Brand | Campaign>;
  loadingData: boolean;
  count: number;
  FilterContext: React.Context<MetadataFilterContext>;
}

const MetadataTable: React.FC<Props> = ({
  head,
  columns,
  TableFilter,
  data,
  loadingData,
  count,
  FilterContext,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { order, orderBy, onChangeMetadataOrder } = React.useContext(FilterContext);

  const metadataHead = !isMobile ? head.desktop : head.mobile;

  if (loadingData) {
    return <PlaceholderTable />;
  }
 // console.log('-----MetadataTable___columns',columns )
 // console.log('-----MetadataTable___data',data)
  
  return (
    <Table
      columns={columns}
      tableData={data}
      rowIdGenerator={(metadata: { id: string }) => metadata.id}
      itemsPerPage={count}
      head={metadataHead}
      tableFilter={TableFilter}
      changeOrder={{
        orderBy,
        order,
        onChangeOrder: (orderBy: string | null, order: any) =>
          onChangeMetadataOrder(orderBy, order),
      }}
      fixedHeader={!isMobile}
      wrapperStyle={{ marginBottom: 40, padding: isMobile ? '0px 15px' : '' }}
    />
  );
};

export { MetadataTable };
