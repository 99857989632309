import gql from 'graphql-tag';

const GET_TMP_AWSCREDENTIALS = gql`
  query getTmpAwsCredentials {
    getTmpAwsCredentials{
      accessKeyId
      secretAccessKey
      sessionToken
    }
  }
`;

export { GET_TMP_AWSCREDENTIALS };
