import { UpdateDateRange } from '../types/UpdateDateRange';
import moment from 'moment';
import { Country } from '../types/graphql/Country';
import SearchMediaVariables, { DateRangeVariableFilter } from '../types/types2.0/SearchMediaVariables';
import { LanguageCountry } from '../types/Language';
import { ContentType } from '../types/ContentType';
import { Format } from '../types/types2.0/Format';                 
import { DateRange } from '../types/DateRange';
import { getDateRange } from './getDateRangeFn';


const getVariables = (
  searchValue: string,
  brandId: string | null,
  serieId: string | null,
  topicId: string | null,
  macroTopic: string |null,
  isEditorial: boolean,
  isBranded: boolean,
  reel:boolean,
  productTypeId: Array<string> | null,
  selectedLanguage: LanguageCountry | null,
  selectedFormat: Array<Format> | null,
  selectedProductionCountry: Country | null,
  selectedDistributionCountry: Country | null,
  members: Array<string> | null,
  contributors: Array<string> | null,
  updateDateRange: UpdateDateRange,
  projectId: string | null,
  platformType: string | null,

  selectedEditorialCampaignId: string | null,
  selectedBrandCampaignId: string | null,
  selectedGenreId: string | null,
  selectedObjectiveId: string | null,
  selectedTemperatureId: string | null,
  selectedProductId: string | null,
 // notCompleted:boolean|undefined,
 // notMapped:boolean|undefined,
  selectedCompletion:string|null

): SearchMediaVariables => {
  
  let vars: SearchMediaVariables = {};

  if(selectedEditorialCampaignId){
    vars.editorialCampaign = selectedEditorialCampaignId ;
  }
  
  /*if(notCompleted != undefined ){
    vars.completed = !notCompleted;
  }

  if(notCompleted == false ){
    vars.completed = undefined;
  }

  if(notMapped != undefined ){
    vars.notMapped = notMapped;
  }

  if(notMapped == false || notMapped===undefined){
    vars.notMapped = undefined;
  }*/

  if(selectedBrandCampaignId){
    vars.campaign = selectedBrandCampaignId ;
  }

  if(selectedGenreId){
    vars.genre = selectedGenreId ;
  }

  if(selectedObjectiveId){
    vars.objective = selectedObjectiveId ;
  }

  if(selectedTemperatureId){
    vars.temperature = selectedTemperatureId ;
  }

  if(selectedProductId){
    vars.product= selectedProductId ;
  }

  if(projectId){
    vars.project = projectId ;
  }

  if(platformType){
    vars.itemType = platformType;
  }
 
  if(reel){
    vars.reel = reel;
  }

  if(searchValue && searchValue.length>2){

    
    if(searchValue.startsWith('"', 0) && searchValue.endsWith('"', searchValue.length)){
      let newString = searchValue

      const searchRegExp = new RegExp('"', 'g'); // Throws SyntaxError
      const result = newString.replace(searchRegExp, '');
      vars.title = result;
      
      vars.keywords = [{name: result} ]

    }
    else{
      vars.title = searchValue ;
      vars.keywords = searchValue
        .split(' ')
        .map(name => ({ name }
        ))
        .filter(param => param.name !== '');
    }
    
    
  }
  
  if (brandId) {
    vars.brand = { id: brandId };
  }

  if (serieId) {
    vars.serie = { id: serieId };
  }
  if(macroTopic){
    vars.macroTopic=macroTopic
  }

  if (topicId) {
    vars.topic = { id: topicId };
    
  }
  if (selectedCompletion) {

    
    vars.completion =  selectedCompletion;

    
  }

  let contentType:any = [];

  if (isEditorial) {
    contentType.push(ContentType.EDITORIAL);
  }

  if (isBranded) {
    contentType.push(ContentType.BRANDED);
  }

  if (contentType.length > 0) {
    vars.contentType_in = contentType;
  }
  else{
    delete vars['contentType_in']; 

  }


  if (productTypeId && productTypeId.length>0) {
    let arr:any=[]
    productTypeId.forEach((value)=>{
      arr.push( { "id": value } );
    })
    vars.productType = arr
  }

  //mettere su parent
  if (selectedLanguage) {
    vars.language = selectedLanguage;
  }

  if (selectedFormat && selectedFormat.length > 0) {
    vars.format_in =  selectedFormat.map(mId => ( mId )) 
  
  }

  if (selectedProductionCountry) {
    vars.productionCountry = selectedProductionCountry;
  }

  if (selectedDistributionCountry) {
    vars.distributionCountry = selectedDistributionCountry;
  }

  if (members && members.length>0) {
    vars.members = members.map(mId => ({ id: mId }))  ;
   
  }

  if (contributors && contributors.length >0) {
    vars.contributors =  contributors.map(id => ({ id: id }));
  }

  if (updateDateRange) {
    let startDate: string | null = null;
    let endDate: string | null = null;

    if (updateDateRange !== DateRange.ANY_TIME) {
      if (typeof updateDateRange === 'object') {
        startDate =
          updateDateRange.from === null
            ? null
            : moment(updateDateRange.from)
                .startOf('day')
                .format();
        endDate =
          updateDateRange.to === null
            ? null
            : moment(updateDateRange.to)
                .endOf('day')
                .format();
        
      } else {

        const range = getDateRange(updateDateRange);
        startDate = range.from;
        endDate = range.to;

      }

      let dateRange = {};

      if (startDate) {
        //dateRange.push({ since: startDate });
        dateRange['since']=moment(startDate).format('YYYY-MM-DDTHH:mm:ss')
      }

      if (endDate) {
        //dateRange.push({ until: endDate });
        dateRange['until'] = moment(endDate).format('YYYY-MM-DDTHH:mm:ss')
      }

      vars.createdAtDates = dateRange as DateRangeVariableFilter;
    }
  }

  /*if( !searchValue  && !brandId && !serieId && !topicId && !isEditorial && !isBranded 
                    && contentType.length==0 && !productTypeId && !selectedLanguage && !selectedProductionCountry && !selectedDistributionCountry
                        && !updateDateRange && !(members && members.length>0) && !(contributors && contributors.length >0) ){
        
       
    }*/
    
    //alert('getVariables '+ vars.skip)

  return vars;
};

export { getVariables };
