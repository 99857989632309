import * as React from 'react';
import {
  InteractiveAvatar,
  Typography,
  Input,
  Select,
  Autocomplete,
  DividerSection
} from '@freeda/react-components';
import { Colors } from '@freeda/react-components/lib/theme';
import { Option } from '@freeda/react-components/lib/Autocomplete';

import { AvatarList, InputWrapperStyled, RowForm  } from './styles';

//import { TeamMember } from '../../../types/TeamMember';
import { LocalContent } from '../../../types/types2.0/LocalContent';
import GetEditFormInfoQuery from '../../../types/types2.0/graphql2.0/GetEditInfoFormQuery';
//import { Contributor } from '../../../types/Contributor';
import { Country } from '../../../types/graphql/Country';
//import { PlatformContent } from '../../../types/types2.0/PlatformContent';
import { User } from '../../../types/types2.0/User';

import Media from 'react-media';
import { Format } from '../../../types/types2.0/Format';
import { ContentType } from '../../../types/ContentType';

  type EditableMediaFields = Pick<LocalContent, 'distributionCountry'>  & {
    members: Array<User>;
    contributors: Array<User>;
    newContributors: Array<string>;
    productionCountry?: string;
    sources?:string;
  };

type Props = Pick<GetEditFormInfoQuery, 'allTeamMembers' | 'allContributors'> & {
  allDistributionsCountry: Array<Country>;
} & EditableMediaFields & {
    onChangeAddedContributors: (contributor: User) => void;
    onChangeRemovedContributors: (contributor: User) => void;
    onChangeRemovedMembers:(member: User) => void;
    onAddNewContributors: (contributorName: string) => void;
    onRemoveNewContributors: (contributorName: string) => void;
    onChangeMembers: (member: User) => void;
    onChangeSources: (sources: string) => void;
    onChangeDistributionCountry: (distributionCountry: Country) => void;
    onChangeInstanArticleUrl: (instanArticleUrl: string) => void;
    readOnlyUser:boolean;
    format:Format;
    mimeType:string;
    
    instantArticleUrl:string|undefined;
  };

const ProductionInfo: React.FC<Props> = ({
  format,
  mimeType,
  readOnlyUser,
  instantArticleUrl,
  allTeamMembers,
  allContributors,
  allDistributionsCountry,
  members,
  contributors,
  newContributors,
  productionCountry,
  sources,
  distributionCountry,
  onChangeAddedContributors,
  onChangeRemovedContributors,
  onChangeRemovedMembers,
  onAddNewContributors,
  onRemoveNewContributors,
  onChangeMembers,
  onChangeSources,
  onChangeDistributionCountry,
  onChangeInstanArticleUrl,
}) => {
  
  //se quando entro qui il format è diverso da instant_article allora devo sbiancare il valore e triggerare l'aggiornamento su platfrom


 const isVideo = mimeType.indexOf('video') >= 0;

  const selectedTeamMembers = members
    .map(memberId => allTeamMembers.find(m => m.name === memberId.name))
    .filter(member => Boolean(member));

  return (
    <Media query={{ maxWidth: 840 }}>
      {(matches: boolean) => (
        <>
          <DividerSection label="Production Info" style={{ margin: '13px 0px 18px' }} />
          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
          <Autocomplete
              style={{display: readOnlyUser? 'none' : 'true'}}
              label="Team"
              placeholder="Select team member"
              styleWrapper={{ width: matches ? '100%' : '50%'}}
              optionsSort="asc"
              items={allTeamMembers.map(m => ({
                value: m.id,
                label: m.name
              }))}
              currentSelectedItem={(selectedTeamMembers as Array<User>).map(m => ({
                value: m.id,
                label: m.name
              }))}
              aligment="column"
             // onSelect={member => onChangeMembers({id:member.value, name:member.label} )}
              onSelect={({ value, label }) => onChangeMembers({ id: value, name: label })  }
              //onUnselect={member => onChangeMembers({id:member.value, name:member.label})}

              onUnselect={({ value, label }) =>
                  onChangeRemovedMembers({ id: value, name: label })
              }

              renderSelections={(teamMembers: any, handleDelete) => (
                <>
                  {(!matches || members.length > 0) && (
                    <AvatarList>
                      {teamMembers.map((member: any) => (
                        <InteractiveAvatar
                          key={member.value}
                          name={member.label.replace('.','')}
                          avatarUrl={member.picture}
                          size={40}
                          handleDelete={ () => !readOnlyUser ?  handleDelete(member) : null  }
                        />
                      ))}
                    </AvatarList>
                  )}
                </>
              )}
            />
            <Autocomplete
              label="Contributors"
              style={{display: readOnlyUser? 'none' : 'true'}}
              placeholder="Add Contributor"
              styleWrapper={{ width: matches ? '100%' : '50%', marginTop: matches ? 20 : 0 }}
              optionsSort="asc"
              currentSelectedItem={contributors.map(c => ({
                value: c.id,
                label: c.name
              }))}
              items={allContributors.map(contributor => ({
                value: contributor.id,
                label: contributor.name
              }))}
              aligment="column"
              onSelect={({ value, label }) => onChangeAddedContributors({ id: value, name: label })}
              onUnselect={({ value, label }) =>
                onChangeRemovedContributors({ id: value, name: label })
              }
              onAdd={onAddNewContributors}
              renderSelections={(selectedContributors: Array<Option>, handleDelete) => (
                <>
                  {(!matches || contributors.length > 0 || newContributors.length > 0) && (
                    <AvatarList>
                      {selectedContributors.map(contributor => (
                        <InteractiveAvatar
                          size={40}
                          key={contributor.value}
                          name={contributor.label.replace('.','')}
                          handleDelete={() => {
                            handleDelete(contributor);
                          }}
                        />
                      ))}
                      {newContributors.map((contributorName: string) => (
                        <InteractiveAvatar
                          size={40}
                          key={contributorName}
                          name={contributorName.replace('.','')}
                          handleDelete={() => {
                            onRemoveNewContributors(contributorName);
                          }}
                        />
                      ))}
                    </AvatarList>
                  )}
                </>
              )}
            />
          </RowForm>
          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
            <InputWrapperStyled>
              <Input
                disabled={readOnlyUser}
                id="sources"
                label= {format == Format.VIDEO || isVideo ? "Source folder*" : "Source folder"}
                value={sources || ''}
                onChange={e => onChangeSources(e.target.value)}
                type="text"
                placeholder="Paste here the path of the sources' folder"
              />
            </InputWrapperStyled>
          </RowForm>
          
          <RowForm style={{ flexWrap: matches ? 'wrap' : 'nowrap' }}>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 35 }}>
              <Typography variantName="label" style={{ marginBottom: 25 }}>
                Prod. country
              </Typography>
              <Typography
                variantName="label"
                style={{
                  marginLeft: 0,
                  color: Colors.PURPLE,
                  fontSize: 14,
                  position: 'relative',
                  top: -10
                }}
              >
                <>{productionCountry}</>
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 35 }}>
              <Select
                isDisabled={readOnlyUser}
                label="Distribution"
                style={{ width: 100 }}
                placeholder="Distribution"
                optionsSort="asc"
                isClearable={false}
                isSearchable={false}
                onChange={(option: any) => onChangeDistributionCountry(option ? option.value : null)}
                options={allDistributionsCountry.map(country => ({
                  value: country,
                  label: country
                }))}
                value={
                  distributionCountry
                    ? {
                        value: distributionCountry,
                        label: distributionCountry
                      }
                    : null
                }
              />
            </div>  
            {format == Format.INSTANT_ARTICLES && 
              <InputWrapperStyled>
                  <Input
                    disabled={readOnlyUser}
                    id="linkInstantArticle"
                    label="Link Instant Art.*"
                    value={ instantArticleUrl ? instantArticleUrl : ""}
                    onChange={e => onChangeInstanArticleUrl(e.target.value) }
                    type="text"
                    placeholder="Paste here the link Instant Art."
                  />
              </InputWrapperStyled>
            }

          </RowForm>
        </>
      )}
    </Media>
  );
};

export { ProductionInfo };
