import { Typography } from '@freeda/react-components';
import * as React from 'react';
import styled from 'styled-components';
//import { MediaFromExternalLinkQuery } from '../components/queries/MediaFromExternalLinkQuery';
import { ExternalPreviewPage } from '../pages/ExternalPreviewPage';
import { MyGallery } from '../pages/ExternalPreviewPageCarousel';
import { ItemType } from '../types/ItemType';
import {Content} from '../utils/contentUtils'



const ErrorWrapper = styled.div`
  transition: opacity 0.4s;
  display: flex;
  align-items: center;
  margin-top: 20px;
  &.entered {
    opacity: 2;
  }
`;

interface Props {
  externalId: string;
}


/*interface Content {
  title: string;
  mimeType: string;
  storagePath: string;
  itemType: string; 
  //localizations: Array<LocalContent>;
}*/

type State = {
  error: string | null;
  isLoaded: boolean | null;
  message: string | null;
  data: { components:boolean, contents:Array<Content>|null, message: string|null , status: number|null } | null
}



class MyComponent  extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    
    this.state = {
      error:null,
      isLoaded:false,
      message:null,
      data:null
    };
  }
  componentDidMount() {
    let h = this.props.externalId
    fetch(  `${process.env.REACT_APP_API_URL!}`.concat("external/mediaFromExternalLink?externalId="+h) )
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            data: result
          });
        },
        (error) => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      )
  }

  render() {

  let { error, isLoaded, data/*, message*/ } = this.state;
    
    if (error) {
      console.log('error___', error)
      return null;
    
    } else {
        
        if (data) {
          console.log('DATA____', data)
          console.log('isLoaded__', isLoaded)

          if(data!.status == 500){

            return(
              <ErrorWrapper className={`wrapper-entered`}>
                  <Typography 
                       style={{ margin: '0px', padding: '0px',  fontSize: '15', textAlign: 'center', width: '100%'}} 
                      variantName="error">{`OooOops, something went wrong ! ${data!.message}` }
                  </Typography>
              </ErrorWrapper>
            )
          }
          else{

      
            if(data!.components){
             
              /*let contents=[
                {
                  title: 'tes1XXXXXXXXXXXXXfgafgaaflakfnasklfhalfaslgf',
                  itemType: "INSTAGRAM" ,
                  src: 'https://media-manager-files-prod.s3.eu-west-1.amazonaws.com/bf9c6c81-c5a1-4cf6-bdab-11433dde4162',
                  mimeType: 'image/jpeg',
                  loading: false,
                },{
                  title: 'test2afaugfajfiughajkfgafaflajflasjhflajsf',
                  itemType: "INSTAGRAM",
                  src: "https://media-manager-files-prod.s3.eu-west-1.amazonaws.com/eb585e82-da0e-43cb-8644-484e3fc201ce",
                  mimeType: 'image/jpeg',
                  loading: false,
                },{
                  title: 'test3faojfhajfhoahfajfhochjgasjhdfgasjhdfgasjhfgas',
                  itemType: "INSTAGRAM",
                  src: 'https://media-manager-files-prod.s3.eu-west-1.amazonaws.com/f59e977a-7a13-45c3-836d-2564f8d3fcda',
                  mimeType: 'video/mp4',
                  loading: false,
                }]*/

            
              return (

                <MyGallery contents={data!.contents}/>
              )
             
            }
            else{
              let cont = data!.contents![0] 
              return (
                <ExternalPreviewPage
                  loading={!isLoaded}
                  title={ cont ? cont.title : ""}
                  src={ cont ? cont.storagePath : ""}
                  mimeType={cont ? cont.mimeType : ""}
                  itemType={ cont ? cont.itemType as ItemType : null}
                />
              );
            }
            
          }
        }
      ;
    }


    return null
  }
}

export { MyComponent };
//{isCarousel:false, contents: [{itemType:string, mimeType:string, title:string, loading:boolean, storagePath:string}], status:null, message: ""}