import * as React from 'react';

import Dropzone from '../components/layout/Dropzone';
import SearchMediaVariables from '../types/types2.0/SearchMediaVariables';
import { FilterContext } from '../providers/FilterProvider2.0';

const WithDropzone = (
  onUpload: (variables: SearchMediaVariables) => (files: Array<File>) => any,
  Component: React.ComponentType<any> | React.ReactElement<any>
) => (
  <FilterContext.Consumer>
    {({ getQueryVariables }) => {
      const variables = getQueryVariables();

      return (
        <>
          <Dropzone enqueue={onUpload(variables)}>
            {Component instanceof Function ? <Component /> : Component}
          </Dropzone>
        </>
      );
    }}
  </FilterContext.Consumer>
);

export { WithDropzone };
