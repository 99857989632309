import * as React from 'react';
import { Mutation } from 'react-apollo';

import { UPDATE_LOCAL_CONTENT } from '../../../apollo/mutation/mutation2.0/UpdateLocalContent';
import { LocalContent } from '../../../types/types2.0/LocalContent';
import { CustomMutationProps } from '../../../types/graphql/utils';

class TypedMutation extends Mutation<{ updateLocalContent: LocalContent }> {}

export const UpdateLocalContentMutation: React.FC<
  CustomMutationProps<{ updateLocalContent: LocalContent }>
> = ({ children, ...others }) => {
  return (
    <TypedMutation  mutation={UPDATE_LOCAL_CONTENT} {...others}>
      {(mutation, result) => {
      
        return children(mutation, result);
      }}
    </TypedMutation>
  );
};
