import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from '@freeda/react-components';

import { zIndex } from '../../utils/zIndex';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(91, 24, 190, 0.5);
  z-index: ${zIndex.Spinnerone};
`;

const Spinnerone: React.FC = () => (
  <Wrapper>
    <Spinner size="large" />
  </Wrapper>
);

export { Spinnerone };
