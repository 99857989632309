import gql from 'graphql-tag';

const FILTER_DATA = gql`
  query filterData {
    filterData {
      brandCategories {
        name
        brand {
          id
          name
          brand_category
        }
      }
      

      productTypes {
        id
        name
        format
      }
      
     
      topics {
        id
        name
        macroTopic
      }

      genres {
        id
        name
       
      }

      objectives {
        id
        name
       
      }

      temperatures {
        id
        name
       
      }

     
      teamMembers {
          id
          name
      }
      contributors  {
        id
        name
      }

      projects {
        id,
        name,
        product { 
          id, 
          name 
        }
        series { 
          id, 
          name 
        }
        editorialCampaigns { 
          id, 
          name 
        }
        campaigns { 
          id, 
          name, 
          brand { 
            id , 
            name , 
            brand_category 
          } 
        }
      }

    
    }
  }
`;

export { FILTER_DATA };
/**type FilterDataLabels {
    id: ID
    name: String
    product: [ProductDao]
    series: [SerieDao]
    editorialCampaigns: [CampaignDao]
    campaigns: [CampaignDao]
}
 */

/**
 * projects {
      id,
      name,
      product { 
        id, 
        name 
      }
      series { 
        id, 
        name 
      }
      editorialCampaigns { 
        id, 
        name 
      }
      campaigns { 
        id, 
        name, 
        brand { 
          id , 
          name , 
          brand_category 
        } 
      }
    }
 * 
 */
